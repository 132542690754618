import React from 'react';
import Alert from '../../Alert';
import {
  getDayValues,
  menusForDayPerType,
  getNoDelivery,
  getSubscriptionForDate,
  addChoice,
} from '../../../utils/lunchOverviewUtils';
import SubscriptionService from '../../../services/SubscriptionService';
import {
  Day as DayOrder,
  getSumForDaySingleOrder,
  WeekTotal,
} from '../../MyLunchCalendar';
import WeekPicker from '../../WeekPicker';

import styles from './lunch.module.css';

class MenuPicker extends React.Component {
  getValuesForDay(date) {
    return getDayValues(
      date,
      this.props.singleOrders ? this.props.singleOrders : [],
      this.props.subscriptionEntries,
      this.props.valuesPerDay[date.format('DD-MM-YYYY')] || [],
      this.props.subscriptionTypes,
      this.props.orders ? this.props.orders : [],
      this.props.admin
    );
  }

  onChange(date, changes) {
    this.props.updateState({
      valuesPerDay: {
        ...this.props.valuesPerDay,
        [date.format('DD-MM-YYYY')]: changes.reduce(
          (values, change) => addChoice(values, change),
          this.getValuesForDay(date)
        ),
      },
    });
  }

  onCancelOrder = (date, clb) => {
    this.props.updateState(
      {
        valuesPerDay: {
          ...this.props.valuesPerDay,
          [date.format('DD-MM-YYYY')]: [],
        },
      },
      () => this.props.updateSingleOrder(date, []).then(clb)
    );
  };

  getWeekTotal() {
    return [...Array(5)]
      .map((x, i) =>
        this.props.week
          .clone()
          .startOf('isoWeek')
          .add(i, 'day')
      )
      .reduce((total, date) => {
        const subscriptionForDay = getSubscriptionForDate(
          date,
          this.props.subscriptionEntries
        );
        return (
          total +
          (getNoDelivery(date, this.props.noDeliveryDays) || !subscriptionForDay
            ? 0
            : getSumForDaySingleOrder(
              null,
              this.props.subscriptionTypes,
              this.props.companyAgreement,
              this.getValuesForDay(date)
            ))
        );
      }, 0);
  }

  render() {
    if (
      !this.props.subscriptionEntries ||
      this.props.subscriptionEntries.length === 0
    ) {
      return <Alert>Du har ingen avtale.</Alert>;
    }

    return (
      <div>
        <div className={styles.menuPicker}>
          {[...Array(this.props.weekend ? 7 : 5)]
            .map((x, i) =>
              this.props.week
                .clone()
                .startOf('isoWeek')
                .add(i, 'day')
            )
            .slice(this.props.weekend ? 5 : 0)
            .map(date => {
              const subscriptionForDate = getSubscriptionForDate(
                date,
                this.props.subscriptionEntries
              );
              return (
                <DayOrder
                  key={date.format()}
                  day={date}
                  subscription={subscriptionForDate}
                  subscriptionTypes={this.props.subscriptionTypes}
                  subscriptionTypesCompanyAgreement={
                    this.props.subscriptionTypesCompanyAgreement
                  }
                  values={this.getValuesForDay(date)}
                  noDeliveryReason={getNoDelivery(
                    date,
                    this.props.noDeliveryDays
                  )}
                  onChange={values => this.onChange(date, values)}
                  onSave={() =>
                    this.props.updateSingleOrder(
                      date,
                      this.getValuesForDay(date)
                    )
                  }
                  onCancelOrder={clb => this.onCancelOrder(date, clb)}
                  menus={menusForDayPerType(date, this.props.menus)}
                  saving={this.props.saving[date.format('DD-MM-YYYY')]}
                  success={this.props.success[date.format('DD-MM-YYYY')]}
                  history={this.props.history}
                  companyAgreement={this.props.companyAgreement}
                  deliveryLocation={
                    this.props.companyAgreement.deliveryLocation
                  }
                  hasActiveSubscription={!!subscriptionForDate}
                  hasFullWeekDiscount={SubscriptionService.hasFullWeekDiscount(
                    subscriptionForDate
                  )}
                  admin={this.props.admin}
                  enableAllergySelection
                  subscriptionTypeUser="company"
                  disableSaving={
                    !this.props.valuesPerDay[date.format('DD-MM-YYYY')]
                  }
                  company={this.props.company}
                />
              );
            })}
          <WeekTotal
            total={{
              customer: this.getWeekTotal(),
              company: 0,
            }}
          />
        </div>
        <WeekPicker
          week={this.props.week}
          onChange={week => this.props.onWeekChange(week)}
          showWeeks={6}
        />
      </div>
    );
  }
}

export default MenuPicker;
