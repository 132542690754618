import React from 'react';

export default ({ name, number, expiry }) => (
  <div>
    <div>{number}</div>
    <div>
      <strong>Utgår: </strong> {expiry}
    </div>
  </div>
);
