import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_START:
      return {
        ...state,
        meta: {
          loading: true,
          error: false,
          loadedAt: null,
        },
      };
    case types.GET_ALL_SUCCESS:
      return {
        ...state,
        entities: {
          ...action.payload.notificationDefinitions.reduce(
            (object, notificationDefinition) => {
              object[notificationDefinition.id] = notificationDefinition;
              return object;
            },
            {}
          ),
        },
        meta: {
          loading: false,
          error: false,
          loadedAt: moment().unix(),
        },
      };
    case types.GET_ALL_ERROR:
      return {
        ...state,
        meta: {
          loading: false,
          error: true,
          loadedAt: moment().unix(),
        },
      };
    default:
      return state;
  }
};
