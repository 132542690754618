import React from 'react';
import Card from '../Card';

import styles from './modal.module.css';
import cn from 'classnames';

class Modal extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.open)
      document.querySelector('body').classList.add('modal-opened');
    if (!nextProps.open)
      document.querySelector('body').classList.remove('modal-opened');
  }

  componentWillUnmount() {
    document.querySelector('body').classList.remove('modal-opened');
  }

  render() {
    if (!this.props.open) {
      return <span />;
    }

    return (
      <div className={styles.overlay}>
        <Card rounded className={styles.modal}>
          <div>
            {this.props.onClose || this.props.header ? (
              <div className={styles.header}>
                <div>{this.props.header ? this.props.header : ''}</div>
                {this.props.onClose ? (
                  <div
                    className={styles.closeWrapper}
                    onClick={this.props.onClose}
                  >
                    <div className={cn(styles.close)} />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div>{this.props.children}</div>
          </div>
        </Card>
      </div>
    );
  }
}

Modal.defaultProps = {
  onClose: null,
};

export default Modal;
