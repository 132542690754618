import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header';
import Container from '../../components/Container';
import Card from '../../components/Card';
import Link from '../../components/Link';

import '../../styles/components/about.scss';

export default () => (
  <div id="about" className="section">
    <Helmet>
      <title>Lunsjkollektivet - Om oss</title>
    </Helmet>
    <Header />
    <Container color="beige" withPadding>
      <div className="content container">
        <h1 className="center">Du fortjener god mat på jobben også</h1>
        <p className="center abstract">
          <strong>Lunsjkollektivet</strong> ble til over en god lunsj i hagen
          etter en lang diskusjon om dårlig mat på jobben. Praten gikk rundt
          dyre og kjedelige kantiner, usunne vaner fra ferdigmatdisken, svett
          hvitost, lite variasjon, kjøleskap som stinker og rot og griseri på
          spiserommet. Burde det ikke være mulig å få servert en skikkelig digg
          og variert lunsj laget av gode råvarer – hver dag?
        </p>
        <p className="center paragraph">
          Vi forfulgte ideen og fikk med oss noen matglade arbeidsfolk:
          Ernæringsfysiolog og kokebokforfatter Hanna, UX-designer og
          drivhus-entusiast Are, utvikleren Ove, og en haug med andre hyggelige
          matnerder.
        </p>
        <p className="center paragraph">
          Hos oss får du sunn og god mat laget fra bunnen av. Vi er også opptatt
          av miljø og har produsert vår egne lunsjbokser av resirkulert papp, og
          unngår plast så godt det tar seg gjøre. Fordi vi kun leverer når du er
          på jobb, blir matsvinnet mindre. Og sist men ikke minst. Maten vår er
          sunn og bra for kroppen din. Næringsrik og god, hver eneste dag. Der
          har du Lunsjkollektivet!
        </p>
      </div>
    </Container>
    <Container width={800} className="deliveryLocationsHeader">
      <div className="deliveryLocationsHeaderContent">
        <div>
          <strong>Se også: </strong>
        </div>
        <div>
          <Link to="/filosofi">Vår filosofi</Link>
        </div>
      </div>
    </Container>
    <div className="background">
      <Container withPadding width={800}>
        <Card rounded withPadding>
          <h1>Kollektivet</h1>
          <div className="profiles">
            <div className="content container">
              <div className="profile">
                <img
                  src="/images/profiles/maria.jpg"
                  alt="Maria Mørseth"
                  className="circle-image"
                />
                <h4>Maria Mørseth</h4>
                <p className="profile-title">Gründer / daglig leder</p>
                <div className="profile-email">
                  <a href="mailto:maria@lunsjkollektivet.no">
                    maria@lunsjkollektivet.no
                  </a>
                </div>
                <p className="profile-text">
                  Maria har bakgrunn som rådgiver innen ledelse og overtok nylig
                  familiegården Mørset Nordre i Stjørdal. Maria har også
                  skogsgriser og vikinghøns til eget bruk og en etterhvert
                  ganske så omfattende kjøkkenhage.
                </p>
              </div>
              <div className="profile">
                <img
                  src="/images/profiles/hanna.jpg"
                  alt="Hanna Fjeldheim Dale"
                  className="circle-image"
                />
                <h4>Hanna Fjeldheim Dale</h4>
                <p className="profile-title">Gründer / ernæringsfysiolog</p>
                <div className="profile-email">
                  <a href="mailto:hanna@lunsjkollektivet.no">
                    hanna@lunsjkollektivet.no
                  </a>
                </div>
                <p className="profile-text">
                  Hanna er utdannet klinisk ernæringsfysiolog, og har en
                  doktorgrad i ernæring. Hanna er opptatt av at maten skal være
                  bra for kroppen din, og vil gjerne hjelpe deg å spise mer
                  grønnsaker. Hun driver matbloggen{' '}
                  <a
                    href="http://www.happyfoodstories.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    happyfoodstories.com
                  </a>
                  , og ga i 2016 ut kokeboken REN, GLAD MAT.
                </p>
              </div>
              <div className="profile">
                <img
                  src="/images/profiles/ove.jpg"
                  alt="Ove Andersen"
                  className="circle-image"
                />
                <h4>Ove Andersen</h4>
                <p className="profile-title">Gründer / utvikler</p>
                <div className="profile-email">
                  <a href="mailto:ove@lunsjkollektivet.no">
                    ove@lunsjkollektivet.no
                  </a>
                </div>
                <p className="profile-text">
                  Ove bruker mesteparten av tiden sin på å programmere gode
                  løsninger for folk, gjerne akkompagnert av en kopp god kaffe.
                  Ove er også en ivrig hobbybaker og sier aldri nei takk til
                  ferske boller. Med kaffe, selvsagt.
                </p>
              </div>
              <div className="profile">
                <img
                  src="/images/profiles/christina.jpg"
                  alt="Christina Tviberg Pettersen"
                  className="circle-image"
                />
                <h4>Christina Tviberg Pettersen</h4>
                <p className="profile-title">Driftssjef</p>
                <div className="profile-email">
                  <a href="mailto:christina@lunsjkollektivet.no">
                    christina@lunsjkollektivet.no
                  </a>
                </div>
                <p className="profile-text">
                  Christina er ansvarlig for at alt fungerer som det skal i
                  Lunsjkollektivet og er kollektivets husfotograf. Hun har
                  jobbet som barista i en årrekke, drevet sin egen kafé, og er
                  en skikkelig mat- og kaffenerd.
                </p>
              </div>
              <div className="profile">
                <img
                  src="/images/profiles/henrik.jpg"
                  alt="Henrik Haanæs"
                  className="circle-image"
                />
                <h4>Henrik Haanæs</h4>
                <p className="profile-title">Salgssjef</p>
                <div className="profile-email">
                  <a href="mailto:henrik@lunsjkollektivet.no">
                    henrik@lunsjkollektivet.no
                  </a>
                </div>
                <p className="profile-text">
                  Henrik er salgssjef, og dukker gjerne opp med lunsj til deg i
                  både Trondheim, Oslo og Bergen. Utover å være over
                  gjennomsnittet interessert i tall, salg og strategi, er Henrik
                  alltid i godt humør når han får seg en god tur på ski i marka.
                  På matfronten er selvskutt viltkjøtt det beste Henrik vet.
                </p>
              </div>
              <div className="profile">
                <img
                  src="/images/profiles/jonas.jpg"
                  alt="Jonas Sæther"
                  className="circle-image"
                />
                <h4>Jonas Sæther</h4>
                <p className="profile-title">Utvikler / student</p>
                <div className="profile-email">
                  <a href="mailto:jonas@lunsjkollektivet.no">
                    jonas@lunsjkollektivet.no
                  </a>
                </div>
                <p className="profile-text">
                  Jonas er glad i å programmere ting som er nyttig. Når han
                  jobber på nettløsningen vår, er det nesten alltid med høy
                  musikk på øret. Som datateknologi-student lever han for å
                  lære, og har fått et øye opp for UX og design.
                </p>
              </div>
              <div className="profile">
                <img
                  src="/images/profiles/ellen.jpg"
                  alt="Ellen Marie Hole"
                  className="circle-image"
                />
                <h4>Ellen Marie Hole</h4>
                <div className="profile-email">
                  <a href="mailto:ellen@lunsjkollektivet.no">
                    ellen@lunsjkollektivet.no
                  </a>
                </div>
                <p className="profile-text">
                  Ellen har ansvaret for at kundene våre har det braog får svar
                  på alt de lurer på, og at alt går som det skal fra dag til dag
                  i kollektivet. Ellen har tidligere jobbet mange år i Too Good
                  To Go, og utdannelse i folkehelse. Hun har stålkontroll på det
                  aller meste, trives best i skogen med joggeskoene på, og
                  kommer som regel syklende til jobb. Hun er selvfølgelig, som
                  resten av gjengen, over gjennomsnittet opptatt av mat, og
                  skikkelig god på matredding!
                </p>
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </div>
  </div>
);
