import selectMeta from '../selectMeta';

export default (state, companyAgreementId, week) => {
  const startDate = week.clone().startOf('week');
  const endDate = week.clone().endOf('week');

  const key = startDate.format() + endDate.format() + companyAgreementId;

  return selectMeta(state.singleOrder.meta[key]);
};
