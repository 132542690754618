import addPrefix from '../../utils/addPrefix';

export default addPrefix('subscription', [
  'GET_FOR_CUSTOMER_START',
  'GET_FOR_CUSTOMER_SUCCESS',
  'GET_FOR_CUSTOMER_ERROR',
  'GET_ALL_FOR_CUSTOMER_START',
  'GET_ALL_FOR_CUSTOMER_SUCCESS',
  'GET_ALL_FOR_CUSTOMER_ERROR',
  'GET_FOR_COMPANYAGREEMENT_CUSTOMERS_START',
  'GET_FOR_COMPANYAGREEMENT_CUSTOMERS_SUCCESS',
  'GET_FOR_COMPANYAGREEMENT_CUSTOMERS_ERROR',
  'UPDATE_FOR_CUSTOMER_START',
  'UPDATE_FOR_CUSTOMER_SUCCESS',
  'UPDATE_FOR_CUSTOMER_ERROR',
  'CREATE_FOR_CUSTOMER_START',
  'CREATE_FOR_CUSTOMER_SUCCESS',
  'CREATE_FOR_CUSTOMER_ERROR',
  'DELETE_FOR_CUSTOMER_START',
  'DELETE_FOR_CUSTOMER_SUCCESS',
  'DELETE_FOR_CUSTOMER_ERROR',
  'DEACTIVATE_START',
  'DEACTIVATE_SUCCESS',
  'DEACTIVATE_ERROR',
  'PAUSE_FOR_CUSTOMER_START',
  'PAUSE_FOR_CUSTOMER_SUCCESS',
  'PAUSE_FOR_CUSTOMER_ERROR',
  'UNPAUSE_FOR_CUSTOMER_START',
  'UNPAUSE_FOR_CUSTOMER_SUCCESS',
  'UNPAUSE_FOR_CUSTOMER_ERROR',
  'CREATE_FOR_CUSTOMERS_START',
  'CREATE_FOR_CUSTOMERS_SUCCESS',
  'CREATE_FOR_CUSTOMERS_ERROR',
  'DEACTIVATE_FOR_CUSTOMERS_START',
  'DEACTIVATE_FOR_CUSTOMERS_SUCCESS',
  'DEACTIVATE_FOR_CUSTOMERS_ERROR',
  'GET_MULTIPLE_ACTIVE_START',
  'GET_MULTIPLE_ACTIVE_SUCCESS',
  'GET_MULTIPLE_ACTIVE_ERROR',
  'DELETE_MULTIPLE_ACTIVE_START',
  'DELETE_MULTIPLE_ACTIVE_SUCCESS',
  'DELETE_MULTIPLE_ACTIVE_ERROR'
]);
