import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_START:
      return {
        ...state,
        meta: {
          loading: true,
          error: false,
          loadedAt: null,
        },
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        meta: {
          loading: false,
          error: false,
          loadedAt: moment().unix(),
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        meta: {
          loading: false,
          error: true,
          loadedAt: moment().unix(),
        },
      };
    default:
      return state;
  }
};
