import React from 'react';
import cn from 'classnames';

import styles from './dropdown.module.css';

export default props => {
  const Row = props.rowComponent;
  return (
    <div
      className={cn(styles.dropDown, {
        [styles.show]: props.focused,
        [styles.above]: props.above,
      })}
    >
      {props.entities.length === 0 ? (
        <div className={styles.dropDownItem}>
          <div>
            <strong>Vi fant ingenting i databasen.</strong>
          </div>
        </div>
      ) : (
        ''
      )}
      {props.entities.map((entity, i) => (
        <div
          className={styles.dropDownItem}
          key={i}
          onClick={e => {
            props.changeFocus(false);
            props.onChange(entity);
            e.stopPropagation();
          }}
        >
          <Row entity={entity} index={i} />
        </div>
      ))}
    </div>
  );
};
