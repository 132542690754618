import React from 'react';
import PropTypes from 'prop-types';
import styles from './bootstrap-grid.module.css';
import cn from 'classnames';

const Col = props => (
  <div
    className={cn(props.className, {
      [styles.col]: !props.xs && !props.s && !props.m && !props.l && !props.xl,
      [styles['col-' + props.xs]]: props.xs,
      [styles['col-sm-' + props.s]]: props.s,
      [styles['col-md-' + props.m]]: props.m,
    })}
    style={props.style}
  >
    {props.children}
  </div>
);

Col.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  xs: PropTypes.number,
  s: PropTypes.number,
  m: PropTypes.number,
  l: PropTypes.number,
  xl: PropTypes.number,
};

export default Col;
