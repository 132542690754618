import React from 'react';
import cn from 'classnames';
import Link from '../../../components/Link';

import Container from '../../../components/Container';
import { Row, Col } from '../../../components/Grid';
import Header from '../../../components/Header';
import Button from '../../../components/Button';

import Steps from './Steps';

import styles from './home.module.css';

import matboksSvg from './assets/matboks.svg';
import mobilSvg from './assets/mobil.svg';
import macSvg from './assets/mac.svg';

class Home extends React.Component {
  render() {
    return (
      <div>
        <Header image="/images/winter/background.jpg" height={400} />
        <Container
          width={700}
          color="gray"
          style={{ padding: '10px 0 100px 0', position: 'relative' }}
        >
          <h1 className={styles.title}>Lunsjboks levert på jobb</h1>
          <h3 className="text-center" style={{ textTransform: 'none' }}>
            Verv en venn!
            <br />- få en gratis lunsj hver
          </h3>
        </Container>
        <Container className={styles.steps} width={700}>
          <Steps
            newLine
            iconBackground
            steps={[
              {
                img: macSvg,
                title: '1. Bli kunde',
                content: (
                  <div>
                    Bli kunde <Link to="/customer-signup">her</Link>
                  </div>
                ),
              },
              {
                img: mobilSvg,
                title: '2. Del vervekode',
                content:
                  'Finn din personlige vervekode på Min side og del med venner og gode kollegaer',
              },
              {
                img: matboksSvg,
                title: '3. Få en gratis lunsj',
                content:
                  'Få en gratis lunsj for hver person du verver* (den du verver får også en gratis lunsj) Nyt maten!',
              },
            ]}
          />
        </Container>
        <Container width={700} style={{ paddingBottom: 50 }}>
          <p className={styles.introText}>
            Allerede kunde? Finn vervekoden din <a href="https://my.lunsjkollektivet.no">her</a>{' '}
            <br />
            <i style={{ fontSize: '0.8em' }}>
              *gjelder ved bestilling av fast levering fem dager i uken
            </i>
          </p>
          <p className={styles.introText + ' mt-5'}>
            Smakfull og næringsrik lunsj, laget av kortreiste råvarer.
            <br />
            <strong>Levert til deg på jobben. Hver dag.</strong> Bra for
            kroppen, enkelt å avbestille eller endre og du betaler kun for de
            dagene du spiser.
          </p>
          <div className="text-center">
            <Button large to="/customer-signup">
              Kom i gang
            </Button>
          </div>
          <div className="text-center mt-5">
            <Link to="/bedrift">Bedrift?</Link>
          </div>
        </Container>
        <Container
          color="gray"
          width={800}
          className={styles.productsContainer}
        >
          <div className={styles.productCardsContainer}>
            <div className={styles.productCards}>
              <div>
                <div className={styles.card}>
                  <div
                    className={styles.cardImage}
                    style={{
                      backgroundImage: `url(${'/images/product/sl-1.jpg'})`,
                    }}
                  />
                  <div className={styles.cardTitle}>Salatlunsj</div>
                  <div>Friskt og sunt</div>
                  <div className="mt-5">
                    <Button
                      color="main-inverse"
                      to="/customer-signup?type=Salad"
                    >
                      Fra 69,-
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <div className={cn(styles.card, styles.big)}>
                  <div
                    className={cn(styles.cardImage, styles.big)}
                    style={{
                      backgroundImage: `url(${'/images/product/ka-1.jpg'})`,
                    }}
                  />
                  <div className={styles.cardTitle}>Variert</div>
                  <div>
                    Varierer mellom nybakt brødlunsj og frisk salatlunsj.
                  </div>
                  <div className="mt-5">
                    <Button to="/customer-signup?type=Recommended">
                      Fra 69,-
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.card}>
                  <div
                    className={styles.cardImage}
                    style={{
                      backgroundImage: `url(${'/images/product/bl-1.jpg'})`,
                    }}
                  />
                  <div className={styles.cardTitle}>Brødlunsj</div>
                  <div>Nybakt og variert</div>
                  <div className="mt-5">
                    <Button
                      to="/customer-signup?type=Bread"
                      color="main-inverse"
                    >
                      Fra 69,-
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center mt-5">
            Prisen er 69 kr inkl. mva. og levering når du har fast bestilling
            fem dager i uken. Du kan selvsagt avbestille så mye du vil uten at
            det endrer prisen. For enkeltbestillinger er prisen 89,-.
          </p>
          <p className="text-center mt-5">
            Oppgi allergier i profilen din, så tilpasser vi leveransen.
          </p>
        </Container>
        <Container withPadding width={1000}>
          <Row>
            <Col className="text-center" s={6}>
              <h3 style={{ marginBottom: 10 }}>Møtemat</h3>
              <div>God lunsj til de du har invitert på møte</div>
              <Link to="/motemat" className="mt-5">
                les mer og bestill
              </Link>
            </Col>
            <Col className="text-center" s={6}>
              <h3 style={{ marginBottom: 10 }}>Bedrift</h3>
              <div>God lunsj til dine ansatte</div>
              <Link to="/bedrift" className="mt-5">
                les mer og bestill
              </Link>
            </Col>
          </Row>
        </Container>
        <Container withPadding color="gray">
          <div className={styles.textGroup}>
            <h3>Ny lunsjmeny hver uke</h3>
            <p>
              Du går aldri lei maten og styrer alt selv enkelt via nettsiden:
              bestill, avbestill, eller sett oss på pause i ferien.{' '}
              <Link to="/lunsj">Les mer</Link>
            </p>
          </div>
          <div className={styles.textGroup}>
            <h3>Lunsjkollektivet om ernæring</h3>
            <p>
              Vi er like opptatt av at du skal få i deg mat som er ordentlig bra
              for kroppen din som at den er variert og smaker skikkelig godt.{' '}
              <Link to="/filosofi">Les mer</Link>.
            </p>
            <p>Huk av eventuelle allergier i profilen, vi ordner resten.</p>
          </div>
          <div className={styles.textGroup}>
            <h3>Enkelt og trivelig for både sjef og ansatt</h3>
            <p>
              Vi garanterer sunne og gode, næringsrike lunsjer levert til rett
              tid hver dag. Her er ingen kantinekostnader. Hver og en håndterer
              sin egen lunsjavtale – ingen administrasjon, og vi tar gjerne
              jobben med en eventuell fordeling av lunsjkostnaden mellom bedrift
              og ansatt.
            </p>
            <p>
              Så, er det i hele tatt noen bakdeler med å få levert lunsj på
              jobben av Lunsjkollektivet? Nei, ikke som vi kan komme på.
            </p>
          </div>
        </Container>
      </div>
    );
  }
}
export default Home;
