import React, { useState, useEffect } from 'react';
import Container from '../../../../../../../components/Container';
import Button, { BackButton } from '../../../../../../../components/Button';
import Card, { Actions } from '../../../../../../../components/Card';
import CompanyAgreementHoc from '../../components/CompanyAgreementHoc';
import DiscountPicker from '../../../../../../../components/DiscountPicker';
import Alert from '../../../../../../../components/Alert';
import PropTypes from 'prop-types';

import CompanyAgreementStartDate from '../../../../../../../components/CompanySettings/CompanyAgreementStartDate/CompanyAgreementStartDate';

const Setup = props => {
  const [amount, setAmount] = useState(100);
  const [type, setType] = useState('percent');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const companyAgreement = props.companyAgreement
      ? props.companyAgreement
      : {};

    setType(companyAgreement.discountType || 'percent');
    setAmount(
      companyAgreement.employeeDiscount >= 0
        ? companyAgreement.employeeDiscount
        : 100
    );
  }, [props.companyAgreement]);

  const validateInput = (amount, type) => {
    let valid = true;
    let error = null;
    if (amount < 0) {
      valid = false;
      error = 'Spons kan ikke være mindre enn 0';
    }
    if (type === 'percent' && amount > 100) {
      valid = false;
      error = 'Spons kan ikke være større enn 100%';
    }
    return [valid, error];
  };

  const save = () => {
    setSaving(true);
    const [valid, inputError] = validateInput(amount, type);
    setError(inputError);
    
    if (valid) {
      props
        .updateCompanyAgreement(props.companyAgreement.id, {
          discountAmount: amount,
          discountType: type,
        })
        .catch(() => {
          setError(
            'Noe skjedde. Vennligst prøv igjen senere eller kontakt kundesupport.'
          );
        })
        .finally(() => {
          setSaving(false);
        });
    }
    else {
      setSaving(false);
    }
  };

  return (
    <Container withPadding color="gray" width={800}>
      <BackButton to="https://company.lunsjkollektivet.no" />
      <Card marginTop header="Sponsing av ansatte" headerExtraLarge>
        <p>
          Bedriften kan velge å betale hele eller deler av de ansattes
          lunsjbestillinger.
        </p>
        <div>
          <strong>Bedriften sponser ansatte med</strong>
          <DiscountPicker
            onChange={(type, amount) => {
              setAmount(amount);
              setType(type);
            }}
            type={type}
            amount={amount}
          />
          <Actions>
            <Button
              onClick={save}
              saving={saving}
              disabled={saving || isNaN(amount)}
            >
              Lagre
            </Button>
          </Actions>
        </div>
        {error ? (
          <Alert color="red" marginBottom>
            {error}
          </Alert>
        ) : null}
      </Card>

      <CompanyAgreementStartDate
        companyAgreement={props.companyAgreement}
        updateCompanyAgreement={props.updateCompanyAgreement}
      />
    </Container>
  );
};

Setup.propTypes = {
  companyAgreement: PropTypes.object,
  updateCompanyAgreement: PropTypes.func,
};

export default () => <CompanyAgreementHoc children={Setup} />;
