import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { withRouter, matchPath } from 'react-router-dom';
import isAdminForCompanyAgreement from '../../selectors/isAdminForCompanyAgreement';
import isCompanyAdmin from '../../selectors/me/isCompanyAdmin';
import CustomLink from '../CustomLink';
import Spinner from '../Spinner';
import Logo from './Logo';
import LoginIcon from './LoginIcon';

import MinSideButton from './MinSideButton';

import styles from './menu.module.scss';

const headerLink = (props, text, to, exact, i) => (
  <div
    key={i}
    className={cn(styles.linkWrapper, {
      [styles.active]: matchPath(props.location.pathname, {
        path: to,
        exact,
      }),
    })}
  >
    <CustomLink
      className={styles.link}
      to={to}
      key={to}
      onClick={() => {
        window.dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'Menu',
          eventAction: 'click',
          eventLabel: text,
        });
      }}
    >
      <span>{text}</span>
    </CustomLink>
  </div>
);

class Header extends React.Component {
  state = { mobileOpened: false, hamburger: React.createRef() };

  componentDidMount() {
    document.body.addEventListener('click', this.windowClicked.bind(this));
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.windowClicked.bind(this));
  }

  windowClicked(e) {
    if (
      this.state.mobileOpened &&
      this.node &&
      !this.node.contains(e.target) &&
      !this.state.hamburger.current.contains(e.target)
    ) {
      this.setState({ mobileOpened: false });
    }
  }

  render() {
    let alwaysVisibleLinks = [];

    if (!this.props.isAuthenticated) {
      alwaysVisibleLinks.push({
        text: 'Lunsjmeny',
        to: 'https://my.lunsjkollektivet.no/',
      });
      alwaysVisibleLinks.push({
        text: 'Slik virker det',
        to: '/company',
        exact: true,
      });
      alwaysVisibleLinks.push({
        text: 'Blogg',
        to: '/blog',
        exact: true,
      });
      alwaysVisibleLinks.push({
        text: 'Om oss',
        to: '/om',
        exact: true,
      });
    }

    return (
      <div
        className={cn(styles.menu, {
          [styles.transparent]:
            this.props.transparent ||
            (this.props.isAuthenticated && !this.state.mobileOpened),
          [styles.noShadow]: this.props.noShadow,
          [styles.show]: this.state.mobileOpened,
          [styles.withOptions]:
            this.props.isAuthenticated &&
            this.props.isAdminForCompanyAgreement &&
            !(this.props.admin || this.props.chef),
        })}
      >
        <Logo onlyMobile />
        <div
          className={styles.hamburger}
          ref={this.state.hamburger}
          onClick={() =>
            this.setState({
              mobileOpened: !this.state.mobileOpened,
            })
          }
        >
          <div>
            <i className="fa fa-bars" />
          </div>
        </div>
        {!this.props.isAuthenticated && (
          <CustomLink
            className={styles.loginIcon}
            to={'/login'}
            onClick={() => {
              window.dataLayer.push({
                event: 'GAEvent',
                eventCategory: 'Menu',
                eventAction: 'click',
                eventLabel: 'Logg inn',
              });
            }}
          >
            <div>
              {!this.props.loadingUser ? <LoginIcon /> : <Spinner black />}
            </div>
          </CustomLink>
        )}
        {this.props.picker && (
          <div className={styles.pickerWrapper}>
            <div className={styles.picker} onClick={e => e.stopPropagation()}>
              <this.props.picker />
            </div>
          </div>
        )}
        <div
          className={cn(styles.content, {
            [styles.show]: this.state.mobileOpened,
          })}
          ref={node => {
            this.node = node;
          }}
        >
          {!this.state.mobileOpened && <Logo />}
          {alwaysVisibleLinks.map((link, i) =>
            headerLink(this.props, link.text, link.to, link.exact, i)
          )}
          {this.props.isAuthenticated ? (
            <MinSideButton
              transparent={this.props.transparent}
              admin={this.props.admin}
              chef={this.props.chef}
            />
          ) : (
            !this.state.mobileOpened &&
            (this.props.loadingUser ? (
              <div className={styles.loadingUser}>
                <CustomLink className={styles.loadingButton}>
                  <Spinner />
                  <div style={{ marginLeft: '10px' }}>Logger inn...</div>
                </CustomLink>
              </div>
            ) : (
              <div>
                <CustomLink
                  className={styles.loginButton}
                  to={'/login'}
                  onClick={() => {
                    window.dataLayer.push({
                      event: 'GAEvent',
                      eventCategory: 'Menu',
                      eventAction: 'click',
                      eventLabel: 'Logg inn',
                    });
                  }}
                >
                  <span>Logg inn</span>
                </CustomLink>
              </div>
            ))
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingUser: state.me.loading,
    isAuthenticated: state.me.isAuthenticated,
    isAdminForCompanyAgreement: isAdminForCompanyAgreement(state),
    isCompanyAdmin: isCompanyAdmin(state),
  };
}

export default withRouter(connect(mapStateToProps, {})(Header));
