import React from 'react';
import { connect } from 'react-redux';

import { setSelectedCompanyAgreement } from '../../../../../stores/me/actions';
import { selectedCompanyAgreement } from '../../../../../stores/me/selectors';

import agreementsWhereUserIsAdmin from '../../../../../selectors/me/agreementsWhereUserIsAdmin';

import Tabs from '../../../../../components/Tabs';

import styles from './CompanyPageHeader.module.css';

const getText = entity => {
  return entity && entity.company && entity.company ? entity.company.name : '';
};

class CompanyPageHeader extends React.Component {
  render() {
    return (
      <div className={styles.picker}>
        <div className={styles.companyInfo}>
          <div className={styles.type}>Bedriftsprofil</div>
          <div className={styles.title}>
            {getText(this.props.selectedCompanyAgreement)}
          </div>
        </div>
        {this.props.children}
        {this.props.showTabs ? (
          <Tabs
            tabs={[
              {
                to: 'https://company.lunsjkollektivet.no',
                text: 'Lunsjkalender',
                exact: false,
              },
              {
                to: 'https://company.lunsjkollektivet.no/settings',
                text: 'Innstillinger',
                exact: true,
              },
              {
                to: 'https://company.lunsjkollektivet.no/settings',
                text: 'Fast levering',
                exact: true,
              }
            ]}
            keepSearchQuery={false}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  agreementsWhereUserIsAdmin: agreementsWhereUserIsAdmin(state),
  selectedCompanyAgreement: selectedCompanyAgreement(state),
});

export default connect(mapStateToProps, {
  setSelectedCompanyAgreement,
})(CompanyPageHeader);
