import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_ALL_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.credits.reduce((object, credit) => {
            object[credit.id] = credit;
            return object;
          }, {}),
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: action.payload.credits.map(so => so.id),
          },
        },
      };
    case types.GET_ALL_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.CREATE_SUCCESS:
      let newMeta = { ...state.meta };

      if (
        newMeta[action.payload.key] &&
        newMeta[action.payload.key].ids.indexOf(action.payload.credit.id) === -1
      ) {
        newMeta[action.payload.key] = {
          ...newMeta[action.payload.key],
          ids: [...newMeta[action.payload.key].ids, action.payload.credit.id],
        };
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.credit.id]: action.payload.credit,
        },
        meta: newMeta,
      };
    default:
      return state;
  }
};
