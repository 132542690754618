import React from 'react';
import cn from 'classnames';
import { Col } from '../../../../components/Grid';

import styles from './steps.module.css';

export default ({ step, size, newLine, iconBackground = false }) => (
  <Col
    xs={newLine ? undefined : size}
    m={newLine ? size : undefined}
    className={styles.step}
  >
    <div
      className={cn({
        [styles.imgBackground]: iconBackground,
      })}
    >
      <div className={styles.imgWrapper}>
        <img src={step.img} alt="test" className={styles.img} />
      </div>
    </div>
    <div className={styles.content}>
      <div className={styles.stepTitle}>{step.title}</div>
      <div>{step.content}</div>
    </div>
    {step.extraContent ? step.extraContent : ''}
  </Col>
);
