const getPriceWithSplit = (
  forCompanyInvoice,
  priceBeforeDiscount,
  companyAgreement
) => {
  if (!companyAgreement) return priceBeforeDiscount;

  let price = priceBeforeDiscount;
  const employeeDiscount = parseInt(companyAgreement.employeeDiscount, 10);

  if (companyAgreement.discountType === 'percent') {
    if (!forCompanyInvoice) {
      let discount = price * employeeDiscount / 100;
      price = price - discount;
    } else {
      let discount = price * (100 - employeeDiscount) / 100;
      price = price - discount;
    }
  } else if (companyAgreement.discountType === 'amount') {
    if (!forCompanyInvoice) {
      let discount = employeeDiscount;

      if (discount > price) discount = price;

      price = price - discount;
    } else {
      let discount = employeeDiscount;

      if (discount > price) discount = price;

      price = discount;
    }
  }

  return price;
};

export default (order, forCompanyInvoice = false) => {
  if (!order.details || order.details.length === 0) {
    return {
      date: order.date,
      details: [],
      totalPriceBeforeSplit: 0,
      orderId: order._id,
    };
  }

  let details = order.details
    .filter(detail => detail.menu && detail.amount > 0)
    .map(d => {
      const price = d.menu.price ? parseInt(d.menu.price, 10) : 0;
      const y = { ...d };
      delete y.menu;

      return {
        ...y,
        price,
      };
    });

  let item = {
    date: order.date,
    details: details,
    totalPriceBeforeSplit: 0,
    orderId: order._id,
  };

  details.forEach(orderDetail => {
    item.totalPriceBeforeSplit += orderDetail.price;
  });

  item.totalPriceAfterSplit = getPriceWithSplit(
    forCompanyInvoice,
    item.totalPriceBeforeSplit,
    order.companyAgreement
  );

  return item;
};
