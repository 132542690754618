import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import MinSideTitle from '../../../components/MinSideTitle';
import Header from '../../../components/Header';
import Button from '../../../components/Button';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import WeekLunchOverviewContainer from '../../../components/CustomerSettings/WeeklyLunchOverview/WeekLunchOverviewContainer';
import WeekOverview from './components/WeekOverview';
import selectedCustomer from '../../../selectors/me/selectedCustomer';
import ProfileOverview from './components/ProfileOverview';
import SanityHoc from '../../../hocs/SanityHoc';

/**
 * Subscription Store functions
 */
import { getForCustomerIfNeeded as getSubscriptionForCustomerIfNeeded } from '../../../stores/subscription/actions';
import {
  selectCustomerSubscription,
  selectCustomerSubscriptionMeta,
} from '../../../stores/subscription/selectors';

import cn from 'classnames';

const GET_UPDATES = `
*[_type == $type && date <= $date] | order(date desc) [0...1] {
  title,
  date,
  author->,
  post->,
  message
}
`;

function CustomerDashboard(props) {
  const updateBlock = (update, loading) => {
    if (!update?.length || loading) {
      return <div className={styles.update}></div>;
    }

    return (
      <div className={styles.update}>
        <div>
          <strong>{update[0].title ? update[0].title + ' - ' : ''}</strong>
          {update[0].message}
          <div className={styles.author}>
            <strong>
              {update[0].author
                ? ' - ' + update[0].author.name.replace(/ .*/, '')
                : ''}
            </strong>
          </div>
        </div>
      </div>
    );
  };
  
  this.props.history.push("https://my.lunsjkollektivet.no");

  const startOfWeek = moment()
    .startOf('isoWeek')
    .add(0, 'week');

  return (
    <div>
      <Header />
      <MinSideTitle />
      <div className={styles.wrapper}>
        <div className={styles.backgroundImage}>
          <div className={styles.myLunchBlock}>
            <div>
              <div className={styles.heading}>
                <div style={{ fontSize: '2em', fontWeight: 'bold' }}>
                  Min lunsj
                </div>
                <ProfileOverview customer={props.customer} />
              </div>
              <div>
                <SanityHoc
                  fetch={client =>
                    client.fetch(GET_UPDATES, {
                      type: 'update',
                      date: moment().format('YYYY-MM-DD'),
                    })
                  }
                >
                  {(loading, error, sanityData) =>
                    updateBlock(sanityData, loading || error)
                  }
                </SanityHoc>
              </div>
              <WeekLunchOverviewContainer
                week={startOfWeek}
                customerId={props.customer.id}
                showWeekPicker={false}
              >
                {props => (
                  <WeekOverview
                    subscription={props.subscription}
                    subscriptionTypes={props.subscriptionTypes}
                    subscriptionTypesCompanyAgreement={
                      props.subscriptionTypesCompanyAgreement
                    }
                    customer={props.customer}
                    menus={props.menus}
                    noDeliveryDays={props.noDeliveryDays}
                    choices={props.choices}
                    orders={props.orders}
                    ratings={props.ratings}
                    week={props.week}
                    valuesPerDay={props.valuesPerDay}
                    savingRating={props.savingRating}
                    onSubmitRating={props.saveRating}
                  />
                )}
              </WeekLunchOverviewContainer>
            </div>
            {props.subscription &&
            props.subscription.startDate &&
            (!props.subscription.endDate ||
              moment(props.subscription.endDate)
                .add(1, 'days')
                .isAfter(moment())) ? (
              <div className={styles.button}>
                <Button medium to="https://my.lunsjkollektivet.no">
                  <div>
                    Mine bestillinger{' '}
                    <div className={cn('fa', 'fa-arrow-right')} />
                  </div>
                </Button>
              </div>
            ) : null}
          </div>
        </div>
        {/* <div className={styles.extraBlockContainer}>
          <div className={styles.extraBlock}>
            <Link to={{pathname:"https://my.lunsjkollektivet.no"}}>
              <div style={{ display: 'flex' }}>
                <div>
                  <div
                    style={{
                      fontSize: '1.5em',
                      fontWeight: 'bold',
                    }}
                  >
                    Noe ekstra til lunsj?
                  </div>
                  <p>
                    Vi leverer også jus og ekstra brød til lunsjen, eller
                    kanskje et mellommåltid til senere? Se
                    <strong> utvalget</strong>.
                  </p>
                </div>
                <img
                  src={'/images/extra_banner.jpg'}
                  alt={'extra-til-lunsj'}
                  className={styles.productImage}
                />
              </div>
            </Link>
          </div>
        </div> */}
      </div>
      {/* <div className={styles.instagram}>
          <Instagram shortened gray />
        </div> */}
    </div>
  );
}

CustomerDashboard.propTypes = {
  customer: PropTypes.object,
  subscription: PropTypes.object,
  subscriptionMeta: PropTypes.object,
};

export default connect(state => ({
  customer: selectedCustomer(state),
  subscription: selectCustomerSubscription(state, selectedCustomer(state).id),
  subscriptionMeta: selectCustomerSubscriptionMeta(
    state,
    selectedCustomer(state).id
  ),
}))(CustomerDashboard, getSubscriptionForCustomerIfNeeded);
