import React from 'react';
import * as Sentry from '@sentry/browser';
import Container from '../Container';
import { Row, Col } from '../Grid';
import Button from '../Button';

export default class SentryErrorBoundry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <Container>
          <Row>
            <Col className="text-center mt-5">
              <h1>Noe skjedde</h1>
              <p>
                Noe skjedde når vi lastet inn siden. Du kan gi oss mer
                informasjon ved å trykke knappen under. Vi har allerede blitt
                varslet og vi vil fikse det så fort som mulig.
              </p>
              <Button
                onClick={() =>
                  Sentry.showReportDialog({
                    eventId: this.state.eventId,
                  })
                }
              >
                Send tilbakemelding
              </Button>
            </Col>
          </Row>
        </Container>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
