import addPrefix from '../../utils/addPrefix';

export default addPrefix('invoice', [
  'GET_START',
  'GET_SUCCESS',
  'GET_ERROR',
  'GET_FOR_COMPANY_AGREEMENT_START',
  'GET_FOR_COMPANY_AGREEMENT_SUCCESS',
  'GET_FOR_COMPANY_AGREEMENT_ERROR',
  'GET_FOR_CUSTOMER_START',
  'GET_FOR_CUSTOMER_SUCCESS',
  'GET_FOR_CUSTOMER_ERROR',
  'GET_ONE_START',
  'GET_ONE_SUCCESS',
  'GET_ONE_ERROR',
  'GET_ONE_COMPANY_AGREEMENT_START',
  'GET_ONE_COMPANY_AGREEMENT_SUCCESS',
  'GET_ONE_COMPANY_AGREEMENT_ERROR',
  'GET_ONE_CUSTOMER_START',
  'GET_ONE_CUSTOMER_SUCCESS',
  'GET_ONE_CUSTOMER_ERROR',
  'UPDATE_SUCCESS',
]);
