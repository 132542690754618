import React from 'react';
import PropTypes from 'prop-types';
/**
 * Need props colors = [{color: 'cssColor', text: 'string'}]
 */
function ColoredDots({ size, colors }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 20 }}>
      {colors.map(colorObj => {
        return (
          <div key={colorObj.color}>
            <span
              style={{
                backgroundColor: colorObj.color,
                display: 'inline-block',
                margin: '0 5px -2px 5px',
                border: '1px solid gray',
                borderRadius: '50%',
                height: size || 15,
                width: size || 15,
              }}
            />
            <i>{colorObj.text}</i>
          </div>
        );
      })}
    </div>
  );
}

ColoredDots.propTypes = {
  colors: PropTypes.array.isRequired,
  size: PropTypes.number,
};
export default ColoredDots;
