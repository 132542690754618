import moment from 'moment';
import types from './types';
import { getKey } from './selectors';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FOR_COMPANY_AGREEMENT_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_FOR_COMPANY_AGREEMENT_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.meetingFoodOrders.reduce(
            (object, meetingFoodOrder) => {
              object[meetingFoodOrder.id] = meetingFoodOrder;
              return object;
            },
            {}
          ),
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: action.payload.meetingFoodOrders.map(so => so.id),
          },
        },
      };
    case types.GET_FOR_COMPANY_AGREEMENT_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.meetingFoodOrder.id]: action.payload.meetingFoodOrder,
        },
      };
    case types.CREATE_SUCCESS:
      let newIds = state.meta[action.payload.key]
        ? [...state.meta[action.payload.key].ids]
        : [];
      if (newIds.indexOf(action.payload.meetingFoodOrder.id) === -1) {
        newIds.push(action.payload.meetingFoodOrder.id);
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.meetingFoodOrder.id]: action.payload.meetingFoodOrder,
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: newIds,
          },
        },
      };
    case types.DELETE_SUCCESS:
      let entities = {
        ...state.entities,
      };

      delete entities[action.payload.meetingFoodOrderId];

      let meta = {
        ...state.meta,
        [getKey(action.payload.companyAgreementId)]: {
          loading: false,
          error: false,
          loadedAt: moment().unix(),
          ids: [
            ...state.meta[getKey(action.payload.companyAgreementId)].ids,
          ].filter(id => id !== action.payload.meetingFoodOrderId),
        },
      };

      delete meta[getKey(action.payload.meetingFoodOrderId)];

      return {
        ...state,
        entities,
        meta,
      };
    default:
      return state;
  }
};
