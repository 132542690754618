import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Link from '../../Link';
import Alert from '../../Alert';
import { isPastOrderDate } from '../../../utils/lunchOverviewUtils';
import { getSumForDaySingleOrder } from '../../MyLunchCalendar';
import formatUserFrendlyDate from '../../../utils/formatUserFrendlyDate';
import formatPrice from '../../../utils/formatPrice';
import styles from './companyMeetingFoodOrder.module.css';
import Loader from '../../Loader/Loader';

import {
  selectForCompanyAgreement,
  selectForCompanyAgreementMeta,
} from '../../../stores/meetingFoodOrder/selectors';
import { getMeetingFoodForCompanyAgreementIfNeeded } from '../../../stores/meetingFoodOrder/actions';
import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../stores/subscriptionType/actions';
import selectAllSubscriptionTypes from '../../../selectors/subscriptionType/selectAll';

class Motemat extends React.Component {
  componentDidMount() {
    this.props.getAllSubscriptionTypesIfNeeded();
    this.props.getMeetingFoodForCompanyAgreementIfNeeded(
      this.props.companyAgreementId
    );
  }
  componentDidUpdate() {
    this.props.getMeetingFoodForCompanyAgreementIfNeeded(
      this.props.companyAgreementId
    );
  }

  buildLine(mfo, isPast = false) {
    return (
      <div key={mfo.id} className={styles.meetingFoodOrder}>
        <div className={styles.meetingFoodOrderLeft}>
          <div className={styles.meetingFoodOrderDate}>
            {formatUserFrendlyDate(moment(mfo.date))}
          </div>
          <div>
            {formatPrice(
              getSumForDaySingleOrder(
                null,
                this.props.subscriptionTypes,
                this.props.companyAgreement,
                mfo.data.entries
              )
            )}
          </div>
        </div>
        {!isPast ? (
          <div>
            <Link to={`${this.props.to}/${mfo.id}`}>Se / Endre</Link>
          </div>
        ) : null}
      </div>
    );
  }

  buildContent() {
    if (this.props.meta.loading) {
      return <Loader />;
    }

    if (this.props.meta.error) {
      return (
        <Alert color="red">
          Noe skjedde når vi lastet inn bestillingene dine
        </Alert>
      );
    }

    const editableOrders = this.props.meetingFoodOrders
      .reverse()
      .filter(mfo => !isPastOrderDate(moment(mfo.date), this.props.admin));

    const oldOrders = this.props.meetingFoodOrders
      .reverse()
      .filter(mfo => isPastOrderDate(moment(mfo.date), this.props.admin));

    return (
      <div>
        {this.props.meetingFoodOrders.length === 0 ? (
          <div>Ingen bestillinger</div>
        ) : (
            ''
          )}
        {editableOrders.reverse().map(mfo => this.buildLine(mfo, false))}

        {oldOrders.length !== 0 ? (
          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <i>Tidligere bestillinger</i>
          </div>
        ) : null}
        {oldOrders.reverse().map(mfo => this.buildLine(mfo, true))}
      </div>
    );
  }
  render() {
    return (
      <div style={{ maxWidth: '500px', margin: '40px auto' }}>
        <div className="text-center">
          <h3>Oversikt over bestillinger</h3>
        </div>
        <div className="text-center mt-5">{this.buildContent()}</div>
      </div>
    );
  }
}
export default connect(
  (state, ownProps) => ({
    subscriptionTypes: selectAllSubscriptionTypes(state),
    meetingFoodOrders: selectForCompanyAgreement(
      state,
      ownProps.companyAgreementId
    ),
    meta: selectForCompanyAgreementMeta(state, ownProps.companyAgreementId),
  }),
  { getMeetingFoodForCompanyAgreementIfNeeded, getAllSubscriptionTypesIfNeeded }
)(Motemat);
