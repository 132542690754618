import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';
import selectMetaKey from '../../selectors/invoice/selectMetaKey';

import types from './types';

export const getCustomerInvoices = (customerId, start = 0, limit = 20) => (
  dispatch,
  getState
) => {
  const metaKey = selectMetaKey(start, limit, { customerId });
  if (!shouldFetch(getState().invoice.meta[metaKey])) return;

  dispatch({
    type: types.GET_FOR_CUSTOMER_START,
    payload: {
      metaKey,
    },
  });

  return fetch(`/customers/${customerId}/invoices`, 'GET', {
    start,
    limit,
  })
    .then(response => {
      dispatch({
        type: types.GET_FOR_CUSTOMER_SUCCESS,
        payload: {
          metaKey,
          entities: response.data.reduce((invoices, invoice) => {
            invoices[invoice.id] = invoice;
            return invoices;
          }, {}),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_CUSTOMER_ERROR,
        payload: {
          metaKey,
          error,
        },
      });
    });
};

export const getCompanyAgreementInvoices = (
  companyAgreementId,
  start = 0,
  limit = 20
) => (dispatch, getState) => {
  const metaKey = selectMetaKey(start, limit, { companyAgreementId });
  if (!shouldFetch(getState().invoice.meta[metaKey])) return;

  dispatch({
    type: types.GET_FOR_COMPANY_AGREEMENT_START,
    payload: {
      metaKey,
    },
  });

  return fetch(`/company-agreements/${companyAgreementId}/invoices`, 'GET', {
    start,
    limit,
  })
    .then(response => {
      dispatch({
        type: types.GET_FOR_COMPANY_AGREEMENT_SUCCESS,
        payload: {
          metaKey,
          entities: response.data.reduce((invoices, invoice) => {
            invoices[invoice.id] = invoice;
            return invoices;
          }, {}),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_COMPANY_AGREEMENT_ERROR,
        payload: {
          metaKey,
          error,
        },
      });
    });
};

export const getPendingInvoices = (start, limit) => (dispatch, getState) => {
  const metaKey = selectMetaKey(start, limit, {});
  if (!shouldFetch(getState().invoice.meta[metaKey])) return;

  dispatch({
    type: types.GET_START,
    payload: {
      metaKey,
    },
  });

  return fetch('/invoices-pending', 'GET', {
    start,
    limit,
  })
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          metaKey,
          entities: response.data.data.reduce((invoices, invoice) => {
            invoices[invoice.id] = invoice;
            return invoices;
          }, {}),
          pageInfo: response.data.pageInfo,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          metaKey,
          error,
        },
      });
    });
};

export const getInvoices = (start, limit, filter) => (dispatch, getState) => {
  const metaKey = selectMetaKey(start, limit, filter);
  if (!shouldFetch(getState().invoice.meta[metaKey])) return;

  dispatch({
    type: types.GET_START,
    payload: {
      metaKey,
    },
  });

  return fetch('/invoices', 'GET', {
    ...filter,
    start,
    limit,
  })
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          metaKey,
          entities: response.data.data.reduce((invoices, invoice) => {
            invoices[invoice.id] = invoice;
            return invoices;
          }, {}),
          pageInfo: response.data.pageInfo,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          metaKey,
          error,
        },
      });
    });
};

export const getIfNeededForCompanyAgreement = (companyAgreementId, id) => (
  dispatch,
  getState
) => {
  if (!shouldFetch(getState(), id)) return;

  dispatch({
    type: types.GET_ONE_COMPANY_AGREEMENT_START,
    payload: {
      _id: id,
    },
  });

  return fetch(
    `/company-agreements/${companyAgreementId}/invoices/${id}`,
    'GET'
  )
    .then(response => {
      dispatch({
        type: types.GET_ONE_COMPANY_AGREEMENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ONE_COMPANY_AGREEMENT_ERROR,
        payload: {
          _id: id,
        },
      });
    });
};

export const getIfNeededForCustomer = (customerId, id) => (
  dispatch,
  getState
) => {
  if (!shouldFetch(getState(), id)) return;

  dispatch({
    type: types.GET_ONE_CUSTOMER_START,
    payload: {
      _id: id,
    },
  });

  return fetch(`/customers/${customerId}/invoices/${id}`, 'GET')
    .then(response => {
      dispatch({
        type: types.GET_ONE_CUSTOMER_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ONE_CUSTOMER_ERROR,
        payload: {
          _id: id,
        },
      });
    });
};

export const getIfNeeded = id => (dispatch, getState) => {
  if (!shouldFetch(getState().invoice.meta[id], id)) return;

  dispatch({
    type: types.GET_ONE_START,
    payload: {
      id: id,
    },
  });

  return fetch('/invoices/' + id, 'GET')
    .then(response => {
      dispatch({
        type: types.GET_ONE_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ONE_ERROR,
        payload: {
          id: id,
        },
      });
    });
};

export const update = (id, data) => dispatch =>
  fetch('/invoices/' + id, 'POST', data).then(response => {
    dispatch({
      type: types.UPDATE_SUCCESS,
      payload: {
        response: response.data,
      },
    });
  });

export const updateInvoiceAdditionalNotes = (id, data) => dispatch =>
  fetch('/invoices/' + id + '/additional-notes', 'POST', data).then(
    response => {
      dispatch({
        type: types.UPDATE_SUCCESS,
        payload: {
          response: response.data,
        },
      });
    }
  );
