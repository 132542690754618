import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
// import { Helmet } from 'react-helmet';
import { DayPickerSingleDateController, CalendarDay } from 'react-dates';

import Header from '../../../components/Header';
import Tabs from '../../../components/Tabs';
import MinSideTitle from '../../../components/MinSideTitle';
import Card from '../../../components/Card';
import Link from '../../../components/Link';
import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Container from '../../../components/Container';
import LunchCalendar from './LunchCalendar'

import firstSubDate from '../../../utils/firstSubDate'

import { cancelCustomerChoices } from '../../../stores/choice/actions'

import { selectCustomerSubscription, selectCustomerSubscriptionMeta } from '../../../stores/subscription/selectors';
import { selectCustomerChoicesForMonth, selectCustomerChoicesForMonthMeta } from '../../../stores/choice/selectors';
// TODO: Take orders into account
// import { getCustomerOrdersForMonthIfNeeded } from '../../../stores/order†/selectors';


//Customer store function
import selectedCustomer from '../../../selectors/me/selectedCustomer';
import { selectCustomer } from '../../../stores/customer/selectors';

import { getForCustomerIfNeeded as getSubscriptionForCustomerIfNeeded } from '../../../stores/subscription/actions';

import {
  selectNoDeliveryDaysForMonth,
  // selectNoDeliveryDaysForMonthMeta,
} from '../../../stores/noDeliveryDay/selectors';

import cn from 'classnames'

import './lunchCalendar.css';

import SubscriptionService from '../../../services/SubscriptionService'
import Loader from '../../../components/Loader';

const LunchCalendarWrapper = props => {
  const dispatch = useDispatch();

  const [orderDays, setOrderDays] = useState([]);
  const [cancel, setCancel] = useState(false);

  const [month, setMonth] = useState(moment(firstSubDate()));
  const [cancelDates, setCancelDates] = useState([]);
  const [date, setDate] = useState(moment(firstSubDate()));

  const me = useSelector(selectedCustomer);
  const customerAdmin = useSelector(state => selectCustomer(state, props.customerId))
  const customer = props.customerId ? customerAdmin : me;

  const subscription = useSelector(state => selectCustomerSubscription(state, customer.id));
  const subscriptionMeta = useSelector(state => selectCustomerSubscriptionMeta(state, customer.id));
  const choices = useSelector(state => selectCustomerChoicesForMonth(state, customer.id, month));
  const choicesMeta = useSelector(state => selectCustomerChoicesForMonthMeta(state, customer.id, month));
  const noDeliveryDays = useSelector(state => selectNoDeliveryDaysForMonth(state, month));
  // const noDeliveryDaysMeta = useSelector(state => selectNoDeliveryDaysForMonthMeta(state, month));

  useEffect(() => {
    dispatch(getSubscriptionForCustomerIfNeeded(customer.id));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!choicesMeta.cancelling && cancel) {
      setTimeout(() => {
        setCancel(false);
      }, 1000);

      setCancelDates([]);
    }

  }, [choicesMeta.cancelling]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!subscription) return;

    let monthSubscriptionStart = moment(subscription.startDate);
    if (monthSubscriptionStart.isBefore(month.clone().startOf('month'))) {
      monthSubscriptionStart = month.clone().startOf('month');
    }

    let monthSubscriptionEnd = month.clone().endOf('month').add(-1, 'days');
    if (subscription.endDate &&
      monthSubscriptionEnd.isAfter(moment(subscription.endDate))) {
      monthSubscriptionEnd = moment(subscription.endDate);
    }

    let result = [];
    for (let day = moment(monthSubscriptionStart); day.diff(monthSubscriptionEnd, 'days') < 1; day.add(1, 'days')) {

      if (noDeliveryDays.find(d => moment(d.date).isSame(day, 'date'))) continue;
      const dayChoice = choices.find(c => moment(c.date).isSame(day, 'date'));

      if (!dayChoice) {
        const dayActive = SubscriptionService.subscriptionAvailableForDay(subscription, day);

        if (dayActive) {
          result.push(day.format('YYYY-MM-DD'))
        }
      }
      else {
        const hasEntries = Object.values(dayChoice.data.entries).reduce((total, e) => total + e.menuAmount, 0);
        hasEntries && result.push(day.format('YYYY-MM-DD'))
      }
    }

    setOrderDays(result);
  }, [subscriptionMeta.loading, choicesMeta.loading, month, cancel]) // eslint-disable-line react-hooks/exhaustive-deps

  const setNewMonth = month => {
    setMonth(month);

    let firstDay = month.clone().startOf('month');
    if (!(firstDay.day() % 6)) {
      firstDay.add(1, 'days').day(1);
    }

    setDate(firstDay);
  }

  const setNewDay = day => {
    if (day.day() === 6) {
      day.add(2, 'days');
      setDate(day);
    }
    else if (day.day() === 0) {
      day.add(-2, 'days');
      setDate(day);
    }
    else {
      setDate(day);
    }

    if (day.month() !== month.month()) {
      setMonth(day);
    }
  }

  const changeCancelDate = cancelDate => {
    const newDates = cancelDates.find((d => cancelDate.isSame(d, 'date'))) ? cancelDates.filter(d => !cancelDate.isSame(d, 'date')) : [...cancelDates, cancelDate]

    setCancelDates(newDates);
  };

  const cancelDays = () => {
    dispatch(cancelCustomerChoices(customer.id, subscription.key, cancelDates));
  };

  const disabled = (checkDate = date) => {
    if (!subscription) return true;
    if (moment(firstSubDate()).isAfter(checkDate, 'date')) return true;
    if (moment(subscription.startDate).isAfter(checkDate, 'date')) return true;
    if (subscription.endDate && moment(subscription.endDate).isBefore(checkDate, 'date')) return true;

    return false;
  };

  const getContent = () => {
    if (subscriptionMeta.loading) return (
      <div className="header">
        <div className="header_info">
          <h2>Lunsjkalender</h2>
        </div>
        <div style={{ display: 'flex' }} >
          <Loader />
        </div>
      </div>
    );

    if (subscriptionMeta.error || choicesMeta.error) return (
      <div>
      <div className="header">
        <div className="header_info">
          <h2>Lunsjkalender</h2>
        </div>
      </div>
        <Alert color="red">Noe skjedde. Prøv igjen senere eller ta kontakt via chatten, så hjelper vi deg.</Alert>
      </div>
    );

    if (!subscription || (subscription.endDate && moment(subscription.endDate).isBefore(moment(firstSubDate()), 'date'))) return (
      <div className="header">
        <div className="header_info">
          <h2>Lunsjkalender</h2>
        </div>
        <div >
          <div className={cn('fa', 'fa-lock')} style={{ marginRight: 10 }} />
          Du har ikke satt opp fast levering. Aktiver lunsjkalenderen ved å sette opp fast levering <a gray href="https://my.lunsjkollektivet.no">her</a>.
        </div>
      </div>
    );

    return (
      <div>
        <div className="header">
          <div className="header_info">
            <h2>Lunsjkalender</h2>
          </div>
          <div className={cn("calendar-picker", { "cancelModal": cancel })}>
            {
              !cancel && window.innerWidth > 1000 &&
              <DayPickerSingleDateController
                noBorder
                hideKeyboardShortcutsPanel
                initialVisibleMonth={() => month}
                date={date}
                focused
                navPrev={<div className={cn('fa', 'fa-angle-left', 'arrow_left')} />}
                navNext={<div className={cn('fa', 'fa-angle-right', 'arrow_right')} />}
                onPrevMonthClick={setNewMonth}
                onNextMonthClick={setNewMonth}
                isOutsideRange={date => date.isoWeekday() > 5}
                isDayHighlighted={date => orderDays.includes(date.format('YYYY-MM-DD'))}
                onDateChange={date => date.isoWeekday() <= 5 && setDate(moment(date))}
              />}
            {
              cancel &&
              <DayPickerSingleDateController
                noBorder
                hideKeyboardShortcutsPanel
                initialVisibleMonth={() => month}
                orientation={window.innerWidth < 1000 ? 'vertical' : 'horizontal'}
                navPrev={<div className={cn('fa', 'fa-angle-left', 'arrow_left')} />}
                navNext={<div className={cn('fa', 'fa-angle-right', 'arrow_right')} />}
                onPrevMonthClick={setNewMonth}
                onNextMonthClick={setNewMonth}
                isOutsideRange={date => date.isoWeekday() > 5 || disabled(date)}
                isDayHighlighted={date => orderDays.includes(date.format('YYYY-MM-DD'))}
                focused
                onDateChange={changeCancelDate}
                renderCalendarDay={props => {
                  const { day, modifiers } = props

                  if (cancelDates.includes(day)) {
                    modifiers && modifiers.add('selected');
                  }
                  else {
                    modifiers && modifiers.delete('selected')
                  }

                  return (
                    <CalendarDay {...props} modifiers={modifiers} />
                  )
                }}
              />
            }
            <div className={cn("actions", { 'cancel': cancel })}>
              {cancel && (
                <div>
                  <Button
                    onClick={cancelDays} saving={choicesMeta.cancelling}
                    disabled={!cancelDates.length}>
                    Bekreft avbestilling
                  </Button>
                </div>
              )}
              {cancel && cancelDates.length > 0 && (
                <div className="cancel_confirm">
                  <strong>
                    Avbestill {cancelDates.length} valgt{cancelDates.length > 1 ? 'e' : ''} dag{cancelDates.length > 1 ? 'er' : ''}?
                      </strong>
                </div>
              )}
              {cancel && (
                <div className="header_info">
                  Her kan du avbestille leveringer et par dager eller uker, dersom du for eksempel ikke skal på kontoret en periode. Velg dager fra kalenderen til venstre.
                </div>
              )}
              {cancel && (
                <div className="mobile_info">
                  Her kan du velge dager fra kalenderen, og enkelt avbestille leveringer.
                </div>
              )}
              {!cancel ? (
                <Link gray onClick={() => { setCancel(true); }}>Avbestill flere dager</Link>
              ) : (
                <div style={{ marginLeft: 'auto' }}>
                  <Link gray onClick={() => { setCancel(false); }}>
                    <div className={cn('fa', 'fa-angle-left')} />
                    {' '}Avbryt</Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <LunchCalendar day={date} month={month} cancel={cancel} isDisabled={disabled} customer={customer}
          decrementDay={() => setNewDay(date.clone().add(-1, 'days'))} incrementDay={() => setNewDay(date.clone().add(1, 'days'))} />
      </div>
    )
  }
  return (
    <div>
      {!props.admin && <Header />}
      {!props.admin && window.innerWidth > 1000 &&
        <MinSideTitle showTabs />
      }
      {!props.admin && window.innerWidth <= 1000 &&
        <div style={{ fontSize: '1.2em' }}>
          <Tabs
            tabs={[
              {
                to: 'https://my.lunsjkollektivet.no',
                text: 'Lunsjkalender',
                exact: true,
              },
              {
                to: 'https://my.lunsjkollektivet.no',
                text: 'Fast levering',
                exact: true,
              },
            ]}
            keepSearchQuery={false}
          />
        </div>}
      <div className={cn({ "calendar_bkg": !props.admin })}>
        <Container width={1000}>
          <Card rounded>
            {getContent()}         </Card>
        </Container>
      </div>
    </div>
  );
};

export default LunchCalendarWrapper;
