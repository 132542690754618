import React from 'react';
import { connect } from 'react-redux';
import Invoice from '../../../../components/Invoice';
import Container from '../../../../components/Container';

/**
 * Invoice Store functions
 */
import { getIfNeededForCustomer } from '../../../../stores/invoice/actions';
import select from '../../../../selectors/invoice/select';
import selectMeta from '../../../../selectors/invoice/selectMeta';

/**
 * Me Store functions
 */
import selectedCustomer from '../../../../selectors/me/selectedCustomer';

const InvoiceDetail = props => (
  <Container withPadding color="gray">
    <Invoice
      invoiceId={props.match.params.invoiceId}
      selectMeta={selectMeta}
      select={select}
      get={invoiceId => getIfNeededForCustomer(props.customerId, invoiceId)}
      backTo="/innstillinger/faktura"
    />
  </Container>
);

export default connect(state => ({
  customerId: selectedCustomer(state).id,
}))(InvoiceDetail);
