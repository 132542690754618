import React from 'react';
import Tabs from '../../Tabs';
import CompanySubscriptionForm from './CompanySubscriptionForm';

class DailyForm extends React.Component {
  state = {
    selectedIndex: 0,
  };

  hasFullWeek = () => {
    const hasEmptyDay = !!this.props.availableDays.find(ad => {
      let day = this.props.dailyEntries.find(de => de.day === ad.key);
      if (!day) return true;

      return !day.data.find(e => e.menuAmount > 0);
    });

    return !hasEmptyDay;
  };

  handleAmountChange = (selectedDay, values) => {
    let data = [...this.props.dailyEntries];
    const index = data.findIndex(
      dataEntry => dataEntry.day === selectedDay.key
    );

    const filteredValues = values
      .filter(v => v.menuAmount > 0)
      .map(v => ({ ...v, menuExtraLarge: false }));

    if (index === -1) {
      data.push({
        day: selectedDay.key,
        data: filteredValues,
      });
    } else {
      data[index].data = filteredValues;
    }

    this.props.onChange(data);
  };

  getDayEntries = day => {
    const selectedDayData =
      this.props.dailyEntries.find(de => de.day === day) || [];
    return selectedDayData.data || [];
  };

  render() {
    const selectedDay = this.props.availableDays[this.state.selectedIndex];
    const selectedDayEntries = this.getDayEntries(selectedDay.key);
    return (
      <div>
        <Tabs
          tabs={this.props.availableDays.map((ad, index) => ({
            text: (
              <div>
                {ad.name} -{' '}
                {this.getDayEntries(ad.key).reduce(
                  (total, entry) => total + entry.menuAmount,
                  0
                )}
                x
              </div>
            ),
            selected: this.state.selectedIndex === index,
          }))}
          onTabChange={i => this.setState({ selectedIndex: i })}
        />
        <div className="mt-5">
          <CompanySubscriptionForm
            values={selectedDayEntries}
            onChange={values => this.handleAmountChange(selectedDay, values)}
            admin={this.props.admin}
            subscriptionTypes={this.props.subscriptionTypes}
            subscriptionTypesCompanyAgreement={
              this.props.subscriptionTypesCompanyAgreement
            }
            deliveryLocation={this.props.deliveryLocation}
            weekDayString={selectedDay.key}
            companyAgreement={this.props.companyAgreement}

          />
        </div>
      </div>
    );
  }
}

export default DailyForm;
