import React from 'react';

import Card from '../Card';
import formatPrice from '../../utils/formatPrice';

import styles from './weekTotal.module.css';

export default ({ total }) => (
  <Card className={styles.weekTotal} transparent>
    <div className={styles.textAlign}>
      <div>
        Sum denne uken: <strong>{formatPrice(total.customer)}</strong>
      </div>
      {total.company > 0 ? (
        <div className={styles.companyTotal}>
          Bedriften betaler: <strong>{formatPrice(total.company)}</strong>
        </div>
      ) : (
        ''
      )}
      <em className={styles.mva}>*Alle priser inkl. 15% mva</em>
    </div>
  </Card>
);
