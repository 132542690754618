import React from 'react';
import { connect } from 'react-redux';
import Card, { Separator } from '../../Card';
import Container from '../../Container';
import Alert from '../../Alert';
import Button, { BackButton } from '../../Button';
import DeliveryLocationPicker from '../../DeliveryLocationPicker';

import { selectCustomer } from '../../../stores/customer/selectors';
import { updateCustomerDeliveryLocation } from '../../../stores/customer/actions';

class DeliveryAddress extends React.Component {
  state = {
    deliveryLocation: null,
  };

  componentDidMount() {
    this.updateStateUser();
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer !== prevProps.customer) {
      this.updateStateUser();
    }
  }

  updateStateUser() {
    this.setState({
      deliveryLocation:
        this.props.customer && this.props.customer.deliveryLocation
          ? this.props.customer.deliveryLocation
          : null,
    });
  }

  save = () => {
    this.setState({ saving: true, error: false, success: false });

    this.props
      .updateCustomerDeliveryLocation(
        this.props.customer.id,
        this.state.deliveryLocation
      )
      .then(() => {
        this.props.onDone();
        this.setState({ saving: false, success: true, locationSaved: true });
        setTimeout(() => this.setState({ success: false }), 2000);
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  };

  render() {
    const deliveryLocation = this.props.customer.companyAgreement
      ? this.props.customer.companyAgreement.deliveryLocation
      : this.props.customer.deliveryLocation;

    // const numberOfBoxWeekly =
    //   this.state.deliveryLocation &&
    //   this.state.deliveryLocation.numberOfBoxWeekly
    //     ? this.state.deliveryLocation.numberOfBoxWeekly
    //     : 0;

    return (
      <Container withPadding color="gray">
        {this.props.message ? this.props.message : ''}
        {this.props.backTo ? <BackButton to={this.props.backTo} /> : ''}
        <Card
          header="Leveringsadresse"
          to="/innstillinger/leveringsadresse"
          linkText="Endre leveringsadresse"
        >
          {this.props.customer.companyAgreement ? (
            deliveryLocation && deliveryLocation.address ? (
              <div>
                <div>{deliveryLocation.address.addressLine}</div>
                <div>
                  {deliveryLocation.address.postalCode.code},{' '}
                  {deliveryLocation.address.postalCode.place}
                </div>
              </div>
            ) : (
              <div>Ingen leveringsadresse er satt opp</div>
            )
          ) : (
            <DeliveryLocationPicker
              onChange={deliveryLocation =>
                deliveryLocation && deliveryLocation.address
                  ? this.setState({
                      deliveryLocation: {
                        ...deliveryLocation,
                        address: {
                          ...deliveryLocation.address,
                          addressLine: deliveryLocation.address.address,
                        },
                      },
                    })
                  : this.setState({
                      deliveryLocation: null,
                    })
              }
              deliveryLocation={this.state.deliveryLocation}
              onReset={() => this.setState({ locationSaved: false })}
              locationSaved={this.state.locationSaved}
              admin={this.props.admin}
            />
          )}
          {/* {this.state.deliveryLocation ? (
            <div className="mt-5">
              {customerDeliveryCost(this.props.customer)
                ? `På dette punktet er det ${numberOfBoxWeekly /
                5} leveringer per dag*, verv på samme adresse så slipper du frakttillegg.`
                : null}
            </div>
          ) : null} */}
          <Separator />
          {this.props.customer.companyAgreement ? (
            <Alert>
              Bare avtaleadministratoren har tilgang til å endre
              leveringsadresse for en avtale
            </Alert>
          ) : (
            <Button
              onClick={this.save}
              saving={this.state.saving}
              success={this.state.success}
              disabled={!this.state.deliveryLocation}
            >
              Lagre
            </Button>
          )}
        </Card>
      </Container>
    );
  }
}

DeliveryAddress.defaultProps = {
  onDone: () => {},
};

export default connect(
  (state, ownProps) => {
    return {
      customer: selectCustomer(state, ownProps.customerId),
    };
  },
  { updateCustomerDeliveryLocation }
)(DeliveryAddress);
