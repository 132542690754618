import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { getIfNeeded } from '../../stores/deliveryLocation/actions';
import {
  selectDeliveryLocations,
  selectDeliveryLocationsMeta,
} from '../../stores/deliveryLocation/selectors';
import DeliveryLocation from './DeliveryLocation';
import Spinner from '../Spinner';
import Alert from '../Alert';
import styles from './deliveryLocationPicker.module.css';

class DeliveryLocationFetcher extends React.Component {
  componentDidMount() {
    this.props.modifiable && this.props.getIfNeeded(this.props.deliveryAddress);
  }

  componentDidUpdate() {
    this.props.modifiable && this.props.getIfNeeded(this.props.deliveryAddress);
  }

  render() {
    if (this.props.modifiable && this.props.meta.loading) {
      return (
        <div style={{ width: '400px' }}>
          <Spinner black />
        </div>
      );
    }

    if (this.props.meta.error || !this.props.deliveryAddress ||
      !this.props.deliveryAddress.postalCode || !this.props.deliveryAddress.postalCode.active) {
      return (
        <div style={{ marginTop: '10px', width: '400px' }}>
          <Alert>
            Oi! Det ser ut som vi ikke leverer til denne adressen. Ta gjerne
            kontakt med oss på hei@lunsjkollektivet.no så kanskje vi kan finne
            en løsning.
          </Alert>
        </div>
      );
    }
    return (
      <div>
        {this.props.deliveryLocations.length ? (
          <div style={{ margin: '10px 0', width: '400px' }}>
            Vi leverer til denne adressen! Du må også velge et leveringspunkt,
            så vi vet hvor lunsjen skal.
          </div>
        ) : null}
        {this.props.deliveryLocations
          .sort((a, b) => b.numberOfBoxWeekly - a.numberOfBoxWeekly)
          .map(dl => (
            <DeliveryLocation
              key={dl.id}
              deliveryLocation={dl}
              onClick={() => this.props.onChange(dl)}
              modifiable={this.props.modifiable}
              onModify={() => this.props.onModify(dl)}
            />
          ))}
        {this.props.modifiable && !this.props.deliveryLocations.length ? (
          <div className={styles.missing}>
            Denne adressen har foreløpig ingen registrerte leveringspunkter.
          </div>
        ) : null}
        <div className="mt-5">
          <div
            className={styles.deliveryLocationChoice}
            onClick={this.props.addNewLocation}
          >
            <div className={styles.row}>
              <div className={cn('fa', 'fa-plus')} />
              <div>
                <strong>Gi navn til nytt leveringspunkt</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    deliveryLocations: selectDeliveryLocations(state, ownProps.deliveryAddress),
    meta: selectDeliveryLocationsMeta(state, ownProps.deliveryAddress),
  }),
  { getIfNeeded }
)(DeliveryLocationFetcher);
