import addPrefix from '../../utils/addPrefix';

export default addPrefix('customer', [
  'GET_LATEST_START',
  'GET_LATEST_SUCCESS',
  'GET_LATEST_ERROR',
  'GET_START',
  'GET_SUCCESS',
  'GET_ERROR',
  'GET_ONE_START',
  'GET_ONE_SUCCESS',
  'GET_ONE_ERROR',
  'DELETE_START',
  'DELETE_SUCCESS',
  'DELETE_ERROR',
  'UPDATE_CUSTOMER_SUCCESS',
  'UPDATE_CUSTOMER_IDENTITY_SUCCESS',
  'UPDATE_CUSTOMER_DELIVERY_ADDRESS_SUCCESS',
]);
