import React from 'react';
import Button from '../Button';

const scrollSupport = 'scrollBehavior' in document.documentElement.style;

const Anchor = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      onClick={() => {
        setTimeout(() => {
          scrollSupport
            ? window.scrollTo({
              behavior: 'smooth',
              top: window.innerHeight * 0.95,
            })
            : window.scrollTo(0, window.innerHeight * 0.95);
        }, 150);
      }}
    >
      {children}
    </Button>
  );
};

Anchor.defaultProps = {
  fullWidth: false,
  color: 'main',
  size: 'normal',
};

export default Anchor;
