import React from 'react';
import { withRouter, Link } from 'react-router-dom';

const CustomLink = ({
  to,
  onClick,
  children,
  history,
  staticContext,
  ...otherProps
}) => {
  const target = to ? to : '';

  if (target.startsWith("http")) {
    return <a href={target} {...otherProps}>{children}</a>
  }

  return <Link
      to={target}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        if (onClick) onClick(e);
        history.push(to);
      }}
      {...otherProps}
    >
      {children}
    </Link>
};

export default withRouter(CustomLink);
