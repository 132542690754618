import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../../components/Loader';
import Container from '../../../components/Container';
import { Row, Col } from '../../../components/Grid';

import { getHashtagFeed } from '../../../stores/instagram/actions';
import {
  selectHashtagFeed,
  selectHashtagFeedMeta,
} from '../../../stores/instagram/selectors';

import styles from './instagram.module.css';

class Instagram extends React.Component {
  componentDidMount() {
    this.props.getHashtagFeed('lunsjkollektivet');
  }

  componentDidUpdate() {
    this.props.getHashtagFeed('lunsjkollektivet');
  }

  getContent() {
    if (this.props.instagramMeta.error) {
      return <div />;
    }

    if (this.props.instagramMeta.loading) {
      return <Loader />;
    }

    let rows = [];
    let i;
    let row = [];

    !this.props.shortened &&
      this.props.instagram.forEach(i => {
        row.push(i);
        if (row.length === 4) {
          rows.push(row);
          row = [];
        }
      });

    if (this.props.shortened) {
      for (i = 0; i <= 12; i++) {
        row.push(this.props.instagram[i]);
        if (row.length === 4) {
          rows.push(row);
          row = [];
        }
      }
    }

    return (
      <div style={{ marginTop: this.props.shortened ? '50px' : '0' }}>
        {rows.map((row, index) => (
          <Row key={index}>
            {row.map(i => (
              <Col key={i.url} className={styles.square}>
                <img src={i.url} alt="instagram" style={{ maxWidth: '100%' }} />
                <div className={styles.overlay}>{i.comment}</div>
              </Col>
            ))}
          </Row>
        ))}
      </div>
    );
  }

  render() {
    return (
      <Container
        width={1000}
        style={this.props.gray && { backgroundColor: '#f6f5f3' }}
        withPadding
      >
        {!this.props.shortened ? <h1>#Lunsjkollektivet</h1> : null}
        {this.getContent()}
      </Container>
    );
  }
}

export default connect(
  state => ({
    instagram: selectHashtagFeed(state, 'lunsjkollektivet'),
    instagramMeta: selectHashtagFeedMeta(state, 'lunsjkollektivet'),
  }),
  {
    getHashtagFeed,
  }
)(Instagram);
