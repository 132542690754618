import React from 'react';

import styles from './groupWithLogo.module.css';

export default props => (
  <div className={styles.groupWithLogo}>
    {props.icon ? (
      <div className={styles.icon}>
        {typeof props.icon === 'string' ? (
          <div className={styles.iconWrapper}>
            <div className={`fa fa-${props.icon}`} />
          </div>
        ) : (
          props.icon
        )}
      </div>
    ) : (
      <div />
    )}
    <div>{props.children}</div>
  </div>
);
