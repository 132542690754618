import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import styles from './day.module.css';
import CompanyLunchOverview from './components/CompanyLunchOverview';

import SubscriptionTypeService from '../../services/SubscriptionTypeService';

class DayOrder extends React.Component {
  buildLunchBoxOverview = group => {
    const menu = group.meal;

    return (
      <CompanyLunchOverview
        key={group.key}
        subscriptionTypes={group.types}
        allergies={group.allergies}
        amount={group.amount}
        type={group.types[0]}
        menu={menu}
        companyAgreement={this.props.companyAgreement}
      />
    );
  };

  getSubscriptionTypes = () => {
    return SubscriptionTypeService.getEnabledSubscriptionTypes(
      this.props.subscriptionTypes,
      this.props.companyAgreement,
      this.props.subscriptionTypeUser,
      this.props.day
        ? this.props.day
            .clone()
            .locale('en')
            .format('dddd')
            .toLowerCase()
        : this.props.weekDayString
        ? this.props.weekDayString
        : null,
      this.props.deliveryLocation
        ? this.props.deliveryLocation.address.postalCode.kitchenId
        : null
    );
  };

  getContent = () => {
    let groups = {};

    let subscriptionTypes = this.props.subscriptionTypes;

    subscriptionTypes.forEach(type => {
      const meal = this.props.menus[type.id];
      let values = this.props.values.filter(v => v.type.id === type.id);
      if (values.filter(v => v.allergies.length === 0).length === 0) {
        values.push({
          allergies: [],
          menuAmount: 0,
        });
      }
      values.forEach(value => {
        const allergies = Array.isArray(value.allergies) ? value.allergies : [];
        const key = `${!meal ? type.id : meal.id}_${type.onlyAdmin}_${
          type.isExtra ? 'isExtra' : 'isNotExtra'
        }_${allergies.map(a => JSON.stringify(a)).join('-')}`;

        if (allergies.length > 0 && value.menuAmount === 0) {
          return;
        }

        if (!groups[key]) {
          groups[key] = {
            types: [],
            meal,
            onlyAdmin: type.onlyAdmin,
            isExtra: type.isExtra,
            allergies,
            amount: 0,
            key,
          };
        }

        groups[key].types.push(type);
        groups[key].amount += value.menuAmount;
      });
    });

    groups = Object.values(groups).map(group => group);

    return (
      <div>
        <div>
          {Object.values(groups).filter(
            group => !group.isExtra && group.amount > 0
          ).length > 0
            ? Object.values(groups)
                .filter(
                  group =>
                    !group.isExtra && (!group.onlyAdmin || group.amount > 0)
                )
                .map(group => this.buildLunchBoxOverview(group))
            : 'Ingen bestillinger'}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={cn(styles.day, styles.locked)}>
        <div className={styles.header}>
          <strong>{this.props.day.format('dddd')}</strong>
          <div>
            <div>{this.props.day.format('D. MMM')}</div>
          </div>
        </div>
        <div className={styles.content}>
          {!this.props.hasActiveSubscription ? null : this.getContent()}
        </div>
      </div>
    );
  }
}

DayOrder.defaultProps = {
  subscriptionTypeUser: 'company',
  menus: {},
};

DayOrder.propTypes = {
  noDeliveryReason: PropTypes.string,
  hasActiveSubscription: PropTypes.bool.isRequired,
  day: PropTypes.object,
  subscriptionTypes: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  subscriptionTypeUser: PropTypes.string.isRequired,
};

export default DayOrder;
