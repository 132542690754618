import moment from 'moment';
import types from './types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.START:
      return {
        ...state,
        [action.payload.key]: {
          loading: true,
          error: false,
          loadedAt: null,
        },
      };
    case types.SUCCESS:
      return {
        ...state,
        [action.payload.key]: {
          loading: false,
          error: false,
          loadedAt: moment().unix(),
          response: action.payload.response,
        },
      };
    case types.ERROR:
      return {
        ...state,
        [action.payload.key]: {
          loading: false,
          error: true,
          loadedAt: moment().unix(),
        },
      };
    default:
      return state;
  }
};
