import selectMeta from '../../selectors/selectMeta';

export const selectMenusForWeek = (state, week) => {
  const meta = selectMenusForWeekMeta(state, week);

  return meta.ids.map(id => state.menu.entities[id]);
};

export const selectMenusForWeekMeta = (state, week) => {
  const startDate = week.clone().startOf('week');
  const endDate = week.clone().endOf('week');
  const key = startDate.format() + endDate.format();

  return selectMeta(state.menu.meta[key]);
};

export const selectMenusForDay = (state, day) => {
  const dayString = day.format('YYYY-MM-DD');

  const meta = state.menu.meta[dayString];

  if (!meta) return null;

  return meta.ids.map(id => state.menu.entities[id]);
};

export const selectMenusForDayMeta = (state, day) =>
  selectMeta(state.menu.meta[day.format('YYYY-MM-DD')]);

export const selectMenuTypeForDay = (state, day, typeId) => {
  return selectMenusForDay(state, day).find(menu => menu.type.id === typeId);
};
