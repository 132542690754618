import React from 'react';
import BlockContent from '../../../../../components/SanityBlockContent';
import Container from '../../../../../components/Container';
import { Col, Row } from '../../../../../components/Grid';
import { buildImageUrl } from '../../../../../utils/sanity';
import styles from './blogPost.module.css';

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    image: props => (
      <div className={styles.imageWrapper}>
        <img src={buildImageUrl(props.node)} alt="From lunsjkollektivet" />
      </div>
    ),
  },
};

export default ({ receipe }) => (
  <Container
    width={900}
    style={{
      padding: '40px 0',
    }}
  >
    <div className={styles.receipe}>
      <Row>
        <Col>
          <div className={styles.receipeTitle}>
            <strong>Slik gjør du det</strong>
          </div>
        </Col>
      </Row>
      <Row>
        <Col m={3}>
          {receipe.ingredientsGroup.map((ig, index) => (
            <div key={index} className={styles.ingredientsGroup}>
              <div>
                <strong>{ig.title}</strong>
              </div>
              {ig.ingredients.map((i, index) => (
                <div key={index}>{i}</div>
              ))}
            </div>
          ))}
        </Col>
        <Col m={9}>
          <BlockContent
            blocks={receipe.instructions}
            serializers={serializers}
          />
        </Col>
      </Row>
    </div>
  </Container>
);
