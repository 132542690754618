import React from 'react';
import { withRouter } from 'react-router-dom';

import SettingCard from '../../SettingCard';

const CompanyMeetingFoodOrdercard = props => (
  <SettingCard
    header="Møtemat"
    to={props.location.pathname + '/motemat'}
    linkText="Endre"
  />
);

export default withRouter(CompanyMeetingFoodOrdercard);
