import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link, withRouter, matchPath } from 'react-router-dom';

import styles from './tabs.module.css';

const Tabs = props => (
  <div
    className={cn(styles.tabs, 'no-print', {
      [styles.outlinedWrapper]: props.outlined,
    })}
    style={{ justifyContent: props.align }}
  >
    {props.tabs.map((tab, index) => {
      let linkProps = {
        className: cn(styles.tab, {
          [styles.tabSelected]:
            tab.selected !== undefined
              ? tab.selected
              : matchPath(props.location.pathname, {
                  path: tab.to,
                  exact: tab.exact,
                }),
          [styles.outlined]: props.outlined,
        }),
        style: {
          borderBottomWidth: props.lineWidth,
        },
        children: <div>{tab.text}</div>,
        text: tab.text,
      };
      if (props.onTabChange) {
        return (
          <div key={index}>
            <div onClick={() => props.onTabChange(index)} {...linkProps} />
            <div className={styles.marker} />
          </div>
        );
      } else {
        return (
          <div key={index}>
            {typeof tab.to === 'string' && tab.to.startsWith('https://') ? (
              <Link
                to={{
                  pathname: tab.to,
                  search: props.keepSearchQuery
                    ? props.location.search
                    : undefined,
                }}
                {...linkProps}
              />
            ) : (
              <a href={tab.to} {...linkProps}>
                {linkProps.text}
              </a>
            )}
            <div className={styles.marker} />
          </div>
        );
      }
    })}
  </div>
);

Tabs.defaultProps = {
  align: 'center',
  lineWidth: 5,
  keepSearchQuery: true,
  outlined: false,
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  align: PropTypes.string,
  lineWidth: PropTypes.number,
  keepSearchQuery: PropTypes.bool,
  outlined: PropTypes.bool,
};

export default withRouter(Tabs);
