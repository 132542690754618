import axios from 'axios';
import moment from 'moment';
import store from 'store';
import AuthHelper from '../services/AuthHelper';
import reduxStore from '../createStore';
import { logout } from '../stores/me/actions';

export default (url, method = 'GET', data) => {
  return new Promise((resolve, reject) => {
    let params = ['GET', 'DELETE'].indexOf(method) !== -1 && data ? data : {};

    params.ts = moment().unix();

    let token = store.get('access_token');

    axios({
      baseURL: process.env.REACT_APP_Api_Url,
      withCredentials: true,
      method,
      url,
      data: method !== 'GET' ? data : null,
      params: params,
      headers: {
        Authorization: token ? 'Bearer ' + token : undefined,
      },
    })
      .then(resolve)
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          AuthHelper.logout();
          reduxStore.dispatch(logout());
        }

        reject(error);
      });
  });
};
