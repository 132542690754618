import store from 'store';
import types from './types';
import fetch from '../../utils/fetch';
import AuthHelper from '../../services/AuthHelper';
import shouldFetchMyKitchens from '../../selectors/me/shouldFetchMyKitchens';

export const setContext = context => ({
  type: types.SET_CONTEXT,
  payload: context,
});

export const getMe = () => (dispatch, getState) => {
  if (!getState().me.loading) {
    return;
  }

  const idToken = store.get('id_token');

  if (idToken === null) {
    dispatch(logout());
  } else {
    dispatch({
      type: types.GET_ME_START,
    });

    return fetch('/me', 'GET')
      .then(response => {
        dispatch({
          type: types.GET_ME_SUCCESS,
          payload: response.data,
        });
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          dispatch(logout());
        } else {
          dispatch({
            type: types.GET_ME_ERROR,
            error: error,
          });
        }
      });
  }
};

export const toggleCompany = () => dispatch => {
  // Toggle company view on home-page
  store.set('toggle_company', true);
};

export const togglePrivate = () => dispatch => {
  // Toggle private view on home-page
  store.set('toggle_company', false);
};

export const getToggleCompany = () => dispatch => {
  // Toggle private or company view on home-page
  // return store.get('toggle_company');
  return false;
};

export const loginSuccess = authResult => dispatch => {
  // Set the time that the access token will expire at
  store.set('access_token', authResult.accessToken);
  store.set('id_token', authResult.idToken);
  store.set('expires_at', authResult.idTokenPayload.exp);
};

export const logout = () => dispatch => {
  AuthHelper.logout();

  dispatch({
    type: types.LOGOUT,
  });
};

export const validateAuthentication = () => dispatch => {
  const accessToken = store.get('access_token');
  const idToken = store.get('id_token');
  let expiresAt = store.get('expires_at');

  if (
    accessToken &&
    idToken &&
    expiresAt &&
    new Date().getTime() / 1000 < parseInt(expiresAt, 10)
  ) {
    return;
  }

  if (!accessToken && !idToken && !expiresAt) return;

  dispatch(logout());
};

export const setSelectedCompanyAgreement = companyAgreementId => ({
  type: types.SET_COMPANY_AGREEMENT,
  payload: {
    companyAgreementId,
  },
});

export const updateAllergies = allergies => dispatch => {
  return fetch('/me/allergies', 'POST', allergies).then(response => {
    dispatch({
      type: types.UPDATE_ALLERGIES_SUCCESS,
      payload: response.data,
    });
  });
};

export const updateMyNotificationSettings = notificationSettings => dispatch => {
  return fetch('/me/notifications', 'POST', notificationSettings).then(
    response => {
      dispatch({
        type: types.UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
        payload: response.data,
      });
    }
  );
};

export const updateMyCustomerAddress = (customerId, data) => dispatch => {
  return fetch(`/customers/${customerId}/delivery-address`, 'POST', data).then(
    response => {
      dispatch({
        type: types.UPDATE_MY_CUSTOMER_DELIVERY_ADDRESS_SUCCESS,
        payload: {
          data: response.data,
          customerId,
        },
      });
    }
  );
};

export const getMyKitchensIfNeeded = () => (dispatch, getState) => {
  if (!shouldFetchMyKitchens(getState())) return;

  dispatch({
    type: types.GET_MY_KITCHENS_START,
  });

  return fetch('/me/kitchens', 'GET')
    .then(response => {
      dispatch({
        type: types.GET_MY_KITCHENS_SUCCESS,
        payload: {
          entities: response.data,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_MY_KITCHENS_ERROR,
        payload: {
          error,
        },
      });
    });
};

export const setSelectedKitchen = id => ({
  type: types.SET_SELECTED_KITCHEN,
  payload: {
    id,
  },
});

export const deleteCard = (customerId, id) => dispatch => {
  return fetch(`/customers/${customerId}/cards/${id}`, 'DELETE').then(
    response => {
      dispatch({
        type: types.DELETE_CARD_SUCCESS,
        payload: response.data,
      });
    }
  );
};

export const getAddCardToken = customerId => dispatch => {
  return fetch(`/customers/${customerId}/cards/token`, 'GET').then(
    response => response.data
  );
};

export const addCard = (customerId, sourceToken) => dispatch => {
  return fetch(`/customers/${customerId}/cards/${sourceToken}`, 'POST').then(
    response => {
      dispatch({
        type: types.ADD_CARD_SUCCESS,
        payload: response.data,
      });
    }
  );
};

export const approveConsent = alias => dispatch => {
  return fetch(`/me/consents/${alias}`, 'POST').then(response => {
    dispatch({
      type: types.UPDATE_MY_IDENTITY_SUCCESS,
      payload: response.data,
    });
  });
};

export const activate = () => dispatch => {
  return fetch(`/me/activate`, 'POST').then(response => {
    dispatch({
      type: types.UPDATE_MY_IDENTITY_SUCCESS,
      payload: response.data,
    });
  });
};

export const createCommunicationForUser = (userId, message) => dispatch => {
  return fetch(`/users/${userId}/add-communication`, 'POST', { message }).then(
    response => {
      dispatch({
        type: types.UPDATE_MY_IDENTITY_SUCCESS,
        payload: response.data,
      });
    }
  );
};
