import React from 'react';
import Header from '../../../components/Header';
import Container from '../../../components/Container';
import CheckPostalCode from './Steps/CheckPostalCode';
import SignupSteps from './SignupSteps/SignupStepsV2';
import MeetingFoodOrderInfo from './Steps/MeetingFoodOrderInfo';
import MeetingFoodCompanyInfo from './Steps/MeetingFoodCompanyInfo';
import styles from './SignupMeeting.module.css';

import { Helmet } from 'react-helmet';

import firstSubDate from '../../../utils/firstSubDate';
import vilkarAndPolicyAlias from '../../../utils/vilkarAndPolicyAlias';
import fetch from '../../../utils/fetch';

export default class MeetingFoodSignup extends React.Component {
  constructor(props) {
    super(props);

    const firstSubDateValue = firstSubDate();

    this.state = {
      step: 0,
      companyName: '',
      orgnr: '',
      deliveryLocation: null,
      extraDeliveryInfo: '',
      invoiceEmail: '',
      ehf: false,
      sendEmailText: null,
      userName: '',
      userPhone: '',
      userEmail: '',
      orders: [],
      date:
        [5, 6].indexOf(firstSubDateValue.weekday()) !== -1
          ? firstSubDateValue.add(1, 'week').startOf('week')
          : firstSubDateValue,
    };
  }

  signup() {
    this.setState({ loading: true });
    const data = {
      companyName: this.state.companyName,
      organizationNumber: this.state.orgnr,
      contactPersonName: this.state.userName,
      contactPersonEmail: this.state.userEmail,
      contactPersonPhone: this.state.userPhone,
      deliveryLocation: this.state.deliveryLocation,
      billingAddress: null,
      ehf: this.state.ehf,
      orders: this.state.orders.map(order => ({
        amount: order.menuAmount,
        type: order.type,
        allergies: order.allergies.map(a => ({ _id: a.id })),
        extraLarge: false,
      })),
      date: this.state.date,
      vilkarAndPolicyAlias,
      sendEmailText: this.state.sendEmailText,
    };

    this.setState({ loading: true });

    fetch('/meeting-food-signup', 'POST', data)
      .then(() => {
        this.props.history.push('/motemat-signup-done');
      })
      .catch(error => {
        console.error(error);
        this.setState({ loading: false, error });
      });
  }

  render() {
    const description = (
      <span style={{ fontSize: '1.2em' }}>
        Lunsjkollektivet leverer også mat til møtet ditt, enten det er
        medarbeidersamtale med deg og en ansatt eller planleggingsdag med 300
        ansatte!
        <p>
          <strong>NB! </strong>Er bedriften du jobber i allerede registrert?
          Da er det enkleste å logge inn for å bestille rett på bedriftsavtalen.
        </p>
      </span>
    );
    return (
      <div>
        <Helmet>
          <title>Lunsjkollektivet - Møtemat</title>
        </Helmet>
        <Header />
        <div className={styles.background}>
          <div style={{ borderTop: '1px solid #e7e7e7' }} />
          <Container height="50vh" width={870}>
            <SignupSteps
              values={this.state}
              onChange={state => this.setState(state)}
              onDone={() => this.signup()}
              onCancel={() => this.props.history.push('/')}
              onStep={(step, done) => this.setState({ step }, done)}
              step={this.state.step}
              onBack={() =>
                this.setState({
                  step: this.state.step > 0 ? this.state.step - 1 : 0,
                })
              }
            >
              <CheckPostalCode
                title={'Møtemat - Alle fortjener god lunsj'}
                content={description}
              />
              <MeetingFoodOrderInfo />
              <MeetingFoodCompanyInfo />
            </SignupSteps>
          </Container>
        </div>
      </div>
    );
  }
}
