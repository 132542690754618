import React from 'react';
import moment from 'moment';

import DatePicker from '../../../../components/DatePicker';
import Button from '../../../../components/Button';
import { ArrayInput } from '../../../../components/Form';
import DiscountPicker from '../../../../components/DiscountPicker';
import validateEmail from '../../../../utils/validateEmail';
import firstSubDate from '../../../../utils/firstSubDate';

class ClientInfo extends React.Component {
  state = {datePickerFocused: false};

  render() {
    return (
      <div style={{ marginTop: 50 }}>
        <h2 >Tilpass bedriftsavtale for <i>{this.props.companyName}</i></h2>
        <br />
        <h3 style={{ fontSize: '1.3em' }}>
          Skal bedriften dekke hele eller litt av lunsjen?
        </h3>
        <div>
          Bedriften betaler
          <DiscountPicker
            onChange={(discountType, discountAmount) =>
              this.props.onChange({
                discountType,
                discountAmount,
              })
            }
            type={this.props.discountType}
            amount={this.props.discountAmount}
          />
           av lunsjen til hver ansatt.
        </div>
        <div style={{ marginTop: '20px' }}>
          <h3 style={{ fontSize: '1.3em' }}>
            Første mulige oppstartsdato
          </h3>
          <p>Ansatte vil <strong>ikke</strong> automatisk startes opp på denne datoen, men ingen kan starte opp tidligere enn angitt dato.</p>
          <DatePicker
            colored
            required
            placeholder=""
            date={this.props.startDate}
            isOutsideRange={day => {
              return day.isBefore(moment(firstSubDate())) || day.isoWeekday() > 5;
            }}
            onDateChange={startDate => this.props.onChange({startDate})}
            focused={this.state.datePickerFocused}
            onFocusChange={({ focused }) =>
              this.setState({ datePickerFocused: focused })
            }
          />
        </div>
        <div style={{ marginTop: '40px' }}>
          <h3 style={{ fontSize: '1.3em' }}>
            Inviter flere fra jobben til bedriftsavtalen
          </h3>
          <p>Fyll inn <strong>e-poster</strong> her:</p>
          <p>
            <i>Det er enkelt å legge til flere ansatte senere også!</i>
          </p>
          <ArrayInput
            values={this.props.customers}
            validate={validateEmail}
            onChange={(value, valid) => {
              this.props.onChange({ customers: value });
              this.setState({ customers: valid });
            }}
          />
        </div>
        <br />
        <div className="mt-5">
          <Button size="medium" onClick={() => this.props.onNext()}>
            Fortsett
          </Button>
        </div>
      </div>
    );
  }
}

export default ClientInfo;
