import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../components/Header';
import Container from '../../../components/Container';
import Button from '../../../components/Button';
import { Input } from '../../../components/Form';
import Steps from '../Home/Steps';

import styles from './hjelp.module.css';

import avbestilling from '../Home/assets/avbestilling.svg';
import bestill from '../Home/assets/Illustration-laptop.svg';
import lunsj from '../Home/assets/Illustration-food.svg';
import kart from '../Home/assets/Illustration-map.svg';
import matboks from '../Home/assets/matboks.svg';

export default class Help extends React.Component {
  state = { search: '' };
  render() {
    return (
      <div>
        <Helmet>
          <title>Lunsjkollektivet - Slik virker det</title>
        </Helmet>
        <Header />
        <div className={styles.background}>
          <Container withPadding width={1000}>
            <div className={styles.stepBody}>
              <h1>Slik virker Lunsjkollektivet</h1>
              <div>
                <Steps
                  newLine
                  steps={[
                    {
                      img: bestill,
                      title: '1. Bestill lunsj',
                      content:
                        'Du bestiller raskt og enkelt lunsj via din egen bruker på nettsiden vår.',
                    },
                    {
                      img: lunsj,
                      title: '2. Lunsjen lages',
                      content:
                        'Kjøkkenet vårt lager fersk og sunn lunsj til deg hver dag.',
                    },
                    {
                      img: kart,
                      title: '3. Levering',
                      content:
                        'Lunsjen leveres rett på døra di, i god tid før lunsj. Hvis du ikke er til stede når vi leverer, sender vi deg et bilde av leveringen din.',
                    },
                  ]}
                />
              </div>
              <div className={styles.smallRow}>
                <Steps
                  newLine
                  steps={[
                    {
                      img: avbestilling,
                      title: '4. Avbestille',
                      content:
                        'Du kan bestille, endre og avbestille inntil klokken 11.00 dagen før.',
                    },
                    {
                      img: matboks,
                      title: '5. Nyt maten!',
                      content:
                        'Nå kan du ta en velfortjent pause fra en travel arbeidsdag og spise lunsj som er bra for kopp og humør.',
                    },
                  ]}
                />
              </div>
              <div className="d-flex flex-center" style={{ marginTop: 20 }}>
                <Button size="medium" to="signup">
                  Kom i gang
                </Button>
              </div>
              <div className={styles.contactInfo}>
                <p>
                  Lurer du på noe mer? Ta gjerne kontakt med oss på{' '}
                  <a
                    href="mailto:hei@lunsjkollektivet.no"
                    className={styles.link}
                  >
                    hei@lunsjkollektivet.no
                  </a>{' '}
                  eller{' '}
                  <a href="tel:72909005" className={styles.link}>
                    729 09 005.
                  </a>
                </p>
              </div>
            </div>
            <img
              src={'/images/logo_white.png'}
              alt="LunsjKollektivet"
              draggable="false"
              className={styles.logo}
            ></img>
          </Container>{' '}
        </div>
        <Container color="gray" width={800} withPadding>
          <h2>Søk i hjelpesenteret</h2>
          <div style={{ paddingBottom: '2em' }}>
            <Button
              onClick={() =>
                (window.location.href = 'https://hjelp.lunsjkollektivet.no')
              }
            >
              Gå til hjelpesenteret
            </Button>
          </div>
          <form
            className={styles.searchInput}
            onSubmit={e => {
              window.location.href =
                'https://hjelp.lunsjkollektivet.no?q=' + this.state.search;
              e.preventDefault();
            }}
          >
            <Input
              value={this.state.search}
              onChange={search => this.setState({ search })}
              withPadding={false}
              placeholder="Hvordan bestille møtemat?"
            />
            <div
              className={styles.button}
              onClick={() =>
                (window.location.href =
                  'https://hjelp.lunsjkollektivet.no?q=' + this.state.search)
              }
            >
              Søk
            </div>
          </form>

          <div style={{ fontSize: '0.7em' }}>
            <i>* Du blir vidersendt til hjelpesenteret</i>
          </div>
        </Container>
        {/* <Instagram shortened /> */}
      </div>
    );
  }
}
