import getSubscriptionPrice from '../../utils/subscriptionPrice';

export default (
  subscription,
  companyAgreement,
  values,
  hasFullWeekDiscount,
  enablePriceSplitting,
  deliveryPrice = 0
) => {
  let total = values.reduce(
    (total, value) =>
      total +
      value.menuAmount *
      getSubscriptionPrice(subscription, companyAgreement, value.type),
    0
  );

  if (deliveryPrice) {
    total += deliveryPrice;
  }

  let response = {
    customer: total,
    company: 0,
  };

  if (
    enablePriceSplitting &&
    companyAgreement &&
    companyAgreement.employeeDiscount > 0
  ) {
    if (companyAgreement.discountType === 'amount') {
      if (companyAgreement.employeeDiscount >= total) {
        response = {
          customer: 0,
          company: total,
        };
      } else {
        response = {
          customer: total - companyAgreement.employeeDiscount,
          company: companyAgreement.employeeDiscount,
        };
      }
    } else {
      let company = (total * companyAgreement.employeeDiscount) / 100;
      response = {
        customer: total - company,
        company,
      };
    }
  }

  return response;
};

const getSubscriptionType = (subscriptionTypes, type) => {
  return (
    subscriptionTypes.find(
      subscriptionType => subscriptionType.id === type.id
    ) || {}
  );
};

export const getSumForDaySingleOrder = (
  subscription,
  subscriptionTypes,
  companyAgreement,
  values
) => {
  return values.reduce(
    (total, value) =>
      total +
      (value.menuAmount === undefined ? value.amount : value.menuAmount) *
      getSubscriptionPrice(
          subscription,
          companyAgreement,
          getSubscriptionType(subscriptionTypes, value.type)
        ),
    0
  );
};