import queryString from 'query-string';
import types from './types';

export const init = () => {
  const query = queryString.parse(location.search); //eslint-disable-line

  return {
    type: types.INIT,
    payload: {
      ...query,
    },
  };
};
