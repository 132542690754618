import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';

import types from './types';
import { getKey, getKeyForCustomerWeek } from './selectors';

export const getIfNeeded = date => (dispatch, getState) => {
  const key = getKey(date);
  if (!shouldFetch(getState().deliveryInfo.meta[key])) return;

  dispatch({
    type: types.GET_START,
    payload: {
      key,
    },
  });

  return fetch('/delivery-infos/' + date.format('YYYY-MM-DD'))
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          key,
          deliveryInfo: response.data,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};

export const getForCustomerIfNeeded = (customerId, week) => (
  dispatch,
  getState
) => {
  const key = getKeyForCustomerWeek(customerId, week);
  if (!shouldFetch(getState().deliveryInfo.meta[key])) return;

  dispatch({
    type: types.GET_START,
    payload: {
      key,
    },
  });

  return fetch(`/customers/${customerId}/delivery-infos`, 'GET', {
    from: week
      .clone()
      .startOf('week')
      .format('YYYY-MM-DD'),
    to: week
      .clone()
      .endOf('week')
      .format('YYYY-MM-DD'),
  })
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          key,
          deliveryInfo: response.data,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};
