import types from './types';
import firstSubDate from '../../utils/firstSubDate';
import moment from 'moment';

const initialState = {
  entities: {},
  subscriptions: [],
  meta: {},
};

const checkSubscriptionsForPause = (a, b) => {
  if (
    !a.endDate ||
    moment(b.startDate).format('YYYY-MM-DD') <=
      moment(a.endDate)
        .clone()
        .add(1, 'days')
        .format('YYYY-MM-DD') ||
    moment(b.startDate).isSameOrBefore(moment(firstSubDate()), 'day') ||
    b.endDate
  )
    return null;

  return {
    pauseFrom: moment(a.endDate),
    pauseTo: moment(b.startDate),
  };
};

const subscriptionsToKeySubscriptions = subscriptions => {
  return subscriptions.reduce((keySubscriptions, subscription) => {
    if (!keySubscriptions[subscription.key]) {
      keySubscriptions[subscription.key] = subscription;
    } else {
      const keySubscription = keySubscriptions[subscription.key];

      const checkPause = checkSubscriptionsForPause(
        keySubscription,
        subscription
      );

      if (checkPause) {
        keySubscriptions[subscription.key].pause = checkPause;
      }

      if (
        !subscription.endDate ||
        (keySubscription.endDate &&
          moment(keySubscription.endDate).isBefore(
            moment(subscription.endDate)
          ))
      ) {
        if (!subscription.endDate) {
          keySubscriptions[subscription.key].endDate = null;
        } else {
          keySubscriptions[subscription.key].endDate = subscription.endDate;
        }
        keySubscriptions[subscription.key].startDate = subscription.startDate;
        keySubscriptions[subscription.key].days = subscription.days;
        keySubscriptions[subscription.key].data = subscription.data;
        keySubscriptions[subscription.key].type = subscription.type;
      }
    }
    return keySubscriptions;
  }, {});
};

const modifySubscription = (metaKey, state, subscription) => {
  let newEntities = { ...state.entities };

  let keys = state.meta[metaKey].keys;

  if (keys.indexOf(subscription.key) === -1) {
    keys = [...keys, subscription.key];
  }

  if (newEntities[subscription.key]) {
    newEntities[subscription.key] = {
      ...newEntities[subscription.key],
      startDate: subscription.startDate,
      endDate: subscription.endDate,
      days: subscription.days,
      data: subscription.data,
      type: subscription.type,
    };
  } else {
    newEntities[subscription.key] = subscription;
  }

  return {
    ...state,
    entities: newEntities,
    meta: {
      ...state.meta,
      [metaKey]: {
        loading: false,
        saving: false,
        success: true,
        error: false,
        loadedAt: moment().unix(),
        keys,
      },
    },
  };
};

export default (state = initialState, action) => {
  let entities = {
    ...state.entities,
  };

  switch (action.type) {
    case types.PAUSE_FOR_CUSTOMER_START:
    case types.UNPAUSE_FOR_CUSTOMER_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [`customerId-${action.payload.customerId}`]: {
            ...state.meta[`customerId-${action.payload.customerId}`],
            loading: true,
            error: false,
          },
        },
      };
    case types.GET_FOR_CUSTOMER_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [`customerId-${action.payload.customerId}`]: {
            loading: true,
            error: false,
            loadedAt: null,
            id: null,
          },
        },
      };
    case types.GET_ALL_FOR_CUSTOMER_START:
      return {
        ...state,
      };
    case types.PAUSE_FOR_CUSTOMER_SUCCESS:
    case types.UNPAUSE_FOR_CUSTOMER_SUCCESS:
    case types.GET_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...subscriptionsToKeySubscriptions(action.payload.subscriptions),
        },
        meta: {
          ...state.meta,
          [`customerId-${action.payload.customerId}`]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            keys: Object.keys(
              subscriptionsToKeySubscriptions(action.payload.subscriptions)
            ),
          },
        },
      };
    case types.GET_ALL_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload.subscriptions,
      };
    case types.PAUSE_FOR_CUSTOMER_ERROR:
    case types.UNPAUSE_FOR_CUSTOMER_ERROR:
    case types.GET_FOR_CUSTOMER_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [`customerId-${action.payload.customerId}`]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            keys: state.meta[`customerId-${action.payload.customerId}`].keys,
          },
        },
      };
    case types.GET_ALL_FOR_CUSTOMER_ERROR:
      return {
        ...state,
      };
    case types.GET_FOR_COMPANYAGREEMENT_CUSTOMERS_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [`companyAgreementId-${action.payload.companyAgreementId}`]: {
            loading: true,
            error: false,
            loadedAt: null,
            id: null,
          },
        },
      };
    case types.GET_FOR_COMPANYAGREEMENT_CUSTOMERS_SUCCESS:
      let meta = {
        ...state.meta,
        [`companyAgreementId-${action.payload.companyAgreementId}`]: {
          loading: false,
          error: false,
          loadedAt: moment().unix(),
          id: null,
        },
      };
      action.payload.customerSubscriptions.map(cs => {
        meta[`customerId-${cs.customer.id}`] = {
          loading: false,
          error: false,
          loadedAt: moment().unix(),
          keys: Object.keys(subscriptionsToKeySubscriptions(cs.subscriptions)),
        };
        entities = {
          ...entities,
          ...subscriptionsToKeySubscriptions(cs.subscriptions),
        };
        return true;
      });
      return {
        ...state,
        entities,
        meta,
      };
    case types.GET_FOR_COMPANYAGREEMENT_CUSTOMERS_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [`companyAgreementId-${action.payload.companyAgreementId}`]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            id: null,
          },
        },
      };
    case types.CREATE_FOR_CUSTOMER_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [`customerId-${action.payload.customerId}`]: {
            ...state.meta[`customerId-${action.payload.customerId}`],
            saving: true,
            success: false,
            error: false,
            loadedAt: null,
            id: null,
          },
        },
      };
    case types.CREATE_FOR_CUSTOMER_SUCCESS:
      return modifySubscription(
        `customerId-${action.payload.customerId}`,
        state,
        action.payload.subscription
      );
    case types.CREATE_FOR_CUSTOMER_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [`customerId-${action.payload.customerId}`]: {
            loading: false,
            saving: false,
            success: false,
            error: true,
            loadedAt: moment().unix(),
            id: null,
          },
        },
      };
    case types.UPDATE_FOR_CUSTOMER_START:
    case types.DEACTIVATE_START:
    case types.DELETE_FOR_CUSTOMER_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [`customerId-${action.payload.customerId}`]: {
            ...state.meta[`customerId-${action.payload.customerId}`],
            saving: true,
            success: false,
            error: false,
            loadedAt: null,
            id: null,
          },
        },
      };
    case types.UPDATE_FOR_CUSTOMER_SUCCESS:
      return modifySubscription(
        `customerId-${action.payload.customerId}`,
        state,
        action.payload.subscription
      );
    case types.UPDATE_FOR_CUSTOMER_ERROR:
    case types.DEACTIVATE_ERROR:
    case types.DELETE_FOR_CUSTOMER_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [`customerId-${action.payload.customerId}`]: {
            loading: false,
            saving: false,
            success: false,
            error: true,
            loadedAt: moment().unix(),
            id: null,
          },
        },
      };
    case types.DELETE_FOR_CUSTOMER_SUCCESS:
    case types.DEACTIVATE_SUCCESS:
      if (entities[action.payload.key]) {
        delete entities[action.payload.key];
      }

      return {
        ...state,
        entities: {
          ...entities,
          ...subscriptionsToKeySubscriptions(action.payload.subscriptions),
        },
        meta: {
          ...state.meta,
          [`customerId-${action.payload.customerId}`]: {
            loading: false,
            error: false,
            saving: false,
            success: true,
            loadedAt: moment().unix(),
            keys: Object.keys(
              subscriptionsToKeySubscriptions(action.payload.subscriptions)
            ),
          },
        },
      };

    case types.CREATE_FOR_CUSTOMERS_SUCCESS:
      let createState = state;

      action.payload.customers.forEach(c => {
        createState = modifySubscription(
          `customerId-${c.customerId}`,
          createState,
          c.subscription
        );
      });

      return createState;

    case types.DEACTIVATE_FOR_CUSTOMERS_SUCCESS:
      const newState = {
        ...state,
        entities: {
          ...entities,
        },
      };

      action.payload.customers.forEach(c => {
        if (newState.entities[c.subscriptionKey]) {
          delete newState.entities[c.subscriptionKey];
        }

        newState.meta[`customerId-${c.customerId}`] = {
          loading: false,
          error: false,
          saving: false,
          succes: true,
          loadedAt: moment().unix(),
          keys: [],
        };
      });

      return newState;

    case types.GET_MULTIPLE_ACTIVE_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: {
            loading: true,
            error: false,
            loadedAt: null,
          },
        },
      };
    case types.GET_MULTIPLE_ACTIVE_SUCCESS:
      return {
        ...state,
        status: action.payload.customerSubscriptions,
        meta: {
          ...state.meta,
          status: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
          },
        },
      };
    case types.GET_MULTIPLE_ACTIVE_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: {
            loading: false,
            error: true,
            loadedAt: null,
          },
        },
      };
    case types.DELETE_MULTIPLE_ACTIVE_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: {
            loading: false,
            saving: true,
            error: false,
            loadedAt: null,
          },
        },
      };
    case types.DELETE_MULTIPLE_ACTIVE_SUCCESS:
      return {
        ...state,
        status: state.status
          .map(cs => {
            return {
              customer: cs.customer,
              subscriptions: cs.subscriptions.filter(
                s => !action.payload.subscriptionIds.includes(s.id)
              ),
            };
          })
          .filter(cs => cs.subscriptions.length > 1),
        meta: {
          ...state.meta,
          status: {
            loading: false,
            saving: false,
            error: false,
            loadedAt: moment().unix(),
          },
        },
      };
    case types.DELETE_MULTIPLE_ACTIVE_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          status: {
            loading: false,
            saving: false,
            error: true,
            loadedAt: null,
          },
        },
      };
    default:
      return state;
  }
};
