import React from 'react';
import { connect } from 'react-redux';
import { CheckboxGroup, OptionBox, OptionGroup } from './Form';

/**
 * Me Store functions
 */
import { updateMyNotificationSettings } from '../stores/me/actions';

/**
 * Notification Definition Store functions
 */
import {
  selectAll,
  selectAllMeta,
} from '../stores/notificationDefinition/selectors';

import { getAllIfNeeded as getAllNotificationDefinition } from '../stores/notificationDefinition/actions';

class NotificationConsents extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: [] };
  }

  componentDidMount() {
    this.props.getAllNotificationDefinition();
    this.props.onChange(this.props.values, false)
  }

  update(newAlias, emailAllowed) {
    let newValues = [...this.props.values];

    this.props.notificationDefinitions.forEach(nd => {
      const index = newValues.findIndex(
        value => value.notificationDefinitionAlias === nd.alias
      );

      if (index === -1) {
        newValues.push({
          notificationDefinitionAlias: nd.alias,
          textShownToUser: nd.description,
          emailAllowed: false,
        });
      }
    });

    if (newAlias) {
      const index = newValues.findIndex(
        nv => nv.notificationDefinitionAlias === newAlias
      );

      if (index !== -1) {
        newValues[index] = {
          ...newValues[index],
          emailAllowed,
        };
      }
    }

    let newAmount = this.state.selected.length;

    if (!this.state.selected.includes(newAlias)) {
      this.setState({selected: [...this.state.selected, newAlias]})
      newAmount += 1;
    }

    this.props.onChange(newValues, newAmount === this.props.notificationDefinitions.length)
  }

  getCheckStatus(alias) {
    const value = this.props.values.find(
      v => v.notificationDefinitionAlias === alias
    );

    if (value) return value.emailAllowed;
    return false;
  }

  render() {
    return (
      <CheckboxGroup direction="column">
        {this.props.notificationDefinitions.map(definition => (
          <OptionGroup row>
          <div style={{marginBottom: '6px'}}>
            {definition.description}
          </div>
          {[{name: "Ja, takk!", value: true}, {name: "Nei, takk.", value: false}].map(answer => (
            <OptionBox
              key={answer.name}
              checked={this.getCheckStatus(definition.alias) === answer.value
                && this.state.selected.includes(definition.alias)}
              onClick={() => this.update(definition.alias, answer.value)}
              label={answer.name}
            />
          ))}
        </OptionGroup>
        ))}
      </CheckboxGroup>
    );
  }
}

export default connect(
  state => ({
    notificationDefinitions: selectAll(state),
    meta: selectAllMeta(state),
  }),
  { updateMyNotificationSettings, getAllNotificationDefinition }
)(NotificationConsents);
