import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Day as DayOrder } from '../../../VisualLunchCalendar';
import Alert from '../../../Alert';
import Loader from '../../../Loader';
import { addChoice } from '../../../../utils/lunchOverviewUtils';

/**
 * Menu Store functions
 */
import { getForWeekIfNeeded as getMenusForWeekIfNeeded } from '../../../../stores/menu/actions';
import selectMenusForWeek from '../../../../selectors/menu/selectForWeek';
import selectMenusMetaForWeek from '../../../../selectors/menu/selectMetaForWeek';

/**
 * NoDeliveryDay Store functions
 */
import { getForWeekIfNeeded as getNoDliveryDayForWeekIfNeeded } from '../../../../stores/noDeliveryDay/actions';
import selectNoDeliveryDaysForWeek from '../../../../selectors/noDeliveryDay/selectForWeek';
import selectNoDeliveryDaysMetaForWeek from '../../../../selectors/noDeliveryDay/selectMetaForWeek';

class MenuPicker extends React.Component {
  componentDidMount() {
    this.props.getMenusForWeekIfNeeded(this.props.date);
    this.props.getNoDliveryDayForWeekIfNeeded(this.props.date);
  }

  componentDidUpdate() {
    this.props.getMenusForWeekIfNeeded(this.props.date);
    this.props.getNoDliveryDayForWeekIfNeeded(this.props.date);
  }

  getValuesForDay() {
    let values = this.props.subscriptionTypes.map(subscriptionType => ({
      menuAmount: 0,
      type: subscriptionType,
      allergies: [],
    }));

    if (!this.props.orders) return values;

    this.props.orders.forEach(value => {
      values = addChoice(values, value);
    });

    return values;
  }

  getNoDelivery(date) {
    const noDeliveryDay = this.props.noDeliveryDays.find(noDeliveryDay =>
      date.isSame(moment(noDeliveryDay.date), 'day')
    );
    if (!noDeliveryDay) return null;
    return noDeliveryDay.reason;
  }

  menusForDayPerType(day) {
    return this.props.menus.reduce((menusForDayPerType, menu) => {
      if (moment(menu.date).isSame(day, 'day')) {
        menusForDayPerType[menu.type.id] = menu.meal;
      }
      return menusForDayPerType;
    }, {});
  }
  
  onChange(changes) {
    this.props.onChange({
      orders: changes.reduce(
        (values, change) => addChoice(values, change),
        this.getValuesForDay()
      )
    });
  }

  buildContent() {
    if (this.props.error) {
      return (
        <Alert color="red">
          Noe skjedde når vi lastet inn siden. Vennligst prøv igjen senere
        </Alert>
      );
    }

    if (this.props.loading) {
      return <Loader />;
    }

    return (
      <div>
        <h3>Velg lunsj</h3>
      <DayOrder
        key={this.props.date.format()}
        day={this.props.date}
        subscriptionTypes={this.props.subscriptionTypes}
        companyAgreement={this.props.companyAgreement}
        withMenu={this.props.withMenu}
        values={this.getValuesForDay()}
        noDeliveryReason={this.getNoDelivery(this.props.date)}
        onChange={changes => this.onChange(changes)}
        menus={this.menusForDayPerType(this.props.date)}
        onCancelOrder={() => {}}
        hasActiveSubscription
        enableAllergySelection={!this.props.disableAllergies}
        subscriptionTypeUser="meeting"
        showWrapper={false}
        showButtons={false}
        admin={this.props.admin}
        extraModalOpened={this.props.extraModalOpened}
        onExtraModalChange={this.props.onExtraModalChange}
        kitchen={this.props.kitchen}
        setHomeMenuAmount={value => this.props.setHomeMenuAmount(value)}
        setHomeMenuAmountVegetar={value => this.props.setHomeMenuAmountVegetar(value)}
        homeMenuAmount={this.props.homeMenuAmount}
        homeMenuAmountVegetar={this.props.homeMenuAmountVegetar}
      />
      </div>
    );
  }

  render() {
    return <div >{this.buildContent()}</div>;
  }
}

export default connect(
  (state, ownProps) => {
    const menusMeta = selectMenusMetaForWeek(state, ownProps.date);
    const noDeliveryDayMeta = selectNoDeliveryDaysMetaForWeek(
      state,
      ownProps.date
    );

    return {
      noDeliveryDays: selectNoDeliveryDaysForWeek(state, ownProps.date),
      menus: selectMenusForWeek(state, ownProps.date),
      loading:
        state.subscriptionType.meta.loading ||
        menusMeta.loading ||
        noDeliveryDayMeta.loading,
      error:
        state.subscriptionType.meta.error ||
        menusMeta.error ||
        noDeliveryDayMeta.error,
    };
  },
  {
    getMenusForWeekIfNeeded,
    getNoDliveryDayForWeekIfNeeded,
  }
)(MenuPicker);
