import React from 'react';
import ReportTable from '../../../../../../components/Reports/CompanyAgreementDailyReport';
import DayReport from '../../../../../../components/Reports/DayReport';
import Container from '../../../../../../components/Container';
import { BackButton } from '../../../../../../components/Button';
import CompanyAgreementHoc from '../components/CompanyAgreementHoc';

const Table = companyAgreement => props => (
  <ReportTable
    {...props}
    companyAgreement={{ id: companyAgreement.id }}
    minSideBedrift
  />
);

const report = props => (
  <Container color="gray" withPadding height="42vh">
    <BackButton to="https://company.lunsjkollektivet.no/" />
    <div style={{ marginTop: 20 }}>
      <DayReport component={Table(props.companyAgreement)} />
    </div>
  </Container>
);

export default () => <CompanyAgreementHoc children={report} />;
