import React, { useState, useEffect, useRef } from 'react';
import styles from './dropDown.module.css';
import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';
import Chevron from '../Chevron';

export function DropDown({ currentOption, onChange, options }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverref = useRef(null);

  useOnScrollOutside(
    popoverref,
    () => {
      setIsPopoverOpen(false);
    },
    isPopoverOpen
  );

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']} // preferred positions by priority
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ childRect }) => (
        <div
          ref={popoverref}
          style={{ width: childRect.width }}
          className={styles.popoverContainer}
        >
          {options?.map(option => {
            return (
              <div
                key={option.alias}
                onClick={() => {
                  onChange(option);
                  setIsPopoverOpen(false);
                }}
                onScroll={() => {
                  setIsPopoverOpen(false);
                  console.log('scrolling');
                }}
                className={styles.optionContainer}
              >
                {option.currentPrice ? (
                  <div className={styles.displayExtra}>
                    <span className={styles.name}>{option.name}</span>
                    <span className={styles.description}>
                      {option.currentPrice},-
                    </span>
                  </div>
                ) : (
                  <span className={styles.name}>{option.name}</span>
                )}
                {option.description && (
                  <i className={styles.description}>{option.description}</i>
                )}
              </div>
            );
          })}
          <div
            onClick={() => {
              onChange(null);
              setIsPopoverOpen(false);
            }}
            className={styles.optionContainer}
          >
            <span className={styles.name}>Ingen</span>
          </div>
        </div>
      )}
    >
      <div
        className={styles.dropDownBox}
        onClick={() => setIsPopoverOpen(prev => !prev)}
      >
        {currentOption?.name || 'Ingen'}
        <div>
          <Chevron
            color={'#b3b3b3'}
            size="15px"
            reversed={isPopoverOpen}
            direction="down"
          />
        </div>
      </div>
    </Popover>
  );
}

export function useOnScrollOutside(ref, handler, change) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('wheel', listener);
    document.addEventListener('touchmove', listener);
    return () => {
      document.removeEventListener('wheel', listener);
      document.removeEventListener('touchmove', listener);
    };
  }, [ref, handler, change]);
}

DropDown.propTypes = {
  currentOption: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    extra: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
      extra: PropTypes.string,
    }).isRequired
  ),
  onChange: PropTypes.func.isRequired,
};

export default DropDown;
