import moment from 'moment';
import firstSubDate from './firstSubDate';

export const getSubscriptionEntry = (date, subscription) => {
  const day = date
    .clone()
    .locale('en')
    .format('dddd')
    .toLowerCase();

  if (subscription && subscription.type && subscription.type === 'daily') {
    const selectedDayData =
      subscription.data.dailyEntries.find(de => de.day === day) || {};

    return selectedDayData.data || null;
  }

  if (
    subscription &&
    subscription.days &&
    subscription.days.indexOf(
      date
        .clone()
        .locale('en')
        .format('dddd')
        .toLowerCase()
    ) !== -1
  ) {
    return subscription.data.entries;
  }

  return null;
};

export const getDayValues = (
  date,
  choices,
  subscriptionEntries,
  values,
  subscriptionTypes,
  orders,
  isAdmin = false
) => {
  const choice = choices.find(choice =>
    moment(choice.date).isSame(date, 'day')
  );
  const subscription = getSubscriptionForDate(date, subscriptionEntries);
  const subscriptionDetails = getSubscriptionEntry(date, subscription);

  return buildValues(
    date,
    choice && subscription ? choice.data.entries : null,
    subscriptionDetails,
    values,
    subscriptionTypes,
    getHistoryDataForDay(date, orders).details,
    isAdmin
  );
};

export const getHistoryDataForDay = (date, orders) => {
  if (!orders || orders.length === 0)
    return {
      details: [],
      deliveryPrice: 0,
    };

  const order = orders.find(order => moment(order.date).isSame(date, 'day'));

  if (order) {
    return {
      details: order.details,
      deliveryPrice: order.summary.deliveryPrice,
    };
  }

  return {
    details: [],
    deliveryPrice: 0,
  };
};

export const addChoice = (original, value) => {
  let dayValues = original;

  let found = false;

  dayValues = dayValues.reduce((dayValues, dayValue) => {
    if (
      dayValue.type &&
      value.type &&
      dayValue.type.id === value.type.id &&
      value.allergies.length === dayValue.allergies.length &&
      !value.allergies.find(
        allergy => dayValue.allergies.findIndex(a => a.id === allergy.id) === -1
      )
    ) {
      dayValue.menuAmount = value.menuAmount;
      found = true;
    }
    dayValues.push(dayValue);
    return dayValues;
  }, []);

  if (!found) {
    dayValues.push(value);
  }

  return dayValues;
};

const buildValues = (
  date,
  choiceDetails,
  subscriptionDetails,
  values,
  subscriptionTypes,
  orderDetails,
  isAdmin
) => {
  let firstHistoryTime = firstSubDate().add(-5, 'day');

  // if (isAdmin) {
  //   firstHistoryTime = moment().startOf('day');
  // }

  if (firstHistoryTime.isSameOrAfter(date, 'day')) {
    return orderDetails.map(d => ({
      menuAmount: d.amount,
      type: subscriptionTypes.find(st => st.alias === d.type),
      allergies: d.allergies.map(a => ({
        name: a.name,
        id: a._id,
      })),
    }));
  }

  let newValues = subscriptionTypes.map(subscriptionType => ({
    menuAmount: 0,
    type: subscriptionType,
    allergies: [],
  }));

  // if we have a choice then overwrite the subscription
  if (choiceDetails) {
    choiceDetails.forEach(detail => {
      newValues = addChoice(
        newValues,
        detailToValue(subscriptionTypes, detail)
      );
    });
  } else if (subscriptionDetails) {
    subscriptionDetails.forEach(detail => {
      newValues = addChoice(
        newValues,
        detailToValue(subscriptionTypes, detail)
      );
    });
  }

  if (!values) return newValues;

  // choices made in frontend always overwrites whats in the db
  values.forEach(value => {
    newValues = addChoice(values, value);
  });

  return newValues;
};

const detailToValue = (subscriptionTypes, detail) => ({
  menuAmount:
    detail.menuAmount !== undefined ? detail.menuAmount : detail.amount,
  type: subscriptionTypes.find(st =>
    typeof detail.type === 'string'
      ? st.alias === detail.type
      : st.id === detail.type.id
  ),
  allergies: detail.allergies ? detail.allergies : [],
});

export const menusForDayPerType = (day, menus) => {
  return menus.reduce((menusForDayPerType, menu) => {
    if (moment(menu.date).isSame(day, 'day')) {
      menusForDayPerType[menu.type.id] = {
        ...menu.meal,
        menuId: menu.id,
      };
    }
    return menusForDayPerType;
  }, {});
};

export const ratingsToMenuKey = ratings => {
  return ratings.reduce((menuKeyed, rating) => {
    menuKeyed[rating.menu.id] = rating;
    return menuKeyed;
  }, {});
};

export const getNoDelivery = (date, noDeliveryDays) => {
  const noDeliveryDay = noDeliveryDays.find(noDeliveryDay =>
    date.isSame(moment(noDeliveryDay.date), 'day')
  );
  if (!noDeliveryDay) return null;
  return noDeliveryDay.reason;
};

export const getSubscriptionForDate = (date, subscriptionEntries) => {
  const sub = subscriptionEntries.find(
    entry =>
      date.isSameOrAfter(moment(entry.startDate), 'day') &&
      (!entry.endDate || date.isSameOrBefore(moment(entry.endDate), 'day'))
  );

  if (
    !sub ||
    (sub.pause &&
      moment(sub.pause.pauseFrom).isBefore(date, 'day') &&
      moment(sub.pause.pauseTo).isAfter(date, 'day'))
  )
    return null;

  return sub;
};

export const isExtraLarge = (type, subscription) => {
  if (!subscription || !subscription.data || !subscription.data.entries)
    return false;
  const subscriptionValue = subscription.data.entries.find(
    c => type === c.type
  );
  if (!subscriptionValue) return false;

  return subscriptionValue.menuExtraLarge;
};

export const isPastOrderDate = (date, isAdmin, type) => {
  // company-subscription can always be changed
  var now = moment();

  // Do not look at the exact hour if the limit is larger than 24 hours
  var h =
    type && type.limitHours > 0 && type.limitHours < 24 ? type.limitHours : 11;

  var deadline = date.clone().set({
    hour: h,
    minute: 0,
    seconds: 0,
  });

  // If user is admin, add 10 min
  if (isAdmin === true) {
    deadline.add({
      minute: 10,
    });
  }

  // If the sub-type has a limit larger than 24h:
  // Calculate what day the limit is, not the specific hour
  if (type && type.limitHours >= 24) {
    if (isAdmin === true) {
      // If user is admin, only set half the day limit
      deadline.subtract(type.limitHours / (24 * 2), 'days');
    } else {
      deadline.subtract(type.limitHours / 24, 'days');
    }
  }
  // If the limit is less than 24h (normal limit), set to one day prior
  else {
    // Unless date is a monday, then the date to compare with is friday the week before
    if (deadline.isoWeekday() === 1) {
      deadline.subtract(3, 'days');
    } else {
      deadline.subtract(1, 'days');
    }
  }

  var isPastOrderDate = now.isAfter(deadline);

  console.debug(
    `Comparing deadline ${deadline} (Order Date: ${date}) with current timestamp ${now}, for admin: ${isAdmin}, gives: ${isPastOrderDate}`
  );

  return isPastOrderDate;
};
