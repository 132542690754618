import React from 'react';
import moment from 'moment';
import WeekPicker from '../../../components/WeekPicker';
import Card from '../../../components/Card/Card';
import Menu from './Menu';
import queryString from 'query-string';
import Container from '../../../components/Container';
import cn from 'classnames';

import styles from './lunch.module.css';

class Lunch extends React.Component {
  constructor(props) {
    super(props);
    let query = queryString.parse(location.search); // eslint-disable-line

    if (!query.uke) {
      query.uke = moment().isoWeek() + '-' + moment().year();
      props.history.push({
        search: queryString.stringify(query),
      });
    }

    const parts = query.uke.split('-');

    this.state = {
      week: moment()
        .year(parseInt(parts[1], 10))
        .isoWeek(parseInt(parts[0], 10)),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.week !== this.state.week) {
      this.props.history.push({
        search: queryString.stringify({
          uke: this.state.week.isoWeek() + '-' + this.state.week.year(),
        }),
      });
    }
  }

  render() {
    return (
      <div
        style={{
          backgroundImage: !this.props.campaign ? `url(${'/images/backgrounds/signup2-bkg.jpg'})` : "",
          backgroundSize: 'cover',
        }}
      >
        <Container width={800} withPadding={!this.props.campaign}>
        <div style={this.props.campaign ? {paddingBottom: '3rem'} : {}}>
          <Card rounded={!this.props.campaign} withPadding transparent={this.props.campaign}>
            <h1 className={cn(styles.textCenter, styles.header)}>Ukens lunsjmeny</h1>
            <WeekPicker
              week={this.state.week}
              onChange={week => this.setState({ week: week.clone() })}
            />
              <Menu week={this.state.week} type={this.props.type} />
          </Card>
        </div>
        </Container>
      </div>
    );
  }
}

export default Lunch;
