import React from 'react';

import Button from '../../../../components/Button';
import { Checkbox, CheckboxGroup, Input3 } from '../../../../components/Form';
import DeliveryLocationPicker from '../../../../components/DeliveryLocationPicker/DeliveryLocationPicker';
import validateEmail from '../../../../utils/validateEmail';

class ClientInfo extends React.Component {
  state = {};

  getTotal() {
    var total = 0;
    var i, amount, price;
    for (i = 0; i < this.props.orders.length; i++) {
      amount = this.props.orders[i].menuAmount;
      price = this.props.orders[i].type ? this.props.orders[i].type.price : 0;
      total += amount * price;
    }
    return total;
  }
  render() {
    return (
      <div>
        <strong>Totalt for maten: kr {this.getTotal()};</strong>
        <br />
        <h2>Informasjon om bedriften</h2>
        <br />
        <div>
          <Input3
            label="Firmanavn"
            placeholder="Skriv bedriftens navn"
            value={this.props.companyName}
            onChange={(value, valid) => {
              this.props.onChange({ companyName: value });
              this.setState({ companyName: valid });
            }}
            validate={string => string.length > 2}
            errorMessage="Navn må være minst 2 bokstaver lang"
          />
          <Input3
            label="Org. nummer"
            placeholder="9 siffer"
            value={this.props.orgnr}
            onChange={(value, valid) => {
              this.props.onChange({ orgnr: value });
              this.setState({ orgnr: valid });
            }}
            validate={string => string.length === 9}
            errorMessage="Organisasjonsnummer må inneholde 9 nummer"
          />
          <DeliveryLocationPicker
            onChange={deliveryLocation =>
              deliveryLocation && deliveryLocation.address
                ? this.props.onChange({
                  deliveryLocation: {
                    ...deliveryLocation,
                    address: {
                      ...deliveryLocation.address,
                      addressLine: deliveryLocation.address.address,
                    },
                  },
                })
                : this.props.onChange({
                  deliveryLocation: null,
                })
            }
            deliveryLocation={this.props.deliveryLocation}
            onReset={() => this.setState({ locationSaved: false })}
            locationSaved={this.state.locationSaved}
            label="Leveringspunkt"
          />
        </div>
        <br />
        <h3>Kontaktperson</h3>
        <div style={{}}>
          <Input3
            label="Fullt navn"
            placeholder="Fornavn og etternavn"
            value={this.props.userName}
            onChange={(value, valid) => {
              this.props.onChange({ userName: value });
              this.setState({ name: valid });
            }}
            validate={string => string.length > 2}
            errorMessage="Navn må være minst 2 bokstaver lang"
          />
          <Input3
            label="E-post"
            placeholder="Din e-postadresse"
            value={this.props.userEmail}
            onChange={(value, valid) => {
              this.props.onChange({ userEmail: value });
              this.setState({ email: valid });
            }}
            validate={validateEmail}
            errorMessage="E-postadressen er ikke gyldig"
          />
          <Input3
            label="Telefon"
            placeholder="Ditt telefonnummer"
            value={this.props.userPhone}
            onChange={(value, valid) => {
              this.props.onChange({ userPhone: value });
              this.setState({ phone: valid });
            }}
            validate={string => string.length >= 8}
            errorMessage="Telefonnummer må være minst 8 bokstaver lang"
          />
        </div>
        <div>
          {' '}
          <br />
          <h3>Vilkår, personvern og informasjon fra Lunsjkollektivet?</h3>
          <CheckboxGroup direction="column">
            <Checkbox
              checked={!!this.props.sendEmailText}
              onChange={checked =>
                this.props.onChange({
                  sendEmailText: checked
                    ? 'Jeg aksepterer at Lunsjkollektivet kontakter meg med informasjon og tilbud. (Valgfritt)'
                    : null,
                })
              }
            >
              Jeg aksepterer at Lunsjkollektivet kontakter meg med informasjon
              og tilbud. (Valgfritt)
            </Checkbox>
          </CheckboxGroup>
          <CheckboxGroup direction="column">
            <Checkbox
              checked={this.state.consent}
              onChange={consent => this.setState({ consent })}
            >
              Jeg aksepterer{' '}
              <a style={{ borderBottom: '2px solid #acacac', paddingBottom: '3px' }}
                href="/vilkar" target="_blank">
                brukervilkårene
              </a>{' '}
              og{' '}
              <a style={{ borderBottom: '2px solid #acacac', paddingBottom: '3px' }}
                href="/personvern" target="_blank">
                personvernserklæring
              </a>
              .
            </Checkbox>
          </CheckboxGroup>
        </div>
        <br />
        <Button
          onClick={() => this.props.onNext()}
          medium
          disabled={
            !this.state.companyName ||
            !this.state.orgnr ||
            !this.props.deliveryLocation ||
            this.state.postalCodeError ||
            !this.state.email ||
            !this.state.name ||
            !this.state.phone ||
            !this.state.consent
          }
          saving={this.state.fetching || this.props.loading}
        >
          Bestill
        </Button>{' '}
      </div>
    );
  }
}

export default ClientInfo;
