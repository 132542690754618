import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';

import PaymentCard from './PaymentCard';
import styles from './newAccountHome.module.css';

import Container from '../../../../../components/Container';
import CollapsableCard from '../../../../../components/Card/CollapsableCard';
import { DeliveryAddressCard } from '../../../../../components/CustomerSettings/DeliveryAddress';
import Button from '../../../../../components/Button';
import Alert from '../../../../../components/Alert';
import DatePicker from '../../../../../components/DatePicker';

import selectAllSubscriptionTypes from '../../../../../selectors/subscriptionType/selectAll';
import selectedCustomer from '../../../../../selectors/me/selectedCustomer';
import { selectCustomerSubscription } from '../../../../../stores/subscription/selectors';
import { activate, setContext } from '../../../../../stores/me/actions';
import {
  createCustomerSubscription,
  getForCustomerIfNeeded,
} from '../../../../../stores/subscription/actions';
import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../../../stores/subscriptionType/actions';
import hasFullSpons from '../../../../../utils/hasFullSpons';
import firstSubDate from '../../../../../utils/firstSubDate';
import formatUserFrendlyDate from '../../../../../utils/formatUserFrendlyDate';

const fullWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
const price = '91';

const Product = props => (
  <div>
    <div
      className={props.styling ? styles.card_selected : styles.card}
      onClick={props.onClick}
    >
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${props.imageUrl})` }}
      />
      <div>
        <strong>{props.title}</strong>
      </div>
      <div className={styles.content}>{props.content}</div>
      <div>
        <Button onClick={() => {}} disabled={props.styling}>
          Kr {(props.price.length && props.price[0].price) || price},-
        </Button>
      </div>
    </div>
  </div>
);

class NewAccountActivation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      choice: null,
      collapsed:
        this.props.isCompanyAgreementAdmin ||
        ((hasFullSpons(this.props.customer) || this.props.cards.length) &&
          (this.props.customer.companyAgreement ||
            this.props.customer.deliveryLocation) &&
          this.props.subscription &&
          this.props.subscription.data.entries.length),
      datePickerFocused: false,
      startDate:
        this.props.customer.companyAgreement?.startDate &&
        moment(this.props.customer.companyAgreement.startDate).isSameOrAfter(
          firstSubDate()
        )
          ? moment(this.props.customer.companyAgreement.startDate)
          : moment(firstSubDate()),
      companyAgreementStartDate:
        this.props.customer.companyAgreement?.startDate &&
        moment(this.props.customer.companyAgreement.startDate).isSameOrAfter(
          firstSubDate()
        )
          ? moment(this.props.customer.companyAgreement.startDate)
          : null,
    };
  }

  componentDidMount() {
    this.props.getAllSubscriptionTypesIfNeeded();
    this.props.getForCustomerIfNeeded(this.props.customerId);

    if (!isEmpty(this.props.subscription) && !this.props.subscription.endDate) {
      this.setState({ subscription: this.props.subscription });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEmpty(nextProps.subscription) &&
      !nextProps.subscription.endDate &&
      this.state.subscription !== nextProps.subscription
    ) {
      this.setState({ subscription: nextProps.subscription });
    }
  }

  updateLunch = choice => {
    if (this.state.saving || this.state.choice === choice) return;
    this.setState({ choice });
  };

  activate = () => {
    this.setState({ saving: true, error: false, success: false });

    // companyAgreement admins can skip subscription, and only be activated
    if (this.props.isCompanyAgreementAdmin && !this.state.choice) {
      this.props
        .activate()
        .then(() => {
          setTimeout(() => this.setState({ success: false }), 3000);
          this.props.setContext({
            type: 'companyAgreement',
            id: this.props.customer.companyAgreement.id,
          });

          this.setState({ saving: false, success: true });
          this.props.history.push('https://company.lunsjkollektivet.no');
        })
        .catch(error => {
          this.setState({
            saving: false,
            error: error.response
              ? error.response.data
              : 'Vi fikk ikke aktivert brukeren. Ta gjerne kontakt med oss, så fikser vi det så fort som mulig.',
          });
        });

      return;
    }

    // first activate the user
    this.props
      .activate()
      .then(() => {
        // then call the conversion trackers
        setTimeout(() => this.setState({ success: false }), 3000);
      })
      .then(
        // then create a subscription of the preferred type
        !this.state.subscription &&
          this.props
            .createCustomerSubscription(this.props.customerId, null, {
              data: {
                entries: [{ type: this.state.choice, menuAmount: 1 }],
                dailyEntries: [],
              },
              type: 'weekly',
              days: fullWeek,
              startDate: this.state.startDate,
              endDate: null,
            })
            .then(() => {
              this.setState({ saving: false, success: true });
              this.props.history.push('/');
            })
            .catch(error => {
              this.setState({
                saving: false,
                error: error.response
                  ? error.response.data
                  : 'Vi fikk ikke aktivert brukeren. Ta gjerne kontakt med oss, så fikser vi det så fort som mulig.',
              });
            })
      );
  };

  getProductPrice = (customPrices, subscriptionTypeAlias) => {
    const { subscriptionTypes } = this.props;
    const customPrice = customPrices.filter(
      p => p.subscriptionTypeAlias === 'Salad'
    );

    const subscriptionTypePrice = subscriptionTypes.filter(
      st => st.alias === subscriptionTypeAlias
    );
    if (customPrice.length > 0) {
      return customPrice;
    } else {
      return subscriptionTypePrice;
    }
  };

  render() {
    const customPrices =
      (this.props.customer &&
        this.props.customer.companyAgreement?.customPrices) ||
      [];

    const cannotBeActivated =
      !this.props.isCompanyAgreementAdmin &&
      ((!hasFullSpons(this.props.customer) && !this.props.cards.length) ||
        (!this.props.customer.companyAgreement &&
          !this.props.customer.deliveryLocation) ||
        (!this.state.choice && !this.state.subscription));

    const spons = hasFullSpons(this.props.customer);

    const firstAllowedStartDate =
      this.state.companyAgreementStartDate || moment(firstSubDate());
    firstAllowedStartDate.set({ hours: 0, minutes: 0 });

    return (
      <Container color="gray" withPadding width={800} height={'50vh'}>
        <div
          className="text-center"
          style={{ fontSize: '1.2em', marginBottom: 50, lineHeight: '2em' }}
        >
          <div>
            Hurra, du har nå opprettet profilen din hos Lunsjkollektivet!
          </div>
          {!this.props.isCompanyAgreementAdmin ? (
            <strong>
              Et par små steg gjenstår før vi kan levere lunsj til deg:
            </strong>
          ) : (
            <div>
              <div>
                Profilen er også registrert som bedriftsadministrator for din
                bedrift.
              </div>
              <strong>
                Du kan nå å fullføre oppsett for egen profil, eller trykke
                "Ferdig" for å gå videre til bedriftsavtalen:
              </strong>
            </div>
          )}
        </div>
        {this.state.error ? (
          <div className="mt-3">
            <Alert color="red">{this.state.error}</Alert>
          </div>
        ) : null}
        {!this.state.subscription && (
          <CollapsableCard
            header="Velg lunsj"
            collapsed={this.state.collapsed}
            onChange={collapsed => this.setState({ collapsed })}
          >
            <div className={styles.product_row}>
              <Product
                onClick={() => {
                  this.updateLunch('Salad');
                }}
                imageUrl="/images/product/sl-3.jpg"
                title="Salatlunsj"
                styling={this.state.choice === 'Salad' ? 1 : 0}
                content="Friskt og sunt"
                price={this.getProductPrice(customPrices, 'Salad')}
              />
              <Product
                onClick={() => {
                  this.updateLunch('Bread');
                }}
                imageUrl="/images/product/bl-1.jpg"
                title="Brødlunsj"
                styling={this.state.choice === 'Bread' ? 1 : 0}
                content="Nybakt og variert"
                price={this.getProductPrice(customPrices, 'Bread')}
              />
              <Product
                onClick={() => {
                  this.updateLunch('Vegetar');
                }}
                imageUrl="/images/product/kf-1.jpg"
                title="Vegetar"
                styling={this.state.choice === 'Vegetar' ? 1 : 0}
                content="Friske vegetarsalater"
                price={this.getProductPrice(customPrices, 'Vegetar')}
              />
            </div>
            <div className="text-center">
              <strong>Lunsj vil bli levert 5 dager i uken.</strong> Du kan
              enkelt avbestille fra lunsjkalenderen dersom du ikke er på
              kontoret en dag, eller endre til færre ukedager på din side.
            </div>
            <br />
          </CollapsableCard>
        )}
        {!this.props.isCompanyAgreementAdmin && (
          <div className="mt-5">
            <DeliveryAddressCard
              customerId={this.props.customer.id}
              button
              validate
            />
          </div>
        )}
        <PaymentCard
          cards={this.props.cards}
          button
          validate
          hasFullSpons={spons}
        />
        {this.state.companyAgreementStartDate && (
          <div style={{ marginTop: '20px' }}>
            Bedriftsavtalen har oppstart{' '}
            <strong>
              {formatUserFrendlyDate(
                moment(this.state.companyAgreementStartDate)
              )}
            </strong>
          </div>
        )}
        {this.state.choice && (
          <div style={{ marginTop: '20px' }}>
            <strong>Første levering</strong>
            <DatePicker
              required
              placeholder=""
              date={this.state.startDate}
              isOutsideRange={day => {
                return (
                  day.isBefore(firstAllowedStartDate) || day.isoWeekday() > 5
                );
              }}
              onDateChange={startDate => this.setState({ startDate })}
              focused={this.state.datePickerFocused}
              onFocusChange={({ focused }) =>
                this.setState({ datePickerFocused: focused })
              }
            />
          </div>
        )}
        <Button
          size="medium"
          disabled={cannotBeActivated}
          saving={this.state.saving}
          onClick={this.activate}
        >
          Ferdig
        </Button>
        {this.state.error ? (
          <div className="mt-3">
            <Alert color="red">{this.state.error}</Alert>
          </div>
        ) : null}
      </Container>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const customer = selectedCustomer(state);
      return {
        subscriptionTypes: selectAllSubscriptionTypes(state),
        subscription: selectCustomerSubscription(state, customer.id),
        cards: customer.cards || [],
        identity: state.me.identity,
        customer,
        setupDone: state.me.identity.meta.setupDone,
      };
    },
    {
      activate,
      setContext,
      getAllSubscriptionTypesIfNeeded,
      createCustomerSubscription,
      getForCustomerIfNeeded,
    }
  )(NewAccountActivation)
);
