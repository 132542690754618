import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './button.module.css';

const Button = ({
  children,
  className,
  onClick,
  onDisabledClick,
  disabled,
  fullWidth,
  color,
  saving,
  success,
  minWidth,
  size,
  logo,
  to,
}) => {
  if (to) {
    if (typeof to === "string" && to.startsWith("https://")) {
      return <a
        className={cn(
          styles.button,
          styles[`button-${color}`],
          styles[`button-${size}`],
          className,
          {
            [styles.disabled]: disabled,
            [styles.fullWidth]: fullWidth,
          }
        )}
        style={{ minWidth }}
        href={to}
      >
        {logo && !saving ? (
          <div className={cn('fa', 'fa-' + logo, styles.logo)} />
        ) : null}
        {saving ? (
          <div className={styles.loading}>
            <div className="fa fa-circle" />
            <div className="fa fa-circle" />
            <div className="fa fa-circle" />
          </div>
        ) : success ? (
          <div className="fa fa-check" />
        ) : (
          children
        )}
    </a>
    }

    return (
      <Link
        className={cn(
          styles.button,
          styles[`button-${color}`],
          styles[`button-${size}`],
          className,
          {
            [styles.disabled]: disabled,
            [styles.fullWidth]: fullWidth,
          }
        )}
        style={{ minWidth }}
        to={to}
      >
        {logo && !saving ? (
          <div className={cn('fa', 'fa-' + logo, styles.logo)} />
        ) : null}
        {saving ? (
          <div className={styles.loading}>
            <div className="fa fa-circle" />
            <div className="fa fa-circle" />
            <div className="fa fa-circle" />
          </div>
        ) : success ? (
          <div className="fa fa-check" />
        ) : (
          children
        )}
      </Link>
    );
  }

  return (
    <div
      className={cn(
        styles.button,
        styles[`button-${color}`],
        styles[`button-${size}`],
        className,
        {
          [styles.disabled]: disabled,
          [styles.fullWidth]: fullWidth,
        }
      )}
      style={{ minWidth }}
      onClick={
        (disabled && !onDisabledClick) || saving
          ? () => {}
          : disabled
          ? onDisabledClick
          : onClick
      }
    >
      {logo && !saving ? (
        <div className={cn('fa', 'fa-' + logo, styles.logo)} />
      ) : null}
      {saving ? (
        <div className={styles.loading}>
          <div className="fa fa-circle" />
          <div className="fa fa-circle" />
          <div className="fa fa-circle" />
        </div>
      ) : success ? (
        <div className="fa fa-check" />
      ) : (
        children
      )}
    </div>
  );
};

Button.defaultProps = {
  children: null,
  className: '',
  onClick: () => {},
  onDisabledClick: () => {},
  minWidth: undefined,
  to: null,
  color: 'main',
  disabled: false,
  fullWidth: false,
  saving: false,
  success: false,
  size: 'normal',
};

export default Button;
