import moment from 'moment';
import types from './types';
import meTypes from '../me/types';

const initialState = {
  entities: {},
  latestMeta: {},
  meta: {
    delete_customer: {}
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LATEST_START:
      return {
        ...state,
        latestMeta: {
          ...state.latestMeta,
          [String(action.payload.count)]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case meTypes.GET_ME_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.customers.reduce((cObject, c) => {
            cObject[c.id] = c;
            return cObject;
          }, {}),
        },
      };
    case meTypes.DELETE_CARD_SUCCESS:
    case meTypes.ADD_CARD_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
      };
    case meTypes.UPDATE_MY_CUSTOMER_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.customerId]: {
            ...state.entities[action.payload.customerId],
            deliveryAddress: action.payload.data,
          },
        },
      };
    case types.GET_LATEST_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        latestMeta: {
          ...state.latestMeta,
          [String(action.payload.count)]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
      };
    case types.GET_LATEST_ERROR:
      return {
        ...state,
        latestMeta: {
          ...state.latestMeta,
          [String(action.payload.count)]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.GET_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          ['search_' + action.payload.search]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        meta: {
          ...state.meta,
          ['search_' + action.payload.search]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          ['search_' + action.payload.search]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.GET_ONE_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          ['single_' + action.payload.id]: {
            loading: true,
            error: false,
            loadedAt: null,
          },
        },
      };
    case types.GET_ONE_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
        meta: {
          ...state.meta,
          ['single_' + action.payload.id]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
          },
        },
      };
    case types.GET_ONE_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          ['single_' + action.payload.id]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
          },
        },
      };
    case types.DELETE_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          ['single_' + action.payload.id]: {
            loading: true,
            error: false,
            loadedAt: null,
          },
          delete_customer: {
            loading: true,
            success: false,
            error: false,
            loadedAt: null,
            id: action.payload.id
          }
        },
      };
    case types.DELETE_SUCCESS:
      let entities = {
        ...state.entities,
      };
      delete entities[action.payload.id];

      let meta = {
        ...state.meta,
        delete_customer: {
          loading: false,
          success: true,
          error: false,
          loadedAt: moment().unix(),
          id: action.payload.id
        }
      };
      delete meta['single_' + action.payload.id];
      delete meta['search_' + action.payload.search]

      return {
        ...state,
        entities,
        meta,
      };
    case types.DELETE_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          ['single_' + action.payload.id]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
          },
          delete_customer: {
            loading: false,
            success: false,
            error: true,
            loadedAt: moment().unix(),
            id: action.payload.id
          }
        },
      };
    case types.UPDATE_CUSTOMER_IDENTITY_SUCCESS:
      return {
        ...state,
        entities: Object.keys(state.entities).reduce((entities, id) => {
          if (
            state.entities[id].identity &&
            state.entities[id].identity.id === action.payload.response.id
          ) {
            entities[id] = {
              ...state.entities[id],
              identities: [action.payload.response],
            };
          } else {
            entities[id] = state.entities[id];
          }
          return entities;
        }, {}),
      };
    case types.UPDATE_CUSTOMER_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.customerId]: {
            ...state.entities[action.payload.customerId],
            deliveryAddress: action.payload.response,
          },
        },
      };
    case types.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
      };
    default:
      return state;
  }
};
