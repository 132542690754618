import React from 'react';

import Button from '../../../../components/Button';
import { CompanyMeetingFoodSchemaRaw } from '../../../../components/CompanySettings/CompanyMeetingFoodOrder';
import firstDate from '../../../../utils/firstDate';

class ClientInfo extends React.Component {
  state = {
    orders: [],
    date: firstDate(1),
  };

  render() {
    return (
      <div>
        <CompanyMeetingFoodSchemaRaw
          withSummary={false}
          orders={this.props.orders}
          date={this.props.date}
          onUpdate={state => this.props.onChange(state)}
        />
        <br />
        <Button
          medium
          disabled={this.props.orders.length === 0}
          onClick={() => this.props.onNext()}
        >
          Fortsett
        </Button>
      </div>
    );
  }
}

export default ClientInfo;
