import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './hero.module.css';

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }

  render() {
    return (
      <div
        className={cn(styles.hero, {
          [styles.top]: this.props.top,
          [styles.homepage]: this.props.homepage,
        })}
        style={{
          minHeight: this.props.homepage ? '100vh' : this.props.height,
          backgroundImage: `${this.props.darken
            ? 'linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), '
            : ''
            }url(${this.props.image})`,
        }}
      >
        {this.props.withoutCard ? (
          this.props.children || null
        ) : (
            <div className={styles.content}>
              <div className={styles.card}>{this.props.children || null}</div>
            </div>
          )}
      </div>
    );
  }
}

Hero.defaultProps = {
  height: 300,
  withoutCard: false,
  homepage: false,
};

Hero.propTypes = {
  image: PropTypes.string.isRequired,
  height: PropTypes.any.isRequired,
};

export default Hero;
