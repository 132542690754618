import React from 'react';
import { Col, Row } from '../../../Grid';
import styles from './imageText.module.css';

export default ({ imagePath, children }) => (
  <Row>
    <Col m={6}>
      <div className={styles.image}>
        <img src={imagePath} alt="sanity" />
      </div>
    </Col>
    <Col m={6} className={styles.imageDescription}>
      {children}
    </Col>
  </Row>
);
