export default meta => {
  if (meta) return meta;

  return {
    loading: true,
    error: false,
    loadedAt: null,
    ids: [],
  };
};
