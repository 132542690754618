import React from 'react';
import { Helmet } from 'react-helmet';

import BlogPost from '../components/BlogPost';
import Alert from '../../../../components/Alert';
import Header from '../../../../components/Header';
import Loader from '../../../../components/Loader';
import { buildImageUrl } from '../../../../utils/sanity';

const LoadedContent = ({ post }) => (
  <React.Fragment>
    <Helmet>
      <title>{post.title} - Lunsjkollektivet</title>
    </Helmet>
    <Header image={buildImageUrl(post.mainImage)} height={600} withoutCard />
    <BlogPost post={post} />
  </React.Fragment>
);

const Latest = ({ error, loading, sanityData }) => {
  return (
    <React.Fragment>
      {loading ? (
        <React.Fragment>
          <Header />
          <Loader />
        </React.Fragment>
      ) : error || sanityData.length === 0 ? (
        <React.Fragment>
          <Header />
          <Alert>Noe Skjedde</Alert>
        </React.Fragment>
      ) : (
        <LoadedContent post={sanityData[0]} />
      )}
    </React.Fragment>
  );
};

export default Latest;
