import moment from 'moment';
import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';
import selectWeekMetaKey from '../../selectors/choice/selectWeekMetaKey';
import selectMonthMetaKey from '../../selectors/choice/selectMonthMetaKey';
import { selectDateMetaKey, selectDateKitchenMetaKey } from './selectors';

import types from './types';

export const getMyOrdersIfNeeded = () => (dispatch, getState) => {
  if (!shouldFetch(getState().order.myOrdersMeta)) return;

  dispatch({
    type: types.GET_MY_ORDERS_START,
  });

  return fetch(
    `/profile/orders/${moment()
      .startOf('month')
      .subtract(3, 'months')
      .format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`,
    'GET'
  )
    .then(response => {
      dispatch({
        type: types.GET_MY_ORDERS_SUCCESS,
        payload: {
          entities: response.data.reduce((orders, order) => {
            orders[order._id] = order;
            return orders;
          }, {}),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_MY_ORDERS_ERROR,
        payload: {
          error,
        },
      });
    });
};

export const getCustomerOrdersForWeekIfNeeded = (customerId, week) =>
  getOrders(
    selectWeekMetaKey(customerId, week),
    `/customers/${customerId}/orders`,
    {
      start: week
        .clone()
        .startOf('week')
        .add(-1, 'days')
        .format('YYYY-MM-DD'),
      end: week
        .clone()
        .endOf('week')
        .add(1, 'days')
        .format('YYYY-MM-DD'),
    }
  );


export const getCustomerOrdersForMonthIfNeeded = (customerId, month) =>
  getOrders(
    selectMonthMetaKey(customerId, month),
    `/customers/${customerId}/orders`,
    {
      start: month
        .clone()
        .startOf('month')
        .add(-1, 'days')
        .format('YYYY-MM-DD'),
      end: month
        .clone()
        .endOf('month')
        .add(1, 'days')
        .format('YYYY-MM-DD'),
    }
  );


export const getCompanyAgreementOrdersForWeekIfNeeded = (
  companyAgreementId,
  week
) =>
  getOrders(
    selectWeekMetaKey(companyAgreementId, week),
    `/company-agreements/${companyAgreementId}/orders`,
    {
      start: week
        .clone()
        .startOf('week')
        .add(-1, 'days')
        .format('YYYY-MM-DD'),
      end: week
        .clone()
        .endOf('week')
        .add(1, 'days')
        .format('YYYY-MM-DD'),
    }
  );

export const getKitchenOrdersForDate = (date, kitchenId) =>
  getOrders(
    selectDateKitchenMetaKey(date, kitchenId),
    `/kitchens/${kitchenId}/orders`,
    {
      date: date.format('YYYY-MM-DD'),
    }
  );

export const getOrdersForDate = date =>
  getOrders(selectDateMetaKey(date), `/orders`, {
    date: date.format('YYYY-MM-DD'),
  });

const getOrders = (key, url, params) => (dispatch, getState) => {
  if (!shouldFetch(getState().order.meta[key])) return;

  dispatch({
    type: types.GET_ORDERS_START,
    payload: {
      key,
    },
  });

  return fetch(url, 'GET', params)
    .then(response => {
      dispatch({
        type: types.GET_ORDERS_SUCCESS,
        payload: {
          entities: response.data.reduce((orders, order) => {
            orders[order.id] = order;
            return orders;
          }, {}),
          key,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ORDERS_ERROR,
        payload: {
          error,
          key,
        },
      });
    });
};
