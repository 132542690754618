import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../Button';
import styles from './lunchBoxOverview.module.css';
import formatPrice from '../../../../utils/formatPrice';
import MealNutrition from '../../../MealNutrition';
import Rating from '../../Rating';
import NumberPicker from '../../../NumberPicker';
import Allergy from '../../../Allergy';
import typeSupportsAllergies from '../../../../utils/typeSupportsAllergies';

import cn from 'classnames';

const title = subscriptionTypes => {
  const names = subscriptionTypes
    .sort(
      (a, b) =>
        (!a.order || a.order === 0 ? 1000 : a.order) -
        (!b.order || b.order === 0 ? 1000 : b.order)
    )
    .map(st => st.name);

  return (
    <span>
      <strong>{names.join(' / ')}</strong>
    </span>
  );
};

const menuAllergies = menu => {
  if (!menu || !menu.allergies || menu.allergies.length === 0) {
    return '';
  }

  const allergies = menu.allergies.filter(a => a.fraMattilsynet);

  if (allergies.length === 0) {
    return '';
  }

  return 'Inneholder: ' + allergies.map(a => a.name).join(', ');
};

class LunchBoxOverview extends React.Component {
  state = {
    showMore: false,
  };

  maxDescriptionLength = 200;

  isMainProduct = () => {
    return (
      this.props.subscriptionTypes[0].type === 'box' ||
      this.props.subscriptionTypes[0].alias === 'bestikk' ||
      !this.props.subscriptionTypes[0].imagePath
    );
  };

  buildDescription() {
    if (this.props.subscriptionTypes[0].alias.includes('warmFood'))
      return 'Krever 5 eller flere bestillinger.';
    if (
      !this.props.subscriptionTypes.some(type =>
        typeSupportsAllergies(this.props.userAllergies, type)
      )
    )
      return 'Kan ikke kombineres med allergier.';
    if (!this.props.menu) {
      return '';
    }

    let description = this.props.menu.description;

    if (
      !this.state.showMore &&
      description.length > this.maxDescriptionLength
    ) {
      description = description.substring(0, this.maxDescriptionLength) + '..';
    }
    return <div>{description}</div>;
  }

  render() {
    return (
      <div
        className={
          !this.props.disabled
            ? this.isMainProduct()
              ? this.props.amount > 0
                ? styles.productRowActive
                : styles.productRow
              : this.props.amount > 0
                ? styles.extraProductRowActive
                : styles.extraProductRow
            : this.isMainProduct()
              ? styles.productRowDisabled
              : styles.extraProductRowDisabled
        }
      >
        <div>
          <div
            onClick={() =>
              !this.props.disabled &&
              !this.props.amount &&
              this.props.subscriptionTypes.some(type =>
                typeSupportsAllergies(this.props.userAllergies, type)
              ) &&
              this.props.onChange(parseInt(1, 10))
            }
          >
            <div className={!this.isMainProduct() ? styles.extraTitle : ''}>
              <strong>
                {this.props.amount > 0 ? (
                  <span>
                    <div className={cn('fa', 'fa-check')} />{' '}
                    {this.props.amount + 'x '}{' '}
                    {title(this.props.subscriptionTypes)}{' '}
                  </span>
                ) : (
                  title(this.props.subscriptionTypes)
                )}
              </strong>
              {!this.props.company && this.isMainProduct()
                ? null
                : ' ' + formatPrice(this.props.price)}
            </div>
            {this.props.allergies.length ? (
              <div>
                Inneholder:{' '}
                {this.props.allergies
                  .map(a => <Allergy key={a.id} allergyId={a.id} />)
                  .reduce((prev, curr) => [prev, ', ', curr])}
              </div>
            ) : null}
            <div>{this.buildDescription()}</div>
          </div>
          {this.props.menu ? (
            !this.state.showMore ? (
              <div
                className={styles.showMore}
                onClick={() => this.setState({ showMore: true })}
              >
                Mer info
              </div>
            ) : (
              <div
                className={styles.showMore}
                onClick={() => this.setState({ showMore: false })}
              >
                Skjul info
              </div>
            )
          ) : null}
          {this.state.showMore ? (
            <div className={styles.showInfo}>
              <i>{menuAllergies(this.props.menu)}</i>
              {'. '}
              <MealNutrition meal={this.props.menu} />.
            </div>
          ) : null}
        </div>
        {!this.props.disabled && this.isMainProduct() && !this.props.amount ? (
          <div className={styles.col}>
            <Button
              className={styles.button}
              disabled={
                !this.props.subscriptionTypes.some(type =>
                  typeSupportsAllergies(this.props.userAllergies, type)
                )
              }
              onClick={() =>
                !this.props.disabled
                  ? this.props.onChange(this.props.amount === 0 ? 1 : 0)
                  : null
              }
            >
              Velg
            </Button>
          </div>
        ) : null}
        {this.props.amount && !this.props.disabled ? (
          <div style={{ margin: '-14px -14px -14px 14px' }}>
            <NumberPicker
              square
              column={this.isMainProduct()}
              value={this.props.amount}
              onChange={number => this.props.onChange(parseInt(number))}
            />
          </div>
        ) : null}
        {this.props.disabled && this.props.enableRating ? (
          this.props.rating ? (
            <Rating rating={this.props.rating.rating} />
          ) : !this.props.savingRating ? (
            <Rating submitRating={this.props.onSubmitRating} />
          ) : null
        ) : null}
        {!this.isMainProduct() ? (
          <div
            onClick={() =>
              !this.props.disabled && this.props.amount === 0
                ? this.props.onChange(1)
                : null
            }
            className={styles.lunchBoxOverviewImage}
            style={{
              backgroundImage:
                this.props.subscriptionTypes[0].imagePath &&
                  this.props.subscriptionTypes[0].imagePath.length > 0
                  ? `url(${this.props.subscriptionTypes[0].imagePath})`
                  : undefined,
            }}
          ></div>
        ) : null}
      </div>
    );
  }
}

LunchBoxOverview.defaultProps = {
  allergies: [],
};

LunchBoxOverview.propTypes = {
  subscriptionTypes: PropTypes.array.isRequired,
  allergies: PropTypes.array,
  menu: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
};

export default LunchBoxOverview;
