import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';

import types from './types';

export const getAllIfNeeded = () => (dispatch, getState) => {
  if (!shouldFetch(getState().notificationDefinition.meta)) return;

  dispatch({
    type: types.GET_ALL_START,
  });

  return fetch('/notification-definitions', 'GET')
    .then(response => {
      dispatch({
        type: types.GET_ALL_SUCCESS,
        payload: {
          notificationDefinitions: Array.isArray(response.data)
            ? response.data
            : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ALL_ERROR,
        payload: {
          error,
        },
      });
    });
};
