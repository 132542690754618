import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';

import types from './types';

export const getForWeekIfNeeded = week => (dispatch, getState) => {
  const startDate = week.clone().startOf('week');
  const endDate = week.clone().endOf('week');
  const key = startDate.format() + endDate.format();

  if (!shouldFetch(getState().noDeliveryDay.meta[key])) return;

  dispatch({
    type: types.GET_FOR_WEEK_START,
    payload: { key },
  });

  return fetch('/no-delivery-days', 'GET', {
    start: week
      .clone()
      .startOf('week')
      .add(-1, 'day')
      .format('YYYY-MM-DD'),
    end: week
      .clone()
      .endOf('week')
      .format('YYYY-MM-DD'),
  })
    .then(response => {
      dispatch({
        type: types.GET_FOR_WEEK_SUCCESS,
        payload: {
          key,
          noDeliveryDays: Array.isArray(response.data) ? response.data : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_WEEK_ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};


export const getForMonthIfNeeded = month => (dispatch, getState) => {
  const startDate = month.clone().startOf('month');
  const endDate = month.clone().endOf('month');
  const key = startDate.format() + endDate.format();

  if (!shouldFetch(getState().noDeliveryDay.meta[key])) return;

  dispatch({
    type: types.GET_FOR_WEEK_START,
    payload: { key },
  });

  return fetch('/no-delivery-days', 'GET', {
    start: month
      .clone()
      .startOf('month')
      .add(-1, 'day')
      .format('YYYY-MM-DD'),
    end: month
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD'),
  })
    .then(response => {
      dispatch({
        type: types.GET_FOR_WEEK_SUCCESS,
        payload: {
          key,
          noDeliveryDays: Array.isArray(response.data) ? response.data : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_WEEK_ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};
