import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../Table';
import Alert from '../../Alert';

import styles from './companyAgreementDayReport.module.css';
import connect from 'react-redux/es/connect/connect';
import { selectData } from '../../../stores/fetch/selectors';
import { fetch } from '../../../stores/fetch/actions';
import Loader from '../../Loader';

class ReportTable extends React.Component {
  componentDidMount() {
    this.props.companyAgreement && this.props.fetch(
      this.props.minSideBedrift
        ? `/companyagreements/${this.props.companyAgreement.id}/reports/daily-overview`
        : '/reports/day-company-agreement',
      'GET',
      {
        date: this.props.date.format('YYYY-MM-DD'),
        companyAgreementId: this.props.minSideBedrift
          ? undefined
          : this.props.companyAgreement
            ? this.props.companyAgreement.id
            : null,
      }
    );
  }

  componentDidUpdate() {
    this.props.companyAgreement && this.props.fetch(
      this.props.minSideBedrift
        ? `/companyagreements/${this.props.companyAgreement.id}/reports/daily-overview`
        : '/reports/day-company-agreement',
      'GET',
      {
        date: this.props.date.format('YYYY-MM-DD'),
        companyAgreementId: this.props.minSideBedrift
          ? undefined
          : this.props.companyAgreement
            ? this.props.companyAgreement.id
            : null,
      }
    );
  }

  getTotals() {
    let totalPerHeader = {};
    this.props.dayCompanyAgreementReport.response.data.users.forEach(user => {
      user.amountsPerDetail.forEach(type => {
        const header = this.getHeader(type);
        if (!totalPerHeader[header]) {
          totalPerHeader[header] = 0;
        }
        totalPerHeader[header] += type.amount;
      });
    });

    return this.getTypesHeaders().map(header => {
      return totalPerHeader[header] ? totalPerHeader[header] : 0;
    });
  }

  getRows() {
    const headers = this.getTypesHeaders();

    return this.props.dayCompanyAgreementReport.response.data.users
      .map(user => {
        let types = this.getTypesHeaders().map(h => '');
        let total = 0;

        user.amountsPerDetail.forEach(type => {
          types[headers.indexOf(this.getHeader(type))] = type.amount;
          total += type.amount;
        });

        return {
          name: user.user ? user.user.name : 'Bedriftsbestillinger',
          types,
          total
        };
      }, [])
      .filter(user => user.total)
      .map((row, index) => (
        <tr key={index}>
          <td>{row.name}</td>
          {row.types.map((t, i) => (
            <td key={i}>{t === 0 ? '' : t}</td>
          ))}
        </tr>
      ));
  }

  getHeader(type) {
    let header = type.type;
    if (type.size !== 'normal') {
      header += ` (${type.size})`;
    } else if (header === 'Salad') {
      return 'Salat';
    } else if (header === 'withoutMeet') {
      return 'Kjøttfri';
    } else if (header === 'Recommended') {
      return 'Variert';
    } else if (header === 'Bread') {
      return 'Brød';
    } else if (header === 'Vegetar') {
      return 'Vegetarsalat';
    } else if (header.includes('warmFood')) {
      return 'Varmmat';
    }
    return header;
  }

  getTypesHeaders() {
    return this.props.dayCompanyAgreementReport.response.data.users
      .reduce((headers, user, allergy) => {
        user.amountsPerDetail.forEach(type => {
          if (type.amount === 0) {
            return;
          }
          let header = this.getHeader(type);
          if (headers.indexOf(header) === -1) {
            headers.push(header);
          }
        });
        return headers;
      }, [])
      .sort();
  }

  getHeaders() {
    return ['Navn', ...this.getTypesHeaders()];
  }

  render() {
    if (!this.props.companyAgreement) {
      return (
        <Alert  >
          Søk etter bedriftsavtale.
        </Alert>
      );
    }

    if (this.props.dayCompanyAgreementReport.error) {
      return (
        <Alert color="red">
          Noe skjedde når vi lastet inn siden. Vennligst prøv igjen senere
        </Alert>
      );
    }

    if (this.props.dayCompanyAgreementReport.loading) {
      return <Loader />;
    }

    if (!this.props.dayCompanyAgreementReport.response)
      return <div>Velg Bedriftsavtale</div>;

    return (
      <div>
        <h2 className={styles.center}>{this.props.company ? this.props.company.name : ''}</h2>
        <Table bigHeader>
          <thead>
            <tr>
              {this.getHeaders().map(header => (
                <th
                  key={header}
                  style={{
                    backgroundImage:
                      header === 'Salat'
                        ? 'url(/images/rodbet.jpg)'
                        : header === 'Brød'
                          ? 'url(/images/havre.jpg)'
                          : '',
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={styles.table}>{this.getRows()}</tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              {this.getTotals().map((t, i) => (
                <th key={i}>{t}</th>
              ))}
            </tr>
          </tfoot>
        </Table>
      </div>
    );
  }
}

ReportTable.propTypes = {
  date: PropTypes.object.isRequired,
};

export default connect(
  (state, ownProps) => ({
    dayCompanyAgreementReport: selectData(
      state,
      ownProps.minSideBedrift
        ? `/companyagreements/${ownProps.companyAgreement.id}/reports/daily-overview`
        : '/reports/day-company-agreement',
      'GET',
      {
        date: ownProps.date.format('YYYY-MM-DD'),
        companyAgreementId: ownProps.minSideBedrift
          ? undefined
          : ownProps.companyAgreement
            ? ownProps.companyAgreement.id
            : null,
      }
    ),
  }),
  { fetch }
)(ReportTable);
