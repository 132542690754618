import React from 'react';
import BlockContent from '../SanityBlockContent';
import { buildImageUrl } from '../../utils/sanity';

import Steps from './blocks/Steps';
import Products from './blocks/Products';
import HeaderImage from './blocks/HeaderImage';
import ImageText from './blocks/ImageText';
import Link from '../Link';
import Anchor from '../Anchor';
import Button from '../Button';
import Col from '../Grid/Col';
import Row from '../Grid/Row';

import styles from './blockRenderer.module.css';

class BlockRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { breakpoint: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const breakPointMD = 768;
    if (window.innerWidth < breakPointMD && !this.state.breakpoint) {
      this.setState({ breakpoint: true });
    }
    else if (window.innerWidth >= breakPointMD && this.state.breakpoint) {
      this.setState({ breakpoint: false });
    }
  }

  render() {
    const serializers = {
      types: {
        block: props => {
          const style = props.node.style || 'normal';

          if (/^h\d/.test(style) || style === 'blockquote') {
            return React.createElement(style, null, props.children);
          }

          if (style.includes('header')) {
            return React.createElement(
              'h1',
              {
                className: styles[`block-style-${style}`],
              },
              props.children
            );
          }

          if (props.node.children[0] && props.node.children[0].marks.length &&
            props.node.children[0].marks[0] === 'strong') {
            return React.createElement(
              'p',
              {
                className: styles[`block-style-strong`],
              },
              props.children
            );
          }


          return React.createElement(
            'p',
            {
              className: styles[`block-style-${style}`],
            },
            props.children
          );
        },
        code: props => (
          <pre data-language={props.node.language}>
            <code>{props.node.code}</code>
          </pre>
        ),
        image: props => (
          <div className={styles.imageWrapper}>
            <div style={{ width: '100%', maxWidth: '40%' }}>
              <img src={buildImageUrl(props.node)} alt="From lunsjkollektivet" />
            </div>
          </div>
        ),
        image_v2: props => (
          <div className={styles.imageWrapper}>
            <div style={{ width: '100%', maxWidth: `${props.node.width || 100}%` }}>
              <img
                src={buildImageUrl(props.node.image)}
                alt="From lunsjkollektivet"
              />
            </div>
          </div>
        ),
        steps: props => <Steps data={props.node} />,
        products: props => <Products data={props.node} />,
        cols: props => (
          <div>
            <Row style={this.state.breakpoint ? { flexDirection: 'column-reverse' } : {}}>
              {props.node.cols.map((col, index) =>
                col._type === 'videoBlock' ? (
                  <Col
                    style={this.state.breakpoint ? { padding: 0 } : {}}
                    key={index}
                    m={this.state.breakpoint ? 1 : 12 / props.node.cols}
                  >
                    <video
                      autoPlay={col.autoplay}
                      controls={false}
                      webkit-playsInline
                      playsInline
                      loop
                      muted
                      className={styles.video}
                      src={'/images/company-vid.mp4'}
                    />
                  </Col>
                ) : (
                    <Col style={{ padding: 0 }} key={index} m={this.state.breakpoint ? 1 : 12 / props.node.cols}
                    >
                      <BlockContent blocks={col.content} serializers={serializers} />
                    </Col>
                  )
              )}
            </Row>
          </div>
        ),
        headerImage: props => <HeaderImage data={props.node} />,
        imageText: props => (
          <ImageText imagePath={buildImageUrl(props.node.image)}>
            <BlockContent
              blocks={props.node.imageTextContent}
              serializers={serializers}
            />
          </ImageText>
        ),
      },
      marks: {
        link: props => (
          <Link gray to={props.mark.href} style={{ fontSize: "0.9em" }}>
            {props.children}
          </Link>
        ),
        button: props =>
          props.mark.padding ? (
            <div style={{ paddingTop: '25px' }}>
              {props.mark.url ? (
                <Button
                  to={props.mark.url}
                  size={props.mark.size}
                  color="secondary"
                  fullWidth
                >
                  {props.children}
                </Button>
              ) : (
                  <Anchor size={props.mark.size} color="secondary">
                    {props.children}
                  </Anchor>
                )}
            </div>
          ) : props.mark.url ? (
            <Button to={props.mark.url} size={props.mark.size} color="secondary" fullWidth>
              {props.children}
            </Button>
          ) : (
                <Anchor size={props.mark.size} color="secondary">
                  {props.children}
                </Anchor>
              ),
      }
    };

    return (
      <div className={styles.blockRenderer}>
        <BlockContent blocks={this.props.content} serializers={serializers} />
      </div>
    );
  }


};

export default BlockRenderer;