import React from 'react';

import Button from '../../../../components/Button';
import { Input3 } from '../../../../components/Form';
import DeliveryLocationPicker from '../../../../components/DeliveryLocationPicker';

class CompanyInfo extends React.Component {
  state = {
    orgnr: true,
    companyName: false,
    loading: false
  };

  render() {
    return (
      <div>
        <p>
          <strong>NB! </strong>Er bedriften du jobber i allerede registrert?
          Spør gjerne en admin for bedriftsavtalen om invitasjon til å bli med.
        </p>
        <h2 className="text-left">Opprett bedriftsavtale</h2>
        <div>
          <div style={{ marginTop: '50px', maxWidth: 400 }}>
            <Input3
              label="Bedriftnavn"
              placeholder="Skriv bedriftens navn"
              value={this.props.companyName}
              onChange={(value, valid) => {
                this.props.onChange({ companyName: value });
                this.setState({ companyName: valid });
              }}
              validate={string => string.length > 2}
              colored
              errorMessage="Navn må være minst 2 bokstaver lang"
            />
          </div>
        </div>
        <div style={{ marginTop: '50px', marginBottom: '40px', maxWidth: 400 }}>
          <DeliveryLocationPicker
            onChange={deliveryLocation =>
              deliveryLocation && deliveryLocation.address
                ? this.props.onChange({
                  deliveryLocation: {
                    ...deliveryLocation,
                    address: {
                      ...deliveryLocation.address,
                      addressLine: deliveryLocation.address.address,
                    },
                  },
                })
                : this.props.onChange({
                  deliveryLocation: null,
                })
            }
            colored
            deliveryLocation={this.props.deliveryLocation}
            onReset={() => this.setState({ locationSaved: false })}
            locationSaved={this.state.locationSaved}
          />
        </div>
        <Button
          size="medium"
          onClick={() => this.props.onNext()}
          disabled={
            !this.state.companyName ||
            !this.state.orgnr ||
            !this.props.deliveryLocation ||
            !this.props.deliveryLocation.name ||
            this.props.deliveryLocation.name.length < 3
          }
          saving={this.state.fetching}
        >
          Fortsett
        </Button>
      </div>
    );
  }
}

export default CompanyInfo;
