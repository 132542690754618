import moment from 'moment';
import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';
import { selectWeekMetaKey, selectDayMetaKey, selectUserDayMetaKey } from './selectors';

import types from './types';

export const getRatings = (userId, week) => (dispatch, getState) =>
  getAllIfNeeded(selectWeekMetaKey(userId, week), `/users/${userId}/ratings`, {
    start: week
      .clone()
      .startOf('week')
      .add(-1, 'day')
      .format('YYYY-MM-DD'),
    end: week
      .clone()
      .endOf('week')
      .format('YYYY-MM-DD'),
  })(dispatch, getState);

export const getRatingsForDay = day => (dispatch, getState) =>
  getAllIfNeeded(selectDayMetaKey(day), `/ratings`, {
    start: day
      .clone()
      .startOf('day')
      .format('YYYY-MM-DD'),
    end: day
      .clone()
      .endOf('day')
      .format('YYYY-MM-DD'),
  })(dispatch, getState);

export const getUserRatingsForDay = (userId, day) => (dispatch, getState) =>
  getAllIfNeeded(selectUserDayMetaKey(userId, day), `/users/${userId}/ratings`, {
    start: day
      .clone()
      .startOf('day')
      .format('YYYY-MM-DD'),
    end: day
      .clone()
      .endOf('day')
      .format('YYYY-MM-DD'),
  })(dispatch, getState);

const getAllIfNeeded = (key, url, params = {}) => (dispatch, getState) => {
  if (!shouldFetch(getState().customerRating.meta[key])) return;

  dispatch({
    type: types.GET_START,
    payload: {
      key,
    },
  });

  return fetch(url, 'GET', params)
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          entities: response.data.reduce((ratings, rating) => {
            ratings[rating.id] = rating;
            return ratings;
          }, {}),
          key,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          error,
          key,
        },
      });
    });
};

export const updateForMenu = (userId, menuId, data) => dispatch =>
  fetch('/users/' + userId + '/ratings/' + menuId, 'POST', data).then(
    response => {
      const key = selectWeekMetaKey(userId, moment(response.data.menu.date));
      dispatch({
        type: types.UPDATE_SUCCESS,
        payload: {
          response: response.data,
          key,
        },
      });
    }
  );
