import moment from 'moment';
import meTypes from '../me/types';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
  customers: {
    entities: {},
    meta: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case meTypes.GET_ME_SUCCESS:
      const caManager = action.payload.companyAgreementsManager.reduce(
        (entities, companyAgreement) => {
          entities[companyAgreement.id] = companyAgreement;
          return entities;
        },
        { ...state.entities }
      );
      const companyAgreements = action.payload.customers.filter(c => c.companyAgreement).reduce(
        (entities, customer) => {
          entities[customer.companyAgreement.id] = customer.companyAgreement;
          return entities;
        },
        { ...caManager }
      )
      return {
        ...state,
        entities: companyAgreements,
      };
    case types.GET_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.metaKey]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        meta: {
          ...state.meta,
          [action.payload.metaKey]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.metaKey]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.GET_CUSTOMERS_START:
    case types.GET_SUBSCRIPTION_CUSTOMERS_START:
      return {
        ...state,
        customers: {
          ...state.customers,
          meta: {
            ...state.customers.meta,
            [action.payload.companyAgreementId]: {
              loading: true,
              error: false,
              loadedAt: null,
              ids: [],
            },
          },
        },
      };
    case types.GET_CUSTOMERS_SUCCESS:
    case types.GET_SUBSCRIPTION_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: {
          entities: {
            ...state.customers.entities,
            [action.payload.companyAgreementId]: action.payload.customers,
          },
          meta: {
            ...state.customers.meta,
            [action.payload.companyAgreementId]: {
              loading: false,
              error: false,
              loadedAt: moment().unix(),
            },
          },
        },
      };
    case types.GET_CUSTOMERS_ERROR:
    case types.GET_SUBSCRIPTION_CUSTOMERS_ERROR:
      return {
        ...state,
        customers: {
          ...state.customers,
          meta: {
            ...state.customers.meta,
            [action.payload.companyAgreementId]: {
              loading: false,
              error: true,
              loadedAt: moment().unix(),
            },
          },
        },
      };
    case types.UPDATE_COMPANY_AGREEMENT:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.companyAgreement.id]: action.payload.companyAgreement,
        },
      };
    case types.GET_COMPANY_AGREEMENT_NOTES:
    case types.UPDATE_COMPANY_AGREEMENT_NOTES:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.companyAgreementId]: {
            ...state.entities[action.payload.companyAgreementId],
            additionalNotes: action.payload.notes
          },
        }
      }
    default:
      return state;
  }
};
