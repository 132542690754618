import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import Loader from '../../../../../components/Loader';
import Container from '../../../../../components/Container';
import Table from '../../../../../components/Table';
import Alert from '../../../../../components/Alert';
import { BackButton } from '../../../../../components/Button';
import Card from '../../../../../components/Card';

import formatUserFrendlyDate from '../../../../../utils/formatUserFrendlyDate';
import orderToItem from '../../../../../utils/orderToItem';

import { getMyOrdersIfNeeded } from '../../../../../stores/order/actions';
import selectMyOrders from '../../../../../selectors/order/selectMyOrders';

import selectAllActive from '../../../../../selectors/subscriptionType/selectAllActive';
import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../../../stores/subscriptionType/actions';

class MyHistory extends React.Component {
  componentDidMount() {
    this.props.getMyOrdersIfNeeded();
    this.props.getAllSubscriptionTypesIfNeeded();
  }

  monthsHistory() {
    const monthSummary = this.props.orders
      .map(o => orderToItem(o))
      .reduce((perMonthOrders, order) => {
        const month = moment(order.date).format('MMMM YYYY');
        if (!perMonthOrders[month]) {
          perMonthOrders[month] = {
            total: 0,
            details: {},
          };
        }

        order.details.forEach(detail => {
          if (!perMonthOrders[month].details[detail.type]) {
            perMonthOrders[month].details[detail.type] = 0;
          }
          perMonthOrders[month].details[detail.type] += detail.amount;
        });

        if (order.totalPriceAfterSplit) {
          perMonthOrders[month].total += order.totalPriceAfterSplit;
        }

        return perMonthOrders;
      }, {});
    return Object.keys(monthSummary).map(month => {
      return (
        <tr key={month}>
          <td>{month}</td>
          <td>
            {Object.keys(monthSummary[month].details).map(
              (detailType, index) => {
                const type = this.props.subscriptionTypes.find(
                  st => st.alias === detailType
                );
                return (
                  <div key={index}>
                    {type ? type.name : detailType} x{' '}
                    {monthSummary[month].details[detailType]}
                  </div>
                );
              }
            )}
          </td>
          <td>{monthSummary[month].total}kr</td>
        </tr>
      );
    });
  }

  render() {
    return (
      <Container withPadding color="gray">
        <Helmet>
          <title>Historikk - Lunsjkollektivet</title>
        </Helmet>
        <BackButton to="/innstillinger" />
        {this.props.loading ? (
          <Loader />
        ) : (
          <div>
            <Alert color="gray">
              NB: Denne historikken kan være opp til 2 timer forsinket i forhold
              til valg du har gjort
            </Alert>
            <Card header="Månedlig bestillinger">
              <Table>
                <thead>
                  <tr>
                    <th className="date" width="200">
                      Måned
                    </th>
                    <th className="status">Levering</th>
                    <th className="" width="100">
                      Sum
                    </th>
                  </tr>
                </thead>
                <tbody>{this.monthsHistory()}</tbody>
              </Table>
            </Card>
            <Card header="Daglig bestillinger">
              <Table>
                <thead>
                  <tr>
                    <th>Dato</th>
                    <th>Beskrivelse</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.orders
                    .map(o => orderToItem(o))
                    .sort((a, b) => moment.utc(b.date).diff(moment.utc(a.date)))
                    .map((order, index) => {
                      return (
                        <tr key={index}>
                          <td>{formatUserFrendlyDate(moment(order.date))}</td>
                          <td>
                            {order.details.map((detail, index) => {
                              const type = this.props.subscriptionTypes.find(
                                st => st.alias === detail.type
                              );
                              return (
                                <div key={index}>
                                  {type ? type.name : detail.type} x{' '}
                                  {detail.amount}
                                </div>
                              );
                            })}
                          </td>
                          <td>{order.totalPriceAfterSplit}kr</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </div>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    subscriptionTypes: selectAllActive(state),
    orders: selectMyOrders(state),
    loading: state.order.myOrdersMeta.loading,
    error: state.order.myOrdersMeta.error,
  };
}

export default connect(mapStateToProps, {
  getMyOrdersIfNeeded,
  getAllSubscriptionTypesIfNeeded,
})(MyHistory);
