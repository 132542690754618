import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../components/Header';
import Container from '../../../components/Container';
import Button from '../../../components/Button';
import { Input } from '../../../components/Form';

import styles from './hjelp.module.css';

export default class Help extends React.Component {
  state = { search: '' };
  render() {
    return (
      <div>
        <Helmet>
          <title>Hjelp - Lunsjkollektivet</title>
        </Helmet>
        <Header />
        <div className={styles.background}>
          <Container withPadding width={800}>
            <div className={styles.stepBody}>
              <h1>Spørsmål og svar</h1>
              <div className={styles.question}>
                <h3>Hva koster det for lunsj?</h3>
                <p>
                  Kr 89,13,- eks 15% mva per lunsj, og du kan avbestille fritt.
                  Prisen er per person, og frakt kommer i tillegg.
                </p>
              </div>
              <div className={styles.question}>
                <h3>Når blir lunsjen levert?</h3>
                <p>Lunsjen blir levert mellom kl. 09:00 og 11:15.</p>
                <p>
                  Ved behov er det også mulig å hente bestillingene selv på vårt
                  hovedkjøkken. Ta kontakt dersom dette er aktuelt for dere.
                </p>
              </div>
              <div className={styles.question}>
                <h3>Er lunsjen forsinket?</h3>
                <p>
                  Dersom leveransen er forsinket kan man kreve følgende avslag:
                  <ul>
                    <li>Opp til 30 min: fritt levert, dvs. vi krediterer</li>
                    <li>transportkostnaden 30 min til 1 time: 50% avslag på</li>
                    <li>
                      totalbeløpet Over 1 time: 100% avslag på totalbeløpet
                    </li>
                  </ul>
                </p>
                <i>
                  Obs: Dersom forsinkelsen skyldes mangelfull adresse i profilen
                  deres, eller unormale vær- og trafikkforhold, så kan ikke
                  avslag kreves.
                </i>
              </div>
              <div className={styles.question}>
                <h3>Hvor sent kan jeg bestille lunsjen min?</h3>
                <p>
                  Du kan bestille, avbestille eller endre valg frem til kl.
                  11.00 arbeidsdagen før.
                </p>
              </div>
              <div className={styles.question}>
                <h3>Kan jeg få levert lunsj bare en dag i uken?</h3>
                <p>
                  Ja, vi er opptatt av å være brukervennlig. Derfor er det mulig
                  med alt fra én fast leveringsdag til full uke.
                </p>
              </div>
              <div className={styles.question}>
                <h3>Hvordan lager jeg bruker?</h3>
                <p>
                  Velg mellom Bedrift eller Privat i menyen øverst. Klikk på
                  «Kom i gang» og følg stegene. Eksisterer allerede bedriften
                  din, kan du be bedriftsadministrator om invitasjon.
                </p>
              </div>
              <div className={styles.question}>
                <h3>Hvordan får jeg bestilt/avbestilt lunsj?</h3>
                <p>
                  Bestilling/avbestilling gjør du enkelt inne på brukeren din.
                  På lunsjkalenderen har du full oversikt fra dag til dag.
                </p>
              </div>
              <div className={styles.question}>
                <h3>Leverer dere møtemat?</h3>
                <p>
                  Ja! For engangsbestillinger kontakt oss på chat eller mail. Vi
                  lager gjerne en bruker til deg så du enkelt og raskt kan
                  bestille på nett hver gang du har møte.
                </p>
              </div>
            </div>
            <img
              src={'/images/logo_white.png'}
              alt="LunsjKollektivet"
              draggable="false"
              className={styles.logo}
            ></img>
          </Container>{' '}
        </div>
        <Container color="gray" width={800} withPadding>
          <h2>Søk i hjelpesenteret</h2>
          <div style={{ paddingBottom: '2em' }}>
            <Button
              onClick={() =>
                (window.location.href = 'https://hjelp.lunsjkollektivet.no')
              }
            >
              Gå til hjelpesenteret
            </Button>
          </div>
          <form
            className={styles.searchInput}
            onSubmit={e => {
              window.location.href =
                'https://hjelp.lunsjkollektivet.no?q=' + this.state.search;
              e.preventDefault();
            }}
          >
            <Input
              value={this.state.search}
              onChange={search => this.setState({ search })}
              withPadding={false}
              placeholder="Hvordan bestille møtemat?"
            />
            <div
              className={styles.button}
              onClick={() =>
                (window.location.href =
                  'https://hjelp.lunsjkollektivet.no?q=' + this.state.search)
              }
            >
              Søk
            </div>
          </form>

          <div style={{ fontSize: '0.7em' }}>
            <i>* Du blir vidersendt til hjelpesenteret</i>
          </div>
        </Container>
      </div>
    );
  }
}
