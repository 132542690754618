import React from 'react';
import SettingCard from '../../../../../components/SettingCard';
import Card from '../../../../../components/Card';
import Button from '../../../../../components/Button';
import { cardIsValid } from '../../../../../utils/validateCard';

const Betaling = props => {
  let content = props.hasFullSpons ? 'Har bedriftsavtale' : 'Ingen Kort';

  if (props.cards.length > 0) {
    content = props.cards.filter(card => cardIsValid(card)).map((card, index) => {
      return <div key={index}>**** **** **** {card.last4}</div>;
    });
    if (content.length === 0) {
      content = 'Ingen Kort'
    }
  }

  if (props.button) {
    return (
      <Card>
        <div style={{ marginBottom: '10px', fontSize: '1.2em' }}>
          {props.validate && (props.cards.length || props.hasFullSpons) ? (
            <div className="fa fa-check" />
          ) : null}
          <strong>Betaling</strong>
        </div>
        <div>{content}</div>
        <div className="mt-5">
          <Button
            to={'/innstillinger/payment/info'}
            color={props.cards.length || props.hasFullSpons ? 'gray' : 'main'}
          >
            Legg til kort
          </Button>
        </div>
      </Card>
    );
  }
  return (
    <SettingCard
      header="Betaling"
      to="/innstillinger/payment/info"
      linkText="Endre"
    >
      {content}
    </SettingCard>
  );
};

export default Betaling;
