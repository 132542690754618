import selectMeta from '../../selectors/selectMeta';

export const selectAll = (state, admin = true) => {
  const meta = state.subscriptionType.meta;
  if (!meta) return [];

  return Object.keys(state.subscriptionType.entities)
    .map(key => state.subscriptionType.entities[key])
    .filter(st => admin || !st.onlyAdmin);
};

export const selectAllForCustomers = (state, admin = false) =>
  selectAll(state, admin).filter(st => st.forCustomers);

export const selectAllForCompanies = (state, admin = false) =>
  selectAll(state, admin).filter(st => st.forCustomers);

export const selectAllBoxes = (state, admin = false) =>
  selectAll(state, admin).filter(st => st.type === "box");

export const selectAllMeta = state => selectMeta(state.subscriptionType.meta);

export const selectCompanyAgreementMeta = state =>
  selectMeta(state.subscriptionType.meta.companyAgreement);
