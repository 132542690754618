import React from 'react';
import Header from '../Header/Header';
import Container from '../Container';

export default () => (
  <React.Fragment>
    <Header />
    <Container withPadding className="text-center">
      <h1 style={{ fontSize: '5em' }}>404</h1>
      <h2>Fant ikke denne siden</h2>
      <p>Vi kan ikke finne siden du spør om.</p>
    </Container>
  </React.Fragment>
);
