export default source => {
  switch (source) {
    case 'selv-signup':
      return 'Registrert seg selv';
    case 'admin-signup':
      return 'Registrert av admin';
    case 'home-delivery-order':
      return 'Bruker fra hjemmekontor';
    case 'selv-meeting-food-signup':
      return 'Bruker fra møtematbestilling';
    case 'selv-company-signup':
    case 'company-signup':
      return 'Bruker fra ny bedrift';
    default:
      return 'Uvisst';
  }
};
