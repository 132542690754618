import * as Sentry from '@sentry/browser';

export const configure = () => {
  if (process.env.REACT_APP_ENABLE_SENTRY === '1') {
    Sentry.init({
      dsn: 'https://437426aee15b41cca68a07f542f93452@sentry.io/1208399',
      release: process.env.VERSION,
      environment: 'production',
    });
  }
};

export const setUserContext = (email, id) => {
  if (process.env.REACT_APP_ENABLE_SENTRY === '1') {
    Sentry.configureScope(scope => {
      scope.setUser({
        id,
        email,
      });
    });
  } else {
    console.log(`Setting user context, email: ${email} and id: ${id}`);
  }
};
