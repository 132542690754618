import React from 'react';

import styles from './loader.module.css';

export default ({ size = 45 }) => (
  <div
    className={styles.loader}
    style={{
      height: size,
      width: size,
      borderTopWidth: size / 6,
      borderBottomWidth: size / 6,
      borderRightWidth: size / 6,
      borderLeftWidth: size / 6,
    }}
  />
);
