import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  myOrdersMeta: {
    loading: false,
    error: false,
    loadedAt: null,
    ids: [],
  },
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MY_ORDERS_START:
      return {
        ...state,
        myOrdersMeta: {
          loading: true,
          error: false,
          loadedAt: null,
          ids: [],
        },
      };
    case types.GET_MY_ORDERS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        myOrdersMeta: {
          loading: false,
          error: false,
          loadedAt: moment().unix(),
          ids: Object.keys(action.payload.entities),
        },
      };
    case types.GET_MY_ORDERS_ERROR:
      return {
        ...state,
        myOrdersMeta: {
          loading: false,
          error: true,
          loadedAt: moment().unix(),
          ids: [],
        },
      };
    case types.GET_ORDERS_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_ORDERS_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
      };
    case types.GET_ORDERS_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    default:
      return state;
  }
};
