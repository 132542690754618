import addPrefix from '../../utils/addPrefix';

export default addPrefix('choices', [
  'GET_CUSTOMER_CHOICES_START',
  'GET_CUSTOMER_CHOICES_SUCCESS',
  'GET_CUSTOMER_CHOICES_ERROR',
  'UPDATE_CHOICE_START',
  'UPDATE_CHOICE_SUCCESS',
  'UPDATE_CHOICE_ERROR',
  'CANCEL_CHOICES_START',
  'CANCEL_CHOICES_SUCCESS',
  'CANCEL_CHOICES_ERROR',
]);
