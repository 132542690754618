import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import firstSubDate from '../../../utils/firstSubDate';
import { isExtraLarge } from '../../../utils/lunchOverviewUtils';

import Spinner from '../../Spinner';
import Link from '../../Link';
import Alert from '../../Alert';
import WeekPicker from '../../WeekPicker';

import { getForCompanyAgreement as getSubscriptionTypesForCompanyAgreement } from '../../../stores/subscriptionType/actions';
import { selectCompanyAgreementMeta as selectCompanyAgreementSubscriptionTypeMeta } from '../../../stores/subscriptionType/selectors';
import selectCompanyAgreementSubscriptionTypes from '../../../selectors/subscriptionType/selectForCompanyAgreement';

/**
 * SubscriptionType Store functions
 */
import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../stores/subscriptionType/actions';
import {
  selectAll as selectAllSubscriptionTypes,
  selectAllMeta as selectAllSubscriptionTypesMeta,
} from '../../../stores/subscriptionType/selectors';

/**
 * Customer Store functions
 */
import { selectCustomer } from '../../../stores/customer/selectors';

/**
 * Menu Store functions
 */
import { getForWeekIfNeeded as getMenusForWeekIfNeeded } from '../../../stores/menu/actions';
import {
  selectMenusForWeek,
  selectMenusForWeekMeta,
} from '../../../stores/menu/selectors';

/**
 * No Delivery Day Store functions
 */
import { getForWeekIfNeeded as getNoDeliveryDayForWeekIfNeeded } from '../../../stores/noDeliveryDay/actions';
import {
  selectNoDeliveryDaysForWeek,
  selectNoDeliveryDaysForWeekMeta,
} from '../../../stores/noDeliveryDay/selectors';

/**
 * Choice Store functions
 */
import {
  getCustomerChoicesForWeekIfNeeded,
  updateCustomerChoiceForDay,
} from '../../../stores/choice/actions';
import {
  selectCustomerChoicesForWeek,
  selectCustomerChoicesForWeekMeta,
} from '../../../stores/choice/selectors';

/**
 * Order Store functions
 */
import { getCustomerOrdersForWeekIfNeeded } from '../../../stores/order/actions';
import {
  selectCustomerOrdersForWeek,
  selectCustomerOrdersForWeekMeta,
} from '../../../stores/order/selectors';

/**
 * Subscription Store functions
 */
import { getForCustomerIfNeeded as getSubscriptionForCustomerIfNeeded } from '../../../stores/subscription/actions';
import {
  selectCustomerSubscription,
  selectCustomerSubscriptionMeta,
} from '../../../stores/subscription/selectors';

/**
 * Customer rating Store functions
 */
import {
  getRatings,
  updateForMenu,
} from '../../../stores/customerRating/actions';
import {
  selectUserRatingsForWeek,
  selectUserRatingsForWeekMeta,
} from '../../../stores/customerRating/selectors';

/**
 * Delivery infos Store functions
 */
import { getForCustomerIfNeeded as getDeliveryInfoForCustomerIfNeeded } from '../../../stores/deliveryInfo/actions';
import {
  selectDeliveryInfosForCustomerWeek,
  selectDeliveryInfosForCustomerWeekMeta,
} from '../../../stores/deliveryInfo/selectors';

/**
 * Customer allergies Store function
 */
import { selectUserAllergies } from '../../../stores/user/selectors';

import Button from '../../Button/Button';

class WeekLunchOverviewContainer extends React.Component {
  state = {
    valuesPerDay: {},
    saving: {},
    savingRating: {},
    success: {},
  };

  constructor(props) {
    super(props);
    this.saveChoice = {};
    this.callNumber = {};
  }

  componentDidMount() {
    this.setState({ _mounted: true });
    this.props.getAllSubscriptionTypesIfNeeded();
    if (this.props.customer.companyAgreement) {
      this.props.getSubscriptionTypesForCompanyAgreement(
        this.props.customer.companyAgreement.id
      );
    }
    this.props.getSubscriptionForCustomerIfNeeded(this.props.customerId);
    this.props.getMenusForWeekIfNeeded(this.props.week);
    this.props.getNoDeliveryDayForWeekIfNeeded(this.props.week);

    if (this.shouldFetchChoicesForWeek()) {
      this.props.getCustomerChoicesForWeekIfNeeded(
        this.props.customerId,
        this.props.week
      );
    }

    if (this.shouldFetchOrdersForWeek()) {
      this.props.getCustomerOrdersForWeekIfNeeded(
        this.props.customerId,
        this.props.week
      );
      this.props.getDeliveryInfoForCustomerIfNeeded(
        this.props.customerId,
        this.props.week
      );
    }

    if (this.shouldFetchRatingsForWeek()) {
      this.props.getRatings(this.props.customer.identity.id, this.props.week);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.getAllSubscriptionTypesIfNeeded();
    if (this.props.customer.companyAgreement) {
      this.props.getSubscriptionTypesForCompanyAgreement(
        this.props.customer.companyAgreement.id
      );
    }
    this.props.getSubscriptionForCustomerIfNeeded(this.props.customerId);
    this.props.getMenusForWeekIfNeeded(this.props.week);
    this.props.getNoDeliveryDayForWeekIfNeeded(this.props.week);

    if (this.shouldFetchChoicesForWeek()) {
      this.props.getCustomerChoicesForWeekIfNeeded(
        this.props.customerId,
        this.props.week
      );
    }

    if (this.shouldFetchOrdersForWeek()) {
      this.props.getCustomerOrdersForWeekIfNeeded(
        this.props.customerId,
        this.props.week
      );
      this.props.getDeliveryInfoForCustomerIfNeeded(
        this.props.customerId,
        this.props.week
      );
    }

    if (this.shouldFetchRatingsForWeek()) {
      this.props.getRatings(this.props.customer.identity.id, this.props.week);
    }
  }

  componentWillUnmount() {
    this.setState({ _mounted: false });
  }

  shouldFetchChoicesForWeek() {
    return firstSubDate()
      .add(-1, 'day')
      .isSameOrBefore(this.props.week.clone().endOf('week'));
  }

  shouldFetchOrdersForWeek() {
    return firstSubDate()
      .add(-1, 'day')
      .isSameOrAfter(this.props.week.clone().startOf('week'));
  }

  shouldFetchRatingsForWeek() {
    return moment().isSameOrAfter(this.props.week.clone().startOf('week'));
  }

  error() {
    return (
      this.props.subscriptionMeta.error ||
      this.props.menusMeta.error ||
      this.props.noDeliveryDaysMeta.error ||
      this.props.subscriptionTypesMeta.error ||
      this.props.subscriptionTypesCompanyAgreementMeta.error ||
      (this.shouldFetchOrdersForWeek() && this.props.ordersMeta.error) ||
      (this.shouldFetchChoicesForWeek() && this.props.choicesMeta.error) ||
      (this.shouldFetchRatingsForWeek() && this.props.ratingsMeta.error)
    );
  }

  loading() {
    return (
      this.props.subscriptionMeta.loading ||
      this.props.menusMeta.loading ||
      this.props.noDeliveryDaysMeta.loading ||
      this.props.subscriptionTypesMeta.loading ||
      this.props.subscriptionTypesCompanyAgreementMeta.loading ||
      (this.shouldFetchOrdersForWeek() && this.props.ordersMeta.loading) ||
      (this.shouldFetchChoicesForWeek() && this.props.choicesMeta.loading) ||
      (this.shouldFetchRatingsForWeek() && this.props.ratingsMeta.loading)
    );
  }

  _saveChoice = (date, values, callNumber, clb = () => { }) => {
    const dayString = date.format('DD-MM-YYYY');

    const data = {
      date: date.format(),
      data: {
        entries: values.map(value => {
          return {
            type: value.type.alias,
            menuAmount: value.menuAmount,
            menuExtraLarge: isExtraLarge(
              value.type.alias,
              this.props.subscription
            ),
          };
        }),
      },
    };

    return this.props
      .updateCustomerChoiceForDay(this.props.customerId, date, data)
      .then(() => {
        if (this.callNumber[dayString] !== callNumber) {
          return;
        }

        let valuesPerDay = { ...this.state.valuesPerDay };
        delete valuesPerDay[dayString];

        if (this.state._mounted) {
          this.setState(
            {
              saving: {
                ...this.state.saving,
                [dayString]: false,
              },
              success: {
                ...this.state.success,
                [dayString]: true,
              },
              valuesPerDay,
            },
            clb
          );

          setTimeout(() => {
            this.setState({
              success: {
                ...this.state.success,
                [dayString]: false,
              },
            });
          }, 2000);
        }
      });
  };

  updateChoiceWrapper = (date, values, clb) => {
    const dayString = date.format('DD-MM-YYYY');

    if (!this.callNumber[dayString]) {
      this.callNumber[dayString] = 0;
    }

    this.callNumber[dayString]++;

    this.setState({
      valuesPerDay: {
        ...this.state.valuesPerDay,
        [dayString]: values,
      },
      saving: {
        ...this.state.saving,
        [dayString]: true,
      },
    });

    if (!this.saveChoice[dayString]) {
      this.saveChoice[dayString] = _.debounce(this._saveChoice, 1500);
    }

    this.saveChoice[dayString](date, values, this.callNumber[dayString], clb);
  };

  saveRating = (menuId, rating) => {
    this.setState({
      savingRating: {
        ...this.state.savingRating,
        [menuId]: true,
      },
    });

    return this.props
      .updateForMenu(this.props.customer.identity.id, menuId, {
        rating,
      })
      .then(() => {
        this.setState({
          savingRating: {
            ...this.state.savingRating,
            [menuId]: false,
          },
        });
      });
  };

  buildContent() {
    if (this.error()) {
      return (
        <Alert color="red">
          Noe skjedde når vi lastet inn siden. Vennligst prøv igjen senere
        </Alert>
      );
    }

    if (this.loading()) {
      return (
        <div style={{ marginTop: '50px' }}>
          <Spinner black />
        </div>
      );
    }

    if (
      !(
        this.props.subscription &&
        (!this.props.subscription.endDate ||
          moment(this.props.subscription.endDate)
            .add(1, 'days')
            .isAfter(moment()))
      ) &&
      !this.props.showWeekPicker
    ) {
      return;
    }

    return this.props.children({
      subscription: this.props.subscription,
      subscriptionTypes: this.props.subscriptionTypes,
      subscriptionTypesCompanyAgreement: this.props
        .subscriptionTypesCompanyAgreement,
      customer: this.props.customer,
      menus: this.props.menus,
      noDeliveryDays: this.props.noDeliveryDays,
      deliveryInfos: this.props.deliveryInfos,
      choices: this.props.choices,
      orders: this.props.orders,
      ratings: this.props.ratings,
      week: this.props.week,
      valuesPerDay: this.state.valuesPerDay,
      saving: this.state.saving,
      success: this.state.success,
      savingRating: this.state.savingRating,
      updateChoice: this.updateChoiceWrapper,
      onWeekChange: week => this.props.onWeekChange(week),
      onSubmitRating: this.saveRating,
      admin: this.props.admin,
      userAllergies: this.props.userAllergies,
    });
  }

  buildActivateSubscription() {
    if (
      !this.props.showReactivateSubscription ||
      this.loading() ||
      (this.props.subscription &&
        (!this.props.subscription.endDate ||
          moment(this.props.subscription.endDate)
            .add(1, 'days')
            .isAfter(moment()))) ||
      this.props.admin
    ) {
      return null;
    }

    return (
      <div className="text-center">
        <div style={{ margin: '20px 0' }}>
          <Alert>Du har ingen fast levering</Alert>
        </div>
        <Button size="medium" to="https://my.lunsjkollektivet.no">
          Start levering
        </Button>
      </div>
    );
  }

  render() {
    if (
      !this.props.admin &&
      (!this.props.customer.cards || this.props.customer.cards.length === 0) &&
      !(
        this.props.customer.companyAgreement &&
        this.props.customer.companyAgreement.employeeDiscount === 100 &&
        this.props.customer.companyAgreement.discountType === 'percent'
      )
    ) {
      return (
        <div>
          <br />
          <Alert color="gray">
            For å endre kommende bestillinger er du nødt til å legge inn{' '}
            <Link to={'/innstillinger/payment/info'}>
              kortinformasjon på siden din.
            </Link>{' '}
            Du kan fortsatt gjøre endringer på ditt abonnement.
          </Alert>
        </div>
      );
    }

    return (
      <div>
        <Helmet>
          <title>Lunsjkalender - Lunsjkollektivet</title>
        </Helmet>
        {this.buildActivateSubscription()}
        {!this.props.showWeekPicker ||
          !this.props.subscription ||
          !this.props.subscription.startDate ? null : (
          <WeekPicker
            week={this.props.week}
            onChange={week => this.props.onWeekChange(week)}
            showWeeks={6}
          />
        )}
        {this.buildContent()}
      </div>
    );
  }
}

WeekLunchOverviewContainer.defaultProps = {
  showWeekPicker: true,
  showReactivateSubscription: true,
};

WeekLunchOverviewContainer.propTypes = {
  showWeekPicker: PropTypes.bool,
  showReactivateSubscription: PropTypes.bool,
};

export default connect(
  (state, ownProps) => {
    const customer = selectCustomer(state, ownProps.customerId);
    return {
      subscriptionTypes: selectAllSubscriptionTypes(state),
      subscriptionTypesMeta: selectAllSubscriptionTypesMeta(state),
      subscription: selectCustomerSubscription(state, ownProps.customerId),
      subscriptionMeta: selectCustomerSubscriptionMeta(
        state,
        ownProps.customerId
      ),
      customer,
      menus: selectMenusForWeek(state, ownProps.week),
      menusMeta: selectMenusForWeekMeta(state, ownProps.week),
      noDeliveryDays: selectNoDeliveryDaysForWeek(state, ownProps.week),
      noDeliveryDaysMeta: selectNoDeliveryDaysForWeekMeta(state, ownProps.week),
      subscriptionTypesCompanyAgreement: selectCompanyAgreementSubscriptionTypes(
        state
      ),
      subscriptionTypesCompanyAgreementMeta: selectCompanyAgreementSubscriptionTypeMeta(
        state
      ),
      deliveryInfos: selectDeliveryInfosForCustomerWeek(
        state,
        ownProps.customerId,
        ownProps.week
      ),
      deliveryInfosMeta: selectDeliveryInfosForCustomerWeekMeta(
        state,
        ownProps.customerId,
        ownProps.week
      ),
      choices: selectCustomerChoicesForWeek(
        state,
        ownProps.customerId,
        ownProps.week
      ),
      choicesMeta: selectCustomerChoicesForWeekMeta(
        state,
        ownProps.customerId,
        ownProps.week
      ),
      orders: selectCustomerOrdersForWeek(
        state,
        ownProps.customerId,
        ownProps.week
      ),
      ordersMeta: selectCustomerOrdersForWeekMeta(
        state,
        ownProps.customerId,
        ownProps.week
      ),
      ratings: selectUserRatingsForWeek(
        state,
        customer.identity.id,
        ownProps.week
      ),
      ratingsMeta: selectUserRatingsForWeekMeta(
        state,
        customer.identity.id,
        ownProps.week
      ),
      userAllergies: selectUserAllergies(state, customer.identity.id),
    };
  },
  {
    getSubscriptionForCustomerIfNeeded,
    getAllSubscriptionTypesIfNeeded,
    getMenusForWeekIfNeeded,
    getNoDeliveryDayForWeekIfNeeded,
    getSubscriptionTypesForCompanyAgreement,
    getCustomerChoicesForWeekIfNeeded,
    getCustomerOrdersForWeekIfNeeded,
    getDeliveryInfoForCustomerIfNeeded,
    updateCustomerChoiceForDay,
    getRatings,
    updateForMenu,
  }
)(WeekLunchOverviewContainer);
