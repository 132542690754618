import React from 'react';
import SettingCard from '../../../../../../../components/SettingCard';
import CompanyAgreementHoc from '../../../Settings/components/CompanyAgreementHoc';
import { DeliveryAddressCard } from '../../../../../../../components/CompanySettings/DeliveryAddress';
import { CompanyAgreementInvoiceCard } from '../../../../../../../components/CompanySettings/CompanyAgreementInvoice';
import CompanyBackground from '../../../../components/CompanyBackground';
import { formatDateString } from '../../../../../../../utils/formatUserFrendlyDate';

function Admin(props) {
  const agreementDetails = () => {
    if (props.companyAgreement.employeeDiscount === 0)
      return <div>Ansatte betaler for lunsjen sin</div>;

    if (props.companyAgreement.discountType === 'percent') {
      return (
        <div>
          Bedriften betaler {props.companyAgreement.employeeDiscount}% av hver
          lunsj
        </div>
      );
    }
    return (
      <div>
        Bedriften betaler {props.companyAgreement.employeeDiscount} kr per lunsj
      </div>
    );
  };

  if (!props.companyAgreement) return <div />;
  return (
    <CompanyBackground
      title="Innstillinger for bedriftsavtale"
      info="Som bedriftsadministrator kan du få oversikt over, og gjøre endringer på
        bedriftsavtalen her. Ved hjelp av menyen over kan du også se bedriftens lunsjkalender,
        eller endre på de faste leveringene."
    >
      <SettingCard
        header="Detaljer om avtalen"
        to="https://company.lunsjkollektivet.no/settings"
        linkText="Endre"
      >
        {agreementDetails()}
        {
          <p>
            Startdato:{' '}
            <i>{formatDateString(props.companyAgreement.startDate)}</i>
          </p>
        }
      </SettingCard>
      <SettingCard
        header="Ansatte i avtalen"
        to="https://company.lunsjkollektivet.no/settings"
        linkText="Se eller overstyre"
      >
        {/* <p>
            Mange bytter nå mye mellom kontoret og hjemmekontor. Vi har gjort det
            enkelt for bedriftsadministrator å starte opp igjen lunsjbestillingene
            til sine ansatte raskt og enkelt!
          </p> */}
        {props.customers.length} ansatte
      </SettingCard>
      {/* <SettingCard
        header="Møtemat"
        to="https://company.lunsjkollektivet.no/settings"
        linkText="Bestill møtemat"
      >
        <p>Alle fortjener god mat - også de du har invitert på møte!</p>
      </SettingCard> */}
      <DeliveryAddressCard
        companyAgreementId={props.companyAgreement.id}
        to="https://company.lunsjkollektivet.no/settings"
      />
      <SettingCard
        header="Daglig oversikt"
        to="https://company.lunsjkollektivet.no/settings"
        linkText="Se"
      >
        Daglig oversikt over bestillinger
      </SettingCard>
      <CompanyAgreementInvoiceCard to="https://company.lunsjkollektivet.no/settings" />
    </CompanyBackground>
  );
}

export default () => <CompanyAgreementHoc children={Admin} withCustomers />;
