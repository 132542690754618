import React from 'react';

import Header from '../../components/Header';
import Container from '../../components/Container';

import formatUserFrendlyDate from '../../utils/formatUserFrendlyDate';
import moment from 'moment/moment';

export const majorVersion = '2';
export const lastChange = '22-05-22';

export default () => (
  <div>
    <Header withoutCard image="/images/winter/background.jpg" height={300} />
    <Container withPadding>
      <h1>Personvern</h1>
      <p>
        Personvernserklæringen trer i kraft 11. juli 2018 og blir gjeldende
        personvernvilkår for nye og eksisterende kunder i Lunsjkollektivet AS.
      </p>
      <p>
        Herunder ønsker vi at du skal være totalt innforstått med hvilke
        personvernopplysninger vi behandler, hvorfor de samles inn og hvordan de
        brukes. Skulle du likevel ha spørsmål angående erklæringen etter å ha
        lest den oppfordrer vi deg til å ta kontakt med oss via
        kontaktopplysningene nederst på siden vår.
      </p>
      <h3>Behandlingsansvarlig</h3>
      <p>
        Lunsjkollektivet AS (org.nr. 919 366 265) er ansvarlig for behandlingen
        av dine personlige opplysninger og behandler disse med ytterst respekt
        når du besøker vår nettside eller benytter deg av våre tjenester.
      </p>
      <h3>Formålet med behandlingen</h3>
      <p>Vi vil bruke opplysningen vi innhenter av deg til følgende formål:</p>
      <ul>
        <li>For å kunne levere tjenesten vår til deg.</li>
        <li>
          For å kunne gi deg et best mulig produkt basert på de preferanser du
          selv aktivt velger.
        </li>
        <li>
          For å bruke din kontaktinformasjon til å blant annet kontakte deg
          angående levering av vårt produkt etter at du aktivt har bestilt det.
        </li>
        <li>
          For å ha mulighet til å kontakte deg ved eventuelle endringer rundt
          vårt produkt eller ditt kundeforhold til oss.
        </li>
        <li>
          For å kunne administrere fakturering etter at du selv har bestilt et
          produkt av oss.
        </li>
        <li>
          Bruk av e-postadresse til utsending av nyhetsbrev, oppdateringer,
          tilbud og annen relevant informasjon du har bedt om via våre nettsider
          og andre kanaler.
        </li>
        <li>
          For å kunne oppfylle våre rettslige forpliktelser i henhold til blant
          annet regnskapsloven og regnskapsførsel.
        </li>
      </ul>
      <p>
        Personvernopplysningene brukes også til statistikkinnhenting,
        aggregering og administrasjon av nettsiden og tjenesten vår. Kort
        fortalt betyr dette at vi kan tilby deg en bedre tjeneste og nettside.
      </p>
      <p>
        Vi behandler i alle av de overnevnte punktene kun personlige
        opplysninger om deg når det er nødvendig, og i alltid i overenstemmelse
        med angitte formål. Beskyttelsen av dine personlige opplysninger vil
        alltid bli tatt i hensyn.
      </p>
      <h3>Hvilke opplysninger behandles</h3>
      <p>
        Vi behandler både personlig identifiserbar informasjon (opplysninger som
        identifiserer deg som enkeltperson), og ikke-personlig identifiserbar
        informasjon (informasjon som ikke identifiserer deg som enkeltperson) om
        deg. Hvilke opplysninger vi samler inn avhenger av om du aktivt har
        benyttet deg av tjenesten om lunsjlevering gjennom Lunsjkollektivet AS,
        om du frivillig har besøkt nettsiden vår eller deltatt i andre
        aktiviteter administrert av oss som for eksempel konkurranser.
      </p>
      <p>
        Opplysninger du aktivt oppgir til oss, samt opplsyninger vi innhenter
        inkluderer følgende:
      </p>
      <ul>
        <li>Navn</li>
        <li>E-postadresse</li>
        <li>Telefonnummer</li>
        <li>Leveringsadresse</li>
        <li>
          Betalingsinformasjon (om du aktivt velger å legge til et
          betalingskort), eller annen fakturainformasjon som for eksempel en
          fakturaadresse eller e-post
        </li>
        <li>Bruker-ID og registreringsdato</li>
        <li>
          Bestillings- og kjøpshistorikk samt handlemønster (hva du bestiller og
          hvor ofte)
        </li>
        <li>
          Tilbakemeldinger du sender inn etter å aktivt delta på
          spørreundersøkelse eller konkurranser.
        </li>
        <li>
          Andre typer informasjon du frivillig oppgir når du registrerer deg for
          å kunne benytte deg av våre tjenester, eller for å kunne ta kontakt
          med oss via telefon, e-post eller andre plattformer.
        </li>
        <li>Påloggingsinformasjon på våre nettsider.</li>
      </ul>
      <h3>Tilgang til informasjonen</h3>
      <p>
        Lunsjkollektivet AS forholder seg til enhver tid til de retningslinjer
        og vedtekter utsted av Datatilsynet og annen myndighet vi er forpliktet
        til å følge.
      </p>
      <ul>
        <li>
          For å være sikker på at opplysninger du oppgir til oss behandles med
          respekt og beskyttelse er det kun godkjente personer i
          Lunsjkollektivet AS som har tilgang på informasjonen.
        </li>
        <li>
          Lunsjkollektivet AS er lokalisert i Norge, men opplysninger vi
          innhenter kan brukes av selskaper som holder til både i Norge og i
          utlandet. Vi vil dog aldri avsløre opplysninger eller personlig
          informasjon innhentet av oss til tredjeparter vi ikke har avtaler med,
          eller som leverer tjenester til oss.
        </li>
        <li>
          Personopplysningene dine vil kun bli brukt for å utføre den tjenesten
          du aktivt har valgt å bestille fra oss.
        </li>
        <li>
          Eventuelle tredjeparter som har tilgang til informasjonen vi innhenter
          er:
          <ul>
            <li>
              Kompetente myndigheter som kan lovpålegge oss å dele informasjon.
            </li>
            <li>
              Utsending av mail:
              <ul>
                <li>Mailchimp</li>
                <li>Mailgun</li>
                <li>Klaviyo</li>
              </ul>
            </li>
            <li>
              CRM:
              <ul>
                <li>HubSpot</li>
              </ul>
            </li>
            <li>
              Kredittkortinformasjon:
              <ul>
                <li>Stripe</li>
              </ul>
            </li>
            <li>
              Teknisk analyse og feilsøking:
              <ul>
                <li>Sentry</li>
                <li>Datadog</li>
              </ul>
            </li>
            <li>
              Adferdsanalyse og rapportering:
              <ul>
                <li>Google</li>
                <li>Microsoft</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h3>Personopplysninger om mindreårige</h3>
      <p>
        Lunsjkollektivet AS innhenter ikke opplysninger om alder og behandler
        ikke bevisst opplysninger om barn under 15 år.
      </p>
      <h3>Det rettslige grunnlaget for behandlingen</h3>
      <p>Vi behandler dine personopplysninger på bakgrunn av følgende formål</p>
      <ul>
        <li>
          For å kunne gjennomføre vårt oppdrag og levere vår tjeneste i henhold
          til Lunsjkollektivet AS sin kundeavtale med deg.
        </li>
        <li>For å utvikle, administrere og markedsføre Lunsjkollektivet AS.</li>
        <li>For å kunne oppfylle lovpålagte plikter.</li>
      </ul>
      <h3>Direkte markedsføring og elektronisk kommunikasjon</h3>
      <p>
        Lunsjkollektivet kan sende informasjon via elektronisk kommunikasjon
        eller kontakt pr. telefon for å kunne levere lunsjbokser til deg, og med
        informasjon om våre produkter, tilbud og lignende dersom du har et
        kundeforhold, jf. Markedsføringsloven §15. Dersom du ikke ønsker å motta
        markedsføringshenvendelser, kan du til enhver tid enkelt reservere deg
        under kontaktpreferanser på Min Side, eller ved å kontakte kundeservice
        på telefon 72909005, eller e-post til hei@lunsjkollektivet.no Uavhengig
        av eventuell markedsføringsreservasjon, vil du motta ordrebekreftelse,
        bestillingspåminnelser og leveringsinformasjon på e-post og/eller SMS.
      </p>
      <h3>Sikring av opplysningene</h3>
      <p>
        Lunsjkollektivet AS har strenge rutiner og regler for å sikre
        beskyttelse av personopplysninger og personvern. Sikkerhetstiltak
        innbefatter både fysiske og tekniske metoder, slik at du kan føle deg
        trygg på at de personlige opplysninger du oppgir hos oss eller vi
        innhenter er trygt oppbevart.
      </p>
      <p>
        Vi jobber for at våre egne medarbeidere til enhver tid er informert og
        oppdatert nok til å behandle opplysninger gitt til oss i samsvar med
        våre forpliktelser etter personvernlovgivningen.
      </p>
      <p>
        Skulle informasjon fra oss bli gitt videre til eventuelle tredjeparter
        skal vi sikre at det finnes ordninger og avtales som sikrer at
        opplysningene kun brukes til avtalte formål.
      </p>
      <h3>Lagring og sletting</h3>
      <p>
        Lunsjkollektivet AS lagrer dine personopplysninger så lenge det er
        nødvendig for det tiltenkte formålet de ble innhentet til eller for det
        som kreves i henhold til inngåtte avtaler.
      </p>
      <p>
        Om du sier opp ditt kundeforhold til oss, deaktiveres ditt abonnement,
        og din e-post knyttet til nyhetsbrev, tilbud, konkurranser ol vil
        slettes. Skulle du ønske å avslutte ditt abonnement hos oss, eller sette
        det på pause, vil vi innen to år slette de opplysninger du har gitt oss.
      </p>
      <p>
        I henhold til regnskaps- og bokføringsloven kan vi være pålagt å
        oppbevare opplysninger om deg utover to år.
      </p>
      <h3>Dine rettigheter</h3>
      <p>
        Du kan når som helst kreve innsyn i, samt retting og sletting av dine
        personopplysninger. Dette innenfor de begrensningene som er fastsatt i
        personvernopplysningsloven. Dersom personopplysninger om deg er uriktige
        eller ufullstendige og du ikke har mulighet til å rette dem selv kan du
        be Lunsjkollektivet AS endre disse.
      </p>
      <p>
        Ønsker du innsyn sendes en skriftlig forespørsel til
        hei@lunsjkollektivet.no
      </p>
      <p>
        På datatilsynets nettsider finner du en mal på en slik forespørsel:
        https://www.datatilsynet.no/globalassets/global/skjema-maler/innsynsmal.pdf.
      </p>
      <h3>Samtykke</h3>
      <p>
        Personvernerklæringen er gjeldende for alle brukere av nettsiden vår, og
        vi krever at brukeren aksepterer å overholde erklæringen. Når du bruker
        vår nettside eller benytter deg av våre tjenester aksepterer du at dine
        personopplysninger kan behandles slik det er forklart i denne
        erklæringen.
      </p>
      <p>
        Lunsjkollektivet er underlagt norsk lov og vi er pålagt å følge norske
        lovgivninger og vedtekter.
      </p>
      <h3>Oppdateringer og endringer i erklæringen</h3>
      <p>
        Vi forbeholder oss retten til å endre denne personvernerklæringen i
        henhold til gjeldende lov. Den nyeste versjonen av personvernerklæringen
        finnes alltid på{' '}
        <a href="https://m.lunsjkollektivet.no">m.lunsjkollektivet.no</a>.
        Dersom det gjøres vesentlige endringer vil vi særskilt informere om de
        tjenestene som berøres på{' '}
        <a href="https://m.lunsjkollektivet.no">m.lunsjkollektivet.no</a> og
        via tilgjengelige kanaler, for eksempel e-post eller direkte varsling på
        våre nettsteder og andre digitale tjenester. Vi oppfordrer deg likevel
        til å jevnlig lese gjennom publisert personvernerklæring på våre
        nettsider, hvor siste versjon er tilgjengelig.
      </p>
      <i>
        <strong>Sist endret: </strong>
        {formatUserFrendlyDate(moment(lastChange, 'DD-MM-YY'))}
      </i>
    </Container>
  </div>
);
