import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './link.module.css';

const CustomLink = props => {
  if (props.onClick) {
    return (
      <div
        onClick={props.onClick}
        className={cn(styles[`link-${props.color}`], props.className, {
          [styles.gray]: props.gray,
        })}
        style={props.style}
      >
        {props.children}
      </div>
    );
  }

  if (props.href) {
    return (
      <a
        href={props.to}
        className={cn(styles[`link-${props.color}`], props.className, {
          [styles.gray]: props.gray,
        })}
        target={props.openInNewTab ? '_blank' : undefined}
        style={props.style}
      >
        {props.children}
      </a>
    );
  }

  return (
    <Link
      to={props.to}
      className={cn(styles[`link-${props.color}`], props.className, {
        [styles.gray]: props.gray,
        [styles.private]: props.private,
        [styles.company]: props.company
      })}
      target={props.openInNewTab ? '_blank' : undefined}
      style={props.style}
    >
      {props.children}
    </Link>
  );
};

CustomLink.defaultProps = {
  color: 'main',
  gray: false,
};

export default CustomLink;
