import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Loadable from 'react-loadable';

import ModuleLoader from '../components/ModuleLoader';
import PrivateRoute from '../components/PrivateRoute';
import { setUserContext } from '../utils/errorReporting';

import LunchCalendar from '../routes/routes/LunchCalendar';
import MySettings from './routes/MySettings';
import Verving from './routes/Home/Verveside';
import Bedrift from './routes/Bedrift';
import MeetingFoodSignup from './routes/Signup/MeetingFoodSignup';
import MeetingFoodSignupDone from './routes/Signup/MeetingFoodDone';
import CustomerSignup from './routes/Signup/CustomerSignup';
import CompanySignup from './routes/Signup/CompanySignup';
import CustomerSignupDone from './routes/Signup/CustomerDone';
import CompanySignupDone from './routes/Signup/CompanyDone';
import Instagram from './routes/Instagram';
import GetStarted from './routes/GetStarted';
import LandingsideBedrift from './routes/LandingsideBedrift';
import CustomerDashboard from './routes/CustomerDashboard';
import About from './routes/About';
import Nutrition from './routes/Nutrition';
import Lunch from './routes/Lunch';
import MyCompany from './routes/MyCompany';
import Help from './routes/Help';
import GenerelleVilkar from './routes/GenerelleVilkar';
import Policy from './routes/Policy';
import Cookies from './routes/Cookies';
import CookieConsent from '../components/CookieConsent';
import Blog from './routes/Blog';
import VarmMat from './routes/VarmMat';
import SentryPages from './routes/SentryPages';
import Questions from './routes/Help/SporsmalOgSvar';

import wisepops from '../utils/wisepops';

import Footer from '../components/Footer';
/**
 * Me Store function
 */
import { getMe, getToggleCompany } from '../stores/me/actions';
import { selectCompanyAgreement } from '../stores/companyAgreement/selectors';
import hasConsent from '../selectors/me/hasConsent';
import selectedCustomer from '../selectors/me/selectedCustomer';

import { init } from '../stores/entryQuery/actions';
import store from 'store';

const Chef = Loadable({
  loader: () => import(/* webpackChunkName: "chef" */ './routes/Chef'),
  loading: ModuleLoader,
  timeout: 30000,
  delay: 1000,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "admin" */ './routes/Admin'),
  loading: ModuleLoader,
  timeout: 30000,
  delay: 1000,
});

class AuthRoot extends React.Component {
  logPrefix = 'RoutesWithMenu.AuthRoot.';

  componentDidMount() {
    this.props.getMe();
    this.props.init();

    if (window.screen.width <= 900) {
      document.addEventListener('scroll', this.checkScrollUp);
      window.addEventListener('blur', this.checkBlur);
    } else {
      document.addEventListener('mouseout', this.checkMouseOut);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mouseout', this.checkMouseOut);
    document.removeEventListener('scroll', this.checkScrollUp);
    window.removeEventListener('blur', this.checkBlur);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.isAuthenticated && !this.props.isAuthenticated) {
      setUserContext(nextProps.identity.email, nextProps.identity.id);

      let nameParts = nextProps.identity.name.split(" ") || [];
      let firstName = nameParts[0];
      let lastName = nameParts[nameParts.length - 1];

      // Identify user for hubspot
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(["identify",{
          email: nextProps.identity.email,
          firstname: firstName,
          lastname: lastName,
          phone: nextProps.identity.phone,
          database_id: nextProps.identity.id
      }]);
      _hsq.push(['trackPageView']);
    }
  }

  checkScrollUp = () => {
    let position,
      timer,
      delay = 50,
      offset = -200;

    const trigger = () => {
      if (position != null && window.scrollY - position < offset) {
        document.removeEventListener('scroll', this.checkScrollUp);
        !this.props.loadingUser &&
          !this.props.customer &&
          wisepops('event', 'uinnlogget');
      }
    };

    position = window.scrollY;
    clearTimeout(timer);
    timer = setTimeout(trigger, delay);
  };

  checkMouseOut = e => {
    if (!e.toElement && !e.relatedTarget) {
      document.removeEventListener('mouseout', this.checkMouseOut);
      !this.props.loadingUser &&
        !this.props.customer &&
        wisepops('event', 'uinnlogget');
    }
  };

  checkBlur = () => {
    window.removeEventListener('blur', this.checkBlur);
    !this.props.loadingUser &&
      !this.props.customer &&
      wisepops('event', 'uinnlogget');
  };

  publicRoutes() {
    const search = this.props.history.location.search;

    if (search.includes('source=')) {
      const entranceSource = search
        .split('source=')
        .pop()
        .split('&')[0];
      store.set('entranceSource', entranceSource);
    }
    if (search.includes('campaign=')) {
      const entranceCampaign = search
        .split('campaign=')
        .pop()
        .split('&')[0];
      store.set('entranceCampaign', entranceCampaign);
    }

    return [
      <Redirect exact from="/lunsj" to="/lunsj/Salad" />,
      <Route path="/om" render={About} />,
      <Route path="/filosofi" component={Nutrition} />,
      <Route path="/lunsj" component={Lunch} />,
      <Route path="/lunsj-meny" render={() => <Lunch campaign />} />,
      <Route path="/hjelp" component={Help} />,
      <Route path="/vilkar" component={GenerelleVilkar} />,
      <Route path="/personvern" component={Policy} />,
      <Route path="/cookies" component={Cookies} />,
      <Route exact path="/varmmat" component={VarmMat} />,
      <Route exact path="/questions" component={Questions} />,
      <Route path="/blog" component={Blog} />,
    ];
  }

  privateRoutes() {
    let missingSetup = false;

    if (this.props.customer) {
      const deliveryLocation = this.props.companyAgreement
        ? this.props.companyAgreement.deliveryLocation
        : this.props.customer.deliveryLocation;

      missingSetup =
        this.props.identity.meta &&
        (!deliveryLocation ||
          !deliveryLocation.address ||
          !deliveryLocation.address.postalCode ||
          !deliveryLocation.address.postalCode.active ||
          !this.props.setupDone);

      // log error
      if (
        this.props.companyAgreement &&
        this.props.companyAgreement.deliveryLocation &&
        (!this.props.companyAgreement.deliveryLocation.address ||
          !this.props.companyAgreement.deliveryLocation.address.postalCode)
      ) {
        console.error(
          this.logPrefix,
          `Missing Customer.CompanyAgreement.DeliveryLocation.Address or Customer.CompanyAgreement.DeliveryLocation.Address.PostalCode.`,
          `Customer=${this.props.customer.id}`,
          `CompanyAgreement=${this.props.companyAgreement.id}`
        );
      }
      if (
        this.props.customer.deliveryLocation &&
        (!this.props.customer.deliveryLocation.address ||
          !this.props.customer.deliveryLocation.address.postalCode)
      ) {
        console.error(
          this.logPrefix,
          `Missing Customer.DeliveryLocation.Address or Customer.DeliveryLocation.Address.PostalCode.`,
          `Customer=${this.props.customer.id}`
        );
      }
    }

    let routes = [
      <Route path="/signup" component={CustomerSignup} />,
    ];

    routes = [
      ...routes,
      <PrivateRoute path="/admin" component={Admin} />,
      <PrivateRoute path="/minbedrift" component={() => { window.location.href = "https://company.lunsjkollektivet.no/ "; return null; }} />,
      <PrivateRoute path="/kokk" component={Chef} />,
    ];

    return routes;
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Lunsjkollektivet</title>
        </Helmet>
        <div>
          <Switch
            children={[
              ...this.publicRoutes(),
              ...this.privateRoutes(),
              this.props.isAuthenticated && this.props.isPrivate ? (
                <Route exact path="/" component={() => { window.location.href = "https://my.lunsjkollektivet.no/ "; return null; }} />
              ) : null,
              <Route component={SentryPages} />,
            ].filter(r => r)}
          />
        </div>
        <CookieConsent />
        {!this.props.location.pathname.includes('/lunsj-kampanje') && (
          <Footer />
        )}
      </div>
    );
  }
}

export default connect(
  state => {
    const customer = selectedCustomer(state);
    let companyAgreement = null;
    if (customer && customer.companyAgreement) {
      companyAgreement =
        selectCompanyAgreement(state, customer.companyAgreement.id) ||
        customer.companyAgreement;
    }
    return {
      loadingUser: state.me.loading,
      isAuthenticated: state.me.isAuthenticated,
      identity: state.me.identity,
      hasAcceptedPolicyAndVilkar: hasConsent(state, 'vilkar-og-personvern'),
      setupDone: state.me.identity.meta && state.me.identity.meta.setupDone,
      isPrivate: state.me.context.type === 'private',
      customer,
      companyAgreement,
    };
  },
  {
    getMe,
    getToggleCompany,
    init,
  }
)(AuthRoot);
