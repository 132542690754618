import React from 'react';
import PropTypes from 'prop-types';
import Table from '../Table';
import Card from '../Card';

import formatPrice from '../../utils/formatPrice';
import SubscriptionTypeService from '../../services/SubscriptionTypeService';
import moment from 'moment';

const monthNames = [
  'januar',
  'februar',
  'mars',
  'april',
  'mai',
  'juni',
  'juli',
  'august',
  'september',
  'oktober',
  'november',
  'desember',
];

export const getSummaryPerCustomer = orders => {
  return orders.reduce((ordersPerCustomerId, order) => {
    const customerId = order.customer
      ? order.customer.id
      : 'Bedriftsbestillinger';

    const month = moment(order.date).format('M');

    if (!ordersPerCustomerId[month]) {
      ordersPerCustomerId[month] = {};
    }

    if (!ordersPerCustomerId[month][customerId]) {
      ordersPerCustomerId[month][customerId] = {
        name: order.user ? order.user.name : 'Bedriftsbestillinger',
        details: {},
        delivery: {
          count: 0,
          sum: 0,
        },
        total: 0,
        customerId,
      };
    }
    ordersPerCustomerId[month][customerId].total += order.totalPriceAfterSplit;
    order.details.forEach(detail => {
      if (!ordersPerCustomerId[month][customerId].details[detail.type]) {
        ordersPerCustomerId[month][customerId].details[detail.type] = 0;
      }
      ordersPerCustomerId[month][customerId].details[detail.type] +=
        detail.amount;
    });

    if (order.deliveryPriceAfterSplit) {
      ordersPerCustomerId[month][customerId].delivery.count++;
      ordersPerCustomerId[month][customerId].delivery.sum += parseFloat(
        order.deliveryPriceAfterSplit
      );
    }

    return ordersPerCustomerId;
  }, {});
};

const SummaryPerCustomer = props => {
  const summaryPerCustomer = getSummaryPerCustomer(
    props.invoice.orders.filter(o => o.totalPriceAfterSplit)
  );

  return Object.keys(summaryPerCustomer).map(month => (
    <Card header={'Oppsummering per ansatt, ' + monthNames[month - 1]} key={month}>
      <Table>
        <thead>
          <tr>
            <th>Navn</th>
            <th>Beskrivelse</th>
            <th>Sum</th>
            {/* {summaryPerCustomer.hasAnyDeliveryCost ? ( */}
            <th>Andel frakt</th>
            {/* ) : null} */}
          </tr>
        </thead>
        <tbody>
          {Object.values(summaryPerCustomer[month])
            .sort((a, b) => b.total - a.total)
            .map(customerSummary => {
              return (
                <tr key={customerSummary.customerId}>
                  <td>{customerSummary.name}</td>
                  <td>
                    {Object.keys(customerSummary.details).map(detailType => (
                      <div key={detailType}>
                        {SubscriptionTypeService.getNameFromAlias(
                          props.subscriptionTypes,
                          detailType
                        )}{' '}
                        x {customerSummary.details[detailType]}
                      </div>
                    ))}
                    {customerSummary.delivery.count > 0 ? (
                      <div>Frakt x {customerSummary.delivery.count}</div>
                    ) : null}
                  </td>
                  <td>{formatPrice(customerSummary.total)}</td>
                  {/* {summaryPerCustomer.hasAnyDeliveryCost ? ( */}
                  <td>{formatPrice(customerSummary.delivery.sum)}</td>
                  {/* ) : null} */}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Card>
  ));
};

SummaryPerCustomer.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default SummaryPerCustomer;
