import selectMeta from '../../selectors/selectMeta';

export const selectHashtagKey = hashtag => `hashtag_${hashtag}`;

export const selectHashtagFeed = (state, hashtag) => {
  const meta = selectHashtagFeedMeta(state, hashtag);

  return Array.isArray(meta.feed) ? meta.feed : [];
};

export const selectHashtagFeedMeta = (state, hashtag) =>
  selectMeta(state.instagram[selectHashtagKey(hashtag)]);
