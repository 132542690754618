import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';
import Loader from '../../../components/Loader';
import MealNutrition from '../../../components/MealNutrition';
import upperCaseFirstLetter from '../../../utils/upperCaseFirstLetter';
import Day from './Day';

/**
 * Menu Store functions
 */
import { getForWeekIfNeeded as getMenusForWeekIfNeeded } from '../../../stores/menu/actions';
import {
  selectMenusForWeek,
  selectMenusForWeekMeta,
} from '../../../stores/menu/selectors';

import { getToggleCompany } from '../../../stores/me/actions';

/**
 * No Delivery Day Store functions
 */
import { getForWeekIfNeeded as getNoDeliveryDayForWeekIfNeeded } from '../../../stores/noDeliveryDay/actions';
import {
  selectNoDeliveryDaysForWeek,
  selectNoDeliveryDaysForWeekMeta,
} from '../../../stores/noDeliveryDay/selectors';

import styles from './lunch.module.css';

const menuAllergies = menu => {
  if (!menu || !menu.allergies || menu.allergies.length === 0) {
    return '';
  }

  const allergies = menu.allergies.filter(a => a.fraMattilsynet);

  if (allergies.length === 0) {
    return '';
  }

  return 'Inneholder: ' + allergies.map(a => a.name).join(', ');
};

const carbonDioxideLegend = (title, description, file) => (
  <div style={{ display: 'flex' }}>
    <img src={`/icons/klimato/${file}.svg`} alt=" " style={{ width: 40, margin: '0 10px' }} />
    <div>
      <strong style={{ color: '#477750' }}>
        {title}
      </strong>
      <i style={{ fontSize: '0.8em' }}>
        <div>
          {description}
        </div>
        <div>
          kg CO2e
        </div>
      </i>
    </div>
  </div>
);

class Menu extends React.Component {
  componentDidMount() {
    this.props.getMenusForWeekIfNeeded(this.props.week);
    this.props.getNoDeliveryDayForWeekIfNeeded(this.props.week);
  }

  componentDidUpdate() {
    this.props.getMenusForWeekIfNeeded(this.props.week);
    this.props.getNoDeliveryDayForWeekIfNeeded(this.props.week);
  }

  menuForDay = day => {
    return this.props.menus.reduce((menuForDay, menu) => {
      if (
        moment(menu.date).isSame(day, 'day') &&
        menu.type.alias === this.props.type.alias
      ) {
        return menu.meal;
      }
      return menuForDay;
    }, null);
  };

  getNoDelivery = date => {
    const noDeliveryDay = this.props.noDeliveryDays.find(noDeliveryDay =>
      date.isSame(moment(noDeliveryDay.date), 'day')
    );
    if (!noDeliveryDay) return null;
    return noDeliveryDay.reason;
  };

  error() {
    return this.props.menusMeta.error || this.props.noDeliveryDaysMeta.error;
  }

  loading() {
    return (
      this.props.menusMeta.loading || this.props.noDeliveryDaysMeta.loading
    );
  }

  render() {
    if (this.error()) {
      return (
        <Alert color="red">
          Noe skjedde når vi lastet inn siden. Vennligst prøv igjen senere
        </Alert>
      );
    }

    if (this.loading()) {
      return <Loader />;
    }

    return (
      <div className={styles.menuForType}>
        <div className={styles.menus}>
          {[...Array(5)]
            .map((x, i) =>
              this.props.week
                .clone()
                .startOf('isoWeek')
                .add(i, 'day')
            )
            .map((date, i) => {
              const menu = this.menuForDay(date);
              const noDelivery = this.getNoDelivery(date);
              return (
                <Day
                  key={date.format()}
                  day={upperCaseFirstLetter(date.format('dddd'))}
                  date={upperCaseFirstLetter(date.format('D. MMMM'))}
                  typeName={this.props.type.name}
                >
                  {noDelivery ? (
                    noDelivery
                  ) : menu ? (
                    <div>
                      <div style={{ display: 'flex' }}>
                        <div>{menu.description}</div>
                        {menu.carbonDioxide > 0 &&
                          <img src={`/icons/klimato/${parseFloat(menu.carbonDioxide).toFixed(1)}.svg`}
                            alt=" " style={{ width: 70, marginLeft: 10 }} />}
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <i style={{ fontSize: '0.8em' }}>
                          {menuAllergies(menu)}
                        </i>
                      </div>
                      <div style={{ fontSize: '0.8em', textAlign: 'center' }}>
                        <MealNutrition meal={menu} />
                      </div>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>Vi jobber enda med å sette sammen denne menyen</div>
                  )}
                </Day>
              );
            })}
        </div>
        <div style={{ paddingTop: '5em', display: 'flex', justifyContent: 'space-between', margin: '0 auto', width: '70%' }}>
          {carbonDioxideLegend('Lav', '0.1-0.5', 'low')}
          {carbonDioxideLegend('Medium', '0.6-1.5', 'mid')}
          {carbonDioxideLegend('Høy', '1.6 +', 'high')}
        </div>
        {!this.props.isAuthenticated ? (
          <div className="text-center" style={{ marginTop: 30 }}>
            <Button
              medium
              to={`/company-signup?type=${this.props.type.alias}`}
            >
              Bestill {this.props.type.name}
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    isAuthenticated: state.me.isAuthenticated,
    menus: selectMenusForWeek(state, ownProps.week),
    menusMeta: selectMenusForWeekMeta(state, ownProps.week),
    noDeliveryDays: selectNoDeliveryDaysForWeek(state, ownProps.week),
    noDeliveryDaysMeta: selectNoDeliveryDaysForWeekMeta(state, ownProps.week),
  }),
  {
    getMenusForWeekIfNeeded,
    getNoDeliveryDayForWeekIfNeeded,
    getToggleCompany,
  }
)(Menu);
