import addPrefix from '../../utils/addPrefix';

export default addPrefix('orders', [
  'GET_MY_ORDERS_START',
  'GET_MY_ORDERS_SUCCESS',
  'GET_MY_ORDERS_ERROR',
  'GET_ORDERS_START',
  'GET_ORDERS_SUCCESS',
  'GET_ORDERS_ERROR',
]);
