import fetch from '../../utils/fetch';
import types from './types';
import shouldFetch from '../../selectors/shouldFetch';

export const getIfNeeded = companyAgreementId => (dispatch, getState) => {
  if (!shouldFetch(getState().companySubscription.meta[companyAgreementId]))
    return;

  dispatch({
    type: types.GET_START,
    payload: {
      companyAgreementId,
    },
  });

  return fetch(
    '/company-agreements/' + companyAgreementId + '/subscriptions',
    'GET'
  )
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          companyAgreementId,
          subscriptions: Array.isArray(response.data) ? response.data : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          companyAgreementId,
          error,
        },
      });
    });
};

export const deactivate = companyAgreementId => dispatch =>
  fetch(
    `/company-agreements/${companyAgreementId}/subscriptions`,
    'DELETE'
  ).then(response => {
    dispatch({
      type: types.DEACTIVATE_SUCCESS,
      payload: {
        companyAgreementId,
        subscriptions: response.data,
      },
    });
  });

export const update = (companyAgreementId, data) => dispatch =>
  fetch(
    `/company-agreements/${companyAgreementId}/subscriptions`,
    'POST',
    data
  ).then(response => {
    dispatch({
      type: types.UPDATE_SUCCESS,
      payload: {
        companyAgreementId,
        subscription: response.data,
      },
    });
  });

export const create = (companyAgreementId, data) => dispatch =>
  fetch(
    `/company-agreements/${companyAgreementId}/subscriptions`,
    'POST',
    data
  ).then(response => {
    dispatch({
      type: types.CREATE_SUCCESS,
      payload: {
        companyAgreementId,
        subscription: response.data,
      },
    });
  });
