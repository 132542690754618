import React from 'react';
import { connect } from 'react-redux';

import styles from './minSideTitle.module.css';
import Tabs from '../Tabs';
import { selectCustomer } from '../../stores/customer/selectors';

const MinSideTitle = props => (
  <div className={styles.header}>
    <div className={styles.name}>
      Hei,{' '}
      {props.name.slice(
        0,
        props.name.indexOf(' ') > 0
          ? props.name.indexOf(' ')
          : props.name.length
      )}
      !
    </div>
    <div className={styles.rodbetIcon} />
    <div className={styles.havreIcon} />
    {props.showTabs ? (
      <div style={{ marginTop: '36px' }}>
        <Tabs
          tabs={[
            {
              to: 'https://my.lunsjkollektivet.no',
              text: 'Lunsjkalender',
              exact: true,
            },
            {
              to: 'https://my.lunsjkollektivet.no',
              text: 'Fast levering',
              exact: true,
            },
          ]}
          keepSearchQuery={false}
        />
      </div>
    ) : (
      <div className={styles.companyName}>
        {props.companyName ? 'Avtale: ' + props.companyName : 'Privatkunde'}
      </div>
    )}
  </div>
);

export default connect(state => {
  const customer = selectCustomer(state, state.me.customers[0]);
  return {
    name: customer.identity.name,
    companyName: customer.companyAgreement
      ? customer.companyAgreement.company.name
      : null,
  };
})(MinSideTitle);
