import addPrefix from '../../utils/addPrefix';

export default addPrefix('menu', [
  'GET_FOR_WEEK_START',
  'GET_FOR_WEEK_SUCCESS',
  'GET_FOR_WEEK_ERROR',
  'GET_FOR_DAY_START',
  'GET_FOR_DAY_SUCCESS',
  'GET_FOR_DAY_ERROR',
  'UPDATE_SUCCESS',
]);
