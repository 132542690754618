import React from 'react';
import moment from 'moment';
import Container from '../../../../../../../components/Container';
import Button, { BackButton } from '../../../../../../../components/Button';
import Card, { Separator } from '../../../../../../../components/Card';
import CompanyAgreementHoc from '../../components/CompanyAgreementHoc';
import Alert from '../../../../../../../components/Alert';
import Table from '../../../../../../../components/Table';
import Tabs from '../../../../../../../components/Tabs';
import DatePicker from '../../../../../../../components/DatePicker';
import Spinner from '../../../../../../../components/Spinner';
import Loader from '../../../../../../../components/Loader';
import NewCustomerForm from './NewCustomerForm';
import firstSubDate from '../../../../../../../utils/firstSubDate';

import styles from './employees.module.css';
import { Checkbox } from '../../../../../../../components/Form';

const limit = firstSubDate();

class Employees extends React.Component {
  state = {
    view: 'Aktive',
    action: '',
    startDate: limit.clone(),
    pauseFrom: limit.clone(),
    pauseUntil: limit.clone().add(1, 'days'),
    fromFocus: false,
    untilFocus: false,
    startFocus: false,
    activeClicked: [],
    inactiveClicked: [],
    saving: false,
  };

  pauseSubscription = (from, until, customerIds) => {
    let saving = this.state.saving;
    saving = true;
    this.setState({ saving, error: false });

    this.props
      .cancelCustomerChoicesRange(
        this.props.companyAgreement.id,
        customerIds,
        // this.props.subscriptions[customerId].key,
        from,
        until
      )
      .then(() => {
        this.setState({
          saving: false,
        });
        !Object.values(this.state.saving).find(s => s) &&
          this.setState({ action: 'success' });
      });
  };

  unpauseSubscription = (from, until, customerId) => {
    let saving = this.state.saving;
    saving[customerId] = true;
    this.setState({ saving, error: false });

    this.props
      .reverseCancelCustomerChoicesRange(
        this.props.companyAgreement.id,
        customerId,
        this.props.subscriptions[customerId].key,
        from,
        until
      )
      .then(() => {
        this.setState({
          saving: { ...this.state.saving, [customerId]: false },
        });
        !Object.values(this.state.saving).find(s => s) &&
          this.setState({ action: 'success' });
      });
  };

  createCustomerSubscriptions = () => {
    // let saving = this.state.saving;
    // saving[customerId] = true;
    // this.setState({ saving, error: false });

    const details = {
      type: 'weekly',
      data: {
        entries: [
          {
            type: 'Salad',
            menuAmount: 1,
          },
        ],
      },
      days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    };

    const customers = this.state.inactiveClicked.map(c => {
      return { customerId: c, subscription: details };
    });

    this.props.createCustomerRangeSubscriptions(
      customers,
      this.props.companyAgreement.id
    );
  };

  endCustomerSubscription = () => {
    const customers = this.state.activeClicked.map(c => {
      return {
        customerId: c,
        subscriptionKey: this.props.subscriptions[c].key,
        deactivateSubscriptionBody: {
          reason: { note: 'Bedriftsadmin' },
          sendConfirmationEmail: 0,
        },
      };
    });

    this.props.deactivateCustomerRangeSubscriptions(
      customers,
      this.props.companyAgreement.id
    );
  };

  removeCustomerFromCompanyAgreement = customerId => {
    let saving = this.state.saving;
    saving[customerId] = true;
    this.setState({ saving, error: false });

    this.props
      .removeCustomerFromCompanyAgreement(
        customerId,
        this.props.companyAgreement.id
      )
      .then(() => {
        this.setState({
          saving: { ...this.state.saving, [customerId]: false },
        });
      });
  };

  activeActionsBox = () => {
    if (!this.state.activeClicked.length && this.state.action !== 'success')
      return;
    else if (
      this.state.activeClicked.length &&
      this.state.action === 'success'
    ) {
      this.setState({ action: '' });
    }

    switch (this.state.action) {
      case 'pausing':
        return this.pauseBox();
      case 'unpausing':
        return this.unpauseBox();
      case 'ending':
        return this.endBox();
      case 'removing':
        return this.removeBox();
      case 'success':
        return this.successBox();
      default:
        return (
          <div className={styles.dateCol}>
            <h3 style={{ marginBottom: 0 }}> Velg handling </h3>
            <div className={styles.buttonRow}>
              <Button
                onClick={() => {
                  this.setState({ action: 'pausing' });
                }}
              >
                Avbestill intervall
              </Button>
              <Button
                onClick={() => {
                  this.setState({ action: 'unpausing' });
                }}
              >
                Bestill intervall
              </Button>
              <Button
                onClick={() => {
                  this.setState({ action: 'ending' });
                }}
              >
                Stopp leveringer
              </Button>
              {/* <Button
                onClick={() => {
                  this.setState({ action: 'removing' });
                }}
              >
                Fjern ansatt
              </Button> */}
            </div>
          </div>
        );
    }
  };

  inactiveActionsBox = () => {
    if (!this.state.inactiveClicked.length && this.state.action !== 'success')
      return;

    switch (this.state.action) {
      case 'starting':
        return this.startBox();
      case 'removing':
        return this.removeBox();
      case 'success':
        return this.successBox();
      default:
        return (
          <div className={styles.dateCol}>
            <div className={styles.buttonRow}>
              <Button
                onClick={() => {
                  this.setState({ action: 'starting' });
                }}
              >
                Start leveringer
              </Button>
              {/* <Button
                onClick={() => {
                  this.setState({ action: 'removing' });
                }}
              >
                Fjern ansatt
              </Button> */}
            </div>
          </div>
        );
    }
  };

  successBox = () => {
    return (
      <div className={styles.dateCol}>
        <div className={styles.back}>
          <div onClick={() => this.setState({ action: '' })}>
            <BackButton />
          </div>
        </div>
        <h3 style={{ marginBottom: 10, marginTop: 40 }}> Suksess! </h3>
        <p>
          {' '}
          Endringene har blitt gjennomført og lunsjkalenderen hos hver enkelt
          ansatt har blitt oppdatert. Det vil ta opp til noen timer før "Daglig
          oversikt" hos bedriftsadmin også oppdateres.
        </p>
      </div>
    );
  };

  pauseBox = () => {
    return (
      <div className={styles.dateCol}>
        <div className={styles.back}>
          <div onClick={() => this.setState({ action: '' })}>
            <BackButton />
          </div>
        </div>
        <h3 style={{ marginBottom: 10, marginTop: 40 }}>
          {' '}
          Avbestill leveringer for intervall{' '}
        </h3>
        <p>
          {' '}
          Dette vil overskrive valg som den enkelte har gjort for det gitte
          intervallet. Gjelder alle ansatte som er valgt fra listen.{' '}
        </p>
        <div className={styles.datePicker}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div>Avbestill fra og med:</div>
              <DatePicker
                date={this.state.pauseFrom}
                isOutsideRange={date => {
                  return date < limit;
                }}
                onDateChange={date => this.setState({ pauseFrom: date })}
                focused={this.state.fromFocus}
                onFocusChange={() =>
                  this.setState({
                    fromFocus: !this.state.fromFocus,
                  })
                }
              />
            </div>
            <div className={styles.col}>
              <div>Avbestill til og med:</div>
              <DatePicker
                date={this.state.pauseUntil}
                isOutsideRange={date => {
                  return date < limit || date < this.state.pauseFrom;
                }}
                onDateChange={date =>
                  this.setState({
                    pauseUntil: date,
                  })
                }
                focused={this.state.untilFocus}
                onFocusChange={() =>
                  this.setState({
                    untilFocus: !this.state.untilFocus,
                  })
                }
              />
            </div>
          </div>
          <div className={styles.button}>
            <Button
              disabled={this.state.pauseUntil < this.state.pauseFrom}
              onClick={() => {
                let from = this.state.pauseFrom.clone();
                let until = this.state.pauseUntil.clone();
                let clicked = this.state.activeClicked;
                this.setState({ activeClicked: [], action: '' });

                this.pauseSubscription(from, until, clicked);

                // clicked.forEach(activeId => {
                //   this.pauseSubscription(from, until, activeId);
                // });
              }}
            >
              Bekreft <div className="fa fa-arrow-right" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  unpauseBox = () => {
    return (
      <div className={styles.dateCol}>
        <div className={styles.back}>
          <div onClick={() => this.setState({ action: '' })}>
            <BackButton />
          </div>
        </div>
        <h3 style={{ marginBottom: 10, marginTop: 40 }}>
          Legg inn bestillinger for intervall
        </h3>
        <p>
          Dette vil overskrive avbestillinger som er gjort for det gitte
          intervallet. Dersom en dag allerede har lunsjbestilling, vil ingen
          endringer bli gjort. Gjelder alle ansatte som er valgt fra listen.
        </p>
        <div className={styles.datePicker}>
          <div className={styles.row}>
            <div className={styles.col}>
              <div>Bestill fra og med:</div>
              <DatePicker
                date={this.state.pauseFrom}
                isOutsideRange={date => {
                  return date < limit;
                }}
                onDateChange={date => this.setState({ pauseFrom: date })}
                focused={this.state.fromFocus}
                onFocusChange={() =>
                  this.setState({
                    fromFocus: !this.state.fromFocus,
                  })
                }
              />
            </div>
            <div className={styles.col}>
              <div>Bestill til og med:</div>
              <DatePicker
                date={this.state.pauseUntil}
                isOutsideRange={date => {
                  return date < limit || date < this.state.pauseFrom;
                }}
                onDateChange={date =>
                  this.setState({
                    pauseUntil: date,
                  })
                }
                focused={this.state.untilFocus}
                onFocusChange={() =>
                  this.setState({
                    untilFocus: !this.state.untilFocus,
                  })
                }
              />
            </div>
          </div>
          <div className={styles.button}>
            <Button
              disabled={this.state.pauseUntil < this.state.pauseFrom}
              onClick={() => {
                let from = this.state.pauseFrom.clone();
                let until = this.state.pauseUntil.clone();
                let clicked = this.state.activeClicked;

                this.setState({ activeClicked: [], action: '' });
                clicked.forEach(activeId => {
                  this.unpauseSubscription(from, until, activeId);
                });
              }}
            >
              Bekreft <div className="fa fa-arrow-right" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  endBox = () => {
    return (
      <div className={styles.dateCol}>
        <div onClick={() => this.setState({ action: '' })}>
          <BackButton />
        </div>
        <h3> Avslutt abonnement for valgte ansatte </h3>
        <div className={styles.buttonRow}>
          <Button
            onClick={() => {
              this.endCustomerSubscription();
              this.setState({ activeClicked: [], action: '' });
            }}
          >
            Avslutt <div className="fa fa-arrow-right" />
          </Button>
        </div>
      </div>
    );
  };

  startBox = () => {
    return (
      <div className={styles.dateCol}>
        <div onClick={() => this.setState({ action: '' })}>
          <BackButton />
        </div>
        <h3> Start opp abonnement for valgte ansatte </h3>
        <div className={styles.datePicker}>
          <div className={styles.col}>
            <div>Oppstartsdato:</div>
            <DatePicker
              date={this.state.startDate}
              isOutsideRange={date => {
                return date < limit;
              }}
              onDateChange={date => this.setState({ startDate: date })}
              focused={this.state.startFocus}
              onFocusChange={() =>
                this.setState({
                  startFocus: !this.state.startFocus,
                })
              }
            />
          </div>
          <div className={styles.button}>
            <Button
              onClick={() => {
                this.createCustomerSubscriptions();
                this.setState({ inactiveClicked: [], action: '' });
              }}
            >
              Start opp <div className="fa fa-arrow-right" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  removeBox = () => {
    return (
      <div className={styles.dateCol}>
        <div onClick={() => this.setState({ action: '' })}>
          <BackButton />
        </div>
        <h3>Fjern valgte ansatte fra avtalen</h3>
        <p>
          Disse ansatte vil forsvinne fra bedriftsavtalen, og ikke lenger dukke
          opp på denne siden.
        </p>
        <div className={styles.buttonRow}>
          <Button
            onClick={() => {
              let clicked;
              if (this.state.view === 'Aktive') {
                clicked = this.state.activeClicked;
                this.setState({ activeClicked: [], action: '' });
              } else if (this.state.view === 'Inaktive') {
                clicked = this.state.inactiveClicked;
                this.setState({ inactiveClicked: [], action: '' });
              }

              clicked.forEach(activeId => {
                this.removeCustomerFromCompanyAgreement(activeId);
              });
            }}
          >
            Fjern <div className="fa fa-arrow-right" />
          </Button>
        </div>
      </div>
    );
  };

  // A customer is active if there is an active subscription, even if cancelled/paused for a while
  isActive = subscription => {
    if (!subscription) return false;
    return !(
      subscription.endDate &&
      moment(subscription.endDate).isBefore(limit, 'date')
    );
  };

  buildContent = (activeCustomers, inactiveCustomers) => {
    if (this.state.view === 'Aktive') {
      if (!activeCustomers.length) {
        return <div className={styles.header}>Ingen aktive ansatte</div>;
      }

      return (
        <div>
          {this.activeActionsBox()}
          <Table bigHeader>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={
                      this.state.activeClicked.length === activeCustomers.length
                    }
                    saving={this.state.saving}
                    onChange={() =>
                      this.state.activeClicked.length === activeCustomers.length
                        ? this.setState({
                          activeClicked: [],
                        })
                        : this.setState({
                          activeClicked: activeCustomers.map(c => c.id),
                        })
                    }
                  />
                </th>
                <th>Ansatt</th>
                <th>Antall leveringsdager</th>
              </tr>
            </thead>
            <tbody>
              {activeCustomers
                .sort(
                  (a, b) =>
                    a.identity &&
                    b.identity &&
                    a.identity.name.localeCompare(b.identity.name)
                )
                .map(customer => {
                  const sub = this.props.subscriptions[customer.id];

                  return (
                    <tr key={customer.id} className={styles.table}>
                      <td>
                        <div>
                          {this.state.saving[customer.id] ? (
                            <Spinner black />
                          ) : (
                            <Checkbox
                              checked={this.state.activeClicked.includes(
                                customer.id
                              )}
                              saving={this.state.saving}
                              onChange={() =>
                                this.state.activeClicked.includes(customer.id)
                                  ? this.setState({
                                    activeClicked: this.state.activeClicked.filter(
                                      c => c !== customer.id
                                    ),
                                  })
                                  : this.setState({
                                    activeClicked: [
                                      ...this.state.activeClicked,
                                      customer.id,
                                    ],
                                  })
                              }
                            />
                          )}
                        </div>
                      </td>
                      <td>{customer.identity.name}</td>
                      <td>
                        {sub.days
                          ? sub.days.length +
                          ` ukedag${sub.days.length === 1 ? '' : 'er'}`
                          : '0 dager'}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      );
    } else if (this.state.view === 'Inaktive') {
      if (!inactiveCustomers.length) {
        return <div className={styles.header}>Ingen inaktive ansatte</div>;
      }

      return (
        <div>
          {this.inactiveActionsBox()}
          <Table bigHeader>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={
                      this.state.inactiveClicked.length ===
                      inactiveCustomers.length
                    }
                    saving={this.state.saving}
                    onChange={() =>
                      this.state.inactiveClicked.length ===
                        inactiveCustomers.length
                        ? this.setState({
                          inactiveClicked: [],
                        })
                        : this.setState({
                          inactiveClicked: inactiveCustomers.map(c => c.id),
                        })
                    }
                  />
                </th>
                <th>Ansatt</th>
              </tr>
            </thead>
            <tbody>
              {inactiveCustomers
                .sort(
                  (a, b) =>
                    a.identity &&
                    b.identity &&
                    a.identity.name.localeCompare(b.identity.name)
                )
                .map(customer => {
                  return (
                    <tr key={customer.id}>
                      <td>
                        {this.state.saving[customer.id] ? (
                          <Spinner black />
                        ) : (
                          <Checkbox
                            checked={this.state.inactiveClicked.includes(
                              customer.id
                            )}
                            saving={this.state.saving}
                            onChange={() =>
                              this.state.inactiveClicked.includes(customer.id)
                                ? this.setState({
                                  inactiveClicked: this.state.inactiveClicked.filter(
                                    c => c !== customer.id
                                  ),
                                })
                                : this.setState({
                                  inactiveClicked: [
                                    ...this.state.inactiveClicked,
                                    customer.id,
                                  ],
                                })
                            }
                          />
                        )}
                      </td>
                      <td>{customer.identity.name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      );
    }
  };

  render() {
    if (this.props.error) {
      return <Alert color="red">Noe skjedde..</Alert>;
    }
    const activeCustomers = this.props.customers.filter(c =>
      this.isActive(this.props.subscriptions[c.id])
    );

    const inactiveCustomers = this.props.customers.filter(
      c => !activeCustomers.includes(c)
    );

    const nCustomersMap = {
      Aktive: activeCustomers.length | 0,
      Inaktive: inactiveCustomers.length | 0,
      Total: this.props.customers.length | 0,
    };

    return (
      <Container withPadding color="gray" width={900}>
        {<BackButton to={this.props.backTo || 'https://company.lunsjkollektivet.no'} />}
        <Card marginTop headerExtraLarge rounded withPadding>
          <h3 style={{ fontSize: '2em' }}>Ansatte i avtalen</h3>
          <h4>Totalt antall ansatte: {nCustomersMap['Total']}</h4>
          {this.props.loading ? (
            <Loader />
          ) : (
            <div>
              <Tabs
                tabs={['Aktive', 'Inaktive'].map(view => ({
                  text: (
                    <>
                      <div>{view} ansatte</div>
                      <div>{nCustomersMap[view]}</div>
                    </>
                  ),
                  selected: this.state.view === view,
                }))}
                onTabChange={i =>
                  this.setState({ view: ['Aktive', 'Inaktive'][i], action: '' })
                }
              />
              {this.buildContent(activeCustomers, inactiveCustomers)}
            </div>
          )}
          <div style={{ marginTop: '50px' }}>
            <Separator />
            <h3>+ Inviter nye ansatte</h3>
            <NewCustomerForm companyAgreement={this.props.companyAgreement} />
          </div>
          {this.state.error ? (
            <div style={{ margin: '20px 0' }}>
              <Alert color="red">Noe skjedde..</Alert>
            </div>
          ) : null}
        </Card>
      </Container>
    );
  }
}

export default props => (
  <CompanyAgreementHoc children={Employees} withCustomers {...props} />
);
