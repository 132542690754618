import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectCustomer } from '../../../stores/customer/selectors';
import SettingCard from '../../SettingCard';
import Card from '../../Card';
import Button from '../../Button';
import customerDeliveryCost from '../../../utils/customerDeliveryCost';
import { isDeliveryLocationInvalid } from '../../../utils/deliveryLocation';

const DeliveryAddressCard = props => {
  const deliveryLocation = props.customer.companyAgreement
    ? props.customer.companyAgreement.deliveryLocation
    : props.customer.deliveryLocation;

  if (props.button) {
    return (
      <Card>
        <div style={{ marginBottom: '10px', fontSize: '1.2em' }}>
          {props.validate && deliveryLocation ? (
            <div className="fa fa-check" />
          ) : null}
          <strong>Leveringsadresse</strong>
        </div>
        {!isDeliveryLocationInvalid(deliveryLocation) ? (
          <div>
            <div>{deliveryLocation.address.addressLine}</div>
            <div>
              {deliveryLocation.address.postalCode.code},{' '}
              {deliveryLocation.address.postalCode.place}
            </div>
            <div>
              Du betaler kr {customerDeliveryCost(props.customer)}
              ,- i frakt <em> *pris eks 15% mva</em>
            </div>
          </div>
        ) : (
          <div>Ingen</div>
        )}
        <div className="mt-5">
          <Button
            to={props.location.pathname + '/leveringsadresse'}
            color={deliveryLocation ? 'gray' : 'main'}
          >
            Endre
          </Button>
        </div>
      </Card>
    );
  }

  return (
    <SettingCard
      header="Leveringsadresse"
      to={props.location.pathname + '/leveringsadresse'}
      linkText="Endre"
    >
      {!isDeliveryLocationInvalid(deliveryLocation) ? (
        <div>
          <div>{deliveryLocation.address.addressLine}</div>
          <div>
            {deliveryLocation.address.postalCode.code},{' '}
            {deliveryLocation.address.postalCode.place}
          </div>
          <div>
            Du betaler kr {customerDeliveryCost(props.customer)}
            ,- i frakt <em> *pris eks 15% mva</em>
          </div>
        </div>
      ) : (
        <div>Ingen</div>
      )}
    </SettingCard>
  );
};

export default withRouter(
  connect((state, ownProps) => {
    return {
      customer: selectCustomer(state, ownProps.customerId),
    };
  })(DeliveryAddressCard)
);
