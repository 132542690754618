import React from 'react';
import Loader from '../../../components/Loader';
import auth0 from '../../../utils/auth0';

import styles from './login.module.css';
import { loginSuccess } from '../../../stores/me/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Login extends React.Component {
  componentDidMount() {
    this.refreshOrRedirect();
  }

  refreshOrRedirect() {
    let callback = '/';
    const state = this.props.location.state;
    if (state && state.from) {
      callback = state.from.pathname + state.from.search;
    }

    const returnUrl = `${process.env.REACT_APP_Auth0_RedirectUri}?callback=${callback}`;

    auth0.checkSession({}, (err, result) => {
      if (err) {
        auth0.authorize({ redirectUri: returnUrl });
      } else {
        this.props.loginSuccess(result);
        window.location.href = callback;
      }
    });
  }

  render() {
    return (
      <div className={styles.overlay}>
        <div className={styles.login}>
          <h1>Logger inn..</h1>
          <Loader />
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(() => ({}), {
    loginSuccess,
  })(Login)
);
