import React from 'react';
import Container from '../Container';
import Link from '../Link';
import ContactPhone from '../../routes/routes/SentryPages/components/ContactPhone';
import admin from '../../routes/routes/Home/assets/Illustration-laptop.svg';
import levering from '../../routes/routes/Home/assets/Illustration-map.svg';
import lunsj from '../../routes/routes/Home/assets/Illustration-food.svg';
import penger from '../../routes/routes/Home/assets/Illustration-laptop.svg';
import cn from 'classnames';

import styles from './campaign.module.css';

class CampaignContent extends React.Component {
  constructor() {
    super();
    this.state = { clicked: false };
  }

  render() {
    return (
      <Container withPadding width={850} color="beige">
        <h1 className={styles.heading}>4 gode grunner for å la </h1>
        <h1 className={styles.heading}>Lunsjkollektivet ordne lunsjen</h1>
        <div className={styles.scrollBox}>
          <div className={styles.iconRow}>
            <div
              onMouseEnter={() => this.setState({ clicked: 'levering' })}
              onMouseLeave={() => this.setState({ clicked: false })}
            >
              <img
                className={cn(styles.icon, {
                  [styles.active]: this.state.clicked === 'levering',
                  [styles.initial]: !this.state.clicked,
                })}
                alt=""
                src={levering}
              />
              <div className={styles.info}>
                <h2>Lunsjen fiks ferdig på døra hver dag!</h2>
                <div>
                  Det er slutt på gammel mat i kjøleskapet og å bruke tid på
                  rydding og vasking. Siden dere kun får levering når dere er på
                  jobb blir det mye mindre matsvinn.
                </div>
              </div>
              <div className={styles.marker} />
            </div>
            <div
              onMouseEnter={() => this.setState({ clicked: 'admin' })}
              onMouseLeave={() => this.setState({ clicked: false })}
            >
              <img
                className={cn(styles.icon, {
                  [styles.active]: this.state.clicked === 'admin',
                })}
                alt=""
                src={admin}
              />
              <div className={styles.info}>
                <h2>Enkelt å administrere</h2>
                <div>
                  Bestill for alle eller la hver enkelt styre sin egen lunsj.
                  Det er lett å avbestille når dere er borte fra jobb, og sånn
                  reduseres totalkostnaden for kantineløsningen.
                </div>
              </div>
              <div className={styles.marker} />
            </div>
            <div
              onMouseEnter={() => this.setState({ clicked: 'lunsj' })}
              onMouseLeave={() => this.setState({ clicked: false })}
            >
              <img
                className={cn(styles.icon, {
                  [styles.active]: this.state.clicked === 'lunsj',
                })}
                alt=""
                src={lunsj}
              />
              <div className={styles.info}>
                <h2>Sunne og glade ansatte</h2>
                <div>
                  Lunsjbokser fra kokker og ernæringsfysiologer, med mat som
                  både smaker godt og gjør godt for kroppen. Bra for de ansatte.
                  Bra for bedriften.
                </div>
              </div>
              <div className={styles.marker} />
            </div>
            <div
              onMouseEnter={() => this.setState({ clicked: 'penger' })}
              onMouseLeave={() => this.setState({ clicked: false })}
            >
              <img
                className={cn(styles.icon, {
                  [styles.active]: this.state.clicked === 'penger',
                })}
                alt=""
                src={penger}
              />
              <div className={styles.info}>
                <h2>Kontroll på kostnader</h2>
                <div>
                  Slipp løpende kantinekostnad eller skjulte kostnader ved å
                  bruke tid på å gå i butikken. Dere betaler kun for leverte
                  lunsjbokser, og har full oversikt på Min side.
                </div>
              </div>
              <div className={styles.marker} />
            </div>
          </div>
        </div>
        <div className={styles.contactPhone}>
          <h3>Prøv oss uforpliktende</h3>
          <div style={{ fontSize: '1.2em' }}>
            Har du noen spørsmål om smått eller stort vil en av oss gjerne komme i kontakt med deg! Legg igjen telefonnummeret ditt her så tar vi kontakt med en gang.
           </div>
          <div style={{ fontSize: '1.2em' }}>
            Ingenting mer å tenke på? <Link gray to="/company-signup" >Bli kunde her!</Link>
          </div>
          <div style={{ padding: '0 0 30px 0', fontSize: '1.2em' }}>
            Lyst til å vite mer? Svar på de aller fleste praktiske spørsmål rundt pris, levering og løsning finner du <Link gray to="/company-signup" >her!</Link>
          </div>
          <ContactPhone />
        </div>
      </Container>
    );
  }
}

export default CampaignContent;
