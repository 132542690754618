import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 245">
    <path d="M217.1 168.3v-0.8h-28.9v0.8c8.2 0 11.5 5.3 11.5 11.3 0 6.9-4.1 18.1-6.7 24.1l0.7 1.1 15.3-31.1C211.1 169.7 212.8 168.3 217.1 168.3z" />
    <path d="M172.6 168.3v-0.8h-31.8v0.8c4.4 0 6.1 1.8 7.3 4.6l29.7 60.5h0.8l9-17 -19.1-41.1C166.4 171.1 168.1 168.3 172.6 168.3z" />
    <path d="M248.5 166.2v0.7c7.3 0.9 11.6 7.1 11.6 15.4 0 10.3-3.3 12.7-22 12.8v0.8h35.3c0.5-2 0.8-4.5 0.8-6.9C274.1 178.2 265.1 166.4 248.5 166.2z" />
    <path d="M252 230.2c-13.1 0-22.7-11.1-22.7-30.3 0-24.4 5.3-31.9 13.8-33v-0.7c-14.9 0.3-31.9 11.7-31.9 34 0 22.2 16.7 34.6 33.9 34.6 8.8 0 20.8-3.6 27.3-12l-0.1-0.7C266.3 227.4 259.8 230.2 252 230.2z" />
    <path d="M301.9 216v-46.6h19.3l1.3-1.7h-20.6v-18.3c-4.6 4-10.9 6.1-17.3 6.2v12.1h-7l-1.3 1.7h8.3v46.2c0 13.8 8.2 19.3 19.5 19.3 9.2 0 17.1-4.2 21-10v-0.7c-4.1 4.4-8.6 6.7-12.9 6.7C305.8 230.9 301.9 224.8 301.9 216z" />
    <path d="M119.6 147.7v0.7c15.2-0.3 32.4-12.4 32.4-34.6 0-22.3-17.1-33.9-32.4-34v0.7c8.7 0.9 14.4 8.4 14.4 33.4C133.9 138.1 128 146.9 119.6 147.7z" />
    <path d="M114.2 148.3v-0.7c-8.4-0.8-14.4-9.6-14.4-33.9 0-24.9 5.7-32.3 14.4-33.4v-0.7c-15.3 0.3-32.4 11.9-32.4 34C81.7 136.1 99.1 148.1 114.2 148.3z" />
    <path d="M258.6 80.4v-0.7c-14.9 0.3-31.9 11.7-31.9 34 0 22.2 16.7 34.6 33.9 34.6 8.8 0 20.8-3.6 27.3-12l-0.1-0.7c-6.1 5.3-12.5 8-20.3 8 -13.1 0-22.7-11.1-22.7-30.3C244.7 89 250 81.5 258.6 80.4z" />
    <path d="M263.8 80.4c7.3 0.9 11.6 7.1 11.6 15.4 0 10.3-3.3 12.7-22 12.8v0.8h35.3c0.5-2 0.8-4.5 0.8-6.9 -0.1-10.8-9.1-22.7-25.7-22.8L263.8 80.4 263.8 80.4z" />
    <path d="M296.9 75.6v64.6c0 3.3-2 6.2-9.2 6.2v0.7h35.9v-0.7c-7.3 0-9.2-2.9-9.2-6.2V63.6c-7.6 3.4-15.8 5-26.5 5v0.7C294.6 69.3 296.9 71.3 296.9 75.6z" />
    <path d="M356.2 139.4l-23.9-34.3 24.9-20.7c2-1.7 4.6-2.6 7.6-2.6v-0.7l-29.7 0.7c5.4 0 7.1 2 7.1 5.1 0 5.7-13.5 20.4-21.8 27.6l15.4 22.4c6.3 9.9 13.6 11.5 20.6 11.5 3.8 0 7.9-0.3 11.2-1.3v-0.7C363.3 146 360.3 145.3 356.2 139.4z" />
    <polygon points="348.5 108.7 347.2 110.4 374.1 110.4 375.4 108.7 " />
    <polygon points="122.1 39.8 106.3 39.8 106.3 10.7 100.4 10.7 100.4 45.4 122.1 45.4 " />
    <path d="M147.6 46.1c7.3 0 12.9-4.4 12.9-12.8V10.7h-5.9V33c0 4.9-2.6 7.6-7.1 7.6 -4.4 0-7.1-2.7-7.1-7.6V10.7h-5.8v22.6C134.7 41.7 140.4 46.1 147.6 46.1z" />
    <polygon points="182.5 19.1 199.6 45.4 205.7 45.4 205.7 10.7 199.8 10.7 199.8 35.3 184.1 10.7 176.6 10.7 176.6 45.4 182.5 45.4 " />
    <path d="M233 40.8c-5 0-7.6-3.3-7.9-6.9l-5.5 1.6c0.5 5 4.6 10.6 13.4 10.6 7.9 0 12.3-5.1 12.3-10.5 0-4.9-3.3-8.7-9.4-10l-5-1c-2.7-0.6-4.1-2.2-4.1-4.4 0-2.6 2.4-5 6-5 4.6 0 6.4 3.2 6.8 5.3l5.3-1.7c-0.8-3.9-4-8.8-12.1-8.8 -6.4 0-11.8 4.8-11.8 10.6 0 5.2 3.5 8.6 8.8 9.7l4.9 1c3 0.6 4.6 2.4 4.6 4.7C239.3 38.7 237.1 40.8 233 40.8z" />
    <path d="M267.7 46.1c6.6 0 10.9-5.1 10.9-11.3V10.7h-5.9V35c0 3.5-2 5.6-5.1 5.6 -3 0-5.3-1.8-5.3-5.9v-3.2l-5.7 1.2v2.2C256.6 41.7 260.9 46.1 267.7 46.1z" />
    <path d="M149.3 66.8v0.7c6.9 0 9.1 2 9.1 6.3 0 0 0.1 0.1 0.1 0.3v7.6 1.7 46.2c0 13.8 8.2 19.3 19.5 19.3 8 0 15-3.2 19.2-7.8 3.3 5.5 9.3 7.8 16.8 7.8 9.2 0 17.1-4.2 21-10v-0.7c-4.1 4.4-8.6 6.7-12.9 6.7 -6.5 0-10.3-6.1-10.3-14.9v-23.6c0-0.1 0-0.1 0-0.2V61.8c-7.6 3.4-15.8 5-26.5 5v0.7c6.9 0 9.1 2 9.1 6.3 0 0 0 0 0.1 0.2v7.7 1.7 46.2c0 4.4 0.8 7.9 2.3 10.7 -3.5 3-7.1 4.6-10.7 4.6 -6.5 0-10.3-6.1-10.3-14.9V83.4v-1.7V63.4l0 0v-1.6C168.2 65.3 160 66.8 149.3 66.8z" />
    <path d="M134.9 226.9v-64.1c-7.6 3.4-16 5-26.6 5H85v-6l0.2-7.1c0-4.4 2.3-6.3 9.2-6.3v-0.7c-2.3 0-4.5-0.1-6.6-0.2 -3.5-0.5-6.3-1.7-9.8-6.9l-24.2-34.7 25.2-21c2-1.7 4.7-2.7 7.7-2.7v-0.7H56.8v0.7c5.5 0 7.2 2 7.2 5.2 0 5.7-13.6 20.7-22 27.9L57.6 138c6.4 10 13.8 11.6 20.8 11.6 0.5 0 1 0 1.5 0 -5.6 2.1-11.9 6.4-12.5 15.9 0 0.4 0 2.3 0 2.3H55l-1.5 1.7h14v46.9c0 14 8.3 19.5 19.8 19.5 9.4 0 17.4-4.3 21.2-10.2V225c-4.1 4.4-8.7 6.8-13.1 6.8 -6.5 0-10.4-6.1-10.4-15.1v-47.3h22.9c6.7 0 9.6 0.9 9.6 5.3v52.1c0 3.3-2 6.2-9.2 6.2v0.7h35.9V233C136.9 233.1 134.9 230.2 134.9 226.9z" />
    <path d="M35.5 141.3V64.5c-7.7 3.5-16 5.1-26.9 5.1v0.7c6.9 0 9.2 2 9.2 6.4v64.7c0 3.3-2 6.3-9.4 6.3v0.7h36.3v-0.7C37.5 147.6 35.5 144.7 35.5 141.3z" />
  </svg>
);
