import React from 'react';
import Invoice from '../../Invoice';
import Container from '../../Container';

/**
 * Invoice Store functions
 */
import { getIfNeededForCompanyAgreement } from '../../../stores/invoice/actions';
import select from '../../../selectors/invoice/select';
import selectMeta from '../../../selectors/invoice/selectMeta';

const InvoiceDetail = props => (
  <Container withPadding color="gray" width={800} admin={props.admin}>
    <Invoice
      invoiceId={props.match.params.invoiceId}
      selectMeta={selectMeta}
      select={select}
      get={invoiceId =>
        getIfNeededForCompanyAgreement(props.companyAgreementId, invoiceId)
      }
      backTo={props.backTo}
    />
  </Container>
);

export default InvoiceDetail;
