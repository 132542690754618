import React from 'react';

import Link from '../../../Link';
import styles from './footer.module.css';
import { Separator } from '../../../Card';
import { Actions, Modal } from '../../../Modal';
import Button from '../../../Button';

const Footer = ({ deliveryInfo }) => {
  const [open, setOpen] = React.useState(false);
  if (!deliveryInfo) {
    return <span />;
  }

  return (
    <React.Fragment>
      <div className={styles.header}>
        <div>{deliveryInfo.delivered ? 'Levert' : 'Ikke levert'}</div>
        {deliveryInfo.image ? (
          <div>
            <Link onClick={() => setOpen(true)}>Se bilde av levering</Link>
          </div>
        ) : (
          ''
        )}
      </div>
      <Modal open={open}>
        <div className={styles.imageWrapper}>
          <img
            src={'data:image/jpg;base64,' + deliveryInfo.image}
            alt="Levering"
          />
        </div>
        <Separator />
        <Actions>
          <Button color="gray" onClick={() => setOpen(false)}>
            Lukk
          </Button>
        </Actions>
      </Modal>
    </React.Fragment>
  );
};

export default Footer;
