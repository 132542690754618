import moment from 'moment';
import firstSubDate from '../../utils/firstSubDate';
import shouldFetch from '../../selectors/shouldFetch';

const isActive = subscription => {
  return (
    !subscription.endDate ||
    moment(subscription.endDate).isSameOrAfter(firstSubDate(), 'day')
  );
};

export const selectCustomerSubscription = (state, customerId) => {
  const meta = state.subscription.meta[`customerId-${customerId}`];
  if (!meta || !meta.keys) return null;

  return meta.keys.reduce((returnSubscription, metaKey) => {
    const subscription = state.subscription.entities[metaKey];

    if (!returnSubscription) return subscription;
    if (
      !moment(subscription.startDate).isSameOrBefore(firstSubDate(), 'day') &&
      isActive(returnSubscription)
    )
      return returnSubscription;
    if (isActive(subscription)) return subscription;
    if (isActive(returnSubscription)) return returnSubscription;

    return moment(subscription.endDate).isAfter(
      moment(returnSubscription.endDate)
    )
      ? subscription
      : returnSubscription;
  }, null);
};

export const selectCustomerSubscriptions = (state, customerId) => {
  return state.subscription.subscriptions;
};

export const selectCustomerSubscriptionMeta = (state, customerId) => {
  const meta = state.subscription.meta[`customerId-${customerId}`];
  if (!meta) {
    return {
      loading: true,
      saving: false,
      success: false,
      error: false,
      loadedAt: null,
      id: null,
    };
  }

  return meta;
};

export const selectCompanyAgreementCustomerSubscriptions = (
  state,
  customers
) => {
  let subscriptions = {};
  customers.forEach(
    c => (subscriptions[c.id] = selectCustomerSubscription(state, c.id))
  );
  return subscriptions;
};

export const selectCompanyAgreementCustomerSubscriptionsMeta = (
  state,
  companyAgreementId
) => {
  const meta =
    state.subscription.meta[`companyAgreementId-${companyAgreementId}`];
  if (!meta) {
    return {
      loading: true,
      saving: false,
      success: false,
      error: false,
      loadedAt: null,
      id: null,
    };
  }

  return meta;
};

export const selectCustomerSubscriptionStatusMeta = (
  state
) => {
  const meta = state.subscription.meta.status;

  if (!meta) {
    return {
      loading: true,
      saving: false,
      error: false,
      loadedAt: null,
    };
  }

  return meta;
};

export const selectCustomerSubscriptionsStatus = (
  state
) => state.subscription.status;

export const shouldFetchForCustomer = (state, customerId) =>
  shouldFetch(state.subscription.meta[`customerId-${customerId}`]);
