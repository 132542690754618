import types from './types';
import moment from 'moment';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_HASHTAG_FEED_START:
      return {
        ...state,
        [action.payload.key]: {
          loading: true,
          error: false,
          loadedAt: null,
          feed: [],
        },
      };
    case types.GET_HASHTAG_FEED_SUCCESS:
      return {
        ...state,
        [action.payload.key]: {
          loading: false,
          error: false,
          loadedAt: moment().unix(),
          feed: action.payload.feed,
        },
      };
    case types.GET_HASHTAG_FEED_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          loading: false,
          error: true,
          loadedAt: moment().unix(),
          feed: [],
        },
      };
    default:
      return state;
  }
};
