import React from 'react';
import cn from 'classnames';
import {
  Elements,
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  StripeProvider,
} from 'react-stripe-elements';

import { Input } from '../Form';
import Spinner from '../Spinner';
import Alert from '../Alert';
import Button from '../Button';

import styles from './creditCardForm.module.css';

import * as Sentry from '@sentry/browser';

class ClientInfo extends React.Component {
  state = {
    number: false,
    cvc: false,
    expiresAt: false,
    loading: false,
    error: false,
  };

  handleChange = field => {
    return e => {
      this.setState({ [field]: e.complete }, this.getToken);
    };
  };

  getToken = () => {
    const { stripe } = this.props;

    if (!this.state.number || !this.state.cvc || !this.state.expiresAt) return;

    this.setState({ loading: true, error: false });
    this.props
      .getToken()
      .then(response => {
        if (response.error) {
          Sentry.captureException(`Getting token error: ${response.error}`);
          return this.setState({
            loading: false,
            error: 'Noe skjedde. Vennligst prøv igjen.',
          });
        }

        stripe
          .handleCardSetup(
            response.client_secret,
            this.state.cardNumberElement,
            {}
          )
          .then(({ setupIntent, error }) => {
            if (error) {
              Sentry.captureException(`Card setup error: ${error.message}`);
              throw Error(JSON.stringify(error));
            }

            if (setupIntent && setupIntent.status === 'succeeded') {
              this.setState({ loading: false, error: false }, () =>
                this.props.onSetupCompleted(setupIntent)
              );
            }
          });
      })
      .catch(err => {
        Sentry.captureException(`General Error: ${err}`);
        this.setState({
          loading: false,
          error: 'Noe skjedde. Vennligst prøv igjen.',
        });
      });
  };

  render() {
    return (
      <div className={styles.creditCardForm}>
        <div className={styles.cardBlock}>
          <div className={styles.cardHeader}>
            <div>
              <strong>Sikker kortbetaling</strong>
            </div>
            <div className={styles.lock}>
              <div className="fa fa-lock" />
            </div>
          </div>
          <p>
            Betaling gjennomføres hver Søndag. Visa eller MasterCard går fint.
          </p>
          {this.props.companyAgreement &&
            this.props.companyAgreement.employeeDiscount > 0 && (
              <p>
                <strong>
                  Bedriften din betaler{' '}
                  {this.props.companyAgreement.employeeDiscount}
                  {this.props.companyAgreement.discountType === 'percent'
                    ? '%'
                    : 'kr'}{' '}
                  av lunsjen din
                </strong>
              </p>
            )}
          <div className={styles.cardNumber}>
            Kortnummer
            <CardNumberElement
              className={cn(Input.className, styles.input)}
              onReady={el => this.setState({ cardNumberElement: el })}
              onChange={this.handleChange('number')}
            />
          </div>
          <div className={styles.cardOtherInfo}>
            <div>
              <div>Utløpsdato</div>
              <CardExpiryElement
                className={cn(Input.className, styles.input)}
                onReady={el => this.setState({ cardExpiryElement: el })}
                onChange={this.handleChange('expiresAt')}
              />
            </div>
            <div>
              CVC2
              <CardCVCElement
                className={cn(Input.className, styles.input)}
                onReady={el => this.setState({ cardCvcElement: el })}
                onChange={this.handleChange('cvc')}
              />
            </div>
          </div>
          {this.state.loading && (
            <div className={styles.spinner}>
              <Spinner black />
            </div>
          )}
          {this.state.error && (
            <div className={styles.error}>
              <Alert color="red">error</Alert>
            </div>
          )}
          {!this.props.disabled && (
            <Button
              className={styles.spinner}
              onClick={this.props.onSave}
              saving={this.props.saving}
              success={this.props.success}
            >
              Lagre kort
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const Enhenced = injectStripe(ClientInfo);

export default props => (
  <StripeProvider apiKey={process.env.REACT_APP_Stripe_PublicKey}>
    <Elements>
      <Enhenced {...props} />
    </Elements>
  </StripeProvider>
);
