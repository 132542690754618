import store from 'store';

class AuthHelper {
  static logout() {
    // Clear access token and ID token from local storage
    store.remove('access_token');
    store.remove('id_token');
    store.remove('expires_at');
    store.set('toggle_company', false);
  }
}

export default AuthHelper;
