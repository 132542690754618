import selectMeta from '../../selectors/selectMeta';

export const selectAddresses = (state, search) => {
  const meta = selectAddressesMeta(state, search);
  if (!meta || !meta.entities) return [];

  return meta.entities;
};

export const selectAddressesMeta = (state, search) => {
  return selectMeta(state.address[search]);
};
