import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectCustomer } from '../../../stores/customer/selectors';
import Card from '../../SettingCard';
import Button from '../../Button';

const SubscriptionCard = props => {
  let content = 'Velg dager';

  if (props.subscription && props.subscription.type === 'daily') {
    const count = props.subscription.data.dailyEntries.filter(de =>
      de.data.find(d => d.menuAmount > 0)
    ).length;
    content = (
      <div>
        <span>{`${count} ${count === 1 ? 'dag' : 'dager'}`}</span>
      </div>
    );
  } else if (
    props.subscription &&
    props.subscription.days &&
    props.subscription.days.length > 0 &&
    props.subscription.data.entries.length > 0
  ) {
    content = props.subscription.data.entries
      .filter(subscription => subscription.menuAmount > 0)
      .map(subscription => {
        const type = props.subscriptionTypes.find(
          type => type.alias === subscription.type
        );
        if (type) {
          return (
            <div key={type.id}>
              {type.name} / {props.subscription.days.length}{' '}
              {props.subscription.days.length > 1 ? 'dager' : 'dag'}
            </div>
          );
        }
        return '';
      });
  }
  return (
    <Card
      header="Fast levering"
      linkText="Endre"
      to={props.button ? null : props.location.pathname + '/subscription'}
    >
      {content}
      {props.button ? (
        <div className="mt-5">
          <Button
            to={props.location.pathname + '/subscription'}
            color={props.subscription ? 'gray' : 'main'}
          >
            Endre
          </Button>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};

export default withRouter(
  connect((state, ownProps) => {
    return {
      customer: selectCustomer(state, ownProps.customerId),
    };
  })(SubscriptionCard)
);
