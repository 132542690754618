import React from 'react';
import { Provider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/nb';
import queryString from 'query-string';
import store from 'store';
import history from '../services/history';

import LoginCallback from './routes/Login/Callback';
import Login from './routes/Login';
import Logout from './routes/Logout';
import LogoutCallback from './routes/LogoutCallback';
import RoutesWithMenu from './RoutesWithMenu';
import ScrollToTop from '../components/ScrollToTop';
import SentryErrorBoundry from '../components/SentryErrorBoundry';

export default class App extends React.Component {
  componentDidMount() {
    const query = queryString.parse(location.search); //eslint-disable-line
    if (query.access_token && query.id_token && query.expires_at) {
      store.set('access_token', query.access_token);
      store.set('id_token', query.id_token);
      store.set('expires_at', query.expires_at);
    }

    moment.locale('nb');
  }

  render() {
    return (
      <SentryErrorBoundry>
        <Provider store={this.props.store}>
          <Router history={history}>
            <ScrollToTop>
              <Switch>
                <Route path="/login/callback" component={LoginCallback} />
                <Route path="/login" component={Login} />
                <Route exact path="/logout" component={Logout} />
                <Route
                  exact
                  path="/logout/callback"
                  component={LogoutCallback}
                />
                <Route component={RoutesWithMenu} />
              </Switch>
            </ScrollToTop>
          </Router>
        </Provider>
      </SentryErrorBoundry>
    );
  }
}
