import React from 'react';
import cn from 'classnames';

import styles from './day.module.css';

export default ({ locked, children, header, footer }) => {
  return (
    <div className={cn(styles.day, { [styles.locked]: locked })}>
      {header}
      {children !== null ? (
        <div className={styles.content}>{children}</div>
      ) : (
        ''
      )}
      {footer}
    </div>
  );
};
