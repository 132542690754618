import special4ServiceTypeAliases from '../constants/special4ServiceTypeAliases';

const standardSupported = [
  'Salad',
  'Vegetar',
  'Vegan',
];
const allergySupported = [...standardSupported, ...special4ServiceTypeAliases];

export default (allergies, type) => {
  if (!type) return false;

  if (allergies && (allergies === true || allergies.length))
    return (
      !type.isExtra &&
      (allergySupported.includes(type.alias) ||
        allergySupported.includes(type.type))
    );
  else return true;
};
