import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import CompanyPageHeader from '../../components/CompanyPageHeader';

import Setup from './routes/Setup/index';
import Employees from './routes/Employees';
import DailyReport from './routes/DailyReport';
import { DeliveryAddress } from '../../../../../components/CompanySettings/DeliveryAddress';
import { CompanySubscription } from '../../../../../components/CompanySettings/CompanySubscription';
import {
  CompanyAgreementInvoiceList,
  CompanyAgreementInvoice,
} from '../../../../../components/CompanySettings/CompanyAgreementInvoice';

import { selectedCompanyAgreement } from '../../../../../stores/me/selectors';

class Admin extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Bedrift - Lunsjkollektivet</title>
        </Helmet>
        <Switch>
          <Route
            exact
            path={this.props.match.url + '/oppsett'}
            render={() => {
              return (
                <div>
                  <CompanyPageHeader />
                  <Setup />
                </div>
              );
            }}
          />
          <Route
            exact
            path={this.props.match.url + '/subscription'}
            render={cprops => {
              return (
                <div>
                  <CompanyPageHeader showTabs />
                  <CompanySubscription
                    {...cprops}
                    backTo={this.props.match.url}
                    companyAgreementId={this.props.companyAgreementId}
                  />
                </div>
              );
            }}
          />
          <Route
            exact
            path={this.props.match.url + '/leveringsadresse'}
            render={cprops => {
              return (
                <div>
                  <CompanyPageHeader />
                  <DeliveryAddress
                    {...cprops}
                    backTo={'https://company.lunsjkollektivet.no/settings'}
                    companyAgreementId={this.props.companyAgreementId}
                  />
                </div>
              );
            }}
          />
          <Route
            exact
            path={this.props.match.url + '/faktura'}
            render={cprops => {
              return (
                <div>
                  <CompanyPageHeader />
                  <CompanyAgreementInvoiceList
                    {...cprops}
                    backTo={'https://company.lunsjkollektivet.no/settings'}
                    companyAgreementId={this.props.companyAgreementId}
                  />
                </div>
              );
            }}
          />
          <Route
            exact
            path={this.props.match.url + '/faktura/:invoiceId'}
            render={cprops => {
              return (
                <div>
                  <CompanyPageHeader />
                  <CompanyAgreementInvoice
                    {...cprops}
                    backTo={'https://company.lunsjkollektivet.no/settings'}
                    companyAgreementId={this.props.companyAgreementId}
                  />
                </div>
              );
            }}
          />
          <Route
            exact
            path={this.props.match.url + '/reports/daily'}
            render={() => {
              return (
                <div>
                  <CompanyPageHeader />
                  <DailyReport />
                </div>
              );
            }}
          />
          <Route
            exact
            path={this.props.match.url + '/ansatte'}
            render={() => {
              return (
                <div>
                  <CompanyPageHeader />
                  <Employees />
                </div>
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}

export default connect(state => ({
  companyAgreementId: selectedCompanyAgreement(state).id,
}))(Admin);
