import { getById } from '../deliveryLocation/selectors';

export const selectCompanyAgreement = (state, companyAgreementId) => {
  let ca = state.companyAgreement.entities[companyAgreementId];

  if (ca && ca.deliveryLocation) {
    const dl = getById(state, ca.deliveryLocation.id);

    if (dl) {
      ca.deliveryLocation = {
        ...dl,
      };
    }
  }
  return {
    ...ca,
  };
};
