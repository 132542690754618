import React from 'react';

import styles from './checkPostalCode.module.css';

import Spinner from '../../../../components/Spinner';
import Button from '../../../../components/Button';

import fetch from '../../../../utils/fetch';

class CheckPostalCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postalCode: '',
    };
  }

  validPostalCode(postalCode) {
    const re = /^[0-9]{4}$/;
    return re.test(postalCode);
  }

  checkPostalCode = () => {
    this.setState({ loading: true });
    fetch('/postal-codes/' + this.state.postalCode)
      .then(response => {
        if (response && response.data.active) {
          this.props.onNext();
        } else {
          this.setState({ error: true, loading: false });
        }
      })
      .catch(() => {
        this.setState({ error: true, loading: false });
      });
  };

  render() {
    return (
      <div>
        <h2>{this.props.title}</h2>
        {this.props.content}
        <div className={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Sjekk postnummer"
            className={styles.input}
            value={this.state.postalCode}
            onChange={e => this.setState({ postalCode: e.target.value })}
            onKeyPress={event =>
              event.key === 'Enter' &&
                !this.state.loading &&
                this.validPostalCode(this.state.postalCode)
                ? this.checkPostalCode()
                : null
            }
          />
          <Button
            className={styles.nextButton}
            onClick={this.checkPostalCode}
            disabled={
              this.state.loading || !this.validPostalCode(this.state.postalCode)
            }
          >
            {this.state.loading ? (
              <div className={styles.spinner}>
                <Spinner />
              </div>
            ) : (
              <div className="fa fa-arrow-right" />
            )}
          </Button>
        </div>
        {this.state.error ? (
          <div className={styles.error}>
            Oi! Det ser ut som vi ikke leverer til dette postnummer. Ta gjerne
            kontakt med oss på hei@lunsjkollektivet.no så kanskje vi kan finne
            en løsning.
          </div>
        ) : null}
        <p>
          <strong>Vi leverer i store deler av Trondheim, Bergen, Oslo og Stavanger</strong>
        </p>
      </div>
    );
  }
}

export default CheckPostalCode;
