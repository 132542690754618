import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { CompanyMeetingFoodOrder } from '../../../../../../components/CompanySettings/CompanyMeetingFoodOrder';

import { selectedCompanyAgreement } from '../../../../../../stores/me/selectors';
import Button from '../../../../../../components/Button';
import CompanyBackground from '../../../components/CompanyBackground';

class Lunch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      week: moment(),
    };
  }

  componentWillUnmount() {
    if (this.state.modified) {
      this.props.choices.actions.invalidate();
    }
  }

  render() {
    return (
      <CompanyBackground title="Møtemat"
        info={<div><p>
          Lunsjkollektivet leverer gjerne mat til møtet ditt, enten det
          er medarbeidersamtale med deg og 1 ansatt eller
          planleggingsdag med 300 ansatte.
        </p><strong>
            Alle fortjener god mat - også de du har invitert på møte!
        </strong>
        </div>}>
        <CompanyMeetingFoodOrder
          companyAgreementId={this.props.companyAgreementId}
          to="https://company.lunsjkollektivet.no"
        />
        <div className="text-center mt-5">
          <Button size="medium" to="https://company.lunsjkollektivet.no">
            Ny bestilling
                </Button>
        </div>
      </CompanyBackground>
    );
  }
}

export default connect(state => ({
  companyAgreementId: selectedCompanyAgreement(state).id,
}))(Lunch);
