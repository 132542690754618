import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import { withRouter } from 'react-router-dom';
import { logout } from '../../stores/me/actions';
import auth0 from '../../utils/auth0';

import styles from './Login/login.module.css';

class Logout extends React.Component {
  componentDidMount() {
    const returnUrl = `${process.env.REACT_APP_Auth0_RedirectUri_Logout}`;
    auth0.logout({
      returnTo: returnUrl,
    });
  }

  render() {
    return (
      <div className={styles.overlay}>
        <div className={styles.login}>
          <h1>Logger ut..</h1>
          <Loader />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(() => ({}), { logout })(Logout));
