import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Loader from './Loader';
import { validateAuthentication } from '../stores/me/actions';
import hasConsent from '../selectors/me/hasConsent';
import ConsentPopup from './ConsentPopup';
import NotificationConsents from './NotificationConsents';
import vilkarAndPolicyAlias from '../utils/vilkarAndPolicyAlias';

/**
 * Notification Definition Store functions
 */
import { selectAll } from '../stores/notificationDefinition/selectors';
import { getAllIfNeeded as getAllNotificationDefinition } from '../stores/notificationDefinition/actions';

class PrivateRoute extends React.Component {
  componentDidMount() {
    this.props.validateAuthentication();
    this.props.getAllNotificationDefinition();
  }

  componentDidUpdate() {
    this.props.validateAuthentication();
  }

  render() {
    const {
      component: Component,
      isAuthenticated,
      loading,
      ...rest
    } = this.props;

    if (loading) {
      return (
        <div style={{ height: '70px', marginTop: '30px' }}>
          <Loader />
        </div>
      );
    }

    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: this.props.location },
          }}
        />
      );
    }

    const showConsentPopup =
      this.props.isAuthenticated && !this.props.hasAcceptedPolicyAndVilkar;

    const notificationConsentsToShow = this.props.notificationDefinitions.filter(
      nd =>
        this.props.notificationSettings.findIndex(
          ns => ns.notificationDefinitionAlias === nd.alias
        ) === -1
    );

    return (
      <Route
        {...rest}
        render={props => (
          <div>
            <Component {...props} />
            {showConsentPopup ? (
              <ConsentPopup vilkarAndPolicyAlias={vilkarAndPolicyAlias} />
            ) : notificationConsentsToShow.length > 0 ? (
              <NotificationConsents
                notificationConsentsToShow={notificationConsentsToShow}
              />
            ) : (
                  ''
                )}
          </div>
        )}
      />
    );
  }
}

export default connect(
  state => ({
    loading: state.me.loading,
    isAuthenticated: state.me.isAuthenticated,
    notificationSettings:
      state.me.identity && state.me.identity.notificationSettings
        ? state.me.identity.notificationSettings
        : [],
    notificationDefinitions: selectAll(state),
    hasAcceptedPolicyAndVilkar: hasConsent(state, vilkarAndPolicyAlias),
  }),
  {
    validateAuthentication,
    getAllNotificationDefinition,
  }
)(PrivateRoute);
