export default () => {
  if (process.env.NODE_ENV === 'production') {
    try {
      const version = require('../version').default;

      window.dataLayer.push({
        event: 'GAEvent',
        eventCategory: 'Page',
        eventAction: 'Loaded',
        eventLabel: version.version,
      });
    } catch (e) {
      console.error(e);
    }
  }
};
