import selectMeta from '../../selectors/selectMeta';

export const selectSearchKey = (offset, limit, filters) =>
  `${offset}_${limit}_${selectTotalKey(filters)}`;

export const selectTotalKey = filters => JSON.stringify(filters);

export const selectSearchUsersMeta = (state, offset, limit, filters) => {
  const key = selectSearchKey(offset, limit, filters);
  return selectMeta(state.user.meta[key]);
};

export const selectSearchUsersCount = (state, filters) => {
  const key = selectTotalKey(filters);
  return state.user.counts[key] !== undefined ? state.user.counts[key] : 0;
};

export const selectSearchUsers = (state, offset, limit, filters) => {
  const meta = selectSearchUsersMeta(state, offset, limit, filters);

  return meta.ids.map(id => state.user.entities[id]);
};

export const selectUsers = (state, search) => {
  const meta = state.user.meta[search];
  if (!meta) return [];

  return meta.ids.map(id => state.user.entities[id]);
};

export const selectUser = (state, id) => state.user.entities[id] || null;

export const selectUserAllergies = (state, id) => {
  const user = selectUser(state, id);

  if (user) {
    return user.allergies;
  }

  return [];
};

export const selectUserMenu = (state, id) => {
  const user = selectUser(state, id);

  if (user) {
    return user.type;
  }

  return [];
};
