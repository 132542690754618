import * as actions from '../actions/data.jsx';
import * as errors from '../constants/errors.jsx';

const initialState = {
  allergies: [],
  isSavingAllergies: false,
  isLoadingAllergies: false,
};

const allergiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ALLERGIES:
      return Object.assign({}, state, {
        allergies: action.payload,
        isLoadingAllergies: false,
      });
    case actions.GET_ALLERGIES_ERROR:
      return Object.assign({}, state, {
        error: errors.UNKNOWN_ERROR,
        isLoadingAllergies: false,
      });
    case actions.GET_ALLERGIES_LOADING:
      return Object.assign({}, state, { isLoadingAllergies: true });
    case actions.SAVE_ALLERGIES_LOADING:
      return Object.assign({}, state, { isSavingAllergies: true });
    case actions.SAVE_ALLERGIES:
      let allergies = [...state.allergies, action.payload, 'id'];
      return Object.assign({}, state, {
        allergies: allergies,
        savedAllergies: action.payload,
        isSavingAllergies: false,
      });
    case actions.SAVE_ALLERGIES_ERROR:
      return Object.assign({}, state, {
        error: errors.UNKNOWN_ERROR,
        isSavingAllergies: false,
      });
    default:
      return state;
  }
};

export default allergiesReducer;
