import React from 'react';
import cn from 'classnames';

import styles from './input.module.css';

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      focused: false,
    };
  }
  componentDidMount() {
    this.props.onChange(
      this.props.value,
      this.props.validate(this.props.value)
    );
  }
  onBlur() {
    this.setState({ focused: false });

    if (!this.props.validate) return;

    this.setState({ error: !this.props.validate(this.props.value) });
  }
  render() {
    return (
      <div
        className={cn(this.props.className, {
          [styles.inputWrapper]: this.props.withPadding,
          [styles.inputNotFocusedWithValue]:
            this.props.value && !this.state.focused && !this.state.error,
        })}
      >
        <div className={cn({ [styles.errored]: this.state.error })}>
          {this.props.label}
        </div>
        <div>
          {this.props.textarea ? (
            <textarea
              className={cn(styles.textarea, {
                [styles.input]: !this.state.error,
                [styles.errorInput]: this.state.error,
                [styles.backgroundGray]: this.props.backgroundGray,
              })}
              onChange={e =>
                this.props.onChange(
                  e.target.value,
                  this.props.validate(e.target.value)
                )
              }
              value={this.props.value}
              onBlur={this.onBlur.bind(this)}
              placeholder={this.props.placeholder}
              onFocus={this.props.onFocus}
              onClick={this.props.onClick}
              rows={this.props.rows}
              onKeyDown={this.props.onKeyDown}
            />
          ) : (
            <input
              className={cn({
                [styles.input]: !this.state.error,
                [styles.errorInput]: this.state.error,
                [styles.backgroundGray]: this.props.backgroundGray,
              })}
              type={this.props.password ? 'password' : this.props.type}
              onChange={e =>
                this.props.onChange(
                  e.target.value,
                  this.props.validate(e.target.value)
                )
              }
              value={this.props.value}
              onBlur={this.onBlur.bind(this)}
              placeholder={this.state.focused ? '' : this.props.placeholder}
              onFocus={e => {
                if (this.props.onFocus) {
                  this.props.onFocus(e);
                }
                this.setState({ focused: true });
              }}
              onClick={this.props.onClick}
              onKeyDown={this.props.onKeyDown}
              autoFocus={this.props.autoFocus}
            />
          )}
        </div>
        {this.state.error && this.props.errorMessage ? (
          <div className={styles.errored}>{this.props.errorMessage}</div>
        ) : (
          ''
        )}
        {this.props.helpingText ? <div>{this.props.helpingText}</div>:null}
      </div>
    );
  }
}

Input.className = styles.input;

Input.defaultProps = {
  validate: () => true,
  type: 'text',
  withPadding: true,
};

export default Input;
