import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SettingCard from '../../SettingCard';
import Card from '../../Card';
import Button from '../../Button';
import { selectUserAllergies } from '../../../stores/user/selectors';

const AllergyCard = props => {
  let content = 'Ingen Allergier';
  if (props.allergies && props.allergies.length > 0) {
    content = props.allergies.map(allergy => allergy.name).join(', ');
  }

  if (props.button) {
    return (
      <Card header="Allergier">
        <div>{content}</div>
        <div className="mt-5">
          <Button to={props.location.pathname + '/allergier'} color="gray">
            Endre
          </Button>
        </div>
      </Card>
    );
  }

  return (
    <SettingCard
      header="Allergier"
      to={props.location.pathname + '/allergier'}
      linkText="Velg"
    >
      {content}
    </SettingCard>
  );
};

const mapStateToProps = (state, ownProps) => ({
  allergies: selectUserAllergies(state, ownProps.userId),
});

export default withRouter(connect(mapStateToProps)(AllergyCard));
