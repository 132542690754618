import React from 'react';
import cn from 'classnames';

import styles from './button.module.css';

const Button = ({ children, onClick, size }) => (
  <div
    className={cn(styles.closeButton)}
    onClick={onClick}
    style={{
      height: size,
      width: size,
    }}
  >
    <div className="fa fa-close" />
  </div>
);

Button.defaultProps = {
  size: 21,
};

export default Button;
