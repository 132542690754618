import React from 'react';

import styles from './optionBox.module.css';

export default ({ checked, label, onClick, children }) => (
  <div className={styles.checkboxWrapper} onClick={onClick}>
    <div className={styles.optionBox}>
      {checked ? <div className={styles.blue} /> : ''}
    </div>
    {label || children ? (
      <div className={styles.label}>{children || label}</div>
    ) : (
      ''
    )}
  </div>
);
