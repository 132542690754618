import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import Settings from './routes/Settings';
import Orders from './routes/Orders';

import agreementsWhereUserIsAdmin from '../../../selectors/me/agreementsWhereUserIsAdmin';

import Header from '../../../components/Header';

class Admin extends React.Component {
  render() {
    return (
      <div>
        <Header />
        {this.props.agreementsWhereUserIsAdmin.length > 0 ? (
          <Switch>
            <Route
              path={this.props.match.url + '/innstillinger'}
              component={Settings}
            />
            <Route path={this.props.match.url} component={Orders} />
          </Switch>
        ) : null}
      </div>
    );
  }
}

export default connect(state => ({
  name: state.me.identity.name,
  agreementsWhereUserIsAdmin: agreementsWhereUserIsAdmin(state),
}))(Admin);
