import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const projectId = 'y1jlh1bd';
export const dataset = 'production';

const client = sanityClient({
  projectId,
  dataset,
  useCdn: true,
});

const builder = imageUrlBuilder(client);

export const buildImageUrl = (data, width) =>
  builder
    .image(data)
    .width(width)
    .url();

export default client;
