import * as actions from '../actions/data.jsx';
import * as errors from '../constants/errors.jsx';

const initialState = {
  isLoadingHistory: false,
  history: [],
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        history: action.payload,
        isLoadingHistory: false,
      });
    case actions.GET_HISTORY_LOADING:
      return Object.assign({}, state, { isLoadingHistory: true });
    case actions.GET_HISTORY_ERROR:
      return Object.assign({}, state, {
        error: errors.UNKNOWN_ERROR,
        isLoadingHistory: false,
      });
    default:
      return state;
  }
};

export default historyReducer;
