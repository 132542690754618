import addPrefix from '../../utils/addPrefix';

export default addPrefix('companyAgreement', [
  'GET_START',
  'GET_SUCCESS',
  'GET_ERROR',
  'GET_CUSTOMERS_START',
  'GET_CUSTOMERS_SUCCESS',
  'GET_CUSTOMERS_ERROR',
  'UPDATE_COMPANY_AGREEMENT',
  'UPDATE_COMPANY_AGREEMENT_DELIVERY_ADDRESS_SUCCESS',
  'GET_SUBSCRIPTION_CUSTOMERS_START',
  'GET_SUBSCRIPTION_CUSTOMERS_SUCCESS',
  'GET_SUBSCRIPTION_CUSTOMERS_ERROR',
  'GET_COMPANY_AGREEMENT_NOTES',
  'UPDATE_COMPANY_AGREEMENT_NOTES'
]);
