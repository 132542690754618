import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';
import { selectRangeMetaKey } from './selectors';

import types from './types';

export const getForCompanyAgreementIfNeeded = companyAgreementId => (
  dispatch,
  getState
) =>
  getAllIfNeeded(
    `company-agreement-${companyAgreementId}`,
    `/company-agreements/${companyAgreementId}/credits`
  )(dispatch, getState);

export const getAllForUserIfNeeded = userId => (dispatch, getState) =>
  getAllIfNeeded(`user-${userId}`, `/users/${userId}/credits`)(
    dispatch,
    getState
  );

export const getAllBetweenDates = (start, end) => (dispatch, getState) =>
  getAllIfNeeded(selectRangeMetaKey(start, end), `/credits`, {
    start: start
      .clone()
      .startOf('day')
      .format('YYYY-MM-DD'),
    end: end
      .clone()
      .endOf('day')
      .format('YYYY-MM-DD'),
  })(dispatch, getState);

const getAllIfNeeded = (key, url, params = {}) => (dispatch, getState) => {
  if (!shouldFetch(getState().credit.meta[key])) return;

  dispatch({
    type: types.GET_ALL_START,
    payload: { key },
  });

  return fetch(url, 'GET', params)
    .then(response => {
      dispatch({
        type: types.GET_ALL_SUCCESS,
        payload: {
          key,
          credits: Array.isArray(response.data) ? response.data : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ALL_ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};

export const createCreditForCompanyAgreement = (
  companyAgreementId,
  data
) => dispatch =>
  fetch(`/company-agreements/${companyAgreementId}/credits`, 'POST', data).then(
    response => {
      dispatch({
        type: types.CREATE_SUCCESS,
        payload: {
          key: `company-agreement-${companyAgreementId}`,
          credit: response.data,
        },
      });
    }
  );

export const createCreditForUser = (userId, data) => dispatch =>
  fetch(`/users/${userId}/credits`, 'POST', data).then(response => {
    dispatch({
      type: types.CREATE_SUCCESS,
      payload: {
        key: `user-${userId}`,
        credit: response.data,
      },
    });
  });
