import React from 'react';
import { connect } from 'react-redux';
import Container from '../../../../components/Container';
import { Checkbox, CheckboxGroup } from '../../../../components/Form';
import Button from '../../../../components/Button';
import { Actions } from '../../../../components/Modal';
import Card, { Separator } from '../../../../components/Card';
import { BackButton } from '../../../../components/Button';
import Loader from '../../../../components/Loader';

/**
 * Notification Definition Store functions
 */
import {
  selectAll,
  selectAllMeta,
} from '../../../../stores/notificationDefinition/selectors';
import { updateMyNotificationSettings } from '../../../../stores/me/actions';
import { updateMeta } from '../../../../stores/user/actions';
import { selectUser } from '../../../../stores/user/selectors';

class Email extends React.Component {
  state = {
    consents: [],
  };

  save() {
    this.setState({ saving: true, error: false, success: false });

    this.props
      .updateMyNotificationSettings(
        this.props.notificationDefinitions.map(ncts => ({
          notificationDefinitionAlias: ncts.alias,
          textShownToUser: ncts.description,
          emailAllowed: this.getCheckStatus(ncts.alias),
        }))
      )
      .then(() => {
        if (
          this.state.disableWeeklyOrderConfirmation !==
            this.props.userMeta.disableWeeklyOrderConfirmation ||
          this.state.disableDeliverySms !==
            this.props.userMeta.disableDeliverySms
        ) {
          this.props
            .updateMeta(this.props.user.id, {
              disableWeeklyOrderConfirmation: this.state
                .disableWeeklyOrderConfirmation,
              disableDeliverySms: this.state.disableDeliverySms,
            })
            .then(() => {
              this.setState({ saving: false, success: true });
              setTimeout(() => this.setState({ success: false }), 2000);
            })
            .catch(() => {
              this.setState({ saving: false, error: true });
            });
        } else {
          this.setState({ saving: false, success: true });
          setTimeout(() => this.setState({ success: false }), 2000);
        }
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    this.update(prevProps);
  }

  update(prevProps) {
    if (this.props.notificationSettings.length === 0) return;

    if (
      !prevProps ||
      prevProps.notificationSettings !== this.props.notificationSettings ||
      prevProps.userMeta.disableWeeklyOrderConfirmation !==
        this.props.userMeta.disableWeeklyOrderConfirmation ||
      prevProps.userMeta.disableDeliverySms !==
        this.props.userMeta.disableDeliverySms
    ) {
      this.setState(
        this.props.notificationSettings.reduce(
          (state, ns) => {
            state[ns.notificationDefinitionAlias] = ns.emailAllowed;
            return state;
          },
          {
            disableWeeklyOrderConfirmation: this.props.userMeta
              .disableWeeklyOrderConfirmation,
            disableDeliverySms: this.props.userMeta.disableDeliverySms,
          }
        )
      );
    }
  }

  getCheckStatus(alias) {
    if (this.state[alias]) return this.state[alias];
    return false;
  }

  buildContent() {
    if (this.props.meta.loading) {
      return <Loader />;
    }

    return (
      <div>
        <h3>Hvordan kan vi kontakte deg?</h3>
        <CheckboxGroup direction="column">
          {this.props.notificationDefinitions.map(definition => (
            <Checkbox
              key={definition.id}
              checked={this.getCheckStatus(definition.alias)}
              onChange={checked =>
                this.setState({ [definition.alias]: checked })
              }
            >
              {definition.description}
            </Checkbox>
          ))}
        </CheckboxGroup>
        <p>
          Uavhengig av eventuell markedsføringsreservasjon, vil du motta
          ordrebekreftelse og leveringsinformasjon på e-post og/eller SMS.
        </p>
        <CheckboxGroup direction="column">
          <Checkbox
            checked={!this.state.disableWeeklyOrderConfirmation}
            onChange={checked =>
              this.setState({ disableWeeklyOrderConfirmation: !checked })
            }
          >
            Ukentlig e-post med dine bestillinger for følgende uke. Om du har et
            aktivt kundeforhold, men ikke har bestillinger lagt inn vil du få
            oversikt over meny og en påminnelse om bestilling.
          </Checkbox>
        </CheckboxGroup>
        <CheckboxGroup direction="column">
          <Checkbox
            checked={!this.state.disableDeliverySms}
            onChange={checked =>
              this.setState({ disableDeliverySms: !checked })
            }
          >
            SMS når ingen er der for å motta lunsjen din.
          </Checkbox>
        </CheckboxGroup>
        <Separator />
        <Actions>
          <Button
            onClick={() => this.save()}
            saving={this.state.saving}
            success={this.state.success}
          >
            Lagre
          </Button>
        </Actions>
      </div>
    );
  }

  render() {
    return (
      <Container withPadding color="gray">
        <BackButton to="/innstillinger" />
        <Card title="E-Post Innstillinger">{this.buildContent()}</Card>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const identity = selectUser(state, state.me.identity.id);
  return {
    user: identity,
    notificationSettings:
      identity && identity.notificationSettings
        ? identity.notificationSettings
        : [],
    userMeta: identity.meta,
    notificationDefinitions: selectAll(state),
    meta: selectAllMeta(state),
  };
}

export default connect(mapStateToProps, {
  updateMyNotificationSettings,
  updateMeta,
})(Email);
