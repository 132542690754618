import React from 'react';

import Header from '../../components/Header';

require('../../styles/components/nutrition.scss');

export default () => (
  <div id="nutrition" className="section">
    <Header image="/images/homeoffice.jpg" height={600} withoutCard />
    <div className="content container">
      <h1 className="center">Sunt og skikkelig godt?</h1>
      <h4 className="center">Ja takk, begge deler.</h4>
      <p className="center abstract">
        I Lunsjkollektivet er vi like opptatt av at du skal få i deg mat som er
        ordentlig bra for kroppen din som at den er variert og smaker skikkelig
        godt. Er det mulig? Jada.
      </p>
      <p className="center">
        Vår matfilosofi er som følger. Du har godt av litt av alt, men et
        fornuftig, variert kosthold med mye grønnsaker og frukt, fullkorn, sunne
        fettsyrer og gode kilder til protein utgjør hovedgrunnlaget. Vår
        ernæringsfysiolog Hanna går nøye gjennom hver eneste meny. Derfor er du
        alltid garantert en variert, sunn og fornuftig sammensatt lunsj som
        dekker dine behov, uansett om du velger salat eller brødboks.
      </p>
      <p className="center">
        Miljøet er også viktig. Derfor får du fisk minimum en gang i uken,
        moderate mengder rødt kjøtt, og masse deilige retter uten kjøtt. Vi er
        også forsiktige med salt i maten, så får du selv krydre som du vil.
      </p>
      <p className="center">
        Når det er sagt er Lunsjkollektivet langt fra noe helsefantastforetak.
        Vi elsker god mat og kan kunsten å kose oss. Målet er både god smak,
        innhold og helsegevinst, uten at det ene går på bekostning av det andre.
        PS! Hver fredag finner du noe ekstra godt i lunsjboksen. Vel fortjent,
        synes vi. Det er jo fredag.
      </p>
    </div>
    <div className="content container">
      <h2 className="center">Spennende salatlunsjer</h2>
      <p className="center">
        Våre salater består ikke bare av grønfor som ikke metter. Vår salatlunsj
        er et velbalansert måltid med en fornuftig fordeling av næringsstoffer –
        god, mettende og næringsrik. Her får du i tillegg til diverse grønt,
        også gode kilder til både protein, sunne fettsyrer, fiber og fullkorn.
        Salatlunsjen passer perfekt for deg som vil spise deg mett på skikkelig
        sunn og god mat. Bare prøv, vi lover du blir overbevist.
      </p>
      <h2 className="center">Superdeilig brødlunsj</h2>
      <p className="center">
        Brødlunsjen vår passer perfekt for deg som er glad i brød, men som har
        lyst på en variert og langt sprekere utgave av den tradisjonelle
        matpakken. Deilig, hjemmebakt grovbrød med kortreist pålegg, frukt og
        grønt. Spennende smakskombinasjoner av sunn hverdagsmat som dekker
        behovet ditt, og som du kan nyte med god samvittighet hver dag.
      </p>
      <h2 className="center">Kaloriene, de kaloriene</h2>
      <p className="center">
        En lunsjboks fra oss vil alltid inneholde gjennomsnittlig 450-700 kcal,
        med en energifordeling som følger: 40-60% karbohydrat (hvorav ca. 10%
        kostfiber), 30-40% fett og 10-20% protein. Dette er i tråd med
        Helsedirektoratets anbefalinger og utgjør definitivt en bra og
        næringsrik lunsj. Brødlunsjen vår er generelt litt mer energirik enn
        salatlunsjen, og inneholder gjennomsnittlig 650 kcal. Den passer fint
        for deg som er glad i brød til lunsj og ønsker det mest mettende
        alternativet. Salatlunsjen inneholder gjennomsnittlig 500 kcal, og er
        det perfekte valget for deg som vil ha en næringsrik, og mettende, men
        samtidig lett, lunsj. Velger du Variert får du det beste fra to
        verdener.
      </p>
      <h2 className="center">Allergier og intoleranser</h2>
      <p className="center">
        Er det noe du ikke tåler? Vi tilrettelegger selvfølgelig for reelle
        allergier. Du kan enkelt krysse av ulike allergener på Min Side. Utover
        kjente allergener tilpasser vi oss dine behov så godt det lar seg gjøre
        – gi oss en lyd dersom du har en spesifikk forespørsel, så skal vi se
        hva vi får til. Vi mener generelt at litt av alt er best, og vårt mål og
        filosofi er å tilby variert, næringsrik, real og smart mat som passer
        for alle.
      </p>
    </div>
  </div>
);
