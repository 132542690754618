import React from 'react';
import PropTypes from 'prop-types';
import NumberPicker from './NumberPicker';
import styles from './lunchBoxOverview.module.css';
import formatPrice from '../../../../utils/formatPrice';
import Rating from '../../Rating';
import Button from '../../../../components/Button';

const Card = props => (
  <div style={{ width: '100%' }}>
    <div
      className={
        props.extra
          ? props.styling
            ? styles.card_selected_extra
            : styles.card_extra
          : props.styling
          ? styles.card_selected
          : styles.card
      }
    >
      <div
        onClick={props.styling ? null : props.onClick}
        className={styles.image}
        style={{ backgroundImage: `url(${props.imageUrl})` }}
      />
      <div className={styles.content}>
        <strong>
          {props.amount > 0 ? props.amount + 'x' : ''}{' '}
          {props.allergies ? 'Allergitilpasset' : ''} {props.title}
        </strong>{' '}
        / {props.price}
        <div>{props.content}</div>
        <div className={styles.info}>{props.info}</div>{' '}
      </div>

      {props.styling ? (
        <div className={styles.numberPicker}>
          <Button className={styles.button} onClick={props.onDelete}>
            Slett
          </Button>
          <NumberPicker
            amount={props.amount}
            onChange={number => props.onChange(parseInt(number, 10))}
          />
        </div>
      ) : (
        <div className={styles.numberPicker}>
          <Button className={styles.button} onClick={props.onClick}>
            Velg
          </Button>
        </div>
      )}
    </div>
  </div>
);

const menuAllergies = menu => {
  if (!menu || !menu.allergies || menu.allergies.length === 0) {
    return '';
  }

  const allergies = menu.allergies.filter(a => a.fraMattilsynet);

  if (allergies.length === 0) {
    return '';
  }

  return 'Inneholder: ' + allergies.map(a => a.name).join(', ');
};

class LunchBoxOverview extends React.Component {
  maxDescriptionLength = 140;

  render() {
    let description = this.props.menu ? this.props.menu.description : '';

    if (this.props.subscriptionTypes[0].name === 'Hjemmekontorlunsj') {
      description =
        'Salatboks + fersk jus og chiapudding/yoghurt. Salatmeny: ' +
        description;
    } else if (
      this.props.subscriptionTypes[0].name === 'Hjemmekontorlunsj Vegetar'
    ) {
      description =
        'Vegetarboks + fersk jus og chiapudding/yoghurt. Vegetarmeny tar utgangspunkt i dagens meny, med vegetarisk proteinkilde.';
    }

    if (description.length > this.maxDescriptionLength) {
      description = description.substring(0, this.maxDescriptionLength) + '...';
    }

    var mealNutrition = '';
    if (this.props.menu) {
      const strings = [];
      if (
        this.props.menu.nutrition.kcal &&
        this.props.menu.nutrition.kcal !== ''
      ) {
        strings.push(`${this.props.menu.nutrition.kcal} kcal`);
      }

      if (
        this.props.menu.nutrition.carbohydrate &&
        this.props.menu.nutrition.carbohydrate !== ''
      ) {
        strings.push(`${this.props.menu.nutrition.carbohydrate}g karbohydrat`);
      }

      if (
        this.props.menu.nutrition.fat &&
        this.props.menu.nutrition.fat !== ''
      ) {
        strings.push(`${this.props.menu.nutrition.fat}g fett`);
      }

      if (
        this.props.menu.nutrition.protein &&
        this.props.menu.nutrition.protein !== ''
      ) {
        strings.push(`${this.props.menu.nutrition.protein}g protein`);
      }
      mealNutrition = strings.join(', ');
    }
    return (
      <div className={styles.lunchBoxOverviewContainer}>
        {!this.props.disabled ? (
          <Card
            allergies={this.props.allergies.length > 0}
            onClick={() => {
              this.props.onChange(this.props.amount === 0 ? 1 : 0);
            }}
            onDelete={() => {
              this.props.onChange(0);
            }}
            amount={this.props.amount}
            onChange={number => this.props.onChange(parseInt(number, 10))}
            imageUrl={`${
              this.props.subscriptionTypes[1] &&
              this.props.subscriptionTypes[1].alias !== 'withoutMeet'
                ? this.props.subscriptionTypes[1].imagePath
                : this.props.subscriptionTypes[0].imagePath
            }`}
            title={
              this.props.subscriptionTypes[1] &&
              this.props.subscriptionTypes[1].alias !== 'withoutMeet'
                ? this.props.subscriptionTypes[1].name
                : this.props.subscriptionTypes[0].name
            }
            styling={this.props.amount !== 0 ? 1 : 0}
            content={description + (this.props.rating ? this.props.rating : '')}
            info={
              this.props.allergies.length > 0
                ? 'Inneholder ' + this.props.allergies.map(a => a.name) + '.'
                : menuAllergies(this.props.menu) + ' ' + mealNutrition
            }
            price={formatPrice(this.props.price)}
            extra={this.props.extra}
          />
        ) : null}
        {this.props.disabled &&
        this.props.enableRating &&
        !this.props.rating &&
        !this.props.savingRating ? (
          <div>
            <Rating submitRating={this.props.onSubmitRating} />
          </div>
        ) : null}
      </div>
    );
  }
}

LunchBoxOverview.defaultProps = {
  allergies: [],
};

LunchBoxOverview.propTypes = {
  subscriptionTypes: PropTypes.array.isRequired,
  allergies: PropTypes.array,
  menu: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
};

export default LunchBoxOverview;
