import React from 'react';

import Button from '../../../../components/Button';
import { Input3, CheckboxGroup, Checkbox } from '../../../../components/Form';
import EmailSettingPicker from '../../../../components/EmailSettingPicker';
import validateEmail from '../../../../utils/validateEmail';

class ClientInfo extends React.Component {
  state = {
    consent: false,
  };

  render() {
    return (
      <div >
        <p>Som bedriftsadministrator må du ha en egen bruker for å logge på.
        Da får du full tilgang til bedriftsavtalen, og kan legge til flere administratorer.
        </p>
        <h2 >Dine opplysninger</h2>
        <div style={{ marginTop: '50px' }}>
          <Input3
            colored
            label="Fullt navn"
            placeholder="Fornavn og etternavn"
            value={this.props.contactPersonName}
            onChange={(value, valid) => {
              this.props.onChange({ contactPersonName: value });
              this.setState({ name: valid });
            }}
            validate={string => string.length > 2}
            errorMessage="Navn må være minst 2 bokstaver lang"
          />
        </div>
        <div style={{ marginTop: '30px', maxWidth: 400 }}>
          <Input3
            colored
            label="E-post"
            placeholder="Din e-postadresse"
            value={this.props.contactPersonEmail}
            onChange={(value, valid) => {
              this.props.onChange({ contactPersonEmail: value });
              this.setState({ email: valid });
            }}
            validate={validateEmail}
            errorMessage="E-postadressen er ikke gyldig"
          />
        </div>
        <div style={{ marginTop: '30px', maxWidth: 400, marginBottom: '40px' }}>
          <Input3
            colored
            label="Telefon"
            placeholder="Skriv inn telefonnummer"
            value={this.props.contactPersonPhone}
            onChange={(value, valid) => {
              this.props.onChange({ contactPersonPhone: value });
              this.setState({ phone: valid });
            }}
            validate={string => string.length >= 8}
            errorMessage="Telefonnummer må være minst 8 bokstaver lang"
          />
        </div>
        <br />
        <div>
          <h3>Brukervilkår, personvern og relevant informasjon</h3>
          <EmailSettingPicker
            values={this.props.notifications}
            onChange={(notifications, allNotificationsSelected) => this.props.onChange({ notifications, allNotificationsSelected })}
          />
          <CheckboxGroup direction="column">
            <Checkbox
              checked={this.state.consent}
              onChange={consent => this.setState({ consent })}
            >
              Jeg aksepterer{' '}
              <a style={{ borderBottom: '2px solid #acacac', paddingBottom: '3px' }}
                href="/vilkar" target="_blank">
                brukervilkårene
              </a>{' '}
              og{' '}
              <a style={{ borderBottom: '2px solid #acacac', paddingBottom: '3px' }}
                href="/personvern" target="_blank">
                personvernserklæring
              </a>
              .
            </Checkbox>
          </CheckboxGroup>
        </div>{' '}
        <br />
        <Button
          size="medium"
          onClick={() => this.props.onNext()}
          disabled={
            !this.state.email ||
            !this.state.name ||
            !this.state.phone ||
            !this.state.consent ||
            !this.props.allNotificationsSelected
          }
          saving={this.props.loading}
        >
          Fullfør
        </Button>
      </div>
    );
  }
}

export default ClientInfo;
