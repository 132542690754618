import React from 'react';
import {
  getDayValues,
  menusForDayPerType,
} from '../../../../utils/lunchOverviewUtils';

import styles from '../styles.module.css';

class MenuPicker extends React.Component {
  getValuesForDay(date) {
    return getDayValues(
      date,
      this.props.choices || [],
      this.props.subscription ? [this.props.subscription] : null,
      this.props.valuesPerDay[date.format('DD-MM-YYYY')] || [],
      this.props.subscriptionTypes,
      this.props.orders || []
    );
  }

  render() {
    if (!this.props.subscription || !this.props.subscription.startDate) {
      return <div>Du har ingen avtale.</div>;
    }

    const today = new Date();

    return (
      <div>
        <div>
          {[...Array(5)]
            .map((x, i) =>
              this.props.week
                .clone()
                .startOf('isoWeek')
                .add(
                  today.getDay() === 0 || today.getDay() > 5 ? i + 7 : i,
                  'day'
                )
            )
            .slice(
              today.getDay() < 2
                ? 0
                : today.getDay() > 5
                  ? 0
                  : today.getDay() - 2,
              today.getDay() < 3 || today.getDay() > 5 ? 3 : 1 + today.getDay()
            )
            .map(date => {
              const orderedBox = this.getValuesForDay(date).filter(v => {
                if (v.menuAmount === 0) return false;
                const st = this.props.subscriptionTypes.find(
                  st => st.id === v.type.id
                );
                return st && st.type === 'box';
              });

              let menu = <div style={{ width: 350 }}>Ingen bestilling</div>;

              const menus = menusForDayPerType(date, this.props.menus);

              const uniqueMenus = (menu, i, list) => {
                return list.indexOf(menu) === i;
              };

              if (orderedBox.length) {
                menu = (
                  <div style={{ width: 350 }}>
                    {orderedBox
                      .map(box => {
                        if (menus[box.type.id]) {
                          return menus[box.type.id].name;
                        } else {
                          const st = this.props.subscriptionTypes.find(
                            st => st.id === box.type.id
                          );
                          if (st) {
                            return st.name;
                          } else {
                            return box.type.name;
                          }
                        }
                      })
                      .filter(uniqueMenus)
                      .map(menu => (
                        <div>{menu}</div>
                      ))}
                  </div>
                );
              }

              return (
                <a href="https://my.lunsjkollektivet.no" key={date.format()}>
                  <div className={styles.day}>
                    <div style={{ width: 100 }}>
                      <div style={{ fontWeight: 'bold' }}>
                        {date.format('dddd')}
                      </div>
                      <div>{date.format('D. MMM')}</div>
                    </div>
                    {menu}
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    );
  }
}

export default MenuPicker;
