import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FOR_COMPANY_AGREEMENT_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_FOR_COMPANY_AGREEMENT_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.singleOrders.reduce((object, singleOrder) => {
            object[singleOrder.id] = singleOrder;
            return object;
          }, {}),
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: action.payload.singleOrders.map(so => so.id),
          },
        },
      };
    case types.GET_FOR_COMPANY_AGREEMENT_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.UPDATE_SUCCESS:
      let newIds = state.meta[action.payload.key]
        ? [...state.meta[action.payload.key].ids]
        : [];
      if (newIds.indexOf(action.payload.singleOrder.id) === -1) {
        newIds.push(action.payload.singleOrder.id);
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.singleOrder.id]: action.payload.singleOrder,
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: newIds,
          },
        },
      };
    default:
      return state;
  }
};
