import fetch from '../../utils/fetch';
import shouldFetchAllergies from '../../selectors/allergy/shouldFetch';

import types from './types';

export const getIfNeeded = count => (dispatch, getState) => {
  if (!shouldFetchAllergies(getState())) return;

  dispatch({
    type: types.GET_START,
    payload: {
      count: count,
    },
  });

  return fetch('/allergies', 'GET', {
    all: true,
  })
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          count,
          entities: response.data.reduce((allergies, allergy) => {
            allergies[allergy.id] = allergy;
            return allergies;
          }, {}),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          count,
          error,
        },
      });
    });
};
