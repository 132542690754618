import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '../Alert';
import Loader from '../Loader';

import CustomerInvoice from './CustomerInvoice';
import CompanyInvoice from './CompanyInvoice';

import selectAllActive from '../../selectors/subscriptionType/selectAllActive';
import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../stores/subscriptionType/actions';

class Invoice extends React.Component {
  state = { saving: false };

  componentDidMount() {
    this.props.dispatch(this.props.get(this.props.invoiceId));
    this.props.dispatch(getAllSubscriptionTypesIfNeeded());
  }

  getContent() {
    if (this.props.error) return <Alert color="red">Noe skjedde</Alert>;
    if (this.props.loading && !this.props.invoice) return <Loader />;
    if (this.props.invoice.customer) {
      return (
        <CustomerInvoice
          invoice={this.props.invoice}
          subscriptionTypes={this.props.subscriptionTypes}
          updateInvoice={(...args) =>
            this.props.dispatch(this.props.update(...args))
          }
          backTo={this.props.backTo}
          admin={this.props.admin}
        />
      );
    }
    return (
      <CompanyInvoice
        invoice={this.props.invoice}
        subscriptionTypes={this.props.subscriptionTypes}
        updateInvoice={(...args) =>
          this.props.dispatch(this.props.update(...args))
        }
        addNotes={(...args) =>
          this.props.dispatch(this.props.addNotes(...args))
        }
        backTo={this.props.backTo}
        admin={this.props.admin}
      />
    );
  }

  render() {
    return this.getContent();
  }
}

Invoice.defaultProps = {
  admin: false,
};

Invoice.propTypes = {
  select: PropTypes.func.isRequired,
  selectMeta: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
  backTo: PropTypes.string.isRequired,
  admin: PropTypes.bool,
};

export default connect((state, ownProps) => {
  const meta = ownProps.selectMeta(state, ownProps.invoiceId);
  return {
    invoice: ownProps.select(state, ownProps.invoiceId),
    subscriptionTypes: selectAllActive(state),
    error: meta.error || state.subscriptionType.meta.error,
    loading: meta.loading || state.subscriptionType.meta.loading,
  };
})(Invoice);
