import fetch from '../../utils/fetch';
import shouldFetchTypes from '../../selectors/subscriptionType/shouldFetch';
import shouldFetch from '../../selectors/shouldFetch';

import types from './types';

export const getAllIfNeeded = () => (dispatch, getState) => {
  if (!shouldFetchTypes(getState())) return;

  dispatch({
    type: types.GET_START,
  });

  return fetch('/subscriptiontypes', 'GET')
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          entities: response.data.reduce(
            (subscriptionTypes, subscriptionType) => {
              subscriptionTypes[subscriptionType.id] = subscriptionType;
              return subscriptionTypes;
            },
            {}
          ),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          error,
        },
      });
    });
};

export const getForCompanyAgreement = companyAgreementId => (
  dispatch,
  getState
) => {
  if (
    !shouldFetch(getState().subscriptionType.meta['companyAgreement']) &&
    (!getState().subscriptionType.meta['companyAgreement'].id ||
      getState().subscriptionType.meta['companyAgreement'].id ===
        companyAgreementId)
  )
    return;

  dispatch({
    type: types.GET_FROM_CA_START,
  });

  return fetch(
    `/company-agreements/${companyAgreementId}/subscriptiontypes`,
    'GET'
  )
    .then(response => {
      dispatch({
        type: types.GET_FROM_CA_SUCCESS,
        payload: {
          companyAgreementId,
          entitiesFromCompanyAgreement: response.data.reduce(
            (subscriptionTypes, subscriptionType) => {
              subscriptionTypes[subscriptionType.id] = subscriptionType;
              return subscriptionTypes;
            },
            {}
          ),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FROM_CA_ERROR,
        payload: {
          error,
        },
      });
    });
};
