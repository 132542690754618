import { useState, useEffect } from 'react';

export const useSize = (
  breakpoint = 900
) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  let isMobile = size.width <= breakpoint;

  useEffect(() => {
    const updateWindowDimensions = () =>
      setSize({ width: window.innerWidth, height: window.innerHeight });

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  return { isMobile, size };
};

export default useSize;
