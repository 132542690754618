import { getById } from '../deliveryLocation/selectors';

export const selectCustomer = (state, id) => {
  let customer = state.customer.entities[id];

  if (customer && customer.deliveryLocation) {
    const dl = getById(state, customer.deliveryLocation.id);
    if (dl) {
      customer.deliveryLocation = dl;
    }
  }

  return customer;
};

export const selectDeleteCustomerMeta = state => {
  return state.customer.meta.delete_customer;
};
