import React from 'react';
import Loader from '../Loader';
import Alert from '../Alert';
import Button from '../Button';

export default props => {
  if (props.error) {
    return (
      <Alert color="red">
        <div>Siden lastet ikke inn!</div>
        <Button onClick={props.retry}>Prøv på nytt</Button>
      </Alert>
    );
  } else if (props.timedOut) {
    return (
      <Alert color="red">
        <div>Siden tar uvanlig lang tid å laste inn...</div>
        <Button onClick={props.retry}>Prøv på nytt</Button>
      </Alert>
    );
  } else if (props.pastDelay) {
    return <Loader />;
  } else {
    return null;
  }
};
