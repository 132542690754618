import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from '../Table';
import Card from '../Card';
import Button, { BackButton } from '../Button';
import Alert from '../Alert';
import { Row, Col } from '../Grid';
import { TextArea, Checkbox } from '../Form';
import formatUserFrendlyDate from '../../utils/formatUserFrendlyDate';
import formatPrice from '../../utils/formatPrice';
import downloadFile from '../../utils/downloadFile';
import isDrink from '../../utils/isDrink';
import SubscriptionTypeService from '../../services/SubscriptionTypeService';

import { getCompanyAgreementNotes } from '../../stores/companyAgreement/actions';
import { selectCompanyAgreement } from '../../stores/companyAgreement/selectors';

import SummaryPerCustomer, {
  getSummaryPerCustomer,
} from './SummaryPerCustomer';
import { connect } from 'react-redux';

class CompanyFaktura extends React.Component {
  state = {
    saving: false,
    success: false,
    printInvoice: false,
    relevantOrders: this.props.invoice.orders.filter(
      x => x.totalPriceAfterSplit
    ),
    additionalNotes: '',
    includeAdditionalNotes: false,
    removeAdditionalNotes: false
  };

  componentDidMount() {
    this.props.getCompanyAgreementNotes(this.props.invoice.companyAgreement.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.state.additionalNotes) {
      if (this.props.invoice && this.props.invoice.additionalNotes) {
        this.setState({additionalNotes: this.props.invoice.additionalNotes});
      }
      else if (this.props.companyAgreement && this.props.companyAgreement.additionalNotes) {
        this.setState({additionalNotes: this.props.companyAgreement.additionalNotes});
      }
    }
  }

  generateCsv() {
    if (this.state.relevantOrders.length === 0) return;
    const summary = getSummaryPerCustomer(this.state.relevantOrders);
    let rows = [['Navn', 'Antall bokser', 'Antall drikke', 'Total (kr)']];
    summary.perCustomer.forEach(customerSummary => {
      rows.push([
        customerSummary.name,
        Object.keys(customerSummary.details)
          .filter(key => !isDrink(key))
          .reduce((total, key) => total + customerSummary.details[key], 0),
        Object.keys(customerSummary.details)
          .filter(key => isDrink(key))
          .reduce((total, key) => total + customerSummary.details[key], 0),
        String(customerSummary.total).replace('.', ','),
      ]);
    });

    const content = rows
      .map(row => row.map(v => `"${v}"`).join(';'))
      .join('\n');

    downloadFile('bestillinger.csv', content);
  }

  update = (prefix, data) => {
    this.setState({
      [prefix + 'saving']: true,
      [prefix + 'success']: false,
      error: false,
    });
    this.props
      .updateInvoice(this.props.invoice.id, data)
      .then(() => {
        this.setState({
          [prefix + 'saving']: false,
          [prefix + 'success']: true,
        });
        setTimeout(() => this.setState({ [prefix + 'success']: false }), 2000);
      })
      .catch(() => {
        this.setState({
          [prefix + 'saving']: false,
          [prefix + 'success']: false,
          error: true,
        });
      });
  };

  action = (text, prefix, data) => {
    return (
      <Button
        onClick={() => this.update(prefix, data)}
        saving={this.state[prefix + 'saving']}
        success={this.state[prefix + 'success']}
      >
        {text}
      </Button>
    );
  };

  addNotes = () => {
    this.setState({savingNotes: true, successNotes: false});

    this.props.addNotes(this.props.invoice.id, {
      status: "send_to_power_office_as_draft_notes",
      notes: this.state.additionalNotes,
      includeAdditionalNotes: this.state.includeAdditionalNotes
    })
    .then(() => {
      this.setState({
        savingNotes: false,
        successNotes: true,
      });
      setTimeout(() => this.setState({ successNotes: false }), 2000);
    })
    .catch(() => {
      this.setState({
        savingNotes: false,
        successNotes: false,
        error: true,
      });
    });
  }

  conditions = (status, paymentMethod) => {
    if (status) {
      let arrayStatus = Array.isArray(status) ? status : [status];
      if (arrayStatus.indexOf(this.props.invoice.status) === -1) {
        return false;
      }
    }
    if (paymentMethod) {
      let arrayPaymentMethod = Array.isArray(paymentMethod)
        ? paymentMethod
        : [paymentMethod];
      if (arrayPaymentMethod.indexOf(this.props.invoice.paymentMethod) === -1) {
        return false;
      }
    }
    return true;
  };

  numberOfOrderDays = () => {
    const days = this.state.relevantOrders.reduce((days, order) => {
      return days.includes(moment(order.date).format('YYYY-MM-DD'))
        ? days
        : [...days, moment(order.date).format('YYYY-MM-DD')];
    }, []);

    return days.length;
  };

  render() {
    const orders = this.state.relevantOrders;

    const summary = orders.reduce((summary, order) => {
      order.details.forEach(detail => {
        if (!summary[detail.type]) {
          summary[detail.type] = 0;
        }
        summary[detail.type] += detail.amount;
      });
      return summary;
    }, {});

    if (this.state.printInvoice) {
      return (
        <div>
          <Button
            onClick={() => this.setState({ printInvoice: false })}
            className="no-print"
          >
            Ferdig
          </Button>
          <SummaryPerCustomer
            invoice={this.props.invoice}
            subscriptionTypes={this.props.subscriptionTypes}
          />
        </div>
      );
    }

    const grandTotal = this.props.invoice.totalPrice + (this.props.invoice.deliveryCost || 0);
    const hasCredit = this.props.invoice.totalPriceBeforeCredits - this.props.invoice.totalPrice !== 0;

    return (
      <div>
        <Row>
          <Col>
            <BackButton to={this.props.backTo} />
          </Col>
        </Row>
        <Row>
          <Col m={7}>
            <Card header="Kunde">
              <div>
                <strong>Navn: </strong>
                {this.props.invoice.billingInformation.name}
              </div>
              <div>
                <strong>E-Post: </strong>
                {this.props.invoice.billingInformation.email}
              </div>
            </Card>
          </Col>
          <Col m={5}>
            <Card header="Total">
              {hasCredit ? (
                <div>
                  <div>
                    Før kreditt:{' '}
                    {formatPrice(this.props.invoice.totalPriceBeforeCredits)}
                  </div>
                  <div>
                    Kreditt:{' '}
                    {formatPrice(
                      this.props.invoice.totalPriceBeforeCredits -
                      this.props.invoice.totalPrice
                    )}
                  </div>
                </div>
              ) : null}
              <strong style={{ fontSize: '2em' }}>
                {formatPrice(grandTotal)}
              </strong>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props.admin && this.props.invoice.status !== 'collecting' ? (
              <Card header="Handlinger">
                {this.action('Sendt', 'sendt', {
                  status: 'done'
                })}
                <Button onClick={() => this.generateCsv()}>Last ned csv</Button>
                {this.conditions(
                  ['ready_for_payment', 'missing_information', 'send_to_power_office_with_notes'],
                  ['manual', 'powerOfficeInvoice']
                )
                  ? this.action(
                    'Send til Power Office som "Draft"',
                    'sendToPowerOfficeAsDraft',
                    {
                      status: 'send_to_power_office_as_draft',
                      paymentMethod: 'powerOfficeInvoice'
                    }
                  )
                  : null}
                <Button
                  onClick={() =>
                    this.setState(
                      {
                        printInvoice: true,
                      },
                      () => {
                        window.print();
                        this.setState({ printInvoice: false });
                      }
                    )
                  }
                >
                  Skriv ut oppsummering per ansatt
                </Button>
                {this.state.error ? (
                  <Alert color="red">Noe skjedde</Alert>
                ) : null}
              </Card>
            ) : (
              <Card header="Handlinger">
                <Button onClick={() => this.generateCsv()}>Last ned csv</Button>
              </Card>
            )}
          </Col>
          <Col m={5}>
            <Card header="Fordeling">
              <div>
                <div style={{ fontSize: '1.3em' }}>
                  Lunsj:{' '}
                  <strong>
                    {formatPrice(this.props.invoice.totalPrice)}
                  </strong>
                </div>
                <div style={{ fontSize: '1.3em' }}>
                  Frakt:{' '}
                  <strong>
                    {formatPrice(this.props.invoice.deliveryCost || 0)}
                  </strong>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        {this.props.admin ? (
          <>
          <Row>
            <Col>
              <Card header="Kommentarer">
                <TextArea value={this.state.additionalNotes} onChange={additionalNotes => this.setState({additionalNotes})} />
                <div style={{display: 'flex' }}>
                  <div style={{margin: '0 10px 10px 0' }}>
                    <Checkbox checked={this.state.includeAdditionalNotes} onChange={includeAdditionalNotes => this.setState({includeAdditionalNotes})} />
                  </div>
                  Inkluder kommentar i faktura
                </div>
                {/* <div style={{display: 'flex'}}>
                  <div style={{margin: '0 10px 10px 0' }}>
                    <Checkbox checked={this.state.removeAdditionalNotes} onChange={removeAdditionalNotes => this.setState({removeAdditionalNotes})} />
                  </div>
                  Fjern kommentar etter fakturering
                </div> */}
                <Button onClick={this.addNotes} saving={this.state.savingNotes}>Oppdater</Button>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card header="Hendelseslogg">
                <Table>
                  <thead>
                  <tr>
                    <th>Når</th>
                    <th>Melding</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.props.invoice.events &&
                  this.props.invoice.events.map((event, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {formatUserFrendlyDate(moment(event.date), true)}
                        </td>
                        <td>{event.message}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          </>
        ) : null}
        {orders.length ? (
          <div>
            <Row>
              <Col>
                <Card header="Oppsummering">
                  <Table>
                    <thead>
                      <tr>
                        <th>Beskrivelse</th>
                        <th>Antall</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(summary).map(key => {
                        return (
                          <tr key={key}>
                            <td>
                              {SubscriptionTypeService.getNameFromAlias(
                                this.props.subscriptionTypes,
                                key
                              )}
                            </td>
                            <td>{summary[key]} stk</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <SummaryPerCustomer
                  invoice={this.props.invoice}
                  subscriptionTypes={this.props.subscriptionTypes}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Card
                  header={`Fakturalinjer, ${this.numberOfOrderDays()} dager`}
                >
                  <Table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '240px' }}>Dato</th>
                        <th>Beskrivelse</th>
                        <th style={{ minWidth: '70px' }}>Prisgrunnlag</th>
                        <th style={{ minWidth: '70px' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders
                        .sort((a, b) => moment(a.date).diff(moment(b.date)))
                        .map((order, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {formatUserFrendlyDate(moment(order.date))}
                              </td>
                              <td>
                                {order.details.map((detail, index) => (
                                  <div key={index}>
                                    {SubscriptionTypeService.getNameFromAlias(
                                      this.props.subscriptionTypes,
                                      detail.type
                                    )}{' '}
                                    x {detail.amount}
                                  </div>
                                ))}
                                {order.deliveryPriceAfterSplit > 0 ? (
                                  <div>
                                    Frakt (
                                    {formatPrice(
                                      parseFloat(
                                        order.deliveryPriceAfterSplit
                                      ).toFixed(2)
                                    )}
                                    )
                                  </div>
                                ) : null}
                              </td>

                              <td>
                                {order.details.map((detail, index) => (
                                  <div key={index + '_price'}>
                                    {formatPrice(detail.price)}
                                  </div>
                                ))}
                              </td>

                              <td>{formatPrice(order.totalPriceAfterSplit)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
            <div style={{ textAlign: 'end', marginRight: '20px' }}>
              <em>*Alle priser inkl. 15% mva</em>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

CompanyFaktura.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default connect(
  (state, ownProps) => {
    return {
      companyAgreement: selectCompanyAgreement(state, ownProps.invoice.companyAgreement.id),
    };
  },
  { getCompanyAgreementNotes }
)(CompanyFaktura);
