import React from 'react';
import cn from 'classnames';
import styles from './container.module.css';

const Container = ({
  children,
  color,
  className,
  withPadding,
  width,
  height,
  admin,
  noBorder,
  ...otherProps
}) => (
  <div
    className={cn(styles[color], className, {
      [styles.withPadding]: withPadding,
      [styles.noBorder]: noBorder,
      [styles.admin]: admin,
    })}
    {...otherProps}
  >
    <div
      className={cn(styles.container, { [styles.noBorder]: noBorder })}
      style={ !admin || width > 700 ? { maxWidth: width, minHeight: height } : {}}
    >
      {children}
    </div>
  </div>
);

Container.defaultProps = {
  width: 700,
  height: 0,
};

export default Container;
