import { combineReducers } from 'redux';

import allergiesReducer from './allergiesReducer';
import historyReducer from './HistoryReducer.jsx';
import noDeliveryDay from '../stores/noDeliveryDay/reducer';
import customer from '../stores/customer/reducer';
import allergy from '../stores/allergy/reducer';
import company from '../stores/company/reducer';
import companyAgreement from '../stores/companyAgreement/reducer';
import user from '../stores/user/reducer';
import invoice from '../stores/invoice/reducer';
import me from '../stores/me/reducer';
import subscriptionType from '../stores/subscriptionType/reducer';
import menu from '../stores/menu/reducer';
import subscription from '../stores/subscription/reducer';
import singleOrder from '../stores/singleOrder/reducer';
import order from '../stores/order/reducer';
import clientOrder from '../stores/clientOrder/reducer';
import choice from '../stores/choice/reducer';
import credit from '../stores/credit/reducer';
import notificationDefinition from '../stores/notificationDefinition/reducer';
import companySubscription from '../stores/companySubscription/reducer';
import address from '../stores/address/reducer';
import customerRating from '../stores/customerRating/reducer';
import orderSummary from '../stores/orderSummary/reducer';
import deliveryLocation from '../stores/deliveryLocation/reducer';
import instagram from '../stores/instagram/reducer';
import entryQuery from '../stores/entryQuery/reducer';
import meetingFoodOrder from '../stores/meetingFoodOrder/reducer';
import homeOfficeOrder from '../stores/homeOfficeOrder/reducer';
import fetch from '../stores/fetch/reducer';
import deliveryInfo from '../stores/deliveryInfo/reducer';
import event from '../stores/event/reducer';

const rootReducer = combineReducers({
  allergies: allergiesReducer,
  history: historyReducer,
  noDeliveryDay,
  customer,
  allergy,
  company,
  companyAgreement,
  user,
  invoice,
  me,
  subscriptionType,
  menu,
  subscription,
  singleOrder,
  order,
  clientOrder,
  choice,
  credit,
  notificationDefinition,
  companySubscription,
  address,
  customerRating,
  orderSummary,
  deliveryLocation,
  instagram,
  entryQuery,
  meetingFoodOrder,
  homeOfficeOrder,
  fetch,
  deliveryInfo,
  event,
});

export default rootReducer;
