import React from 'react';
import cn from 'classnames';
import styles from './checkbox.module.css';

export default props => (
  <div
    className={cn(styles.checkboxWrapper, props.className)}
    onClick={() => !props.disabled && props.onChange(!props.checked)}
  >
    <div className={styles.verticalCenter}>
      <div
        className={cn(styles.checkbox, {
          [styles.checked]: props.checked,
          [styles.disabled]: props.disabled,
        })}
        onClick={() => !props.disabled && props.onChange(!props.checked)}
      >
        <div className="fa fa-check" />
      </div>
    </div>
    {props.children ? <div className={styles.label}>{props.children}</div> : ''}
  </div>
);
