import addPrefix from '../../utils/addPrefix';

export default addPrefix('subscriptionTypes', [
  'GET_START',
  'GET_SUCCESS',
  'GET_ERROR',
  'GET_FROM_CA_START',
  'GET_FROM_CA_SUCCESS',
  'GET_FROM_CA_ERROR',
]);
