import addPrefix from '../../utils/addPrefix';

export default addPrefix('company-subscription', [
  'GET_START',
  'GET_SUCCESS',
  'GET_ERROR',
  'UPDATE_SUCCESS',
  'CREATE_SUCCESS',
  'DEACTIVATE_SUCCESS',
]);
