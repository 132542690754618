import selectMeta from '../../selectors/selectMeta';

export const getKey = (companyAgreementId, meetingFoodId = null) =>
  `meeting-food-${companyAgreementId}${
    meetingFoodId !== null ? `-${meetingFoodId}` : ''
  }`;

export const selectForCompanyAgreementMeta = (state, companyAgreementId) =>
  selectMeta(state.meetingFoodOrder.meta[getKey(companyAgreementId)]);

export const selectForCompanyAgreement = (state, companyAgreementId) =>
  selectForCompanyAgreementMeta(state, companyAgreementId).ids.map(
    id => state.meetingFoodOrder.entities[id]
  );

export const selectOneForCompanyAgreement = (
  state,
  companyAgreementId,
  meetingFoodId
) => state.meetingFoodOrder.entities[meetingFoodId];

export const selectOneForCompanyAgreementMeta = (
  state,
  companyAgreementId,
  meetingFoodId
) =>
  selectMeta(
    state.meetingFoodOrder.meta[getKey(companyAgreementId, meetingFoodId)]
  );
