import React from 'react';
import { buildImageUrl } from '../../../../../utils/sanity';
import BlockRenderer from '../../../../../components/BlockRenderer';
import ContactPhone from '../ContactPhone';
import Button from '../../../../../components/Button';
import Header from '../../../../../components/Header';
import Container from '../../../../../components/Container';
import Link from '../../../../../components/Link';
import CampaignContent from '../../../../../components/CampaignContent';
import ProductDisplay from '../../../../../components/ProductDisplay';

import styles from './headerCampaign.module.scss';

class HeaderCampaign extends React.Component {
  constructor() {
    super();
    this.state = { contact: false };
  }

  render() {
    return (
      <div>
        <Header darken top withoutCard />
        <div
          style={{ backgroundImage: `url(${buildImageUrl(this.props.image)})` }}
          className={styles.background}
        />
        <Container color="beige" width={900}>
          <div className={styles.campaignBody}>
            <BlockRenderer content={this.props.body} />
            {this.state.contact ? (
              <div className={styles.contact}>
                <ContactPhone />
              </div>
            ) : (
              <div className={styles.actionGroup}>
                <div className={styles.row}>
                  <Button
                    onClick={() =>
                      this.setState({ contact: !this.state.contact })
                    }
                  >
                    Ta kontakt
                  </Button>
                  <div>
                    <Link gray to={'/company'}>
                      Les mer
                    </Link>
                  </div>
                  <div>
                    <Link gray to={'/company-signup'}>
                      Bli kunde
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <div style={{ textAlign: 'center' }}>
              <p>
                Eller ta kontakt med oss på
                <br />
                <a href="tel:72909005" className={styles.link}>
                  729 09 005
                </a>{' '}
                /{' '}
                <a
                  href="mailto:hei@lunsjkollektivet.no"
                  className={styles.link}
                >
                  hei@lunsjkollektivet.no
                </a>
              </p>
            </div>
          </div>
        </Container>
        <ProductDisplay />
        <CampaignContent />
      </div>
    );
  }
}

export default HeaderCampaign;
