import React from 'react';
import formatPrice from '../../utils/formatPrice';

export default ({ total, deliveryPrice, company }) => (
  <div>
    <div>
      {company && total.company > 0 ? (
        <span style={{ fontSize: '0.8em' }}>Du betaler </span>
      ) : null}
      {company ? (
        <strong>{formatPrice(total.customer)}</strong>
      ) : (
        <strong>Bestilling lagret</strong>
      )}
    </div>
    {company && total.company > 0 ? (
      <div>
        <span style={{ fontSize: '0.8em' }}>Bedriften betaler</span>{' '}
        <span>{formatPrice(total.company)}</span>
      </div>
    ) : null}
  </div>
);
