import addPrefix from '../../utils/addPrefix';

export default addPrefix('meetingFood', [
  'GET_FOR_COMPANY_AGREEMENT_START',
  'GET_FOR_COMPANY_AGREEMENT_SUCCESS',
  'GET_FOR_COMPANY_AGREEMENT_ERROR',
  'UPDATE_SUCCESS',
  'CREATE_SUCCESS',
  'DELETE_SUCCESS',
]);
