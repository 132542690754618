import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from '../Table';
import Card from '../Card';
import Button, { BackButton } from '../Button';
import Alert from '../Alert';
import { Row, Col } from '../Grid';
import formatPrice from '../../utils/formatPrice';
import formatUserFrendlyDate from '../../utils/formatUserFrendlyDate';

class CustomerInvoice extends React.Component {
  state = {
    saving: false,
    success: false,
  };

  update = (prefix, data) => {
    this.setState({
      [prefix + 'saving']: true,
      [prefix + 'success']: false,
      error: false,
    });
    this.props
      .updateInvoice(this.props.invoice.id, data)
      .then(() => {
        this.setState({
          [prefix + 'saving']: false,
          [prefix + 'success']: true,
        });
        setTimeout(() => this.setState({ [prefix + 'success']: false }), 2000);
      })
      .catch(() => {
        this.setState({
          [prefix + 'saving']: false,
          [prefix + 'success']: false,
          error: true,
        });
      });
  };

  action = (text, prefix, data) => {
    return (
      <Button
        onClick={() => this.update(prefix, data)}
        saving={this.state[prefix + 'saving']}
        success={this.state[prefix + 'success']}
      >
        {text}
      </Button>
    );
  };

  conditions = (status, paymentMethod) => {
    if (status) {
      let arrayStatus = Array.isArray(status) ? status : [status];
      if (arrayStatus.indexOf(this.props.invoice.status) === -1) {
        return false;
      }
    }
    if (paymentMethod) {
      let arrayPaymentMethod = Array.isArray(paymentMethod)
        ? paymentMethod
        : [paymentMethod];
      if (arrayPaymentMethod.indexOf(this.props.invoice.paymentMethod) === -1) {
        return false;
      }
    }
    return true;
  };

  render() {
    const summary = this.props.invoice.orders.reduce((summary, order) => {
      order.details.forEach((detail, index) => {
        if (!summary[detail.type]) {
          summary[detail.type] = 0;
        }
        summary[detail.type] += detail.amount;
      });
      return summary;
    }, {});

    const grandTotal = this.props.invoice.totalPrice + (this.props.invoice.deliveryCost || 0);

    return (
      <div>
        <Row>
          <Col>
            <BackButton to={this.props.backTo} />
          </Col>
        </Row>
        {this.props.admin ? (
          <Row>
            <Col m={8}>
              <Card header="Ekstra Info">
                <div>
                  <strong>Betalingsmåte: </strong>
                  {this.props.invoice.paymentMethod}
                </div>
                <div>
                  <strong>Status: </strong>
                  {this.props.invoice.status}
                </div>
              </Card>
            </Col>
            <Col m={4}>
              <Card header="Total">
                <strong style={{ fontSize: '2em' }}>
                  {formatPrice(grandTotal)}
                </strong>
              </Card>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col m={8}>
            <Card header="Kunde">
              <div>
                <strong>Navn: </strong>
                {this.props.invoice.billingInformation.name}
              </div>
              <div>
                <strong>E-Post: </strong>
                {this.props.invoice.billingInformation.email}
              </div>
            </Card>
          </Col>
          <Col m={4}>
            <Card header="Fordeling">
              <div>
                <div style={{ fontSize: '1.3em' }}>
                  Lunsj:{' '}
                  <strong>
                    {formatPrice(this.props.invoice.totalPrice)}
                  </strong>
                </div>
                <div style={{ fontSize: '1.3em' }}>
                  Frakt:{' '}
                  <strong>
                    {formatPrice(this.props.invoice.deliveryCost || 0)}
                  </strong>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        {this.props.admin && this.props.invoice.status !== 'collecting' ? (
          <Row>
            <Col>
              <Card header="Handlinger">
                {this.action('Sendt', 'sendt', {
                  status: 'done',
                })}
                {this.conditions(
                  ['ready_for_payment', 'missing_information'],
                  ['manual', 'powerOfficeInvoice']
                )
                  ? this.action(
                      'Send til Power Office som "Draft"',
                      'sendToPowerOfficeAsDraft',
                      {
                        status: 'send_to_power_office_as_draft',
                        paymentMethod: 'powerOfficeInvoice',
                      }
                    )
                  : null}
                {this.state.error ? (
                  <Alert color="red">Noe skjedde</Alert>
                ) : null}
              </Card>
            </Col>
          </Row>
        ) : null}
        {this.props.admin ? (
          <Row>
            <Col>
              <Card header="Hendelseslogg">
                <Table>
                  <thead>
                    <tr>
                      <th>Når</th>
                      <th>Melding</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.invoice.events &&
                      this.props.invoice.events.map((event, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              {formatUserFrendlyDate(moment(event.date), true)}
                            </td>
                            <td>{event.message}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <Card header="Oppsummering">
              <Table>
                <thead>
                  <tr>
                    <th>Beskrivelse</th>
                    <th>Antall</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(summary).map((key, index) => {
                    return (
                      <tr key={key}>
                        <td>{key}</td>
                        <td>{summary[key]} stk</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card header="Fakturalinjer">
              <Table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Beskrivelse</th>
                    <th>Total</th>
                    <th>Hvorav frakt</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.invoice.orders.map((order, index) => {
                    return (
                      <tr key={index}>
                        <td>{formatUserFrendlyDate(moment(order.date))}</td>
                        <td>
                          {order.details.map((detail, index) => (
                            <div key={index}>
                              {detail.type} x {detail.amount}
                            </div>
                          ))}
                        </td>
                        <td>{formatPrice(order.totalPriceAfterSplit)}</td>
                        <td>{formatPrice(order.deliveryPriceAfterSplit)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <div style={{ textAlign: 'end', marginRight: "20px" }}>
          <em>*Alle priser inkl. 15% mva</em>
        </div>
      </div>
    );
  }
}

CustomerInvoice.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default CustomerInvoice;
