import React from 'react';
import cn from 'classnames';
import styles from './lunch.module.css';

const Star = ({ index, cursorPosition, onChange, onClick }) => {
  const show = cursorPosition !== null && cursorPosition >= index;
  return (
    <div
      className={cn('fa', {
        'fa-star': show,
        'fa-star-o': !show,
      })}
      onMouseEnter={() => (onChange ? onChange(index) : () => {})}
      onMouseLeave={() => (onChange ? onChange(null) : () => {})}
      onClick={onClick ? onClick : () => {}}
    />
  );
};

export default class Rating extends React.Component {
  state = { cursorPosition: null };
  render() {
    if (this.props.rating) {
      return (
        <div className={styles.smallRating}>
          <Star index={0} cursorPosition={parseInt(this.props.rating)} />
          <Star index={1} cursorPosition={parseInt(this.props.rating)} />
          <Star index={2} cursorPosition={parseInt(this.props.rating)} />
        </div>
      );
    }
    let ratingText = '';
    switch (this.state.cursorPosition) {
      case 0:
        ratingText = 'Dårlig';
        break;
      case 1:
        ratingText = 'Ok';
        break;
      case 2:
        ratingText = 'Veldig godt!';
        break;
      default:
    }
    return (
      <div>
        <div className={styles.rating}>
          <Star
            index={0}
            cursorPosition={this.state.cursorPosition}
            onChange={index => this.setState({ cursorPosition: index })}
            onClick={() => this.props.submitRating('0')}
          />
          <Star
            index={1}
            cursorPosition={this.state.cursorPosition}
            onChange={index => this.setState({ cursorPosition: index })}
            onClick={() => this.props.submitRating('1')}
          />
          <Star
            index={2}
            cursorPosition={this.state.cursorPosition}
            onChange={index => this.setState({ cursorPosition: index })}
            onClick={() => this.props.submitRating('2')}
          />
        </div>
        <div className={styles.ratingDescription}>{ratingText}</div>
      </div>
    );
  }
}
