import selectMeta from '../../selectors/selectMeta';

export const selectNoDeliveryDaysForWeek = (state, week) => {
  const meta = selectNoDeliveryDaysForWeekMeta(state, week);

  return meta.ids.map(id => state.noDeliveryDay.entities[id]);
};

export const selectNoDeliveryDaysForWeekMeta = (state, week) => {
  const startDate = week.clone().startOf('week');
  const endDate = week.clone().endOf('week');
  const key = startDate.format() + endDate.format();

  return selectMeta(state.noDeliveryDay.meta[key]);
};

export const selectNoDeliveryDaysForMonth = (state, month) => {
  const meta = selectNoDeliveryDaysForMonthMeta(state, month);

  return meta.ids.map(id => state.noDeliveryDay.entities[id]);
};

export const selectNoDeliveryDaysForMonthMeta = (state, month) => {
  const startDate = month.clone().startOf('month');
  const endDate = month.clone().endOf('month');
  const key = startDate.format() + endDate.format();

  return selectMeta(state.noDeliveryDay.meta[key]);
};
