import React from 'react';
import BlockContent from '../../../../../components/SanityBlockContent';
import { Col, Row } from '../../../../../components/Grid';
import Container from '../../../../../components/Container';
import { buildImageUrl } from '../../../../../utils/sanity';

import Receipe from './Receipe';

import styles from './blogPost.module.css';

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    image: props => (
      <div className={styles.imageWrapper}>
        <div>
          <img src={buildImageUrl(props.node)} alt="From lunsjkollektivet" />
        </div>
      </div>
    ),
    image_v2: props => (
      <div className={styles.imageWrapper}>
        <div>
          <img
            src={buildImageUrl(props.node.image)}
            alt="From lunsjkollektivet"
          />
          <div className={styles.imageLegend}>{props.node.imageLegend}</div>
        </div>
        {props.node.image2 && props.node.image2.asset ? (
          <div style={{ marginLeft: 10 }}>
            <img
              src={buildImageUrl(props.node.image2)}
              alt="From lunsjkollektivet"
            />
            <div className={styles.imageLegend}>{props.node.imageLegend2}</div>
          </div>
        ) : null}
      </div>
    ),
  },
};

export default ({ post }) => (
  <React.Fragment>
    <Container
      width={900}
      style={{
        paddingTop: 20,
      }}
    >
      <Row>
        <Col>
          <h1 className={styles.postTitle}>{post.title}</h1>
          {post.author ? (
            <div className={styles.writtenBy}>
              Skrevet av <i>{post.author.name}</i>
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
    {post.ingress ? (
      <Container width={900}>
        <Row>
          <Col className="text-center">
            <BlockContent blocks={post.ingress} serializers={serializers} />
          </Col>
        </Row>
      </Container>
    ) : null}
    {post.receipe ? <Receipe receipe={post.receipe} /> : null}
    <Container
      width={900}
      style={{
        paddingBottom: 20,
      }}
    >
      <Row>
        <Col>
          <div className={styles.content}>
            <BlockContent blocks={post.body} serializers={serializers} />
          </div>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);
