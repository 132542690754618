import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import WeeklyCompanyLunchOverviewContainer from './WeeklyCompanyLunchOverviewContainer';

import queryString from 'query-string';

class Lunch extends React.Component {
  constructor(props) {
    super(props);
    let query = queryString.parse(location.search); // eslint-disable-line

    if (!query.uke) {
      query.uke = moment().isoWeek() + '-' + moment().year();
      props.history.push({
        search: queryString.stringify(query),
      });
    }

    const parts = query.uke.split('-');

    this.state = {
      week: moment()
        .year(parseInt(parts[1], 10))
        .isoWeek(parseInt(parts[0], 10)),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.week !== this.state.week) {
      this.props.history.push({
        search: queryString.stringify({
          uke: this.state.week.isoWeek() + '-' + this.state.week.year(),
        }),
      });
    }
  }

  render() {
    return (
      <WeeklyCompanyLunchOverviewContainer
        week={this.state.week}
        weekend={this.props.weekend}
        onWeekChange={week => this.setState({ week })}
        companyAgreementId={this.props.companyAgreementId}
        admin={this.props.admin}
        company={this.props.company}
      />
    );
  }
}

export default withRouter(Lunch);
