import React from 'react';
import PropTypes from 'prop-types';
import styles from './lunchBoxOverview.module.css';

class CompanyLunchOverview extends React.Component {
  buildDescription() {
    if (!this.props.menu) {
      return this.props.type ? <div>{this.props.type.name}</div> : '';
    }
    return <div>{this.props.menu.description}</div>;
  }

  render() {
    if (this.props.amount > 0) {
      return (
        <div className={styles.lunchBoxOverviewContainer}>
          <div className={styles.lunchBoxOverview}>
            <div className={styles.menu}>{this.buildDescription()}</div>
          </div>
        </div>
      );
    }
    return <span></span>;
  }
}

CompanyLunchOverview.propTypes = {
  menu: PropTypes.object,
  amount: PropTypes.number.isRequired,
};

export default CompanyLunchOverview;
