import React from 'react';
import PropTypes from 'prop-types';

import styles from './dropdown.module.css';
import DropdownContent from './DropdownContent';
import cn from 'classnames';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputFocused: false,
    };
  }

  componentDidMount() {
    document.body.addEventListener('click', this.windowClicked.bind(this));
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.windowClicked.bind(this));
  }

  windowClicked(e) {
    if (this.state.inputFocused && this.node && !this.node.contains(e.target)) {
      this.setState({ inputFocused: false });
    }
  }

  render() {
    return (
      <div
        ref={node => {
          this.node = node;
        }}
        onClick={() =>
          this.setState({ inputFocused: !this.state.inputFocused })
        }
        className={cn(this.props.className, {[styles.colored]: this.props.colored})}
      >
        <div><strong>{this.props.label}</strong></div>
        <div className={styles.inputDropDown}>
          <div>
            {this.props.value
              ? this.props.valueToString(this.props.value)
              : 'Vennligst velg et alternativ'}
          </div>
          <DropdownContent
            rowComponent={this.props.rowComponent}
            above={this.props.above}
            entities={this.props.entities}
            onChange={this.props.onChange}
            focused={this.state.inputFocused}
            changeFocus={focus => this.setState({ inputFocused: focus })}
          />
        </div>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  label: '',
  value: null,
};

Dropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  rowComponent: PropTypes.func.isRequired,
  valueToString: PropTypes.func.isRequired,
  above: PropTypes.bool,
  entities: PropTypes.array.isRequired,
};

export default Dropdown;
