import React from 'react';
import { withRouter } from 'react-router-dom';

import Card from '../../SettingCard';

const CompanyAgreementAdminCard = props => {
  return (
    <Card
      header="Faktura"
      to={props.to ? props.to : props.location.pathname + '/faktura'}
      linkText="Se"
    >
      Oversikt
    </Card>
  );
};

export default withRouter(CompanyAgreementAdminCard);
