import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.search]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        meta: {
          ...state.meta,
          [action.payload.search]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.search]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.GET_ONE_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          ['single_' + action.payload.id]: {
            loading: true,
            error: false,
            loadedAt: null,
          },
        },
      };
    case types.GET_ONE_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
        meta: {
          ...state.meta,
          ['single_' + action.payload.id]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
          },
        },
      };
    case types.GET_ONE_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          ['single_' + action.payload.id]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
          },
        },
      };
    case types.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        },
      };

      case types.DELETE_COMPANY_START:
        return {
          ...state
        };
      case types.DELETE_COMPANY_SUCCESS:
        return {
          ...state,
          entities: {
            ...state.entities,
            [action.payload.id]: null,
          },
          meta: {
            ...state.entities.meta,
            [action.payload.search]: {
              loading: false,
              error: false,
              loadedAt: null,
              ids: []
            }
          }
        };
      case types.DELETE_COMPANY_ERROR:
        return {
          ...state
        };
    default:
      return state;
  }
};
