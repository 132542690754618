import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Latest from './Latest';
import Post from './Post';

export default props => (
  <Switch>
    <Route exact path={props.match.url} component={Latest} />
      <Route path={props.match.url + '/:postId'} component={Post} />
  </Switch>
);
