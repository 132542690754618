export default (state, alias) => {
  if (
    !state.me.isAuthenticated ||
    !state.me.identity ||
    !state.me.identity.consents ||
    !Array.isArray(state.me.identity.consents)
  )
    return false;

  const consent = state.me.identity.consents.find(c => c.alias === alias);

  if (consent) return consent.approved;

  return false;
};
