import React from 'react';
import { OptionGroup, OptionBox } from '../Form';
import Button from '../Button';
import Card from '../Card';
import AllergyPicker from '../AllergyPicker';
import Link from '../Link';
import cn from 'classnames';
import styles from './day.module.css';

class NewAllergyOrder extends React.Component {
  state = { type: null, allergies: [] };

  allergyCompatibility() {
    var i;
    for (i = 0; i < this.state.allergies.length; i++) {
      if (this.state.allergies[i].name.slice(0, 6) === 'Gluten') {
        return false;
      }
    }
    return true;
  }

  render() {
    this.allergyCompatibility();
    return (
      <Card>
        <strong>Lunsjvalg</strong>
        <OptionGroup row>
          {this.props.subscriptionTypes.map(type => (
            <OptionBox
              key={type.id}
              checked={this.state.type && this.state.type.id === type.id}
              onClick={() => this.setState({ type })}
              label={type.name}
            />
          ))}
        </OptionGroup>
        <AllergyPicker
          label={<strong>Allergier</strong>}
          allergies={this.state.allergies}
          onChange={allergies => this.setState({ allergies })}
          admin={this.props.admin}
          fullObject={this.props.fullAllergyObject}
        />
        <div className={cn(styles.actions)}>
          <div className={styles.info}>
            Merk at all mat fra oss produseres på samme kjøkken, spor av
            allergener kan forekomme.
          </div>
          {this.state.type &&
          this.state.type.alias === 'Bread' &&
          !this.allergyCompatibility() ? (
            <div>
              <br />
              Du kan dessverre ikke kombinere <strong>brødmat</strong> og
              <strong> glutenallergi</strong>.<br />
            </div>
          ) : (
            <div></div>
          )}
          <br />
          <Button
            onClick={() =>
              this.props.onAdd(this.state.type, this.state.allergies)
            }
            disabled={
              (this.state.type &&
                this.state.type.alias === 'Bread' &&
                !this.allergyCompatibility()) ||
              !this.state.type ||
              (this.state.allergies.length === 0 &&
                this.state.type.alias !== 'withoutMeet')
            }
          >
            Legg til
          </Button>
          <Link onClick={() => this.props.onClose()}>Avbryt</Link>
        </div>
      </Card>
    );
  }
}

export default NewAllergyOrder;
