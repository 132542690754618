import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './bootstrap-grid.module.css';

const Row = props => (
  <div className={cn(styles.row, props.className)} style={props.style}>
    {props.children}
  </div>
);

Row.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Row;
