import React from 'react';
import { connect } from 'react-redux';
import Container from '../../../../../components/Container';
import Card from '../../../../../components/SettingCard';

import PaymentCard from './PaymentCard';
import { DeliveryAddressCard } from '../../../../../components/CustomerSettings/DeliveryAddress';
import { AllergyCard } from '../../../../../components/CustomerSettings/Allergy';
import { DeliveryAddress } from '../../../../../components/CustomerSettings/DeliveryAddress';
import { DeliveryAddress as CompanyAgreementDeliveryAddress } from '../../../../../components/CompanySettings/DeliveryAddress';
import { SubscriptionCard } from '../../../../../components/CustomerSettings/Subscription';
import Alert from '../../../../../components/Alert';

import { agreementsWhereUserIsAdmin } from '../../../../../stores/me/selectors';
import { selectCustomerSubscription } from '../../../../../stores/subscription/selectors';
import { selectCompanyAgreement } from '../../../../../stores/companyAgreement/selectors';

import selectedCustomer from '../../../../../selectors/me/selectedCustomer';

import NewAccountHome from './NewAccountHome';

import selectAllSubscriptionTypes from '../../../../../selectors/subscriptionType/selectAll';
import { isDeliveryLocationInvalid } from '../../../../../utils/deliveryLocation';

class Home extends React.Component {
  render() {
    const isCompanyAgreementAdmin =
      this.props.companyAgreement &&
      this.props.adminForCompanyAgreements.length &&
      this.props.companyAgreement.id ===
        this.props.adminForCompanyAgreements[0].id;

    if (!this.props.setupDone) {
      return (
        <NewAccountHome
          customerId={this.props.customer.id}
          isCompanyAgreementAdmin={isCompanyAgreementAdmin}
        />
      );
    }

    const deliveryLocation = this.props.companyAgreement
      ? this.props.companyAgreement.deliveryLocation
      : this.props.customer.deliveryLocation;

    const deliveryLocationInvalid = isDeliveryLocationInvalid(deliveryLocation);

    const isTrondheim =
      deliveryLocation?.address?.postalCode?.kitchenId ===
      '5ad61a479c59ea2600004d42';

    // show warning when delivery location isn't ok
    // and if the user is CompanyAgreement manager/admin then allow to edit delivery location
    if (!isCompanyAgreementAdmin && deliveryLocationInvalid) {
      return (
        <DeliveryAddress
          customerId={this.props.customer.id}
          message={
            <div
              className="text-center"
              style={{ fontSize: '1.2em', marginBottom: 20 }}
            >
              <Alert>
                <strong>
                  {isTrondheim
                    ? 'Vi tar en utvidet sommerferie i Trondheim. Mer informasjon om når vi gjenopptar levering (med flere og nye menyer) kommer i september.'
                    : ''}
                </strong>
                <br />
                <br />
                Vennligst oppdater din adresse. Vi leverer ikke til den du har
                lagret nå.
              </Alert>
            </div>
          }
        />
      );
    } else if (isCompanyAgreementAdmin && deliveryLocationInvalid) {
      return (
        <CompanyAgreementDeliveryAddress
          companyAgreementId={this.props.companyAgreement.id}
          message={
            <div
              className="text-center"
              style={{ fontSize: '1.2em', marginBottom: 20 }}
            >
              <Alert>
                <strong>
                  {isTrondheim
                    ? 'Vi tar en utvidet sommerferie i Trondheim. Mer informasjon om når vi gjenopptar levering (med flere og nye menyer) kommer i september.'
                    : ''}
                </strong>
                <br />
                <br />
                Vennligst oppdater adresse for bedriftsavtalen til{' '}
                {this.props.customer.companyAgreement.company.name}. Vi leverer
                ikke til den som er lagret nå.
              </Alert>
            </div>
          }
        />
      );
    }

    let discountType =
      this.props.companyAgreement &&
      this.props.companyAgreement.discountType === 'percent'
        ? '%'
        : ',-';

    return (
      <Container color="beige" withPadding height={'50vh'}>
        <SubscriptionCard
          subscription={this.props.subscription}
          subscriptionTypes={this.props.subscriptionTypes}
        />
        <DeliveryAddressCard customerId={this.props.customer.id} />
        <Card header="Bedriftsavtale">
          <div>
            {this.props.companyAgreement
              ? this.props.companyAgreement.company.name
              : 'Ikke registrert'}
          </div>
          <div>
            {this.props.companyAgreement
              ? 'Spons ' +
                this.props.companyAgreement.employeeDiscount +
                discountType
              : null}
          </div>
          <div>
            {this.props.companyAgreement
              ? this.props.companyAgreement.accountManagers.map(
                (am, i) => (
                  <div key={am.id}>
                    Admin {i + 1}: {am.email}
                  </div>
                ))
              : null}
          </div>
        </Card>
        <Card header="Profil" to="/innstillinger/profile" linkText="Rediger">
          <div>{this.props.identity.name}</div>
          <div>{this.props.identity.email}</div>
          <div>{this.props.identity.phone}</div>
        </Card>
        <PaymentCard cards={this.props.cards} />
        <AllergyCard userId={this.props.identity.id} />
        <Card
          header="Hvordan kan vi kontakte deg?"
          to="/innstillinger/email"
          linkText="Endre"
        >
          Hvordan kan vi kontakte deg?
        </Card>
        <Card header="Faktura" to="/innstillinger/faktura" linkText="Se">
          Oversikt over faktura du har mottat
        </Card>
      </Container>
    );
  }
}

export default connect(state => {
  const customer = selectedCustomer(state);
  let companyAgreement = null;
  if (customer && customer.companyAgreement) {
    companyAgreement =
      selectCompanyAgreement(state, customer.companyAgreement.id) ||
      customer.companyAgreement;
  }
  return {
    subscriptionTypes: selectAllSubscriptionTypes(state),
    subscription: selectCustomerSubscription(state, customer.id),
    adminForCompanyAgreements: agreementsWhereUserIsAdmin(state),
    cards: customer.cards || [],
    allergies: state.me.identity.allergies || [],
    identity: state.me.identity,
    customer,
    companyAgreement,
    setupDone: state.me.identity.meta.setupDone,
  };
})(Home);
