import selectCompanyAgreement from '../../selectors/companyAgreement/selectCompanyAgreement';
import selectAllCompanyAgreements from '../../selectors/companyAgreement/selectAllCompanyAgreements';

export const myCustomers = state => {
  if (!state.me || !state.me.customers || !Array.isArray(state.me.customers))
    return [];

  return state.me.customers.map(id => state.customer.entities[id]);
};

export const agreementsWhereUserIsAdmin = state => {
  if (
    !state.me ||
    !state.me.companyAgreementsManager ||
    !Array.isArray(state.me.companyAgreementsManager)
  )
    return [];

  return state.me.companyAgreementsManager.map(
    id => state.companyAgreement.entities[id]
  );
};

const selectCompanyAgreementId = state => {
  if (state.me.selectedCompanyAgreement)
    return state.me.selectedCompanyAgreement;
  const agreements = agreementsWhereUserIsAdmin(state);
  if (agreements.length === 0) return null;
  const selected = state.me.context.id;

  if (!selected || state.me.context.type !== 'companyAgreement')
    return agreements[0].id;

  const agreement = agreements.find(a => a.id === selected);
  if (!agreement) return agreements[0].id;
  return agreement.id;
};

const selectAllCompanyAgreementId = state => {
  const agreements = agreementsWhereUserIsAdmin(state);
  if (agreements.length === 0) return null;

  let ids = [];
  let i;

  for (i = 0; i < agreements.length; i++) {
    ids.push(agreements[i].id);
  }

  return ids;
};

export const selectedCompanyAgreement = state => {
  const id = selectCompanyAgreementId(state);
  if (!id) return null;
  return selectCompanyAgreement(state, id);
};

export const allCompanyAgreements = state => {
  const ids = selectAllCompanyAgreementId(state);
  if (!ids) return null;
  return selectAllCompanyAgreements(state, ids);
};

export const selectedContext = state => {
  if (state.me.context.type === 'private') {
    return {
      type: state.me.context.type,
      customer: state.customer.entities[state.me.customers[0]],
    };
  }

  return {
    type: state.me.context.type,
    companyAgreement: selectedCompanyAgreement(state),
  };
};
