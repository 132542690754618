import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIfNeeded } from '../stores/allergy/actions';
import selectAllergies from '../selectors/allergy/selectAllergies';

import '../styles/components/spinner.scss';

class Allergy extends React.Component {
  componentDidMount() {
    this.props.getIfNeeded();
  }
  render() {
    return (
      <span>
        {this.props.allergy ? this.props.allergy.name : this.props.allergyId}
      </span>
    );
  }
}

Allergy.propTypes = {
  allergyId: PropTypes.string.isRequired,
};

export default connect(
  (state, ownProps) => {
    const allergies = selectAllergies(state, true);
    return {
      allergy: allergies.find(a => a.id === ownProps.allergyId),
    };
  },
  { getIfNeeded }
)(Allergy);
