import React, { useState } from 'react';

import Card from '../../Card';
import DatePicker from '../../DatePicker';
import Button from '../../Button';
import Alert from '../../Alert';

import PropTypes from 'prop-types';
import { isDateBefore } from '../../../utils/isBefore';
import { formatDateString } from '../../../utils/formatUserFrendlyDate';
import firstSubDate from '../../../utils/firstSubDate';
import moment from 'moment';

function CompanyAgreementStartDate(props) {
  const [newStartDate, setNewStartDate] = useState(
    props.companyAgreement?.startDate
      ? moment(props.companyAgreement.startDate)
      : moment(firstSubDate())
  );
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const [isDatePickerFocused, setIsDatePickerFocused] = useState(false);

  const firstAllowedStartDate = moment(firstSubDate()).set({
    hours: 0,
    minutes: 0,
  });

  const updateStartDate = () => {
    setSaving(true);
    setError(null);
    props
      .updateCompanyAgreement(props.companyAgreement.id, {
        startDate: newStartDate,
        discountType: props.companyAgreement.discountType,
        discountAmount: props.companyAgreement.employeeDiscount,
      })
      .catch(() => {
        setError(
          'Noe skjedde. Vennligst prøv igjen senere eller kontakt kundesupport.'
        );
      })
      .finally(() => setSaving(false));
  };

  return (
    <Card marginTop header={'Startdato på avtale'} headerExtraLarge>
      <div style={{ marginTop: 20 }}>
        {props.companyAgreement?.startDate && (
          <p>
            <i>Startdato:</i>{' '}
            {formatDateString(props.companyAgreement?.startDate)}
          </p>
        )}
        {!isDateBefore(props.companyAgreement?.startDate) && (
          <>
            <div style={{ marginBottom: '1em' }}>
              <h4>Startdato kan forsatt endres</h4>
              <div>Velg første levering</div>
              <DatePicker
                required
                placeholder=""
                date={newStartDate}
                isOutsideRange={day => {
                  return (
                    day.isBefore(firstAllowedStartDate) || day.isoWeekday() > 5
                  );
                }}
                onDateChange={newSubscriptionStartDate =>
                  setNewStartDate(newSubscriptionStartDate)
                }
                focused={isDatePickerFocused}
                onFocusChange={({ focused }) => setIsDatePickerFocused(focused)}
              />
            </div>
            <Button onClick={() => updateStartDate()} saving={saving}>
              Endre startdato
            </Button>
            {error && <Alert color="red">{error}</Alert>}
          </>
        )}
      </div>
    </Card>
  );
}

CompanyAgreementStartDate.propTypes = {
  updateCompanyAgreement: PropTypes.func.isRequired,
  companyAgreement: PropTypes.object.isRequired,
};

export default CompanyAgreementStartDate;
