import React from 'react';

import ContactPhoneContainer from './ContactPhoneContainer';
import styles from './contactPhone.module.scss';
import Spinner from '../../../../../components/Spinner';
import cn from 'classnames';

export default props => (
  <ContactPhoneContainer>
    {(contact, loading, error, success) => (
      <div className={styles.wrapper}>
        <div>
          <form
            className={styles.contactPhone}
            onSubmit={e => {
              e.stopPropagation();
              e.preventDefault();
              contact(
                e.target.phone.value,
                e.target.companyName.value,
                e.target.name.value
              );
            }}
          >
            <input
              className={styles.input}
              name="companyName"
              type="text"
              placeholder="Navn på bedriften"
            />
            <input
              className={styles.input}
              name="phone"
              type="tel"
              placeholder="Telefonnummer"
            />
            <button className={styles.button}>
              {loading ? (
                <Spinner />
              ) : (
                  <div className={cn('fa', 'fa-arrow-right')} />
                )}
            </button>
          </form>
          {error ? (
            <div
              style={{ marginTop: 5, fontStyle: 'italic', color: '#721c24' }}
            >
              {error}.
            </div>
          ) : success ? (
            <div style={{ marginTop: 5, fontStyle: 'italic', color: 'green' }}>
              Så fint at du er interessert! Vi kontakter deg så snart vi kan.
            </div>
          ) : null}
        </div>
      </div>
    )}
  </ContactPhoneContainer>
);
