export default customer => {
  const defaultCost = 62.5;
  const weeklyLimit = 5;

  if (customer.companyAgreement?.employeeDiscount) return 0;

  if (
    !customer.identity?.meta?.eligibleForDeliveryCost ||
    customer.companyAgreement?.eligibleForDeliveryCost
  )
    return 0;

  //TODO: Change the numberOfBoxWeekly to a live number
  const numberOfBoxWeekly = customer.deliveryLocation?.numberOfBoxWeekly || 0;

  return numberOfBoxWeekly / 5 > weeklyLimit ? 0 : defaultCost;
};
