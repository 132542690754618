import React from 'react';
import cn from 'classnames';
import Input from '../Input';
import styles from './textarea.module.css';

class TextArea extends React.Component {
  render() {
    const { value, onChange, ...otherProps } = this.props;
    return (
      <textarea
        className={cn(Input.className, styles.textarea)}
        value={value}
        onChange={e => onChange(e.target.value)}
        {...otherProps}
      />
    );
  }
}

export default TextArea;
