import _ from 'lodash';
import moment from 'moment';

const dayCount = subscription => {
  if (subscription.type === 'daily') {
    return subscription.data.dailyEntries.filter(de =>
      de.data.find(d => d.menuAmount > 0)
    ).length;
  }

  if (subscription.type === 'weekly') {
    const n = subscription.days
      ? subscription.days.filter(d => d !== '').length
      : 0;
    return subscription.data.entries.some(de => de.menuAmount > 0) ? n : 0;
  }

  return 0;
};

const isFullWeek = subscription => {
  if (!subscription) {
    return false;
  }

  return dayCount(subscription) === 5;
};

const extract = (prices, subscriptionType, fullWeek) => {
  const priceObject = prices.find(
    cp => cp.subscriptionTypeAlias === subscriptionType.alias
  );

  if (!priceObject) {
    return null;
  }

  const price = fullWeek ? priceObject.priceFullWeek : priceObject.price;

  if (price == null) {
    return null;
  }

  return price;
};

const getCustomPrice = (companyAgreement, user, subscriptionType, fullWeek) => {
  const customCompanyAgreementPrice =
    companyAgreement && companyAgreement.customPrices
      ? extract(companyAgreement.customPrices, subscriptionType, fullWeek)
      : null;

  const userPrice =
    user && user.customPrices
      ? extract(user.customPrices, subscriptionType, fullWeek)
      : null;

  if (customCompanyAgreementPrice == null && userPrice == null) {
    return null;
  }

  if (customCompanyAgreementPrice == null) {
    return userPrice;
  }

  if (userPrice == null) {
    return customCompanyAgreementPrice;
  }

  return userPrice > customCompanyAgreementPrice
    ? customCompanyAgreementPrice
    : userPrice;
};

const getSubscriptionPrice = (
  subscription = null,
  companyAgreement,
  subscriptionType,
  user = null
) => {
  if (!subscriptionType) {
    return 0;
  }

  const fullWeek = isFullWeek(subscription);

  // Get any custom prices
  const customPrice = getCustomPrice(
    companyAgreement,
    user,
    subscriptionType,
    fullWeek
  );

  var price = null;

  if (customPrice != null) {
    price = customPrice;
  } else if (subscription != null) {
    // Calculate price based on subscription
    if (subscriptionType.pricing && subscriptionType.pricing.length > 0) {
      var relevantPricing = subscriptionType.pricing.filter(x =>
        fullWeek ? x.type === 'PriceFullWeek' : x.type === 'Price'
      );
      var subscriptionPricing = _.orderBy(
        relevantPricing,
        'startDate',
        'desc'
      ).find(x => moment(x.startDate).isBefore(subscription.startDate));

      if (subscriptionPricing != null) {
        price = subscriptionPricing.price;
      }
    }
  }

  if (price == null) {
    price = fullWeek ? subscriptionType.priceFullWeek : subscriptionType.price;
  }

  return price;
};

export default getSubscriptionPrice;
