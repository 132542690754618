import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';

import upperCaseFirstLetter from '../../utils/upperCaseFirstLetter';

import selectAllergies from '../../selectors/allergy/selectAllergies';
import selectAllergiesMenu from '../../selectors/allergy/selectAllergiesMenu';
import { getIfNeeded } from '../../stores/allergy/actions';

import styles from './allergyPicker.module.css';

class AllergyPicker extends React.Component {
  getSelectedIds() {
    if (this.props.fullObject) return this.props.allergies.map(a => a.id);

    return this.props.allergies;
  }

  allergiesObject(allergies) {
    if (!this.props.fullObject) return allergies;
    return this.props.menu
      ? allergies.map(aId => this.getMenuAllergies().find(a => a.id === aId))
      : allergies.map(aId => this.getAllAllergies().find(a => a.id === aId));
  }

  getAllAllergies() {
    if (!this.props.fullObject) return this.props.allAllergies;

    return this.props.allergies.reduce((allergies, allergy) => {
      if (allergies.findIndex(a => a.id === allergy.id) === -1) {
        allergies.push(allergy);
      }
      return allergies;
    }, this.props.allAllergies);
  }

  getMenuAllergies() {
    if (!this.props.fullObject) return this.props.menuAllergies;

    return this.props.allergies.reduce((allergies, allergy) => {
      if (allergies.findIndex(a => a.id === allergy.id) === -1) {
        allergies.push(allergy);
      }
      return allergies;
    }, this.props.menuAllergies);
  }

  buildAllergies() {
    var allergyArray = this.props.menu
      ? this.getMenuAllergies()
      : this.getAllAllergies();
    return allergyArray.map((allergy, i) => {
      return (
        <div
          key={i}
          className={cn(styles.allergy, {
            [styles.selected]: this.getSelectedIds().indexOf(allergy.id) !== -1,
          })}
          onClick={() => {
            const oldSelectedIds = this.getSelectedIds();
            const index = oldSelectedIds.indexOf(allergy.id);
            const allergies = [...oldSelectedIds];
            if (index !== -1) {
              allergies.splice(index, 1);
            } else {
              allergies.push(allergy.id);
            }

            this.props.onChange(this.allergiesObject(allergies));
          }}
        >
          {upperCaseFirstLetter(allergy.name)}
        </div>
      );
    });
  }
  componentDidMount() {
    this.props.getIfNeeded();
  }
  render() {
    return (
      <div>
        {this.props.label ? (
          <div className={styles.title}>{this.props.label}</div>
        ) : null}
        <div className={styles.wrapper}>{this.buildAllergies()}</div>
      </div>
    );
  }
}

AllergyPicker.defaultProps = {
  fullObject: false,
  admin: false,
  menu: false,
};

AllergyPicker.propTypes = {
  allergies: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  fullObject: PropTypes.bool,
  admin: PropTypes.bool,
  menu: PropTypes.bool,
};

export default connect(
  (state, ownProps) => ({
    allAllergies: selectAllergies(state, ownProps.admin),
    menuAllergies: selectAllergiesMenu(state, ownProps.admin),
  }),
  {
    getIfNeeded,
  }
)(AllergyPicker);
