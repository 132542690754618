import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';
import selectWeekMetaKey from '../../selectors/order/selectWeekMetaKey';
import selectMonthMetaKey from '../../selectors/order/selectMonthMetaKey';

import types from './types';

export const getCustomerChoicesForWeekIfNeeded = (customerId, week) => (
  dispatch,
  getState
) => {
  const key = selectWeekMetaKey(customerId, week);

  if (!shouldFetch(getState().choice.meta[key])) return;

  dispatch({
    type: types.GET_CUSTOMER_CHOICES_START,
    payload: {
      key,
    },
  });

  return fetch(`/customers/${customerId}/choices`, 'GET', {
    start: week
      .clone()
      .startOf('week')
      .add(-1, 'day')
      .format('YYYY-MM-DD'),
    end: week
      .clone()
      .endOf('week')
      .format('YYYY-MM-DD'),
  })
    .then(response => {
      dispatch({
        type: types.GET_CUSTOMER_CHOICES_SUCCESS,
        payload: {
          entities: response.data.reduce((choices, choice) => {
            choices[choice.id] = choice;
            return choices;
          }, {}),
          key,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_CUSTOMER_CHOICES_ERROR,
        payload: {
          error,
          key,
        },
      });
    });
};

export const getCustomerChoicesForMonthIfNeeded = (customerId, month) => (
  dispatch,
  getState
) => {
  const key = selectMonthMetaKey(customerId, month);

  if (!shouldFetch(getState().choice.meta[key])) return;

  dispatch({
    type: types.GET_CUSTOMER_CHOICES_START,
    payload: {
      key,
    },
  });

  return fetch(`/customers/${customerId}/choices`, 'GET', {
    start: month
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD'),
    end: month
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD'),
  })
    .then(response => {
      dispatch({
        type: types.GET_CUSTOMER_CHOICES_SUCCESS,
        payload: {
          entities: response.data.reduce((choices, choice) => {
            choices[choice.id] = choice;
            return choices;
          }, {}),
          key,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_CUSTOMER_CHOICES_ERROR,
        payload: {
          error,
          key,
        },
      });
    });
};

export const cancelCustomerChoices = (
  customerId,
  subscriptionKey,
  cancelDates
) => dispatch => {
  const keys = [];

  cancelDates.forEach(date => {
    const key = selectMonthMetaKey(customerId, date.clone());
    if (!keys.includes(key)) {
      keys.push(key);
    }
  });

  dispatch({
    type: types.CANCEL_CHOICES_START,
    payload: {
      keys,
    },
  });

  return fetch(
    '/customers/' +
      customerId +
      '/subscriptions/' +
      subscriptionKey +
      '/cancel',
    'POST',
    { dates: cancelDates }
  )
    .then(response => {
      dispatch({
        type: types.CANCEL_CHOICES_SUCCESS,
        payload: {
          choices: response.data,
          keys,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.CANCEL_CHOICES_ERROR,
        payload: {
          error,
          keys,
        },
      });
    });
};

export const cancelCustomerChoicesRange = (
  companyAgreementId,
  customerIds,
  // subscriptionKey,
  fromDate,
  toDate
) => dispatch => {
  return fetch(
    'company-agreement/' + companyAgreementId + '/customers/cancel',
    'POST',
    { fromDate, toDate, customerIds }
  );
};

export const reverseCancelCustomerChoicesRange = (
  companyAgreementId,
  customerId,
  subscriptionKey,
  fromDate,
  toDate
) => dispatch => {
  return fetch(
    'company-agreement/' +
      companyAgreementId +
      '/customers/' +
      customerId +
      '/subscriptions/' +
      subscriptionKey +
      '/reverse-cancel',
    'POST',
    { fromDate, toDate }
  );
};

export const updateCustomerChoiceForDay = (
  customerId,
  date,
  data
) => dispatch => {
  const key = selectMonthMetaKey(customerId, date);

  dispatch({
    type: types.UPDATE_CHOICE_START,
    payload: {
      key,
    },
  });

  return fetch('/customers/' + customerId + '/choices', 'POST', data)
    .then(response => {
      dispatch({
        type: types.UPDATE_CHOICE_SUCCESS,
        payload: {
          choice: response.data,
          key,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.UPDATE_CHOICE_ERROR,
        payload: {
          error,
          key,
        },
      });
    });
};
