import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../Button';
import { Modal, Actions } from '../../Modal';
import { Separator } from '../../Card';
import Link from '../../Link';
import { Input } from '../../Form';

const DeactivateForm = props => {
  const [reason, setReason] = useState({
    value: '',
    valid: false,
  });

  return (
    <React.Fragment>
      <h2>Stopp levering</h2>
      <div>
        <p>
          Skal du på ferie eller avslutte leveringer midlertidig? Det er enkelt
          å avbestille flere dager i Lunsjkalenderen
        </p>
      </div>
      <Input
        textarea
        label="Hva er grunnen til at du ønsker å stoppe dine leveringer? *"
        value={reason.value}
        onChange={(value, valid) => {
          setReason({
            value,
            valid,
          });
        }}
        validate={string => string.length > 2}
        errorMessage="Begrunnelsen må være minst 3 bokstaver lang"
      />
      <Separator />
      <Actions>
        <Button
          onClick={() => props.onDeactivate(reason.value)}
          disabled={!reason.valid}
          saving={props.isDeactivating}
        >
          Stopp levering
        </Button>
        <Button onClick={() => props.onClose()} color="gray">
          Lukk
        </Button>
      </Actions>
    </React.Fragment>
  );
};

const ConfirmDialog = props => (
  <React.Fragment>
    <h2>Stopp levering</h2>
    <p>Er du sikker at du vil stoppe dine leveringer?</p>
    <Separator />
    <Actions>
      <Button onClick={() => props.onClose()}>Lukk</Button>
      <Link onClick={() => props.onDeactivate()}>Stopp levering</Link>
    </Actions>
  </React.Fragment>
);

const DeactivateModal = props => {
  const [deactivateStep2, setDeactivateStep2] = useState(true);

  return (
    <Modal open={props.open}>
      {!deactivateStep2 ? (
        <ConfirmDialog
          {...props}
          onDeactivate={() => setDeactivateStep2(true)}
        />
      ) : (
        <DeactivateForm {...props} />
      )}
    </Modal>
  );
};

export default connect(state => ({ code: state.me.identity.code }))(
  DeactivateModal
);
