import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';

import types from './types';
import { getDeliveryAddressKey } from './selectors';

export const getIfNeeded = deliveryAddress => (dispatch, getState) => {
  const key = getDeliveryAddressKey(deliveryAddress);
  if (!shouldFetch(getState().deliveryLocation.meta[key])) return;

  dispatch({
    type: types.GET_START,
    payload: {
      key,
    },
  });

  return fetch('/delivery-locations', 'POST', deliveryAddress)
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          key,
          deliveryLocations: response.data,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};

export const update = (id, data) => dispatch =>
  fetch('/delivery-locations/' + id, 'POST', data).then(response => {
    dispatch({
      type: types.UPDATE_SUCCESS,
      payload: response.data,
    });
  });
