import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AuthHelper from '../../services/AuthHelper';
import Button from '../../components/Button';

import styles from './Login/login.module.css';

class Logout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    AuthHelper.logout();
  }

  render() {
    return (
      <div>
        <div className={styles.overlay}>
          <div className={styles.login}>
            <h2>Du har blitt logget ut</h2>
            <Button onClick={() => this.props.history.push('/')}>
              Til forsiden
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(() => ({}), {})(Logout));
