import React from 'react';

import { Helmet } from 'react-helmet';

import BlogPostSmall from '../components/BlogPostSmall';
import Container from '../../../../components/Container';
import { Row, Col } from '../../../../components/Grid';
import Alert from '../../../../components/Alert';
import Header from '../../../../components/Header';
import Loader from '../../../../components/Loader';

const Latest = ({ sanityData, history, match, loading, error }) => (
  <React.Fragment>
    <Helmet>
      <title>Lunsjkollektivet - Blogg</title>
    </Helmet>
    <Header />
    {loading ? (
      <Loader />
    ) : error ? (
      <Alert>Noe Skjedde</Alert>
    ) : (
          <Container withPadding width={900}>
            <Row>
              {sanityData.map((post, index) => (
                <Col key={index} m={4}>
                  <BlogPostSmall
                    post={post}
                    baseUrl={match.url}
                    onClick={() => history.push(match.url + '/' + post._id)}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        )}
  </React.Fragment>
);

export default Latest;
