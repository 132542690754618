import React from 'react';
import { connect } from 'react-redux';

import { selectCustomerSubscription } from '../../../../stores/subscription/selectors';

import moment from 'moment';

import styles from '../styles.module.css';
import selectAllSubscriptionTypes from '../../../../selectors/subscriptionType/selectAll';

const ProfileOverview = props => {
  const deliveryLocation = props.customer.companyAgreement
    ? props.customer.companyAgreement.deliveryLocation
    : props.customer.deliveryLocation;

  let content = 'Ingen fast levering';

  if (props.subscription && props.subscription.type === 'daily') {
    const count = props.subscription.data.dailyEntries.filter(de =>
      de.data.find(d => d.menuAmount > 0)
    ).length;
    content = (
      <div>
        <span>Fast {`${count} ${count === 1 ? 'ukedag' : 'ukedager'}`}</span>
      </div>
    );
  } else if (
    props.subscription &&
    props.subscription.days &&
    props.subscription.days.length > 0 &&
    props.subscription.data.entries.length > 0 &&
    (!props.subscription.endDate ||
      moment(props.subscription.endDate)
        .add(1, 'days')
        .isAfter(moment()))
  ) {
    content = props.subscription.data.entries
      .filter(subscription => subscription.menuAmount > 0)
      .map(subscription => {
        const type = props.subscriptionTypes.find(
          type => type.alias === subscription.type
        );
        if (type) {
          return (
            <div key={type.id}>
              {type.name} fast {props.subscription.days.length}{' '}
              {props.subscription.days.length > 1 ? 'ukedager' : 'ukedag'}
            </div>
          );
        }
        return null;
      });
  }

  return (
    <div className={styles.profileBlock}>
      <div>
        <strong>{deliveryLocation.address.addressLine}</strong>
      </div>
      <div>{content}</div>
    </div>
  );
};

export default connect((state, ownProps) => ({
  subscriptionTypes: selectAllSubscriptionTypes(state),
  subscription: selectCustomerSubscription(state, ownProps.customer.id),
}))(ProfileOverview);
