import React from 'react';
import store from 'store';

import styles from './styles.module.css';
import Button from '../Button';
import Link from '../Link';

const timeoutMs = 1000 * 60 * 60 * 24 * 180;

export default class CookieConsent extends React.Component {
  state = { closed: false };

  componentDidMount() {
    let closed = false;
    try {
      const stored = store.get('cookiesAcceptedAt');

      if (
        stored &&
        !isNaN(parseInt(stored, 10)) &&
        Date.now() < parseInt(stored, 10) + timeoutMs
      ) {
        closed = true;
      }
    } catch (e) {}

    this.setState({ closed });
  }

  close = () => {
    try {
      store.set('cookiesAcceptedAt', Date.now());
    } catch (e) {}

    this.setState({ closed: true });
  };

  render() {
    if (this.state.closed) {
      return <span />;
    }

    return (
      <div className={styles.cookieConsent}>
        <div className={styles.bigger}>
          <span>
            Denne nettsiden bruker informasjonkapsler for å kunne tilby deg best
            mulig brukeropplevelse.{' '}
            <Link gray to="/cookies">
              Les mer
            </Link>
          </span>
        </div>
        <div>
          <Button onClick={this.close}>Skjønner</Button>
        </div>
      </div>
    );
  }
}
