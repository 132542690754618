import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Link from '../../../../../../components/Link';

import WeeklyCompanyLunchOverview from '../../../../../../components/CompanySettings/WeeklyCompanyLunchOverview';
import { selectedCompanyAgreement } from '../../../../../../stores/me/selectors';

import CompanyBackground from '../../../components/CompanyBackground';

class Lunch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      week: moment(),
    };
  }

  componentWillUnmount() {
    if (this.state.modified) {
      this.props.choices.actions.invalidate();
    }
  }

  render() {
    return (
      <CompanyBackground title="Lunsjkalender for bedrift"
        info={<div>
          Her kan du enkelt velge mellom eller avbestille de ulike
          lunsjalternativene fra dag til dag. Ønsker du å endre fast
        abonnement, gjør du det{' '}
          <strong>
            <Link gray to="https://company.lunsjkollektivet.no/settings">
              her.
          </Link>
          </strong>
        </div>}>
        <WeeklyCompanyLunchOverview
          company
          companyAgreementId={this.props.companyAgreementId}
        />
      </CompanyBackground>
    );
  }
}

export default connect(state => ({
  companyAgreementId: selectedCompanyAgreement(state).id,
}))(Lunch);
