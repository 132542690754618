import selectMetaKey from './selectMetaKey';
import selectMeta from '../selectMeta';

export default (state, companyAgreementId, start = 0, limit = 20) => {
  const meta = selectMeta(
    state.invoice.meta[selectMetaKey(start, limit, { companyAgreementId })]
  );

  return meta;
};
