import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';

import types from './types';

export const getIfNeeded = (search = '') => (dispatch, getState) => {
  if (!shouldFetch(getState().address[search])) return;

  dispatch({
    type: types.GET_START,
    payload: {
      search,
    },
  });

  return fetch('/address/search/active', 'GET', {
    q: search,
  })
    .then(response => {
      dispatch({
        type: types.GET_ACTIVE_SUCCESS,
        payload: {
          search,
          addresses: response.data,
        },
      });
    })
    .then(() => {
      fetch('/address/search', 'GET', {
        q: search,
      }).then(response => {
        dispatch({
          type: types.GET_SUCCESS,
          payload: {
            search,
            addresses: response.data,
          },
        })
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          search,
          error,
        },
      });
    });
};
