import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import styles from './searchableInput.module.css';

class DropDown extends React.Component {
  render() {
    const Row = this.props.rowComponent;
    return (
      <div
        className={cn(styles.dropDown, {
          [styles.show]: this.props.focused,
          [styles.absolute]: this.props.absolute,
        })}
      >
        {!this.props.triggerWhenEmpty && this.props.searchText === '' ? (
          !this.props.withoutPrompt && <div className={styles.dropDownItem}>
            <div>
              <strong>Vennligst skriv noe</strong>
            </div>
          </div>
        ) : (this.props.meta !== null && this.props.meta.loading) ||
        this.props.search !== this.props.searchText ? (
          <div className={styles.dropDownItem}>
            <div>
              <strong>Søker...</strong>
            </div>
          </div>
        ) : this.props.entities.length === 0 ? (
          <div className={styles.dropDownItem}>
            <div>
              <strong>Vi fant ingenting i databasen.</strong>
            </div>
          </div>
        ) : (
          this.props.entities.map((entity, i) => (
            <div
              className={styles.dropDownItem}
              key={i}
              onClick={e => {
                this.props.changeFocus(false);
                this.props.onChange(entity);
                e.stopPropagation();
              }}
            >
              <Row entity={entity} index={i} />
            </div>
          ))
        )}
      </div>
    );
  }
}

export default connect((state, ownProps) => {
  return {
    entities: ownProps.selector(state, ownProps.search),
    meta: ownProps.metaSelector
      ? ownProps.metaSelector(state, ownProps.search)
      : null,
  };
})(DropDown);
