import React from 'react';
import { connect } from 'react-redux';
import firstSubDate from '../../../utils/firstSubDate';

import Loader from '../../Loader';
import Alert from '../../Alert';
import WeekPicker from '../../WeekPicker';
import WeekLunchOverview from './WeeklyCompanyLunchOverview';

/**
 * SubscriptionType Store functions
 */
import {
  getAllIfNeeded as getAllSubscriptionTypesIfNeeded,
  getForCompanyAgreement as getSubscriptionTypesForCompanyAgreement,
} from '../../../stores/subscriptionType/actions';
import selectAllSubscriptionTypes from '../../../selectors/subscriptionType/selectAll';

import selectCompanyAgreementSubscriptionTypes from '../../../selectors/subscriptionType/selectForCompanyAgreement';

/**
 * Menu Store functions
 */
import { getForWeekIfNeeded as getMenusForWeekIfNeeded } from '../../../stores/menu/actions';
import {
  selectMenusForWeek,
  selectMenusForWeekMeta,
} from '../../../stores/menu/selectors';

/**
 * No Delivery Day Store functions
 */
import { getForWeekIfNeeded as getNoDeliveryDayForWeekIfNeeded } from '../../../stores/noDeliveryDay/actions';
import {
  selectNoDeliveryDaysForWeek,
  selectNoDeliveryDaysForWeekMeta,
} from '../../../stores/noDeliveryDay/selectors';

/**
 * Order Store functions
 */
import { getCompanyAgreementOrdersForWeekIfNeeded } from '../../../stores/order/actions';
import {
  selectCompanyAgreementOrdersForWeek,
  selectCompanyAgreementOrdersForWeekMeta,
} from '../../../stores/order/selectors';

/**
 * Subscription Store functions
 */
import { getIfNeeded as getCompanySubscriptionIfNeeded } from '../../../stores/companySubscription/actions';
import {
  selectCompanyAgreementSubscription,
  selectCompanyAgreementSubscriptionMeta,
} from '../../../stores/companySubscription/selectors';

/**
 * Single Orders Store functions
 */
import {
  getForWeekForCompanyAgreementIfNeeded as getAllSingleOrdersForWeekForCompanyAgreementIfNeeded,
  updateSingleOrder,
} from '../../../stores/singleOrder/actions';
import selectSingleOrdersForWeek from '../../../selectors/singleOrder/selectSingleOrdersForWeek';
import selectSingleOrdersForWeekMeta from '../../../selectors/singleOrder/selectSingleOrdersMetaForWeek';

import { selectCompanyAgreement } from '../../../stores/companyAgreement/selectors';
import {
  selectAllMeta as selectAllSubscriptionTypesMeta,
  selectCompanyAgreementMeta as selectCompanyAgreementSubscriptionTypeMeta,
} from '../../../stores/subscriptionType/selectors';

class WeekLunchOverviewContainer extends React.Component {
  state = {
    valuesPerDay: {},
    saving: {},
    success: {},
  };

  componentDidMount() {
    this.props.getAllSubscriptionTypesIfNeeded();
    this.props.getSubscriptionTypesForCompanyAgreement(
      this.props.companyAgreementId
    );
    this.props.getCompanySubscriptionIfNeeded(this.props.companyAgreementId);
    this.props.getMenusForWeekIfNeeded(this.props.week);
    this.props.getNoDeliveryDayForWeekIfNeeded(this.props.week);

    if (this.shouldFetchSingleOrdersForWeek()) {
      this.props.getAllSingleOrdersForWeekForCompanyAgreementIfNeeded(
        this.props.companyAgreementId,
        this.props.week
      );
    }

    if (this.shouldFetchOrdersForWeek()) {
      this.props.getCompanyAgreementOrdersForWeekIfNeeded(
        this.props.companyAgreementId,
        this.props.week
      );
    }
  }

  componentDidUpdate() {
    this.props.getAllSubscriptionTypesIfNeeded();
    this.props.getSubscriptionTypesForCompanyAgreement(
      this.props.companyAgreementId
    );
    this.props.getCompanySubscriptionIfNeeded(this.props.companyAgreementId);
    this.props.getMenusForWeekIfNeeded(this.props.week);
    this.props.getNoDeliveryDayForWeekIfNeeded(this.props.week);

    if (this.shouldFetchSingleOrdersForWeek()) {
      this.props.getAllSingleOrdersForWeekForCompanyAgreementIfNeeded(
        this.props.companyAgreementId,
        this.props.week
      );
    }

    if (this.shouldFetchOrdersForWeek()) {
      this.props.getCompanyAgreementOrdersForWeekIfNeeded(
        this.props.companyAgreementId,
        this.props.week
      );
    }
  }

  shouldFetchSingleOrdersForWeek() {
    return firstSubDate()
      .add(-1, 'day')
      .isSameOrBefore(this.props.week.clone().endOf('week'));
  }

  shouldFetchOrdersForWeek() {
    return firstSubDate()
      .add(-1, 'day')
      .isSameOrAfter(this.props.week.clone().startOf('week'));
  }

  error() {
    return (
      this.props.subscriptionEntriesMeta.error ||
      this.props.menusMeta.error ||
      this.props.noDeliveryDaysMeta.error ||
      this.props.subscriptionTypesMeta.error ||
      this.props.subscriptionTypesCompanyAgreementMeta.error ||
      (this.shouldFetchOrdersForWeek() && this.props.orders.error) ||
      (this.shouldFetchSingleOrdersForWeek() &&
        this.props.singleOrdersMeta.error)
    );
  }

  loading() {
    return (
      this.props.subscriptionEntriesMeta.loading ||
      this.props.menusMeta.loading ||
      this.props.noDeliveryDaysMeta.loading ||
      this.props.subscriptionTypesMeta.loading ||
      this.props.subscriptionTypesCompanyAgreementMeta.loading ||
      (this.shouldFetchOrdersForWeek() && this.props.orders.loading) ||
      (this.shouldFetchSingleOrdersForWeek() &&
        this.props.singleOrdersMeta.loading)
    );
  }

  updateSingleOrder = (date, values) => {
    const dayString = date.format('DD-MM-YYYY');
    this.setState({
      saving: {
        ...this.state.saving,
        [dayString]: true,
      },
    });

    return this.props
      .updateSingleOrder(this.props.companyAgreementId, date, {
        details: values
          .filter(v => v.menuAmount > 0)
          .map(value => ({
            amount: value.menuAmount,
            menuType: value.type.alias,
            allergyIds: value.allergies.map(a => a.id),
          })),
      })
      .then(() => {
        let valuesPerDay = { ...this.state.valuesPerDay };
        delete valuesPerDay[dayString];

        this.setState({
          saving: {
            ...this.state.saving,
            [dayString]: false,
          },
          success: {
            ...this.state.success,
            [dayString]: true,
          },
          valuesPerDay,
        });

        setTimeout(() => {
          this.setState({
            success: {
              ...this.state.success,
              [dayString]: false,
            },
          });
        }, 2000);
      });
  };

  buildContent() {
    if (this.error()) {
      return (
        <Alert color="red">
          Noe skjedde når vi lastet inn siden. Vennligst prøv igjen senere
        </Alert>
      );
    }

    if (this.loading()) {
      return <Loader />;
    }

    return (
      <WeekLunchOverview
        weekend={this.props.weekend}
        subscriptionEntries={this.props.subscriptionEntries}
        subscriptionTypes={this.props.subscriptionTypes}
        subscriptionTypesCompanyAgreement={
          this.props.subscriptionTypesCompanyAgreement
        }
        companyAgreement={this.props.companyAgreement}
        menus={this.props.menus}
        noDeliveryDays={this.props.noDeliveryDays}
        singleOrders={this.props.singleOrders}
        orders={this.props.orders}
        week={this.props.week}
        updateState={(newState, clb) => this.setState(newState, clb)}
        valuesPerDay={this.state.valuesPerDay}
        saving={this.state.saving}
        success={this.state.success}
        updateSingleOrder={this.updateSingleOrder}
        onWeekChange={week => this.props.onWeekChange(week)}
        admin={this.props.admin}
        company={this.props.company}
      />
    );
  }

  render() {
    return (
      <div>
        {!this.props.subscriptionEntries ||
          this.props.subscriptionEntries.length === 0 ? (
            ''
          ) : (
            <WeekPicker
              week={this.props.week}
              onChange={week => this.props.onWeekChange(week)}
              showWeeks={6}
            />
          )}
        {this.buildContent()}
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      subscriptionTypes: selectAllSubscriptionTypes(state),
      subscriptionTypesCompanyAgreement: selectCompanyAgreementSubscriptionTypes(
        state
      ),
      subscriptionTypesMeta: selectAllSubscriptionTypesMeta(state),
      subscriptionTypesCompanyAgreementMeta: selectCompanyAgreementSubscriptionTypeMeta(
        state
      ),
      subscriptionEntries: selectCompanyAgreementSubscription(
        state,
        ownProps.companyAgreementId
      ),
      subscriptionEntriesMeta: selectCompanyAgreementSubscriptionMeta(
        state,
        ownProps.companyAgreementId
      ),
      companyAgreement: selectCompanyAgreement(
        state,
        ownProps.companyAgreementId
      ),
      menus: selectMenusForWeek(state, ownProps.week),
      menusMeta: selectMenusForWeekMeta(state, ownProps.week),
      noDeliveryDays: selectNoDeliveryDaysForWeek(state, ownProps.week),
      noDeliveryDaysMeta: selectNoDeliveryDaysForWeekMeta(state, ownProps.week),
      singleOrders: selectSingleOrdersForWeek(
        state,
        ownProps.companyAgreementId,
        ownProps.week
      ),
      singleOrdersMeta: selectSingleOrdersForWeekMeta(
        state,
        ownProps.companyAgreementId,
        ownProps.week
      ),
      orders: selectCompanyAgreementOrdersForWeek(
        state,
        ownProps.companyAgreementId,
        ownProps.week
      ),
      ordersMeta: selectCompanyAgreementOrdersForWeekMeta(
        state,
        ownProps.companyAgreementId,
        ownProps.week
      ),
    };
  },
  {
    getCompanySubscriptionIfNeeded,
    getAllSubscriptionTypesIfNeeded,
    getSubscriptionTypesForCompanyAgreement,
    getMenusForWeekIfNeeded,
    getNoDeliveryDayForWeekIfNeeded,
    getAllSingleOrdersForWeekForCompanyAgreementIfNeeded,
    getCompanyAgreementOrdersForWeekIfNeeded,
    updateSingleOrder,
  }
)(WeekLunchOverviewContainer);
