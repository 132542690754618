import React from 'react';
import Container from '../Container';
import { Link } from 'react-router-dom';

import styles from './footer.module.css';

export default () => (
  <div className={styles.footerWrapper}>
    <Container color="beige" className={styles.footer} width={900}>
      <div className={styles.footerContent}>
        <div className={styles.footerLogos}>
          <img
            src="/images/logos/lunsjkollektivet.svg"
            alt="Lunsjkollektivet"
            height={90}
          />
        </div>
        <p>
          Ta gjerne kontakt på chat eller nå oss på:
          <br />
          <strong>
            <a href="tel:72909005">729 09 005</a>
          </strong>{' '}
          /{' '}
          <strong>
            <a href="mailto:hei@lunsjkollektivet.no">hei@lunsjkollektivet.no</a>
          </strong>
        </p>
        <p>
          <Link to="/vilkar" className={styles.link}>
            Vilkår
          </Link>{' '}
          -{' '}
          <Link to="/personvern" className={styles.link}>
            Personvern
          </Link>
        </p>
        <p>
          <Link
            to="/blog/778c1923-91ca-4f06-9bfe-a466eb26cb9c"
            className={styles.link}
          >
            Tiltak mot koronaviruset
          </Link>
        </p>
        <p className={styles.social}>
          <a href="https://facebook.com/lunsjk0llektivet/">
            <i className="fa fa-facebook" aria-hidden="true" />
          </a>
          <a href="https://instagram.com/lunsjkollektivet/">
            <i className="fa fa-instagram" aria-hidden="true" />
          </a>
        </p>
      </div>
    </Container>
  </div>
);
