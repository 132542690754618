import React from 'react';
import { OptionGroup, OptionBox } from '../Form';
import Button from '../Button';
import Card from '../Card';
import AllergyPicker from '../AllergyPicker';
import Link from '../Link';
import cn from 'classnames';
import styles from './day.module.css';

class NewAllergyOrder extends React.Component {
  state = { type: null, allergies: [] };

  render() {
    return (
      <Card color="gray" header="Allergitilpasset">
        <OptionGroup>
          {this.props.subscriptionTypes.map(type => (
            <OptionBox
              key={type.id}
              checked={this.state.type && this.state.type.id === type.id}
              onClick={() => this.setState({ type })}
              label={type.name}
            />
          ))}
        </OptionGroup>
        <AllergyPicker
          label={<strong>Allergier</strong>}
          allergies={this.state.allergies}
          onChange={allergies => this.setState({ allergies })}
          admin={this.props.admin}
          fullObject={this.props.fullAllergyObject}
        />
        <div className={cn(styles.actions)}>
          <Button
            onClick={() =>
              this.props.onAdd(this.state.type, this.state.allergies)
            }
            disabled={!this.state.type || this.state.allergies.length === 0}
          >
            Legg til
          </Button>
          <Link onClick={() => this.props.onClose()}>Avbryt</Link>
        </div>
      </Card>
    );
  }
}

export default NewAllergyOrder;
