export default (state, id) => {
  const meta = state.invoice.meta[String(id)];

  if (!meta) {
    return {
      loading: true,
      error: false,
      loadedAt: null,
      ids: [],
    };
  }

  return meta;
};
