import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '../Button';
import Link from '../Link';

import styles from './day.module.css';

import getSumForDay from './getSumForDay';
import Header from './components/Header';

import Day from './components/Day';
import LunchBoxOverview from './components/LunchBoxOverview';
import Footer from './components/Footer';
import NewAllergyOrder from './NewAllergyOrder';
import SubscriptionTypeService from '../../services/SubscriptionTypeService';
import { isPastOrderDate } from '../../utils/lunchOverviewUtils';
import getSubscriptionPrice from '../../utils/subscriptionPrice';

class DayOrder extends React.Component {
  state = {
    creatingNew: false,
    collapsed: true,
  };

  getTotal = () => {
    return getSumForDay(
      this.props.subscription,
      this.props.companyAgreement,
      this.props.values,
      this.props.hasFullWeekDiscount,
      this.props.enablePriceSplitting,
      this.props.deliveryPrice
    );
  };

  getTotalAmount = () => {
    return this.props.values.reduce(
      (total, value) => total + value.menuAmount,
      0
    );
  };

  avbestill = () => {
    this.props.onCancelOrder();
  };

  isRatingEnabled = menu =>
    !!(
      !this.props.admin &&
      this.props.enableRating &&
      menu &&
      this.props.day &&
      this.props.day.isSameOrBefore(moment(), 'day')
    );

  onChange = (group, value) => {
    if (!this.props.autoSave && this.props.saving) return;
    this.props.onChange(
      group.types.map((type, index) => {
        return {
          menuAmount: index === 0 ? value : 0,
          allergies: group.allergies,
          type,
        };
      })
    );
  };

  buildLunchBoxOverview = (group, disabled, withPicture = false) => {
    const menu = group.meal;
    const menuRating = menu ? this.props.ratings[menu.menuId] : null;
    const savingRating = menu ? this.props.savingRating[menu.menuId] : false;

    const price = getSubscriptionPrice(
      null,
      this.props.companyAgreement,
      group.types[0]
    );
    return (
      <LunchBoxOverview
        key={group.key}
        subscriptionTypes={group.types}
        allergies={group.allergies}
        amount={group.amount}
        price={price}
        disabled={disabled}
        onChange={value => this.onChange(group, value)}
        menu={menu}
        companyAgreement={this.props.companyAgreement}
        enableRating={this.isRatingEnabled(menu)}
        rating={menuRating}
        savingRating={savingRating}
        onSubmitRating={rating =>
          this.props.onSubmitRating(menu.menuId, rating)
        }
        withPicture={withPicture}
        userAllergies={this.props.userAllergies}
        company={this.props.company}
      />
    );
  };

  getSubscriptionTypes = () => {
    return SubscriptionTypeService.getEnabledSubscriptionTypes(
      this.props.subscriptionTypes,
      this.props.companyAgreement,
      this.props.subscriptionTypeUser,
      this.props.day
        ? this.props.day
            .clone()
            .locale('en')
            .format('dddd')
            .toLowerCase()
        : this.props.weekDayString
        ? this.props.weekDayString
        : null,
      this.props.deliveryLocation
        ? this.props.deliveryLocation.address.postalCode.kitchenId
        : null,
      this.props.subscriptionTypesCompanyAgreement
    );
  };

  getContent = isDisabled => {
    const totalAmount = this.getTotalAmount();

    if (isDisabled && totalAmount === 0) return null;

    let groups = {};

    let subscriptionTypes = this.props.subscriptionTypes;

    if (!isDisabled) {
      subscriptionTypes = this.getSubscriptionTypes();
    }

    subscriptionTypes.forEach(type => {
      const meal = this.props.menus[type.id];
      let values = this.props.values.filter(
        v => v.type && v.type.id === type.id
      );
      if (values.filter(v => v.allergies.length === 0).length === 0) {
        values.push({
          allergies: [],
          menuAmount: 0,
        });
      }
      values.forEach(value => {
        const allergies = Array.isArray(value.allergies) ? value.allergies : [];
        const key = `${!meal ? type.id : meal.id}_${type.onlyAdmin}_${
          type.isExtra ? 'isExtra' : 'isNotExtra'
        }_${
          isPastOrderDate(
            this.props.day,
            this.props.admin,
            type,
            this.props.companySubscription
          )
            ? 'locked'
            : 'notLocked'
        }_${allergies.map(a => JSON.stringify(a)).join('-')}`;

        if (allergies.length > 0 && value.menuAmount === 0) {
          return;
        }

        if (!groups[key]) {
          groups[key] = {
            types: [],
            meal,
            onlyAdmin: type.onlyAdmin,
            isExtra: type.type !== 'box',
            locked: isPastOrderDate(
              this.props.day,
              this.props.admin,
              type,
              this.props.companySubscription
            ),
            allergies,
            amount: 0,
            key,
          };
        }

        groups[key].types.push(type);
        groups[key].amount += value.menuAmount;
      });
    });

    groups = Object.values(groups).map(group => group);

    return (
      <div>
        <div className={styles.dayWrapper}>
          {Object.values(groups)
            .filter(
              group =>
                (!isDisabled &&
                  (this.props.admin || !group.onlyAdmin || group.amount > 0)) ||
                group.amount > 0
            )
            .filter(a => !a.isExtra || !a.types[0].imagePath || isDisabled)
            .sort((a, b) =>
              a.isExtra && a.types[0].imagePath ? 1 : b.isExtra ? -1 : 0
            )
            .map(group =>
              this.buildLunchBoxOverview(
                group,
                isDisabled ||
                  group.locked ||
                  (!this.props.admin && group.onlyAdmin)
              )
            )}
          <div className={styles.row}>
            {Object.values(groups)
              .filter(
                group =>
                  (!isDisabled &&
                    (this.props.admin ||
                      !group.onlyAdmin ||
                      group.amount > 0)) ||
                  group.amount > 0
              )
              .filter(a => a.isExtra && a.types[0].imagePath && !isDisabled)
              .map(group =>
                this.buildLunchBoxOverview(
                  group,
                  isDisabled ||
                    group.locked ||
                    (!this.props.admin && group.onlyAdmin)
                )
              )}
          </div>
        </div>
        {isDisabled || !this.props.enableAllergySelection ? null : this.state
            .creatingNew ? (
          <NewAllergyOrder
            fullAllergyObject
            subscriptionTypes={this.getSubscriptionTypes().filter(
              st => (this.props.admin || !st.onlyAdmin) && !st.isExtra
            )}
            onAdd={(type, allergies) => {
              this.props.onChange([
                {
                  menuAmount: 1,
                  allergies: allergies,
                  type,
                },
              ]);
              this.setState({ creatingNew: false });
            }}
            onClose={() => this.setState({ creatingNew: false })}
            admin={this.props.admin}
          />
        ) : (
          <div className={styles.customAllergiesButton}>
            <Link onClick={() => this.setState({ creatingNew: true })}>
              Legg til allergitilpasset
            </Link>
          </div>
        )}
        {isDisabled || !this.props.showButtons ? null : (
          <div className={cn(styles.actions)}>
            {!this.props.autoSave ? (
              <Button
                className={styles.button}
                onClick={() => this.props.onSave()}
                success={this.props.success}
                saving={this.props.saving}
                disabled={this.props.disableSaving}
              >
                Lagre
              </Button>
            ) : null}
            {!this.props.autoSave && !this.props.disableSaving ? (
              <strong>Husk å lagre!</strong>
            ) : null}
            {totalAmount ? (
              <Button onClick={() => this.avbestill()} className={styles.link}>
                Avbestill
              </Button>
            ) : null}
          </div>
        )}
      </div>
    );
  };

  render() {
    const total = this.getTotal();
    const totalAmount = this.getTotalAmount();
    const pastOrderDate =
      this.props.day &&
      isPastOrderDate(
        this.props.day,
        this.props.admin,
        null,
        this.props.companySubscription
      );

    const isDisabled = pastOrderDate || !this.props.hasActiveSubscription;

    const content =
      this.props.noDeliveryReason ||
      (this.state.collapsed && !isDisabled && !this.props.companySubscription)
        ? null
        : this.getContent(isDisabled);

    if (!this.props.showWrapper || !this.props.day) {
      return <div>{content}</div>;
    }

    return (
      <Day
        locked={isDisabled}
        open={!this.state.collapsed}
        date={this.props.day}
        header={
          <Header
            onClick={() =>
              !isDisabled &&
              !this.props.noDeliveryReason &&
              this.setState({ collapsed: !this.state.collapsed })
            }
            open={!this.state.collapsed}
            hasActiveSubscription={this.props.hasActiveSubscription}
            noDeliveryReason={this.props.noDeliveryReason}
            totalAmount={totalAmount}
            total={total}
            date={this.props.day}
            deliveryPrice={this.props.deliveryPrice}
            locked={isDisabled}
            saving={!!(this.props.autoSave && this.props.saving)}
            success={!!(this.props.autoSave && this.props.success)}
            company={this.props.company}
          />
        }
        footer={<Footer deliveryInfo={this.props.deliveryInfo} />}
      >
        {content}
      </Day>
    );
  }
}

DayOrder.defaultProps = {
  enableRating: false,
  enableAllergySelection: false,
  enablePriceSplitting: false,
  hasFullWeekDiscount: false,
  showWrapper: true,
  showButtons: true,
  disableSaving: false,
  subscriptionTypeUser: 'customer',
  ratings: {},
  savingRating: {},
  menus: {},
  day: null,
  autoSave: false,
};

DayOrder.propTypes = {
  noDeliveryReason: PropTypes.string,
  hasActiveSubscription: PropTypes.bool.isRequired,
  hasFullWeekDiscount: PropTypes.bool,
  showWrapper: PropTypes.bool,
  showButtons: PropTypes.bool,
  disableSaving: PropTypes.bool,
  success: PropTypes.bool,
  saving: PropTypes.bool,
  day: PropTypes.object,
  ratings: PropTypes.object,
  savingRating: PropTypes.object,
  subscription: PropTypes.object,
  subscriptionTypes: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
  enableRating: PropTypes.bool,
  enableAllergySelection: PropTypes.bool,
  enablePriceSplitting: PropTypes.bool,
  subscriptionTypeUser: PropTypes.string.isRequired,
  autoSave: PropTypes.bool,
};

export default DayOrder;
