import React from 'react';
import PropTypes from 'prop-types';
import styles from './header.module.css';
import upperCaseFirstLetter from '../../../../utils/upperCaseFirstLetter';
import DayPrice from '../../DayPrice';
import Loader from '../../../Loader';

const Header = ({
  locked,
  date,
  saving,
  success,
  hasActiveSubscription,
  noDeliveryReason,
  totalAmount,
  total,
  deliveryPrice,
}) => (
  <div className={styles.header}>
    <div className={styles.headerDateWrapper}>
      <div className={styles.headerDateContainer}>
        {locked ? (
          <div className={styles.headerLock}>
            <div className="fa fa-lock" />
          </div>
        ) : null}
        <div className={styles.headerDateDay}>
          {upperCaseFirstLetter(date.format('dddd'))}
        </div>
        <div className={styles.headerDate}>
          <div>{date.format('D. MMM')}</div>
        </div>
      </div>
    </div>
    <div className={styles.dayHeaderInfo}>
      {saving ? (
        <div className={styles.loaderWrapper}>
          <Loader size={30} />
        </div>
      ) : success ? (
        <div className={styles.checkWrapper}>
          <div className="fa fa-check" />
        </div>
      ) : !hasActiveSubscription ? (
        'Ingen aktiv avtale'
      ) : noDeliveryReason ? (
        noDeliveryReason
      ) : totalAmount === 0 ? (
        'Ingen bestilling'
      ) : (
        <DayPrice total={total} deliveryPrice={deliveryPrice} />
      )}
    </div>
  </div>
);

Header.propTypes = {
  locked: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  hasActiveSubscription: PropTypes.bool.isRequired,
  noDeliveryReason: PropTypes.string,
  date: PropTypes.object.isRequired,
  deliveryPrice: PropTypes.number.isRequired,
};

export default Header;
