import { createBrowserHistory } from 'history';

const h = createBrowserHistory({
  forceRefresh: false,
});

h.listen((location, action) => {
  var _hsq = window._hsq = window._hsq || [];
  _hsq.push(['setPath', location]);
  _hsq.push(['trackPageView']);
});

export default h;