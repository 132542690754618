import React from 'react';
import AddressSearch from '../AddressSearch';
import Input3 from '../Form/Input3';
import { BackButton } from '../Button';
import NumberPicker from '../NumberPicker';

export default class DeliveryLocationPicker extends React.Component {
  onChange(state) {
    this.props.onDeliveryLocationChange({
      ...this.props.deliveryLocation,
      address: this.props.deliveryAddress,
      ...state,
    })
  }

  render() {
    return (
      <div >
        <AddressSearch
          colored={this.props.colored}
          label={<strong>Leveringsadresse</strong>}
          onChange={() => { }}
          onClick={this.props.close}
          choice={this.props.deliveryAddress}
        />
        <div style={{ marginTop: 20 }} onClick={this.props.close}>
          <BackButton />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Input3
            colored={this.props.colored}
            label="Navn på leveringspunkt"
            subtext="Velg et navn det er enkelt for oss å kjenne igjen. Eks:
            «Lunsjkollektivet innerst i gangen», «Resepsjonen på UiB»."
            value={this.props.deliveryLocation.name}
            onChange={name => this.onChange({ name })}
            placeholder="Beskrivende navn"
            validate={string => string.length >= 2}
            errorMessage="Må i hvertfall ha 2 tegn"
          />
          <div style={{ marginLeft: 20 }}>
            <strong>Etasje</strong>
            <NumberPicker
              value={parseInt(this.props.deliveryLocation.floor || 0)}
              colored={this.props.colored}
              onChange={floor => this.onChange({ floor })}
              placeholder="Etasje"
            />
          </div>
        </div>
        <Input3
          colored={this.props.colored}
          label="Eventuell annen info"
          subtext="Eks: «Lever i resepsjonen», «Settes rett utenfor døren»."
          value={this.props.deliveryLocation.deliveryNote}
          onChange={deliveryNote => this.onChange({ deliveryNote })}
          placeholder="Annen info"
        />
        {/* <h4>Leveringstidspunkt</h4>
        <div style={{ fontSize: '0.9em', marginBottom: '15px' }}>
          <i>
            For å kunne kjøre så miljøvennlig og effektivt som mulig kan vi
            dessverre ikke spesifisere et klokkeslett innenfor tidsrommet vi
            leverer.
          </i>
        </div> */}
      </div>
    );
  }
}
