import React from 'react';
import styles from './card.module.css';

import Card from './Card';

const CollapsableCard = ({
  children,
  header,
  collapsed,
  onChange,
  ...props
}) => (
  <Card
    header={
      <div
        className={styles.collapsableHeader}
        onClick={() => onChange(!collapsed)}
      >
        <div>{header}</div>
        <div>
          <div className="fa fa-caret-down" />
        </div>
      </div>
    }
    children={collapsed ? '' : children}
    {...props}
  />
);

CollapsableCard.defaultProps = {
  marginTop: false,
};

export default CollapsableCard;
