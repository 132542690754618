import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { DeliveryAddress } from '../../../components/CustomerSettings/DeliveryAddress';
import { Allergy } from '../../../components/CustomerSettings/Allergy';

import Subscription from './routes/Subscription';
import MyHistory from './routes/MyHistory/index';
import ManagePayment from './routes/ManagePayment';
import Home from './routes/Home/index';

import Loader from '../../../components/Loader';
import Alert from '../../../components/Alert';
import Header from '../../../components/Header';
import MinSideTitle from '../../../components/MinSideTitle';
import InvoiceList from './routes/InvoiceList';
import Invoice from './routes/Invoice';
import Email from './routes/Email';

import { getForCustomerIfNeeded } from '../../../stores/subscription/actions';
import {
  selectCustomerSubscription,
  selectCustomerSubscriptionMeta,
} from '../../../stores/subscription/selectors';

import selectedCustomer from '../../../selectors/me/selectedCustomer';

/**
 * SubscriptionType Store functions
 */
import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../stores/subscriptionType/actions';
import selectAllSubscriptionTypes from '../../../selectors/subscriptionType/selectAll';
import { UserInfo } from '../../../components/CustomerSettings/UserInfo';

class MySettings extends React.Component {
  componentDidMount() {
    this.props.getAllSubscriptionTypesIfNeeded();
    this.props.getForCustomerIfNeeded(this.props.customer.id);
  }

  componentDidUpdate() {
    this.props.getAllSubscriptionTypesIfNeeded();
  }

  onStepDone = () => {
    if (this.props.setupDone) return;

    const deliveryLocation = this.props.customer.companyAgreement
      ? this.props.customer.companyAgreement.deliveryLocation
      : this.props.customer.deliveryLocation;

    if (!deliveryLocation) {
      return this.props.history.push('/innstillinger/leveringsadresse');
    }

    if (this.props.subscription === null) {
      return this.props.history.push('https://my.lunsjkollektivet.no');
    }

    if (this.props.cards.length === 0) {
      return this.props.history.push('https://my.lunsjkollektivet.no');
    }

    this.props.history.push('https://my.lunsjkollektivet.no');
  };

  buildContent() {
    if (this.props.loading) {
      return <Loader />;
    }

    if (this.props.error) {
      return (
        <Alert color="red">
          Noe skjedde når vi lastet inn siden. Vennligst prøv igjen senere
        </Alert>
      );
    }

    return (
      <Switch>
        <Route
          path={this.props.match.url + '/profile'}
          exact
          render={cprops => {
            return (
              <UserInfo
                {...cprops}
                backTo={this.props.match.url}
                userId={this.props.identity.id}
              />
            );
          }}
        />
        <Route
          path={this.props.match.url + '/subscription'}
          exact
          render={cprops => {
            return (
              <Subscription
                {...cprops}
                customerId={this.props.customer.id}
                userId={this.props.identity.id}
                onDone={this.onStepDone}
              />
            );
          }}
        />
        <Route
          path={this.props.match.url + '/history'}
          exact
          component={MyHistory}
        />
        <Route
          path={this.props.match.url + '/payment'}
          render={() => (
            <ManagePayment
              onDone={this.onStepDone}
              userId={this.props.identity.id}
            />
          )}
        />
        <Route path={this.props.match.url} exact component={Home} />
        <Route
          exact
          path={this.props.match.url + '/faktura'}
          component={InvoiceList}
        />
        <Route
          exact
          path={this.props.match.path + '/leveringsadresse'}
          render={cprops => {
            return (
              <DeliveryAddress
                {...cprops}
                backTo={this.props.match.url}
                customerId={this.props.customer.id}
                onDone={this.onStepDone}
              />
            );
          }}
        />
        <Route
          exact
          path={this.props.match.path + '/allergier'}
          render={cprops => {
            return (
              <Allergy
                {...cprops}
                backTo={this.props.match.url}
                userId={this.props.identity.id}
                customerId={this.props.customer.id}
              />
            );
          }}
        />
        <Route exact path={this.props.match.url + '/email'} component={Email} />
        <Route
          exact
          path={this.props.match.url + '/faktura/:invoiceId'}
          component={Invoice}
        />
      </Switch>
    );
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>Innstillinger - Lunsjkollektivet</title>
        </Helmet>
        <Header />
        {this.props.history.location.pathname !==
          '/innstillinger/subscription' ? (
            <MinSideTitle />
          ) : null}
        {this.buildContent()}
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const customer = selectedCustomer(state);
    const subscriptionMeta = selectCustomerSubscriptionMeta(state, customer.id);
    return {
      subscriptionTypes: selectAllSubscriptionTypes(state),
      subscription: selectCustomerSubscription(state, customer.id),
      loading:
        ownProps.loading ||
        subscriptionMeta.loading ||
        state.subscriptionType.meta.loading,
      error:
        ownProps.error ||
        subscriptionMeta.error ||
        state.subscriptionType.meta.error,
      cards: customer.cards || [],
      allergies: state.me.identity.allergies || [],
      identity: state.me.identity,
      customer,
      setupDone: state.me.identity.meta.setupDone,
    };
  },
  { getForCustomerIfNeeded, getAllSubscriptionTypesIfNeeded }
)(MySettings);
