import React from 'react';
import { Link } from 'react-router-dom';

import Container from '../../../components/Container';
import Header from '../../../components/Header';

import styles from './getStarted.module.css';

class GetStarted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      signupDialogOpen: false,
    };
  }

  render() {
    return (
      <div id="home" className="section">
        {' '}
        <Header />
        <div className={styles.background}>
          <Container width={700} withPadding>
            <div className={styles.choices}>
              <Link className={styles.choice} to="/signup">
                <div className={styles.logo}>
                  <i className="fa fa-user" />
                </div>
                <div className={styles.header}>Privat</div>
                <p className="text-center">
                  Trykk her hvis du betaler lunsjen selv
                </p>
              </Link>
              <Link className={styles.choice} to="/company-signup">
                <div className={styles.logo}>
                  <i className="fa fa-building" />
                </div>
                <div className={styles.header}>Bedrift</div>
                <p className="text-center">
                  Trykk her hvis du vil inngå en bedriftsavtale
                </p>
              </Link>
            </div>
            <img
             src={'/images/logo_white.png'}
              alt="LunsjKollektivet"
              draggable="false"
              className={styles.image}
            ></img>
          </Container>
        </div>{' '}
      </div>
    );
  }
}
export default GetStarted;
