import selectMeta from '../../selectors/selectMeta';
import selectWeekMetaKey from '../../selectors/choice/selectWeekMetaKey';

export const selectDateMetaKey = date =>
  `${date
    .clone()
    .startOf('day')
    .format()}`;

export const selectDateKitchenMetaKey = (date, kitchenId) =>
  `${kitchenId}_${date
    .clone()
    .startOf('day')
    .format()}`;

export const selectCustomerWeekMetaKey = (customerId, week) =>
  `${customerId}_${week
    .clone()
    .startOf('week')
    .format()}`;

export const selectCustomerMonthMetaKey = (customerId, month) =>
  `${customerId}_month_${month
    .clone()
    .startOf('month')
    .format()}`;

export const selectKitchenOrdersForDate = (state, date, kitchenId) => {
  const meta = selectKitchenOrdersForDateMeta(state, date, kitchenId);

  return meta.ids.map(id => state.order.entities[id]);
};

export const selectKitchenOrdersForDateMeta = (state, date, kitchenId) =>
  selectMeta(state.order.meta[selectDateKitchenMetaKey(date, kitchenId)]);

export const selectOrdersForDate = (state, date) => {
  const meta = selectOrdersForDateMeta(state, date);

  return meta.ids.map(id => state.order.entities[id]);
};

export const selectOrdersForDateMeta = (state, date) =>
  selectMeta(state.order.meta[selectDateMetaKey(date)]);

export const selectCustomerOrdersForWeek = (state, customerId, week) => {
  const meta = selectCustomerOrdersForWeekMeta(state, customerId, week);

  return meta.ids.map(id => state.order.entities[id]);
};

export const selectCustomerOrdersForWeekMeta = (state, customerId, week) =>
  selectMeta(state.order.meta[selectCustomerWeekMetaKey(customerId, week)]);


export const selectCustomerOrdersForMonth = (state, customerId, month) => {
  const meta = selectCustomerOrdersForMonthMeta(state, customerId, month);

  return meta.ids.map(id => state.order.entities[id]);
};

export const selectCustomerOrdersForMonthMeta = (state, customerId, month) =>
  selectMeta(state.order.meta[selectCustomerMonthMetaKey(customerId, month)]);



export const selectCompanyAgreementOrdersForWeek = (
  state,
  customerId,
  week
) => {
  const meta = selectCompanyAgreementOrdersForWeekMeta(state, customerId, week);

  return meta.ids.map(id => state.order.entities[id]);
};

export const selectCompanyAgreementOrdersForWeekMeta = (
  state,
  customerId,
  week
) => selectMeta(state.order.meta[selectWeekMetaKey(customerId, week)]);
