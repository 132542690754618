import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import CompanyPageHeader from '../../components/CompanyPageHeader';
import Lunch from './routes/CompanyLunch';
import MeetingFoodOrder from './routes/MeetingFoodOrder';
import { CompanyMeetingFoodSchema } from '../../../../../components/CompanySettings/CompanyMeetingFoodOrder';
import Overview from './routes/Overview/index';
import { selectedCompanyAgreement } from '../../../../../stores/me/selectors';

class Admin extends React.Component {

  render() {
    return (
      <div>
        <Helmet>
          <title>Bedrift - Lunsjkollektivet</title>
        </Helmet>
        <CompanyPageHeader showTabs location={this.props.location} />
        <Switch>
          <Route
            exact
            path={this.props.match.url}
            component={Overview}
          />
          <Route
            exact
            path={this.props.match.url + '/bedriftlunsj'}
            component={Lunch}
          />
          <Route
            exact
            path={this.props.match.url + '/motemat'}
            component={MeetingFoodOrder}
          />
          <Route
            path={this.props.match.url + '/motemat/bestill'}
            render={cprops => {
              return (
                <div>
                  <CompanyMeetingFoodSchema
                    {...cprops}
                    backTo={this.props.match.url + '/motemat'}
                    companyAgreementId={this.props.companyAgreementId}
                  />
                </div>
              );
            }}
          />
        </Switch>
      </div>
    );
  }
}

export default connect(state => ({
  companyAgreementId: selectedCompanyAgreement(state).id,
}))(Admin);
