import React from 'react';
import ReactDOM from 'react-dom';

import App from './routes';
import store from './createStore';

/** Global css libraries **/
import 'font-awesome/css/font-awesome.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-table/react-table.css';
import './styles/base.scss';
import './styles/globals.css';

import 'react-dates/initialize';

import { configure } from './utils/errorReporting';

import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import logVersion from './utils/logVersion';

import moment from 'moment-timezone';

moment.tz.setDefault('Europe/Oslo');

logVersion();
configure();

const root = document.getElementById('app');

ReactDOM.render(<App store={store} />, root);

if (module.hot) {
  module.hot.accept('./routes', () => {
    const NextApp = require('./routes').default;
    ReactDOM.render(<NextApp store={store} />, root);
  });
}

unregisterServiceWorker();
