import React from 'react';
import Post from './Post';

import SanityHoc from '../../../../hocs/SanityHoc';

const GET_BY_ID = `
*[_type == $type && _id == $id][0...5]{
  title,
  body,
  publishedAt,
  author->,
  receipe->,
  mainImage,
  _id,
  _type,
  ingress
}
`;

export default props => (
  <SanityHoc
    fetch={client =>
      client.fetch(GET_BY_ID, {
        type: 'post',
        id: props.match.params.postId,
      })
    }
  >
    {(loading, error, sanityData) => (
      <Post
        {...props}
        sanityData={sanityData}
        loading={loading}
        error={error}
      />
    )}
  </SanityHoc>
);
