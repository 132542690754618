import React from 'react';
import cn from 'classnames';
import Hero from '../Hero';
import Menu from './Menu';
import styles from './header.module.scss';

export default props => (
  <div className={cn(styles.header, 'no-print')}>
    <div className={!!props.image && props.children ? styles.homepage : null}>
      <Menu picker={props.picker} homepage={!!props.image && props.children}
        admin={props.admin} chef={props.chef} />
    </div>
    {props.image && (
      <Hero
        homepage={!!props.image && props.children}
        image={props.image}
        height={props.height}
        center={props.center}
        children={props.children}
        darken={props.darken}
        top={props.top}
        noShadow
        withoutCard={props.withoutCard}
      />
    )}
  </div>
);
