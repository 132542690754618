import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './deliveryDayPicker.module.css';
import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';
import { DropDown } from '../DropDown/DropDown';
import formatPrice from '../../utils/formatPrice';

export function DeliveryDayPicker(props) {
  const [lunchType, setLunchType] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);

  const setAccessories = accessories => {
    props.addAccessory(props.day, accessories);
  };

  const selectLunch = choice => {
    setLunchType(choice);
    const dailyEntry = {
      day: props.day,
      data: choice
        ? [
            {
              type: choice?.alias,
              menuAmount: 1,
            },
          ]
        : [],
    };
    props.selectLunchHandler(props.day, dailyEntry);
  };

  const calculateCurrentPrices = useCallback(items => {
    let price = 0;
    if (items && items.length > 0) {
      items.forEach(item => {
        price += item.menuAmount
          ? Number(item.currentPrice * item.menuAmount)
          : item.currentPrice;
      });
    }

    return price;
  }, []);

  useEffect(() => {
    if (props.lunchType && props.lunchType.length > 0) {
      setLunchType(props.lunchType[0]);
    }
  }, [props.allLunsjTypes, props.lunchType]);

  useEffect(() => {
    const { lunchType, savedAccessories } = props;
    let price = 0;
    if (lunchType && lunchType.length > 0) {
      price += calculateCurrentPrices(lunchType);
    }
    if (savedAccessories && savedAccessories.length > 0) {
      price += calculateCurrentPrices(savedAccessories);
    }

    setTotalPrice(price);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateCurrentPrices, props.lunchType, props.savedAccessories]);

  const inlineStyles = {
    backgroundColor:
      lunchType || props.savedAccessories.length > 0 ? '#CBDFD1' : '#DCD4D4',
  };

  return (
    <div style={inlineStyles} className={styles.typePicker}>
      <header>
        <h1 className={styles.weekday}>{props.weekday}</h1>
        <div className="price">Du betaler: {formatPrice(totalPrice)}</div>
      </header>
      <label className={styles.typeTitle}>Lunsjtype</label>
      <DropDown
        currentOption={lunchType}
        onChange={selectLunch}
        options={props.allLunsjTypes}
      />
      <label className={styles.typeTitle}>Lunsjtilbehør</label>
      <AccessoriesPicker
        allAccessories={props.allAccessories}
        savedAccessories={props.savedAccessories}
        setAccessories={setAccessories}
        day={props.day}
      />
    </div>
  );
}

DeliveryDayPicker.propTypes = {
  weekday: PropTypes.string.isRequired,
  lunchType: PropTypes.object,
  setLunchType: PropTypes.func.isRequired,
  allLunsjTypes: PropTypes.object,
  allAccessories: PropTypes.object,
  savedAccessories: PropTypes.object,
};

export function AccessoriesPicker(props) {
  const [current, setCurrent] = useState([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (props.savedAccessories.length > 0) {
      setCurrent(props.savedAccessories);
    }
  }, [props.day, props.savedAccessories]);

  const addOneAccessory = accessoryType => {
    if (current.find(x => x.alias === accessoryType)) {
      const updatedAccessory = [...current].map(x =>
        x.alias === accessoryType
          ? { ...x, menuAmount: Number(x.menuAmount) + 1 }
          : x
      );

      const newAccessoriesList = updatedAccessory.map(acc => {
        return {
          type: acc.alias,
          menuAmount: acc.menuAmount,
        };
      });
      console.log(
        'Current Accessory: %o, updated accessory: %o and New Accessories List: %o',
        current,
        updatedAccessory,
        newAccessoriesList
      );
      setCurrent(updatedAccessory);
      props.setAccessories(newAccessoriesList);
    } else {
      const obj = props.allAccessories.find(x => x.alias === accessoryType);
      const newArray = [...current].concat({
        ...obj,
        menuAmount: 1,
      });
      setCurrent(newArray);
      const newAccessoriesList = newArray.map(acc => {
        return {
          type: acc.alias,
          menuAmount: acc.menuAmount,
        };
      });
      props.setAccessories(newAccessoriesList);
    }
  };

  const removeOneAccessory = accessoryType => {
    const newArray = [...current]
      .map(x =>
        x.alias === accessoryType ? { ...x, menuAmount: x.menuAmount - 1 } : x
      )
      .filter(x => x.menuAmount > 0);

    const newAccessoriesList = newArray.map(acc => {
      return {
        type: acc.alias,
        menuAmount: acc.menuAmount,
      };
    });
    setCurrent(newArray);
    props.setAccessories(newAccessoriesList);
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom']}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <div className={styles.card}>
          {props.allAccessories?.map(accessory => {
            return (
              <div
                key={accessory.alias}
                onClick={() => {
                  addOneAccessory(accessory.alias);
                  setIsPopoverOpen(false);
                }}
                className={styles.accessoryItem}
              >
                <span className={styles.name}>{accessory.name}</span>
                <i className={styles.price}>{accessory.currentPrice},-</i>
              </div>
            );
          })}
        </div>
      }
    >
      <div className={styles.tagContainer}>
        {current.map(accessory => {
          return (
            <AccessoriesTag
              key={accessory.name}
              amount={accessory.menuAmount}
              name={accessory.name}
              type={accessory.alias}
              addOneAccessory={addOneAccessory}
              removeOneAccessory={removeOneAccessory}
            />
          );
        })}
        <button
          onKeyUp={e => e.key === 'Enter' && setIsPopoverOpen(true)}
          onClick={() => setIsPopoverOpen(true)}
          className={styles.tag}
        >
          +
        </button>
      </div>
    </Popover>
  );
}

AccessoriesPicker.propTypes = {
  savedAccessories: PropTypes.array,
  allAccessories: PropTypes.array.isRequired,
};

export function AccessoriesTag(props) {
  const [isFocused, setIsFocused] = useState(false);
  const accessoryTagRef = useRef(null);

  useOnClickOutside(accessoryTagRef, () => {
    setIsFocused(false);
  });

  const tagText =
    props.amount > 1 ? `${props.amount}x ${props.name}` : props.name;

  return (
    <div
      ref={accessoryTagRef}
      className={styles.tag}
      onClick={() => (isFocused ? null : setIsFocused(true))}
    >
      {isFocused && (
        <button
          className={styles.tagButton}
          onClick={() => props.removeOneAccessory(props.type)}
        >
          -
        </button>
      )}
      <label className={styles.tagLabel}>{tagText}</label>
      {isFocused && (
        <button
          className={styles.tagButton}
          onClick={() => props.addOneAccessory(props.type)}
        >
          +
        </button>
      )}
    </div>
  );
}

AccessoriesTag.propTypes = {
  amount: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  addOneAccessory: PropTypes.func.isRequired,
  removeOneAccessory: PropTypes.func.isRequired,
};

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export default DeliveryDayPicker;
