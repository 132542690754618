import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FOR_WEEK_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_FOR_WEEK_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.menus.reduce((object, menu) => {
            object[menu.id] = menu;
            return object;
          }, {}),
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: action.payload.menus.map(so => so.id),
          },
        },
      };
    case types.GET_FOR_WEEK_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.GET_FOR_DAY_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.dayString]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_FOR_DAY_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        meta: {
          ...state.meta,
          [action.payload.dayString]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
      };
    case types.GET_FOR_DAY_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.dayString]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.UPDATE_SUCCESS:
      let newMeta = {
        ...state.meta,
      };

      if (
        newMeta[action.payload.dayString] &&
        newMeta[action.payload.dayString].ids.indexOf(
          action.payload.response.id
        ) === -1
      ) {
        newMeta[action.payload.dayString].ids = [
          ...newMeta[action.payload.dayString].ids,
          action.payload.response.id,
        ];
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.response.id]: action.payload.response,
        },
        meta: newMeta,
      };
    default:
      return state;
  }
};
