import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';
import shouldFetchForDay from '../../selectors/menu/shouldFetchForDay';

import types from './types';

export const getForWeekIfNeeded = week => (dispatch, getState) => {
  const startDate = week.clone().startOf('week');
  const endDate = week.clone().endOf('week');
  const key = startDate.format() + endDate.format();
  if (!shouldFetch(getState().menu.meta[key])) return;

  dispatch({
    type: types.GET_FOR_WEEK_START,
    payload: { key },
  });

  return fetch('menus', 'GET', {
    startDate: week
      .clone()
      .startOf('week')
      .format('YYYY-MM-DD'),
    endDate: week
      .clone()
      .endOf('week')
      .format('YYYY-MM-DD'),
  })
    .then(response => {
      dispatch({
        type: types.GET_FOR_WEEK_SUCCESS,
        payload: {
          key,
          menus: Array.isArray(response.data) ? response.data : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_WEEK_ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};

export const getForDayIfNeeded = date => (dispatch, getState) => {
  if (!shouldFetchForDay(getState(), date)) return;
  const dayString = date.format('YYYY-MM-DD');

  dispatch({
    type: types.GET_FOR_DAY_START,
    payload: {
      dayString,
    },
  });

  return fetch('/menus', 'GET', {
    startDate: dayString,
    endDate: dayString,
  })
    .then(response => {
      dispatch({
        type: types.GET_FOR_DAY_SUCCESS,
        payload: {
          dayString,
          entities: response.data.reduce((menus, menu) => {
            menus[menu.id] = menu;
            return menus;
          }, {}),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_DAY_ERROR,
        payload: {
          dayString,
          error,
        },
      });
    });
};

export const update = (date, type, data) => dispatch =>
  fetch('/menus', 'POST', {
    ...data,
    date: date.format('YYYY-MM-DD'),
    type: {
      id: type,
    },
  }).then(response => {
    dispatch({
      type: types.UPDATE_SUCCESS,
      payload: {
        dayString: date.format('YYYY-MM-DD'),
        type,
        response: response.data,
      },
    });
  });
