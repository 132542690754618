import React from 'react';

import GroupWithLogo from '../GroupWithLogo';

import styles from './privateButton.module.css';

export default props => (
  <GroupWithLogo icon={<i className="fa fa-bars"
    style={{
      textAlign: 'center', fontSize: '1.3em',
      color: (props.admin || props.chef) ? '#ad5763' : props.private ? '' : '#d88b65'
    }} />}>
    <div className={styles.private}>
      {(!props.admin && !props.chef) ? props.private ? 'Min Profil' : 'Bedrift' : ''}
      {props.admin && 'LK Admin'}
      {props.chef && 'Kjøkken'}
    </div>
    <div className={styles.name}>
      {props.name}
    </div>
  </GroupWithLogo>
);
