import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_START:
      return {
        ...state,
        [action.payload.search]: {
          loading: true,
          loadingActive: true,
          error: false,
          loadedAt: null,
          ids: [],
          entities: []
        },
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        [action.payload.search]: {
          ...state[action.payload.search],
          loading: false,
          error: false,
          loadedAt: moment().unix(),
          entities: action.payload.addresses,
        },
      };
    case types.GET_ACTIVE_SUCCESS:
      return {
        ...state,
        [action.payload.search]: {
          ...state[action.payload.search],
          loadingActive: false,
          error: false,
          loadedAt: moment().unix(),
          entities: [...state[action.payload.search].entities,
          ...(action.payload.addresses || [])],
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        [action.payload.search]: {
          loading: false,
          loadingActive: false,
          error: true,
          loadedAt: moment().unix(),
          ids: [],
        },
      };
    default:
      return state;
  }
};
