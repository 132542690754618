import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.UPDATE_SUCCESS:
      let ids = state.meta[action.payload.key].ids || [];
      if (ids.indexOf(action.payload.response.id) === -1) {
        ids.push(action.payload.response.id);
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.response.id]: action.payload.response,
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            ...state.meta[action.payload.key],
            ids: [...ids],
          },
        },
      };
    default:
      return state;
  }
};
