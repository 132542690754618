import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import auth0 from '../../../utils/auth0';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';
import Loader from '../../../components/Loader';
import { loginSuccess } from '../../../stores/me/actions';

import styles from './login.module.css';

class LoginCallback extends React.Component {
  state = { error: false, loading: true };

  componentDidMount() {
    let query = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    let to = 'https://my.lunsjkollektivet.no';

    if (query.callback) {
      to = query.callback;
    }

    auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.props.loginSuccess(authResult);
        this.props.history.push(to);
      } else {
        this.setState({
          loading: false,
          error: 'Noe skjedde',
        });
      }
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <div className={styles.overlay}>
            <div className={styles.login}>
              <h2>Laster profil..</h2>
              <Loader />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className={styles.overlay}>
          <div className={styles.login}>
            <h2>Feil</h2>
            <Alert color="red">Det oppstod en feil ved påloggingen</Alert>
            <Button onClick={() => this.props.history.push('/login')}>
              Prøv på nytt
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(() => ({}), {
    loginSuccess,
  })(LoginCallback)
);
