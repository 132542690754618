import React from 'react';
import { buildImageUrl } from '../../../../../utils/sanity';
import { Link } from 'react-router-dom';

import styles from './blogPostSmall.module.css';

function toSummary(blocks = []) {
  const summary = blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return '';
      }
      return block.children.map(child => child.text).join('');
    })
    .join('\n\n');

  return summary.length > 100
    ? summary.substring(0, 100).trim() + '...'
    : summary;
}

export default ({ post, baseUrl, onClick }) => (
  <div className={styles.blogPostSmall} onClick={onClick}>
    <div className={styles.imageWrapper}>
      <img src={buildImageUrl(post.mainImage)} alt="" />
    </div>
    <div className={styles.title}>
      <Link to={baseUrl + '/' + post._id}>{post.title}</Link>
    </div>
    <div className={styles.summary}>{toSummary(post.ingress)}</div>

    {post.author ? (
      <div className={styles.writtenBy}>
        Skrevet av <i>{post.author.name}</i>
      </div>
    ) : null}
  </div>
);
