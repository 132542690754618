import React from 'react';
import formatPrice from '../../utils/formatPrice';

export default ({ total, deliveryPrice }) => (
  <div>
    <div>
      {total.company > 0 ? (
        <span style={{ fontSize: '0.8em' }}>Du betaler </span>
      ) : (
        ''
      )}
      <strong>{formatPrice(total.customer)}</strong>
    </div>
    {total.company > 0 ? (
      <div>
        <span style={{ fontSize: '0.8em' }}>Bedriften betaler</span>{' '}
        <span>{formatPrice(total.company)}</span>
      </div>
    ) : (
      ''
    )}
    {deliveryPrice ? (
      <div>
        <span style={{ fontSize: '0.8em' }}>levering</span>{' '}
        <span>{formatPrice(deliveryPrice)}</span>
      </div>
    ) : null}
  </div>
);
