import React from 'react';

import Container from '../../../../../components/Container';
import Card from '../../../../../components/Card';
import styles from './CompanyBackground.module.css';

const CompanyBackground = props => {
  return (
    <div className={styles.background}>
      <Container width={870}  >
        <Card rounded>
          <div className={styles.content}>
            <h2>{props.title}</h2>
            {props.info}
          </div>
          {props.children}
        </Card>
      </Container>
    </div>
  );
}

export default CompanyBackground;
