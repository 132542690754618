import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/user/shouldFetch';

import { selectSearchKey, selectTotalKey } from './selectors';

import types from './types';

export const getIfNeeded = (search = '') => (dispatch, getState) => {
  if (!shouldFetch(getState(), search)) return;

  dispatch({
    type: types.GET_START,
    payload: {
      search,
    },
  });

  return fetch('/users', 'GET', {
    q: search,
  })
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          search,
          entities: response.data.reduce((users, user) => {
            users[user.id] = user;
            return users;
          }, {}),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          search,
          error,
        },
      });
    });
};

export const getIfNeededV2 = (offset, limit, filters = {}) => (
  dispatch,
  getState
) => {
  const search = selectSearchKey(offset, limit, filters);
  const totalKey = selectTotalKey(filters);

  if (!shouldFetch(getState(), search)) return;

  dispatch({
    type: types.GET_START,
    payload: {
      search,
    },
  });

  return fetch('/v2/users', 'GET', {
    offset,
    limit,
    ...filters,
  })
    .then(response => {
      dispatch({
        type: types.GET_V2_SUCCESS,
        payload: {
          search,
          entities: response.data.data.reduce((users, user) => {
            users[user.id] = user;
            return users;
          }, {}),
          total: response.data.pageInfo.count,
          totalKey,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          search,
          error,
        },
      });
    });
};

export const updateAllergies = (userId, allergies) => dispatch =>
  fetch('/users/' + userId + '/allergies', 'POST', allergies).then(response => {
    dispatch({
      type: types.UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  });

export const update = (userId, data) => dispatch =>
  fetch('/users/' + userId, 'POST', data).then(response => {
    dispatch({
      type: types.UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  });

export const updateMeta = (userId, data) => dispatch =>
  fetch('/users/' + userId + '/meta', 'POST', data).then(response => {
    dispatch({
      type: types.UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  });

export const createCommunicationForUser = (userId, message) => dispatch => {
  return fetch(`/users/${userId}/add-communication`, 'POST', { message }).then(
    response => {
      dispatch({
        type: types.UPDATE_USER_SUCCESS,
        payload: response.data,
      });
    }
  );
};

export const updateContact = (userId, data) => dispatch => {
  return fetch(`/users/${userId}/contact`, 'POST', data).then(response => {
    dispatch({
      type: types.UPDATE_USER_SUCCESS,
      payload: response.data,
    });
  });
};
