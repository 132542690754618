import React from 'react';
import Product from './Product';

import { buildImageUrl } from '../../../../utils/sanity';

import styles from './product.module.css';

export default ({ data }) => (
  <div className={styles.productCardsContainer}>
    <div className={styles.productCards}>
      {data.products.map((product, i) => (
        <Product
          key={i}
          title={product.title}
          alias={product.alias}
          price={product.price}
          text={product.text}
          big={product.big}
          imageUrl={buildImageUrl(product.image)}
        />
      ))}
    </div>
  </div>
);
