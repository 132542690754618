import selectMeta from '../../selectors/selectMeta';

export const selectCustomerChoicesForWeek = (state, customerId, week) => {
  const meta = selectCustomerChoicesForWeekMeta(state, customerId, week);

  return meta.ids.map(id => state.choice.entities[id]);
};

export const selectCustomerChoicesForWeekMeta = (state, customerId, week) =>
  selectMeta(state.choice.meta[selectCustomerWeekMetaKey(customerId, week)]);

export const selectCustomerWeekMetaKey = (customerId, week) =>
  `${customerId}_${week
    .clone()
    .startOf('week')
    .format()}`;

export const selectCustomerChoicesForMonth = (state, customerId, month) => {
  const meta = selectCustomerChoicesForMonthMeta(state, customerId, month);

  return meta.ids.map(id => state.choice.entities[id]);
};

export const selectCustomerChoicesForMonthMeta = (state, customerId, month) =>
  state.choice.meta[selectCustomerMonthMetaKey(customerId, month)] || {
    loading: true,
    cancelling: false,
    error: false,
    loadedAt: null,
    ids: [],
  };;

export const selectCustomerMonthMetaKey = (customerId, month) =>
  `${customerId}_month_${month
    .clone()
    .startOf('month')
    .format()}`;
