import React from 'react';
import cn from 'classnames';

import styles from './alert.module.css';

export default props => (
  <div
    className={cn('alert', {
      'alert-success': props.color === 'green',
      'alert-error': props.color === 'red',
      'alert-info': props.color === 'gray',
      [styles.marginBottom]: props.marginBottom,
    })}
  >
    {props.children}
  </div>
);
