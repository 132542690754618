import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  let entities = null;
  switch (action.type) {
    case types.GET_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.companyAgreementId]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.subscriptions.reduce((toObject, subscription) => {
            toObject[subscription.id] = subscription;
            return toObject;
          }, {}),
        },
        meta: {
          ...state.meta,
          [action.payload.companyAgreementId]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: action.payload.subscriptions.map(s => s.id),
          },
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.companyAgreementId]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.DEACTIVATE_SUCCESS:
      return {
        ...state,
        entities: {
          ...entities,
          [action.payload.subscription.id]: action.payload.subscription,
        },
      };
    case types.UPDATE_SUCCESS:
    case types.CREATE_SUCCESS:
      let ids = state.meta[action.payload.companyAgreementId].ids;
      if (ids.indexOf(action.payload.subscription.id) === -1) {
        ids = [...ids, action.payload.subscription.id];
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.subscription.id]: action.payload.subscription,
        },
        meta: {
          ...state.meta,
          [action.payload.companyAgreementId]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: ids,
          },
        },
      };
    default:
      return state;
  }
};
