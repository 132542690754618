import React from 'react';

import Header from '../../../components/Header';
import Container from '../../../components/Container';

import fetch from '../../../utils/fetch';
import vilkarAndPolicyAlias from '../../../utils/vilkarAndPolicyAlias';

import { Helmet } from 'react-helmet';
import moment from 'moment';

import SignupSteps from './SignupSteps/SignupStepsV2';
import AgreementInfo from './Steps/AgreementInfo';
import ClientInfo from './Steps/ClientInfo';
import CompanyInfoStep from './Steps/CompanyInfo';
import Alert from '../../../components/Alert';
import styles from './SignupCompany.module.css';

import store from 'store';
import firstSubDate from '../../../utils/firstSubDate';

class CompanyInfo extends React.Component {
  state = {
    companyName: '',
    orgnr: '',
    deliveryLocation: null,
    discountType: 'percent',
    discountAmount: 100,
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    notifications: [],
    customers: [],
    startDate: moment(firstSubDate()),
    step: 0,
    allNotificationsSelected: false,
  };

  signup() {
    const data = {
      companyName: this.state.companyName,
      organizationNumber: this.state.orgnr,
      contactPersonName: this.state.contactPersonName,
      contactPersonEmail: this.state.contactPersonEmail,
      contactPersonPhone: this.state.contactPersonPhone,
      discountType: this.state.discountType,
      discountAmount: this.state.discountAmount,
      deliveryLocation: this.state.deliveryLocation,
      billingAddress: this.state.deliveryLocation.address,
      companyAgreementUsers: this.state.customers,
      vilkarAndPolicyAlias,
      notifications: this.state.notifications,
      startDate: this.state.startDate,
    };

    const entranceSource = store.get('entranceSource');
    const entranceCampaign = store.get('entranceCampaign');

    if (entranceSource) {
      data['entranceSource'] = entranceSource;
    }
    if (entranceCampaign) {
      data['entranceCampaign'] = entranceCampaign;
    }

    this.setState({ loading: true });

    fetch('/company-signup', 'POST', data)
      .then(() => {
        this.props.history.push('/company-signup-done');
      })
      .catch(error => {
        this.setState({
          loading: false,
          error,
          errorMessage: error.response ? error.response.data : null,
        });
      });
  }

  render() {
    return (
      <div>
        <Header />
        <Helmet>
          <title>Lunsjkollektivet - Bedriftsavtale</title>
        </Helmet>
        <div className={styles.background}>
          <div style={{ borderTop: '1px solid #e7e7e7' }} />
          <Container noBorder width={900}>
            <SignupSteps
              signupType="company"
              values={this.state}
              onChange={state => this.setState(state)}
              onDone={() => this.signup()}
              onCancel={() => this.props.history.push('/')}
              onStep={(step, done) => this.setState({ step }, done)}
              step={this.state.step}
              onBack={() =>
                this.setState({
                  step: this.state.step > 0 ? this.state.step - 1 : 0,
                })
              }
            >
              <CompanyInfoStep />
              <AgreementInfo />
              <ClientInfo />
            </SignupSteps>
            {this.state.error ? (
              <div className="mt-5">
                <Alert color="red">
                  {this.state.errorMessage ? (
                    <p>{this.state.errorMessage}</p>
                  ) : (
                    <p>
                      Noe skjedde. Ta kontakt på meldingstjenesten vår hvis
                      problemet vedvarer så skal vi fikse bestillingen for deg.
                    </p>
                  )}
                </Alert>
              </div>
            ) : null}
          </Container>
        </div>
      </div>
    );
  }
}

export default CompanyInfo;
