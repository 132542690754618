const availableDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

const hasFullWeekDiscountWeekly = subscription => {
  if (!subscription || subscription.days.length < 5) return false;

  return !!subscription.data.entries.find(entry => entry.menuAmount > 0);
};

const hasFullWeekDiscount = subscription => {
  if (!subscription) return false;

  if (subscription && subscription.type === undefined)
    return hasFullWeekDiscountWeekly(subscription);

  switch (subscription.type) {
    case 'daily':
      const hasEmptyDay = !!availableDays.find(ad => {
        let day = subscription.data.dailyEntries.find(de => de.day === ad);
        if (!day) return true;

        return !day.data.find(e => e.menuAmount > 0);
      });

      return !hasEmptyDay;
    case 'weekly':
      return hasFullWeekDiscountWeekly(subscription);
    default:
      return false;
  }
};

const subscriptionAvailableForDay = (subscription, checkDay) => {
  const subscriptionDays = subscription.days;

  if (!subscriptionDays || !subscriptionDays.length) {
    return false;
  }

  return subscriptionDays.includes(availableDays[checkDay.day() - 1]);
}

export default {
  hasFullWeekDiscount,
  subscriptionAvailableForDay
};
