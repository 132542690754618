import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import Link from '../CustomLink';
import PrivatButton from './components/PrivatButton';
import GroupWithLogo from './components/GroupWithLogo';
import {
  selectedContext,
  agreementsWhereUserIsAdmin,
  myCustomers,
} from '../../stores/me/selectors';

import {
  setContext,
  setSelectedCompanyAgreement,
} from '../../stores/me/actions';

import styles from './minSideButton.module.scss';

class MinSideButton extends React.Component {
  state = {
    opened: false,
  };

  componentDidMount() {
    document.body.addEventListener('click', this.windowClicked.bind(this));
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.windowClicked.bind(this));
  }

  windowClicked(e) {
    if (this.state.opened && this.node && !this.node.contains(e.target)) {
      this.setState({ opened: false });
    }
  }

  getLinks() {
    let menyItems = [];
    const isPrivate = this.props.context.type === 'private';

    isPrivate &&
      menyItems.push({
        text: 'Mine bestillinger',
        to: 'https://my.lunsjkollektivet.no',
        exact: false,
      });

    isPrivate &&
      menyItems.push({
        text: 'Innstillinger',
        to: 'https://my.lunsjkollektivet.no/settings',
        exact: false,
      });

    !isPrivate &&
      menyItems.push({
        text: 'Bedriftsoversikt',
        to: 'https://company.lunsjkollektivet.no',
        exact: false,
      });

    menyItems.push({
      text: 'Lunsjmeny',
      to: 'https://my.lunsjkollektivet.no/',
      exact: false,
    });

    menyItems.push({
      text: 'Blogg',
      to: '/blog',
      exact: false,
    });

    if (this.props.roles.indexOf('admin') > -1) {
      menyItems.push({ text: 'Admin', to: '/admin/kunder', exact: false });
    }

    if (this.props.roles.indexOf('chef') > -1) {
      menyItems.push({ text: 'Kjøkken', to: '/kokk', exact: false });
    }

    return menyItems;
  }

  render() {
    return (
      <div
        className={cn(styles.buttonWrapper, {
          [styles.transparent]: this.props.transparent,
        })}
        ref={node => {
          this.node = node;
        }}
      >
        <div className={styles.alignedButton}>
          <div
            onClick={() =>
              this.setState({
                opened: !this.state.opened,
              })
            }
            className={cn(styles.button, {
              [styles.transparent]: this.props.transparent,
            })}
          >
            <PrivatButton
              name={
                this.props.context.type === 'private'
                  ? this.props.context.customer.identity.name
                  : this.props.context.companyAgreement.company.name
              }
              admin={this.props.admin}
              chef={this.props.chef}
              private={this.props.context.type === 'private'}
            />
            <div
              className={cn(styles.carretWrapper, {
                [styles.rotated]: false,
              })}
            >
              <i
                className={cn('fa', 'fa-angle-down', styles.caret, {
                  [styles.rotated]: this.state.opened,
                })}
              />
            </div>
          </div>
          <div
            className={cn(styles.buttonDropDownContent, {
              [styles.visible]: this.state.opened,
            })}
          >
            <div className={cn(styles.group, styles.column)}>
              {this.props.context.type === 'private' ? (
                <strong className={styles.contextHeaderBlue}>MIN PROFIL</strong>
              ) : (
                <strong className={styles.contextHeaderOrange}>BEDRIFT</strong>
              )}
              {this.getLinks().map(l => {
                if (typeof l.to === 'string' && l.to.startsWith('https://')) {
                  return (
                    <a className={styles.buttonLink} href={l.to} key={l.to}>
                      <div>{l.text}</div>
                    </a>
                  );
                }

                return (
                  <Link
                    className={styles.buttonLink}
                    to={l.to}
                    key={l.to}
                    onClick={() =>
                      this.setState({
                        opened: false,
                      })
                    }
                  >
                    <div>{l.text}</div>
                  </Link>
                );
              })}
            </div>
            {this.props.myCustomers
              .filter(
                c =>
                  this.props.context.type !== 'private' ||
                  this.props.context.customer.id !== c.id
              )
              .map(customer => (
                <div
                  key={`c_${customer.id}`}
                  className={cn(styles.group, styles.backgroundGray)}
                >
                  <Link
                    className={cn(styles.buttonLink, styles.buttonWithLogo)}
                    to={'/'}
                    onClick={() => {
                      this.props.setContext({
                        type: 'private',
                        id: customer.id,
                      });
                      this.setState({
                        opened: false,
                      });
                    }}
                  >
                    <PrivatButton name={customer.identity.name} private />
                  </Link>
                </div>
              ))}
            {this.props.myCompanyAgreements
              .filter(
                c =>
                  this.props.context.type !== 'companyAgreement' ||
                  this.props.context.companyAgreement.id !== c.id
              )
              .map(companyAgreement => (
                <div
                  key={`ca_${companyAgreement.id}`}
                  className={cn(styles.group, styles.backgroundGray)}
                >
                  <Link
                    className={cn(styles.buttonLink, styles.buttonWithLogo)}
                    to={'https://company.lunsjkollektivet.no'}
                    onClick={() => {
                      this.props.setContext({
                        type: 'companyAgreement',
                        id: companyAgreement.id,
                      });
                      this.props.setSelectedCompanyAgreement(
                        companyAgreement.id
                      );
                      this.setState({
                        opened: false,
                      });
                    }}
                  >
                    <PrivatButton
                      name={companyAgreement.company.name}
                      private={false}
                    />
                  </Link>
                </div>
              ))}
            <div className={cn(styles.group, styles.backgroundGray)}>
              <Link
                className={cn(styles.buttonLink, styles.buttonWithLogo)}
                to={'/logout'}
              >
                <GroupWithLogo icon="lock">Logg ut</GroupWithLogo>
              </Link>
            </div>
          </div>
        </div>
        {!(this.props.admin || this.props.chef) &&
        this.props.myCompanyAgreements.length &&
        this.props.context.type !== 'companyAgreement' ? (
          <Link
            to={'https://company.lunsjkollektivet.no'}
            onClick={() => {
              this.props.setContext({
                type: 'companyAgreement',
                id: this.props.myCompanyAgreements[0].id,
              });
            }}
            className={styles.companyButton}
          >
            Administrer bedriftsavtale
            <div className={cn('fa fa-angle-right', styles.arrow)} />
          </Link>
        ) : null}
        {!(this.props.admin || this.props.chef) &&
        this.props.myCustomers.length &&
        this.props.context.type !== 'private' ? (
          <Link
            to={'/'}
            onClick={() => {
              this.props.setContext({
                type: 'private',
                id: this.props.myCustomers[0].id,
              });
            }}
            className={styles.companyButton}
          >
            Tilbake til Min Profil
            <div className={cn('fa fa-angle-right', styles.arrow)} />
          </Link>
        ) : null}
      </div>
    );
  }
}

export default connect(
  state => ({
    context: selectedContext(state),
    myCustomers: myCustomers(state),
    myCompanyAgreements: agreementsWhereUserIsAdmin(state),
    roles:
      state.me.isAuthenticated && state.me.identity.roles
        ? state.me.identity.roles
        : [],
  }),
  { setContext, setSelectedCompanyAgreement }
)(MinSideButton);
