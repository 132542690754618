export const GET_COMPANY_AGREEMENTS = 'GET_COMPANY_AGREEMENTS',
  GET_COMPANY_AGREEMENTS_ERROR = 'GET_COMPANY_AGREEMENTS_ERROR',
  GET_COMPANIES = 'GET_COMPANIES',
  GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR',
  GET_INSTAGRAM_FEED = 'GET_INSTAGRAM_FEED',
  GET_INSTAGRAM_FEED_ERROR = 'GET_INSTAGRAM_FEED_ERROR',
  GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS',
  GET_HISTORY_LOADING = 'GET_HISTORY_LOADING',
  GET_HISTORY_ERROR = 'GET_HISTORY_ERROR',
  GET_SUBSCRIPTION = 'GET_SUBSCRIPTION',
  GET_SUBSCRIPTION_LOADING = 'GET_SUBSCRIPTION_LOADING',
  GET_SUBSCRIPTION_ERROR = 'GET_SUBSCRIPTION_ERROR',
  ADD_SUBSCRIPTION_LOADING = 'ADD_SUBSCRIPTION_LOADING',
  ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION',
  ADD_SUBSCRIPTION_ERROR = 'ADD_SUBSCRIPTION_ERROR',
  UPDATE_SUBSCRIPTION_LOADING = 'UPDATE_SUBSCRIPTION_LOADING',
  UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS',
  UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR',
  GET_SUBSCRIPTION_TYPES = 'GET_SUBSCRIPTION_TYPES',
  GET_SUBSCRIPTION_TYPES_ERROR = 'GET_SUBSCRIPTION_TYPES_ERROR',
  GET_ADDRESSES = 'GET_ADDRESSES',
  GET_ADDRESSES_ERROR = 'GET_ADDRESSES_ERROR',
  SEARCH_ADDRESS = 'SEARCH_ADDRESS',
  SEARCH_ADDRESS_ERROR = 'SEARCH_ADDRESS_ERROR',
  GET_ALLERGIES = 'GET_ALLERGIES',
  GET_ALLERGIES_ERROR = 'GET_ALLERGIES_ERROR',
  GET_ALLERGIES_LOADING = 'GET_ALLERGIES_LOADING',
  SAVE_ALLERGIES = 'SAVE_ALLERGIES',
  SAVE_ALLERGIES_LOADING = 'SAVE_ALLERGIES_LOADING',
  SAVE_ALLERGIES_ERROR = 'SAVE_ALLERGIES_ERROR',
  GET_MENUS_UPCOMING_LOADING = 'GET_MENUS_UPCOMING_LOADING',
  GET_MENUS_UPCOMING = 'GET_MENUS_UPCOMING',
  GET_MENUS_UPCOMING_ERROR = 'GET_MENUS_UPCOMING_ERROR',
  SAVE_CHOICE_LOADING = 'SAVE_CHOICE_LOADING',
  SAVE_CHOICE = 'SAVE_CHOICE',
  SAVE_CHOICE_ERROR = 'SAVE_CHOICE_ERROR',
  SAVE_PROFILE_LOADING = 'SAVE_PROFILE_LOADING',
  SAVE_PROFILE = 'SAVE_PROFILE',
  SAVE_PROFILE_ERROR = 'SAVE_PROFILE_ERROR',
  ADD_CUSTOMER = 'ADD_CUSTOMER',
  ADD_CUSTOMER_ERROR = 'ADD_CUSTOMER_ERROR',
  ADD_COMPANY = 'ADD_COMPANY',
  ADD_COMPANY_ERROR = 'ADD_COMPANY_ERROR',
  ADD_MENU = 'ADD_MENU',
  ADD_MENU_ERROR = 'ADD_MENU_ERROR',
  GET_CUSTOMERS = 'GET_CUSTOMERS',
  GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR',
  GET_CHOICES = 'GET_CHOICES',
  GET_CHOICES_LOADING = 'GET_CHOICES_LOADING',
  GET_CHOICES_ERROR = 'GET_CHOICES_ERROR',
  SIGNUP = 'SIGNUP',
  SIGNUP_ERROR = 'SIGNUP_ERROR',
  GET_INVOICING_REPORT = 'GET_INVOICING_REPORT',
  GET_INVOICING_REPORT_ERROR = 'GET_INVOICING_REPORT_ERROR',
  GET_PRODUCTION_REPORT = 'GET_PRODUCTION_REPORT',
  GET_PRODUCTION_REPORT_ERROR = 'GET_PRODUCTION_REPORT_ERROR',
  GET_DELIVERY_REPORT = 'GET_DELIVERY_REPORT',
  GET_DELIVERY_REPORT_ERROR = 'GET_DELIVERY_REPORT_ERROR',
  GET_NO_DELIVERY_DAY = 'GET_NO_DELIVERY_DAY',
  GET_NO_DELIVERY_DAY_LOADING = 'GET_NO_DELIVERY_DAY_LOADING',
  GET_NO_DELIVERY_DAY_ERROR = 'GET_NO_DELIVERY_DAY_ERROR';
