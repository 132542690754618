export default state => {
  if (
    !state.me ||
    !state.me.companyAgreementsManager ||
    !Array.isArray(state.me.companyAgreementsManager)
  )
    return [];

  return state.me.companyAgreementsManager.map(
    id => state.companyAgreement.entities[id]
  );
};
