import React from 'react';
import styles from './numberPicker.module.css';
import cn from "classnames";

export default class NumberPicker extends React.Component {
  state = {
    input: null,
  };

  render() {
    return (
      <div className={cn(styles.numberPicker, {
        [styles.column]: this.props.column,
        [styles.square]: this.props.column && this.props.square,
        [styles.big]: this.props.big,
        [styles.colored]: this.props.colored,
        [styles.active]: this.props.value
      })}>
        <div>
          <div onClick={() => this.props.onChange(this.props.value - 1)} className={this.props.green ? styles.gray : ""}>
            <div
              className="fa fa-minus"
            />
          </div>
          <div className={styles.value}>
            <input
              style={{ fontFamily: 'inherit', color: '#3c4249', fontSize: this.props.big || this.props.colored ? '1.1em' : 'auto' }}
              type="text"
              value={this.state.input ? this.state.input : this.props.value}
              onChange={e => this.setState({ input: e.target.value })}
              onBlur={() => {
                let value = parseInt(this.state.input);

                if (!isNaN(value)) {
                  this.props.onChange(value);
                  this.setState({ input: null });
                }
              }}
            />
          </div>
          <div onClick={() => { this.props.onChange(this.props.value + 1) }} className={this.props.green ? styles.green : ""}>
            <div
              className="fa fa-plus"
            />
          </div>
        </div>
      </div>
    );
  }
}
