import React from 'react';
import { Row } from '../../../../components/Grid';

import Step from './Step';

import styles from './steps.module.css';

export default props => (
  <div>
    {props.title ? <h2 className={styles.center}>{props.title}</h2> : ''}
    <Row>
      {props.steps.map((step, i) => (
        <Step
          key={i}
          step={step}
          size={12 / props.steps.length}
          newLine={props.newLine}
          iconBackground={props.iconBackground}
        />
      ))}
    </Row>
  </div>
);
