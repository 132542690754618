import React from 'react';
import cn from 'classnames';

import styles from './product.module.css';
import Link from '../../../CustomLink';

export default ({ title, text, price, imageUrl, alias, big = false }) =>
  alias ? (
    <div
      className={cn(styles.card, {
        [styles.big]: big,
      })}
    >
      <Link to={`/lunsj/${alias}`}>
        <div
          className={cn(styles.image, {
            [styles.big]: big,
          })}
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        />
        <div className={styles.title}>
          <strong>{title}</strong>
          {price && <div>kr {price},-</div>}
        </div>
        {text && <div>{text}</div>}
      </Link>
    </div>
  ) : (
      <div
        className={cn(styles.card, styles.noLink, {
          [styles.big]: big,
        })}
      >
        <div
          className={cn(styles.image, {
            [styles.big]: big,
          })}
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        />
        <div className={styles.title}>
          <strong>{title}</strong>
          {price && <div>kr {price},-</div>}
        </div>
        {text && <div>{text}</div>}
      </div>
    );
