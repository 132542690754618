import selectMeta from '../../selectors/selectMeta';

export const selectCompanyAgreementSubscription = (state, companyAgreementId) =>
  selectCompanyAgreementSubscriptionMeta(state, companyAgreementId).ids.map(
    id => state.companySubscription.entities[id]
  );

export const selectCompanyAgreementSubscriptionMeta = (
  state,
  companyAgreementId
) => selectMeta(state.companySubscription.meta[companyAgreementId]);
