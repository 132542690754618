import React from 'react';

import styles from './arrayInput.module.css';
import { Input3 } from '..';
import { CloseButton } from '../../Button';

class ArrayInput extends React.Component {
  state = {
    errors: [],
  };

  componentDidMount() {
    this.setState({ errors: this.props.values.map(v => true) });
  }

  onChange = (i, value) => {
    let newValues = [...this.props.values];
    let newErrors = [...this.state.errors];
    if (i === this.props.values.length) {
      newValues.push('');
      newErrors.push(true);
    }
    newValues[i] = value;
    newErrors[i] = !this.props.validate(value);
    this.props.onChange(newValues, newErrors.find(e => e) ? true : false);
    this.setState({ errors: newErrors });
  };

  onRemove(index) {
    let newValues = [...this.props.values];
    let newErrors = [...this.state.errors];
    newValues.splice(index, 1);
    newErrors.splice(index, 1);
    this.props.onChange(newValues, newErrors.find(e => e) ? true : false);
    this.setState({ errors: newErrors });
  }

  buildInputs() {
    return [...this.props.values, ''].map((value, index) => {
      return (
        <div className={styles.row} key={index}>
          <div className={styles.verticalCenter}>{index + 1}.</div>
          <div className={styles.input}>
            <Input3
              colored
              value={value}
              onChange={(inputValue, valid) => {
                if (value !== inputValue) {
                  this.onChange(index, inputValue);
                }
                this.setState({ name: valid });
              }}
              validate={this.props.validate}
              withPadding={false}
            />
          </div>
          <div className={styles.remove}>
            {this.props.values.length !== index ? (
              <CloseButton size={30} onClick={() => this.onRemove(index)} />
            ) : (
              ''
            )}
          </div>
        </div>
      );
    });
  }

  render() {
    return <div>{this.buildInputs()}</div>;
  }
}

ArrayInput.defaultProps = {};

export default ArrayInput;
