import React from 'react';

export default ({ meal }) => {
  if (!meal || !meal.nutrition) {
    return <span />;
  }

  const strings = [];

  if (meal.nutrition.kcal && meal.nutrition.kcal !== '') {
    strings.push(`${meal.nutrition.kcal} kcal`);
  }

  if (meal.nutrition.carbohydrate && meal.nutrition.carbohydrate !== '') {
    strings.push(`${meal.nutrition.carbohydrate}g karbohydrat`);
  }

  if (meal.nutrition.fat && meal.nutrition.fat !== '') {
    strings.push(`${meal.nutrition.fat}g fett`);
  }

  if (meal.nutrition.protein && meal.nutrition.protein !== '') {
    strings.push(`${meal.nutrition.protein}g protein`);
  }

  if (strings.length === 0) {
    return <span />;
  }

  return <i>Næringsinnhold: {strings.join(', ')}</i>;
};
