import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Container from '../../Container';
import Loader from '../../Loader';
import Alert from '../../Alert';
import Table from '../../Table';
import Card from '../../Card';
import { BackButton } from '../../Button';
import formatUserFrendlyDate from '../../../utils/formatUserFrendlyDate';

/**
 * Invoice Store functions
 */
import { getCompanyAgreementInvoices } from '../../../stores/invoice/actions';
import selectCompanyAgreementInvoices from '../../../selectors/invoice/selectCompanyAgreementInvoices';
import selectCompanyAgreementInvoicesMeta from '../../../selectors/invoice/selectCompanyAgreementInvoicesMeta';

/**
 * Me Store functions
 */
import { selectCompanyAgreement } from '../../../stores/companyAgreement/selectors';

class InvoiceList extends React.Component {
  componentDidMount() {
    this.props.getCompanyAgreementInvoices(this.props.companyAgreement.id);
  }
  componentDidUpdate() {
    this.props.getCompanyAgreementInvoices(this.props.companyAgreement.id);
  }

  getContent() {
    if (this.props.loading) {
      return <Loader />;
    }
    if (this.props.error) {
      return <Alert color="red">Noe skjedde</Alert>;
    }

    if (this.props.invoices.length === 0) {
      return <Card header="Faktura">Ingen Faktura</Card>;
    }

    var invoices = this.props.invoices.filter(x => x.totalPrice);

    return (
      <Card header="Faktura">
        <Table clickable>
          <thead>
            <tr>
              <th>Fakturadato</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(invoice => {
              const grandTotal = invoice.totalPrice + (invoice.deliveryCost || 0);

              return (
                <tr
                  key={invoice.id}
                  onClick={() =>
                    this.props.history.push(
                      this.props.match.url + '/' + invoice.id
                    )
                  }
                >
                  <td>{formatUserFrendlyDate(moment(invoice.invoiceDate))}</td>
                  <td>{grandTotal}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    );
  }
  render() {
    return (
      <Container
        color="gray"
        withPadding
        height="42vh"
        width={800}
        admin={this.props.admin}
      >
        <BackButton to={this.props.backTo} />
        <div style={{ marginTop: 20 }}>{this.getContent()}</div>
      </Container>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const companyAgreement = selectCompanyAgreement(
      state,
      ownProps.companyAgreementId
    );
    const companyAgreementId = companyAgreement ? companyAgreement.id : null;
    const invoicesMeta = selectCompanyAgreementInvoicesMeta(
      state,
      companyAgreement.id
    );

    return {
      invoices: selectCompanyAgreementInvoices(state, companyAgreement.id),
      loading: invoicesMeta.loading,
      error: invoicesMeta.error,
      companyAgreementId,
      companyAgreement,
    };
  },
  { getCompanyAgreementInvoices }
)(InvoiceList);
