import React from 'react';
import Link from '../CustomLink';
import Container from '../Container';
import cn from 'classnames';

import styles from './productDisplay.module.css';

const Product = props =>
  props.link ? (
    <div className={styles.card}>
      <Link to={props.link}>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${props.imageUrl})` }}
        />
        <div>
          <strong>{props.title}</strong>
        </div>
      </Link>
    </div>
  ) : (
      <div className={cn(styles.card, styles.noLink)}>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${props.imageUrl})` }}
        />
        <div>
          <strong>{props.title}</strong>
        </div>
      </div>
    );

const ProductDisplay = () => {
  return (
    <Container withPadding width={1100}>
      <h1>Se ukens lunsjmenyer</h1>
      <div style={{ textAlign: 'center' }}>
        <h3>Trykk for å velge</h3>
      </div>
      <div className={styles.product_row}>
        <Product
          imageUrl="/images/product/salat.jpg"
          title="Salatlunsj"
          link="https://my.lunsjkollektivet.no/"
        />
        <Product
          imageUrl="/images/product/bl-5.jpg"
          title="Brødlunsj"
          link="https://my.lunsjkollektivet.no/"
        />
        <Product
          imageUrl="/images/product/vegetar.jpg"
          title="Vegetar"
          link="https://my.lunsjkollektivet.no/"
        />
        <Product imageUrl="/images/products/tilbehor.jpg" title="Tilbehør" />
      </div>
    </Container>
  );
};

export default ProductDisplay;
