import fetchUtil from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';

import types from './types';

export const fetch = (...params) => (dispatch, getState) => {
  const key = JSON.stringify(params);
  if (!shouldFetch(getState().fetch[key])) return;

  dispatch({
    type: types.START,
    payload: {
      key,
    },
  });

  return fetchUtil(...params)
    .then(response => {
      dispatch({
        type: types.SUCCESS,
        payload: {
          key,
          response,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: types.ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};
