import React from 'react';
import { connect } from 'react-redux';
import Button from '../Button';
import { Modal, Actions } from '../Modal';
import { Checkbox } from '../Form';
import Loader from '../Loader';

import styles from './style.module.css';

/**
 * Me Store functions
 */
import { updateMyNotificationSettings } from '../../stores/me/actions';

/**
 * Notification Definition Store functions
 */
import {
  selectAll,
  selectAllMeta,
} from '../../stores/notificationDefinition/selectors';

class NotificationConsents extends React.Component {
  state = {
    consents: [],
  };

  approve() {
    this.setState({ saving: true, error: false, success: false });

    this.props
      .updateMyNotificationSettings(
        this.props.notificationConsentsToShow.map(ncts => ({
          notificationDefinitionAlias: ncts.alias,
          textShownToUser: ncts.description,
          emailAllowed: this.getCheckStatus(ncts.alias),
        }))
      )
      .then(() => {
        this.setState({ saving: false, success: true });
        setTimeout(() => this.setState({ success: false }), 2000);
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  }

  getCheckStatus(alias) {
    if (this.state[alias]) return this.state[alias];
    return false;
  }

  buildContent() {
    if (this.props.meta.loading) {
      return <Loader />;
    }

    return (
      <div>
        <h3>Hvordan kan vi kontakte deg?</h3>
        <p style={{ fontSize: '1.1em' }}>
          Vi trenger din godkjennelse for å kunne fortsette å kontakte deg. Vi
          lover å ikke spamme deg og du vil alltid ha mulighet til å endre
          innstillingene senere. Vi ønsker å sende deg følgende mail:
        </p>
        {this.props.notificationConsentsToShow.map(definition => (
          <Checkbox
            key={definition.id}
            className={styles.checkbox}
            checked={this.getCheckStatus(definition.alias)}
            onChange={checked => this.setState({ [definition.alias]: checked })}
          >
            {definition.description}
          </Checkbox>
        ))}
        <Actions>
          <Button
            onClick={() => this.approve()}
            saving={this.state.saving}
            success={this.state.success}
          >
            Lagre
          </Button>
        </Actions>
      </div>
    );
  }

  render() {
    return <Modal open={true}>{this.buildContent()}</Modal>;
  }
}

export default connect(
  state => ({
    notificationDefinitions: selectAll(state),
    meta: selectAllMeta(state),
  }),
  { updateMyNotificationSettings }
)(NotificationConsents);
