import React from 'react';
import cn from 'classnames';
import styles from './card.module.css';

import Link from '../CustomLink';

const Card = ({
  children,
  transparent,
  className,
  header,
  marginTop,
  marginBottom,
  headerExtraLarge,
  color,
  company,
  rounded,
  admin,
  right,
  backTo,
  back,
  withPadding,
  link,
  report,
  isAdmin,
}) => {
  const adminStyle = isAdmin ? styles.cardAdmin : null;
  return (
    <div
      className={cn(
        styles.card,
        {
          [styles.transparent]: transparent || report,
          [styles.gray]: color === 'gray',
          [styles.marginTop]: marginTop,
          [styles.marginBottom]: marginBottom,
          [styles.rounded]: rounded,
          [styles.admin]: admin,
          [styles.withPadding]: withPadding,
          [styles.link]: link,
          [styles.report]: report,
        },
        className,
        adminStyle
      )}
    >
      {header && backTo && back ? (
        <div className={right ? styles.rowReverse : styles.row}>
          <div
            className={cn({
              [styles.header]: !headerExtraLarge,
              [styles.headerExtraLarge]: headerExtraLarge,
              [styles.headerRounded]: rounded,
              [styles.headerRoundedRight]: right,
              [styles.headerRoundedRightCompany]: right && company,
            })}
          >
            {header}
          </div>
          <div
            className={
              right
                ? styles.backButtonRight
                : company
                ? styles.backButtonCompany
                : styles.backButton
            }
          >
            <Link to={backTo}>
              <div className={styles.back}>
                {back} <div className={cn('fa', 'fa-arrow-right')} />
              </div>
            </Link>
          </div>
        </div>
      ) : header ? (
        <div
          className={cn({
            [styles.header]: !headerExtraLarge,
            [styles.headerExtraLarge]: headerExtraLarge,
            [styles.headerRounded]: rounded,
            [styles.headerRoundedRight]: right,
            [styles.headerRoundedRightCompany]: right && company,
          })}
        >
          {header}
        </div>
      ) : null}

      {children}
    </div>
  );
};

Card.defaultProps = {
  marginTop: false,
  marginBottom: false,
};

export default Card;
