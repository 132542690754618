import selectMeta from '../../selectors/selectMeta';

export const selectRangeMetaKey = (start, end) =>
  `${start
    .clone()
    .startOf('day')
    .format()}_${end
    .clone()
    .endOf('day')
    .format()}`;

export const selectCompanyAgreementCredits = (state, companyAgreementId) => {
  const meta = selectCompanyAgreementCreditsMeta(state, companyAgreementId);

  if (!meta) return [];

  return meta.ids.map(id => state.credit.entities[id]);
};

export const selectCompanyAgreementCreditsMeta = (state, companyAgreementId) =>
  selectMeta(state.credit.meta[`company-agreement-${companyAgreementId}`]);

export const selectUserCredits = (state, companyAgreementId) => {
  const meta = selectUserCreditsMeta(state, companyAgreementId);

  if (!meta) return [];

  return meta.ids.map(id => state.credit.entities[id]);
};

export const selectUserCreditsMeta = (state, companyAgreementId) =>
  selectMeta(state.credit.meta[`user-${companyAgreementId}`]);

export const selectRangeCredits = (state, start, end) => {
  const meta = selectRangeCreditsMeta(state, start, end);

  if (!meta) return [];

  return meta.ids.map(id => state.credit.entities[id]);
};

export const selectRangeCreditsMeta = (state, start, end) =>
  selectMeta(state.credit.meta[selectRangeMetaKey(start, end)]);
