import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import SettingCard from '../../SettingCard';

import { selectCompanyAgreementSubscription } from '../../../stores/companySubscription/selectors';

import { getIfNeeded } from '../../../stores/companySubscription/actions';

import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../stores/subscriptionType/actions';
import selectAllSubscriptionTypes from '../../../selectors/subscriptionType/selectAll';
import { selectAllMeta as selectAllSubscriptionTypesMeta } from '../../../stores/subscriptionType/selectors';

class CompanySubscriptionCard extends React.Component {
  componentDidMount() {
    this.props.getAllSubscriptionTypesIfNeeded();
    this.props.getIfNeeded(this.props.companyAgreementId);
  }
  render() {
    let content = 'Ingen Abonnement';

    if (
      this.props.subscriptionTypesMeta.loading ||
      this.props.subscriptionTypesMeta.error
    ) {
      content = '';
    } else if (this.props.subscriptionEntries.length !== 0) {
      const latestSubscription = this.props.subscriptionEntries.reduce(
        (entry, subscription) => {
          if (!entry) return subscription;
          if (subscription.endDate === null) return subscription;
          if (moment(subscription.startDate).isAfter(moment(entry.startDate)))
            return subscription;
          return entry;
        },
        null
      );

      content = latestSubscription.data.entries
        .filter(subscription => subscription.menuAmount > 0)
        .map((subscription, index) => {
          const type = this.props.subscriptionTypes.find(
            type => type.alias === subscription.type
          );
          if (type) {
            return (
              <div key={index} className="mt-2">
                <div>
                  {type.name} / {latestSubscription.days.length}{' '}
                  {latestSubscription.days.length > 1 ? 'dager' : 'dag'}
                </div>
                <div style={{ fontSize: '0.8em' }}>
                  <i>{subscription.allergies.map(a => a.name).join(', ')}</i>
                </div>
              </div>
            );
          }
          return '';
        });
    }

    return (
      <SettingCard
        header="Fast bedriftsbestilling"
        to={this.props.location.pathname + '/subscription'}
        linkText="Endre"
      >
        {content}
      </SettingCard>
    );
  }
}
export default withRouter(
  connect(
    (state, ownProps) => ({
      subscriptionEntries: selectCompanyAgreementSubscription(
        state,
        ownProps.companyAgreementId
      ),
      subscriptionTypes: selectAllSubscriptionTypes(state),
      subscriptionTypesMeta: selectAllSubscriptionTypesMeta(state),
    }),
    { getIfNeeded, getAllSubscriptionTypesIfNeeded }
  )(CompanySubscriptionCard)
);
