import addPrefix from '../../utils/addPrefix';

export default addPrefix('company', [
  'GET_START',
  'GET_SUCCESS',
  'GET_ERROR',
  'GET_ONE_START',
  'GET_ONE_SUCCESS',
  'GET_ONE_ERROR',
  'UPDATE_COMPANY_SUCCESS',
  'DELETE_COMPANY_START',
  'DELETE_COMPANY_SUCCESS',
  'DELETE_COMPANY_ERROR',
]);
