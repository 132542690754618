import React from 'react';
import cn from 'classnames';

import styles from './optionGroup.module.css';

export default ({ label, children, row }) => (
  <div>
    <div>{label}</div>
    <div
      className={cn({
        [styles.optionGroupColumn]: !row,
        [styles.optionGroupRow]: row,
      })}
    >
      {children}
    </div>
  </div>
);
