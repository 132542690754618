import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/spinner.scss';

const Spinner = ({ black }) => {
  return <div className={black ? 'black-spinner' : 'white-spinner'} />;
};

Spinner.propTypes = {
  black: PropTypes.bool,
};

export default Spinner;
