import types from './types';
import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';

import { selectHashtagKey } from './selectors';

export const getHashtagFeed = hashtag => (dispatch, getState) => {
  const key = selectHashtagKey(hashtag);

  if (!shouldFetch(getState().instagram[key])) {
    return;
  }

  dispatch({
    type: types.GET_HASHTAG_FEED_START,
    payload: { key },
  });

  return fetch('/feeds/instagram/hashtag/' + hashtag, 'GET')
    .then(response => {
      dispatch({
        type: types.GET_HASHTAG_FEED_SUCCESS,
        payload: {
          ...response.data,
          key,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: types.GET_HASHTAG_FEED_ERROR,
        payload: {
          error,
          key,
        },
      });
    });
};
