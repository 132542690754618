import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../../../components/Header';
import Tabs from '../../../components/Tabs';
import MenuContainer from './MenuContainer';
import Instagram from '../Instagram';

import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../stores/subscriptionType/actions';
import { selectAll } from '../../../stores/subscriptionType/selectors';

import Alert from '../../../components/Alert/Alert';
import Loader from '../../../components/Loader/Loader';
import Logo from '../../../components/Logo';

class Lunch extends React.Component {
  componentDidMount() {
    this.props.getAllSubscriptionTypesIfNeeded();
  }

  render() {
    if (this.props.meta.error) {
      return (
        <Alert color="red">
          Noe skjedde når vi lastet inn siden. Vennligst prøv igjen senere
        </Alert>
      );
    }

    if (this.props.meta.loading || this.props.subscriptionTypes.length === 0) {
      return <Loader />;
    }

    return (
      <div style={this.props.campaign ? { background: '#d1dabf' } : {}}>
        <Helmet>
          <title>Lunsjkollektivet - Meny</title>
        </Helmet>
        {!this.props.campaign ? (
          <Header />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1rem 1rem 0 1rem',
              maxWidth: 1000,
              margin: '0 auto 2rem auto',
            }}
          >
            <div style={{ width: 100 }}>
              <a href="https://bedrift.lunsjkollektivet.no">
                <Logo />
              </a>
            </div>
            <div>
              <Link to="/company-signup">
                <div
                  style={{
                    borderRadius: '4px',
                    border: '3px solid #ad5763',
                    padding: '1rem 2rem',
                  }}
                >
                  <strong>Få konto nå!</strong>
                </div>
              </Link>
            </div>
          </div>
        )}
        <div style={{ position: 'relative', zIndex: 30 }}>
          <Tabs
            outlined={this.props.campaign}
            tabs={this.props.subscriptionTypes
              .filter(st => st.alias !== 'Drink' && st.active)
              .map(st => ({
                to: st.alias,
                text: st.name,
                exact: true,
                selected: this.props.location.pathname.includes(st.alias),
              }))}
          />
        </div>
        <Switch>
          {this.props.subscriptionTypes.map(st => (
            <Route
              key={st.id}
              exact
              path={
                this.props.campaign
                  ? `/lunsj-kampanje/${st.alias}`
                  : `${this.props.match.url}/${st.alias}`
              }
              render={ownProps => (
                <MenuContainer
                  campaign={this.props.campaign}
                  {...ownProps}
                  type={st}
                />
              )}
            />
          ))}
          <Redirect
            to={
              this.props.campaign
                ? '/lunsj-kampanje/Recommended'
                : '/lunsj/Recommended'
            }
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      subscriptionTypes: selectAll(state, false).filter(
        x =>
          ['Recommended', 'Salad', 'Bread', 'Vegetar'].indexOf(x.alias) !== -1
      ),
      meta: state.subscriptionType.meta,
    }),
    { getAllSubscriptionTypesIfNeeded }
  )(Lunch)
);
