import moment from 'moment';

export default () => {
  const now = new Date();
  const limit = moment()
    .hours(11)
    .minutes(0)
    .seconds(0);

  if (now.getDay() === 6) {
    limit.add(3, 'day');
  } else if (now.getDay() === 0) {
    limit.add(2, 'day');
  } else if (moment().isBefore(limit)) {
    limit.add(1, 'day');
    if (now.getDay() === 5) {
      limit.add(2, 'day');
    }
  } else {
    limit.add(2, 'day');
    if (now.getDay() === 4 || now.getDay() === 5) {
      limit.add(2, 'day');
    }
  }

  return limit;
};
