import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '../Button';
import Link from '../Link';

import styles from './day.module.css';

import getSumForDay from './getSumForDay';
import Header from './components/Header';

import Day from './components/Day';
import LunchBoxOverview from './components/LunchBoxOverview';
import Footer from './components/Footer';
import NewAllergyOrder from './NewAllergyOrder';
import SubscriptionTypeService from '../../services/SubscriptionTypeService';
import { isPastOrderDate } from '../../utils/lunchOverviewUtils';

class DayOrder extends React.Component {
  state = {
    creatingNew: false,
  };

  getTotal = () => {
    return getSumForDay(
      this.props.subscriptionTypes,
      this.props.companyAgreement,
      this.props.values,
      this.props.hasFullWeekDiscount,
      this.props.enablePriceSplitting,
      this.props.deliveryPrice
    );
  };

  getTotalAmount = () => {
    return this.props.values.reduce(
      (total, value) => total + value.menuAmount,
      0
    );
  };

  avbestill = () => {
    this.props.onCancelOrder();
  };

  getPrice = (subscriptionType, companyAgreement) => {
    let companyPrice =
      companyAgreement &&
      companyAgreement.customPrices &&
      companyAgreement.customPrices.find(
        p => p.subscriptionTypeAlias === subscriptionType.alias
      );

    if (this.props.hasFullWeekDiscount) {
      return companyPrice
        ? companyPrice.priceFullWeek
        : subscriptionType.currentPriceWeekly;
    }

    return companyPrice ? companyPrice.price : subscriptionType.currentPrice;
  };

  isRatingEnabled = menu =>
    !!(
      !this.props.admin &&
      this.props.enableRating &&
      menu &&
      this.props.day &&
      this.props.day.isSameOrBefore(moment(), 'day')
    );

  onChange = (group, value) => {
    if (!this.props.autoSave && this.props.saving) return;
    this.props.onChange(
      group.types.map((type, index) => {
        return {
          menuAmount: index === 0 ? value : 0,
          allergies: group.allergies,
          type,
        };
      })
    );
  };

  buildModalContent(groups) {
    return (
      <div>
        <div>
          {groups.map(group => this.buildLunchBoxOverview(group, false, true))}
        </div>
        <div>
          {this.props.enableAllergySelection && this.state.creatingNew ? (
            <NewAllergyOrder
              fullAllergyObject
              subscriptionTypes={this.getSubscriptionTypes().filter(
                st => (this.props.admin || !st.onlyAdmin) && st.isExtra
              )}
              onAdd={(type, allergies) => {
                this.props.onChange([
                  {
                    menuAmount: 1,
                    allergies: allergies,
                    type,
                  },
                ]);
                this.setState({ creatingNew: false });
              }}
              onClose={() => this.setState({ creatingNew: false })}
              admin={this.props.admin}
            />
          ) : (
            <div className={styles.customAllergiesButton}>
              <Link onClick={() => this.setState({ creatingNew: true })}>
                Legg til allergitilpasset
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }

  buildLunchBoxOverview = (group, disabled, extra = false) => {
    const menu = group.meal;
    const menuRating = menu ? this.props.ratings[menu.menuId] : null;
    const savingRating = menu ? this.props.savingRating[menu.menuId] : false;

    const price = this.getPrice(
      group.types[0],
      this.props.companyAgreement,
      this.props.deliveryPrice
    );
    return (
      <LunchBoxOverview
        key={group.key}
        subscriptionTypes={group.types}
        allergies={group.allergies}
        amount={group.amount}
        price={price}
        disabled={disabled}
        onChange={value => this.onChange(group, value)}
        menu={menu}
        companyAgreement={this.props.companyAgreement}
        enableRating={this.isRatingEnabled(menu)}
        rating={menuRating}
        savingRating={savingRating}
        onSubmitRating={rating =>
          this.props.onSubmitRating(menu.menuId, rating)
        }
        extra={extra}
      />
    );
  };

  getSubscriptionTypes = () => {
    return SubscriptionTypeService.getEnabledSubscriptionTypes(
      this.props.subscriptionTypes,
      this.props.companyAgreement,
      this.props.subscriptionTypeUser,
      this.props.day
        ? this.props.day
            .clone()
            .locale('en')
            .format('dddd')
            .toLowerCase()
        : this.props.weekDayString
        ? this.props.weekDayString
        : null,
      this.props.kitchen
    );
  };

  getContent = isDisabled => {
    const totalAmount = this.getTotalAmount();

    if (isDisabled && totalAmount === 0) return null;

    let groups = {};

    let subscriptionTypes = this.props.subscriptionTypes;

    if (!isDisabled) {
      subscriptionTypes = this.getSubscriptionTypes();
    }

    subscriptionTypes.forEach(type => {
      const meal = this.props.menus[type.id];
      let values = this.props.values.filter(v => v.type.id === type.id);
      if (values.filter(v => v.allergies.length === 0).length === 0) {
        values.push({
          allergies: [],
          menuAmount: 0,
        });
      }
      values.forEach(value => {
        const allergies = Array.isArray(value.allergies) ? value.allergies : [];
        const key = `${!meal ? type.id : meal.id}_${type.onlyAdmin}_${
          type.isExtra ? 'isExtra' : 'isNotExtra'
        }_${allergies.map(a => JSON.stringify(a)).join('-')}`;

        if (allergies.length > 0 && value.menuAmount === 0) {
          return;
        }

        if (!groups[key]) {
          groups[key] = {
            types: [],
            meal,
            onlyAdmin: type.onlyAdmin,
            isExtra: type.isExtra,
            allergies,
            amount: 0,
            key,
          };
        }
        groups[key].types.push(type);
        groups[key].amount += value.menuAmount;
      });
    });

    groups = Object.values(groups).map(group => group);

    return (
      <div>
        <div className={styles.productRow}>
          {this.props.withMenu ? (
            <div>
              <LunchBoxOverview
                subscriptionTypes={[
                  {
                    name: 'Hjemmekontorlunsj',
                    imagePath: '/images/homeoffice.jpg',
                  },
                ]}
                amount={this.props.homeMenuAmount}
                onChange={value => this.onChange('hjemmekontorlunsj', value)}
                price={169}
                menu={this.props.menus['59ddd86ab27ad10900000a33']}
                disabled={false}
              />
              <LunchBoxOverview
                subscriptionTypes={[
                  {
                    name: 'Hjemmekontorlunsj Vegetar',
                    imagePath: '/images/homeoffice.jpg',
                  },
                ]}
                amount={this.props.homeMenuAmountVegetar}
                onChange={value =>
                  this.onChange('hjemmekontorlunsj_vegetar', value)
                }
                price={169}
                menu={this.props.menus['59ddd86ab27ad10900000a33']}
                disabled={false}
              />
            </div>
          ) : (
            Object.values(groups)
              .filter(
                group =>
                  (isDisabled || !group.isExtra) &&
                  ((!isDisabled &&
                    (this.props.admin ||
                      !group.onlyAdmin ||
                      group.amount > 0)) ||
                    group.amount > 0) &&
                  group.allergies.length === 0
              )
              .map(group =>
                this.buildLunchBoxOverview(
                  group,
                  isDisabled || (!this.props.admin && group.onlyAdmin)
                )
              )
          )}
          {Object.values(groups)
            .filter(
              group =>
                (isDisabled || !group.isExtra) &&
                ((!isDisabled &&
                  (this.props.admin || !group.onlyAdmin || group.amount > 0)) ||
                  group.amount > 0) &&
                group.allergies.length !== 0
            )
            .map(group =>
              this.buildLunchBoxOverview(
                group,
                isDisabled || (!this.props.admin && group.onlyAdmin)
              )
            )}
        </div>
        <h3>Lyst på noe ekstra?</h3>
        <div className={styles.productRow}>
          {Object.values(groups)
            .filter(
              group =>
                (isDisabled || group.isExtra) &&
                ((!isDisabled &&
                  (this.props.admin || !group.onlyAdmin || group.amount > 0)) ||
                  group.amount > 0) &&
                group.allergies.length === 0
            )
            .map(group =>
              this.buildLunchBoxOverview(
                group,
                isDisabled || (!this.props.admin && group.onlyAdmin),
                true
              )
            )}
        </div>
        {isDisabled || !this.props.enableAllergySelection ? (
          ''
        ) : this.state.creatingNew ? (
          <div>
            <h3>Noen med allergi?</h3>
            <NewAllergyOrder
              fullAllergyObject
              subscriptionTypes={this.getSubscriptionTypes().filter(
                st => (this.props.admin || !st.onlyAdmin) && !st.isExtra
              )}
              onAdd={(type, allergies) => {
                this.props.onChange([
                  {
                    menuAmount: 1,
                    allergies: allergies,
                    type,
                  },
                ]);
                this.setState({ creatingNew: false });
              }}
              onClose={() => this.setState({ creatingNew: false })}
              admin={this.props.admin}
            />
          </div>
        ) : (
          <div
            className={styles.link}
            onClick={() => this.setState({ creatingNew: true })}
          >
            Allergitilpass lunsj
          </div>
        )}{' '}
        <br />
        {isDisabled || !this.props.showButtons ? (
          ''
        ) : (
          <div className={cn(styles.actions)}>
            {!this.props.autoSave ? (
              <Button
                onClick={() => this.props.onSave()}
                success={this.props.success}
                saving={this.props.saving}
                disabled={this.props.disableSaving}
              >
                Lagre
              </Button>
            ) : null}
            {totalAmount === 0 ? (
              ''
            ) : (
              <Link onClick={() => this.avbestill()}>Avbestill</Link>
            )}
          </div>
        )}
      </div>
    );
  };

  render() {
    const total = this.getTotal();
    const totalAmount = this.getTotalAmount();
    const pastOrderDate =
      this.props.day && isPastOrderDate(this.props.day, this.props.admin);

    const isDisabled = pastOrderDate || !this.props.hasActiveSubscription;

    const content =
      this.props.noDeliveryReason || !this.props.hasActiveSubscription
        ? null
        : this.getContent(isDisabled);

    if (!this.props.showWrapper || !this.props.day) {
      return <div>{content}</div>;
    }

    return (
      <Day
        locked={isDisabled}
        date={this.props.day}
        header={
          <Header
            hasActiveSubscription={this.props.hasActiveSubscription}
            noDeliveryReason={this.props.noDeliveryReason}
            totalAmount={totalAmount}
            total={total}
            date={this.props.day}
            deliveryPrice={this.props.deliveryPrice}
            locked={isDisabled}
            saving={!!(this.props.autoSave && this.props.saving)}
            success={!!(this.props.autoSave && this.props.success)}
          />
        }
        footer={<Footer deliveryInfo={this.props.deliveryInfo} />}
      >
        {content}
      </Day>
    );
  }
}

DayOrder.defaultProps = {
  enableRating: false,
  enableAllergySelection: false,
  enablePriceSplitting: false,
  hasFullWeekDiscount: false,
  showWrapper: true,
  showButtons: true,
  disableSaving: false,
  subscriptionTypeUser: 'customer',
  ratings: {},
  savingRating: {},
  menus: {},
  autoSave: false,
};

DayOrder.propTypes = {
  noDeliveryReason: PropTypes.string,
  hasActiveSubscription: PropTypes.bool.isRequired,
  hasFullWeekDiscount: PropTypes.bool,
  showWrapper: PropTypes.bool,
  showButtons: PropTypes.bool,
  disableSaving: PropTypes.bool,
  success: PropTypes.bool,
  saving: PropTypes.bool,
  day: PropTypes.object,
  ratings: PropTypes.object,
  savingRating: PropTypes.object,
  subscriptionTypes: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onCancelOrder: PropTypes.func.isRequired,
  enableRating: PropTypes.bool,
  enableAllergySelection: PropTypes.bool,
  enablePriceSplitting: PropTypes.bool,
  subscriptionTypeUser: PropTypes.string.isRequired,
  autoSave: PropTypes.bool,
};

export default DayOrder;
