import React from 'react';
import Button from '../../Button';
import Link from '../../Link';
import { Modal, Actions } from '../../Modal';
import { Separator } from '../../Card';

export default props => (
  <Modal open={props.open}>
    <h2>Tilleggsprodukter</h2>
    <p>
      Lyst på drikke til møtet? Eller kanskje et mellommåltid? Velg fersk jus
      eller yoghurt med granola og bær om du ønsker det.
    </p>
    <Separator />
    <Actions>
      <Button onClick={() => props.onOrderExtra()}>Tilleggsprodukter</Button>
      <Link onClick={() => props.onContinue()}>Send inn bestilling</Link>
    </Actions>
  </Modal>
);
