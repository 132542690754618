import React from 'react';
import { Link } from 'react-router-dom';

import styles from './button.module.css';

const BackButton = props =>
  props.to ? (
    <Link className={styles.backButton} to={props.to}>
      <div className="fa fa-arrow-left" />
      <div>Tilbake</div>
    </Link>
  ) : (
    <div className={styles.backButton}>
      <div className="fa fa-arrow-left" />
      <div>Tilbake</div>
    </div>
  );

export default BackButton;