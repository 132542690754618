// TODO: Remove after 4service special kitchen onboarding
export default [
  'special4ServiceBuffet',
  'special4ServicePortion',
  'special4ServiceBaguetteAss',
  'special4ServiceBaguetteNoPork',
  'special4ServiceBaguetteNoGluten',
  'special4ServiceBaguetteNoLactose',
  'special4ServiceHummus',
  'special4ServiceChicken',
  'special4ServiceVegetar',
  'special4ServiceChia',
  'special4ServiceFruit',
];
