import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';
import { getKey } from './selectors';

import types from './types';

export const getMeetingFoodForCompanyAgreementIfNeeded = companyAgreementId => (
  dispatch,
  getState
) => {
  const key = getKey(companyAgreementId);
  if (!shouldFetch(getState().meetingFoodOrder.meta[key])) return;

  dispatch({
    type: types.GET_FOR_COMPANY_AGREEMENT_START,
    payload: { key },
  });

  return fetch(
    `/company-agreements/${companyAgreementId}/meeting-food-orders`,
    'GET'
  )
    .then(response => {
      dispatch({
        type: types.GET_FOR_COMPANY_AGREEMENT_SUCCESS,
        payload: {
          key,
          meetingFoodOrders: Array.isArray(response.data) ? response.data : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_COMPANY_AGREEMENT_ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};

export const getMeetingFoodOrderIfNeeded = (
  companyAgreementId,
  meetingFoodOrderId
) => (dispatch, getState) => {
  const key = getKey(companyAgreementId, meetingFoodOrderId);
  const state = getState();
  if (
    state.meetingFoodOrder.entities[meetingFoodOrderId] ||
    !shouldFetch(getState().meetingFoodOrder.meta[key])
  )
    return;

  dispatch({
    type: types.GET_FOR_COMPANY_AGREEMENT_START,
    payload: { key },
  });

  return fetch(
    `/company-agreements/${companyAgreementId}/meeting-food-orders/${meetingFoodOrderId}`,
    'GET'
  )
    .then(response => {
      dispatch({
        type: types.GET_FOR_COMPANY_AGREEMENT_SUCCESS,
        payload: {
          key,
          meetingFoodOrders: [response.data],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_COMPANY_AGREEMENT_ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};

export const updateMeetingFoodOrder = (
  companyAgreementId,
  meetingFoodOrderId,
  data
) => dispatch =>
    fetch(
      `/company-agreements/${companyAgreementId}/meeting-food-orders/${meetingFoodOrderId}`,
      'POST',
      data
    ).then(response => {
      dispatch({
        type: types.UPDATE_SUCCESS,
        payload: {
          meetingFoodOrder: response.data,
        },
      });
    });

export const createMeetingFoodOrder = (companyAgreementId, data) => dispatch =>
  fetch(
    `/company-agreements/${companyAgreementId}/meeting-food-orders`,
    'POST',
    data
  ).then(response => {
    dispatch({
      type: types.CREATE_SUCCESS,
      payload: {
        meetingFoodOrder: response.data,
        key: getKey(companyAgreementId),
      },
    });
    return response.data;
  });

export const deleteMeetingFoodOrder = (
  companyAgreementId,
  meetingFoodOrderId,
  clb
) => dispatch =>
    fetch(
      `/company-agreements/${companyAgreementId}/meeting-food-orders/${meetingFoodOrderId}/cancel`,
      'POST'
    ).then(() => {
      clb();
      dispatch({
        type: types.DELETE_SUCCESS,
        payload: {
          meetingFoodOrderId,
          companyAgreementId,
        },
      });
    });
