import React from 'react';
import { connect } from 'react-redux';
import Container from '../../Container';
import Button, { BackButton } from '../../Button';
import Alert from '../../Alert';
import Card, { Separator } from '../../Card';
import { Input } from '../../Form';

import { update } from '../../../stores/user/actions';
import { selectUser } from '../../../stores/user/selectors';
import validateEmail from '../../../utils/validateEmail';

class Allergies extends React.Component {
  state = { name: '', phone: '', email: '' };

  componentDidMount() {
    this.setState({
      name: this.props.user.name,
      phone: this.props.user.phone,
      email: this.props.user.email,
    });
  }

  componentDidUpdate(prevProps) {
    let newState = {};
    if (
      this.props.user.name !== prevProps.user.name ||
      this.props.user.phone !== prevProps.user.phone
    ) {
      newState.name = this.props.user.name;
      newState.phone = this.props.user.phone;
    }

    if (this.props.user.email !== prevProps.user.email) {
      newState.email = this.props.user.email;
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }
  }

  onSaveUser(e) {
    this.setState({ saving: true, error: false, success: false });

    let data = {
      name: this.state.name,
      phone: this.state.phone,
    };

    if (this.props.isAdmin) {
      data.email = this.state.email;
    }

    this.props
      .update(this.props.userId, data)
      .then(() => {
        this.setState({ saving: false, success: true });
        setTimeout(() => this.setState({ success: false }), 2000);
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  }

  isValid() {
    if (this.state.name.length <= 2 || this.state.phone.length !== 8) {
      return false;
    }

    if (this.props.isAdmin && !validateEmail(this.state.email)) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <Container withPadding color="gray">
        <BackButton to={this.props.backTo} />
        <Card header="Bruker">
          {this.props.isAdmin ? (
            <Input
              label="E-Post"
              value={this.state.email}
              onChange={(value, valid) => {
                this.setState({ email: value, emailValid: valid });
              }}
              validate={validateEmail}
              errorMessage="Navn må være minst 2 bokstaver lang"
            />
          ) : null}
          <Input
            label="Navn"
            value={this.state.name}
            onChange={(value, valid) => {
              this.setState({ name: value, nameValid: valid });
            }}
            validate={string => string.length > 2}
            errorMessage="Navn må være minst 2 bokstaver lang"
          />
          <Input
            label="Telefonnummer"
            value={this.state.phone}
            onChange={(value, valid) => {
              this.setState({ phone: value, phoneValid: valid });
            }}
            validate={string => string.length === 8}
            errorMessage="Telefonnummer må være 8 bokstaver lang"
          />
          <Separator />
          {this.state.error ? <Alert color="red">Noe skjedde</Alert> : ''}
          <Button
            onClick={this.onSaveUser.bind(this)}
            saving={this.state.saving}
            success={this.state.success}
            disabled={!this.isValid()}
          >
            Lagre
          </Button>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: selectUser(state, ownProps.userId),
});

export default connect(
  mapStateToProps,
  { update }
)(Allergies);
