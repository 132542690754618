import React from 'react';

import fetch from '../../../../../utils/fetch';

export default ({ children }) => {
  const [status, setStatus] = React.useState({
    loading: false,
    error: false,
    success: false,
  });

  return children(
    (phone, companyName) => {
      if (phone.length === 0 || companyName.length === 0) {
        return setStatus({
          loading: false,
          error: 'Du må fylle ut bedriftsnavn og telefon',
        });
      }

      setStatus({
        loading: true,
        error: false,
      });

      fetch('/company-lead', 'POST', {
        phone,
        companyName,
      })
        .then(() => {
          setStatus({
            loading: false,
            error: false,
            success: true,
          });
        })
        .catch(() => {
          setStatus({
            loading: false,
            error: 'Noe skjedde',
          });
        });
    },
    status.loading,
    status.error,
    status.success
  );
};
