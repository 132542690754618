import React from 'react';

import sanityClient from '../utils/sanity';

export default props => {
  const [response, setResponse] = React.useState({
    error: false,
    loading: true,
    data: null,
  });

  React.useEffect(() => {
    setResponse({
      ...response,
      loading: true,
    });

    props
      .fetch(sanityClient)
      .then(res => {
        setResponse({
          error: false,
          loading: false,
          data: res,
        });
      })
      .catch(err => {
        setResponse({
          error: err,
          loading: false,
          data: null,
        });
      });
    // eslint-disable-next-line
  }, [props.fetch]);

  return props.children(response.loading, response.error, response.data);
};
