import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  entitiesFromCompanyAgreement: {},
  meta: {
    loading: false,
    error: false,
    loadedAt: null,
    companyAgreement: {
      loading: false,
      error: false,
      loadedAt: null,
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
          error: false,
          loadedAt: null,
        },
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        meta: {
          ...state.meta,
          loading: false,
          error: false,
          loadedAt: moment().unix(),
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: false,
          error: true,
          loadedAt: moment().unix(),
        },
      };
    case types.GET_FROM_CA_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...state.meta,
          companyAgreement: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
          },
        },
      };
    case types.GET_FROM_CA_SUCCESS:
      return {
        ...state,
        entitiesFromCompanyAgreement: {
          ...action.payload.entitiesFromCompanyAgreement,
        },
        meta: {
          ...state.meta,
          ...state.meta,
          companyAgreement: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            id: action.payload.companyAgreementId,
          },
        },
      };
    case types.GET_FROM_CA_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...state.meta,
          companyAgreement: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
          },
        },
      };
    default:
      return state;
  }
};
