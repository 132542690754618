import React from 'react';
import { Row } from '../../../Grid';
import Container from '../../../Container';

import Step from './Step';

export default ({ data }) => (
  <Container width={900}>
    <Row>
      {data.steps.map((step, i) => (
        <Step
          key={i}
          step={step}
          size={12 / data.steps.length}
          newLine={true}
          iconBackground={false}
        />
      ))}
    </Row>
  </Container>
);
