import React from 'react';
import { connect } from 'react-redux';
import Button from '../Button';
import { Modal, Actions } from '../Modal';

import NewLocationForm from './NewLocationForm';

/**
 * Credit Store functions
 */
import { update } from '../../stores/deliveryLocation/actions';

class UpdateDeliveryLocationModal extends React.Component {
  state = {
    name: '',
    floor: '',
    entrance: '',
    deliveryNote: '',
    open: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.deliveryLocation && !!this.props.deliveryLocation) {
      this.setState({ ...this.props.deliveryLocation, open: true });
    }
    if (!!prevProps.deliveryLocation && !this.props.deliveryLocation) {
      this.setState({ open: false });
    }
  }

  save() {
    this.setState({ saving: true, error: false, success: false });

    this.props
      .update(this.props.deliveryLocation.id, this.state)
      .then(() => {
        this.setState({ saving: false, success: true });
        setTimeout(() => this.setState({ success: false }), 2000);

        this.props.onClose();
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  }

  render() {
    if (!this.state.open) return <span />;
    return (
      <Modal open={this.state.open}>
        <div>
          <NewLocationForm
            onDeliveryAddressChange={address => this.setState({ address })}
            onDeliveryLocationChange={deliveryLocation => {
              this.setState(deliveryLocation);
            }}
            deliveryAddress={this.state.address}
            deliveryLocation={this.state}
          />
        </div>
        <Actions>
          <Button
            onClick={() => this.save()}
            saving={this.state.saving}
            success={this.state.success}
          >
            Lagre
          </Button>
          <Button color="gray" onClick={() => this.props.onClose()}>
            Lukk
          </Button>
        </Actions>
      </Modal>
    );
  }
}

export default connect(
  () => ({}),
  { update }
)(UpdateDeliveryLocationModal);
