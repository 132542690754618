import types from './types';
import auth0 from '../../utils/auth0';

const allowedContextTypes = ['private', 'companyAgreement'];

const initialState = {
  context: {
    id: null,
    type: allowedContextTypes[0],
  },
  selectedCompanyAgreement: null,
  selectedKitchen: null,
  selectedCustomer: null,
  kitchens: {
    entities: null,
  },
  customers: [],
  identity: {},
  loading: true,
  isAuthenticated: false,
  toggle_company: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGOUT:
      setTimeout(() => auth0.logout(), 100);

      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    case types.SET_COMPANY_AGREEMENT:
      return {
        ...state,
        selectedCompanyAgreement: action.payload.companyAgreementId,
      };
    case types.GET_ME_START:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ME_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.GET_ME_SUCCESS:
      return {
        ...state,
        ...action.payload,
        customers: action.payload.customers.map(c => c.id),
        companyAgreementsManager: action.payload.companyAgreementsManager.map(
          c => c.id
        ),
        context: {
          ...state.context,
          type:
            action.payload.companyAgreementsManager.length > 0 &&
            window.location.pathname.startsWith('/minbedrift')
              ? 'companyAgreement'
              : 'private',
        },
        loading: false,
        isAuthenticated: true,
      };
    case types.GET_MY_KITCHENS_START:
      return {
        ...state,
        kitchens: {
          ...state.kitchens,
          loading: true,
        },
      };
    case types.GET_MY_KITCHENS_SUCCESS:
      return {
        ...state,
        kitchens: {
          ...state.kitchens,
          loading: false,
          entities: action.payload.entities,
        },
        selectedKitchen: action.payload.entities[0].id,
      };
    case types.GET_MY_KITCHENS_ERROR:
      return {
        ...state,
        kitchens: {
          ...state.kitchens,
          loading: false,
          error: action.payload.error,
          entities: null,
        },
      };
    case types.SET_SELECTED_KITCHEN:
      return {
        ...state,
        selectedKitchen: action.payload.id,
      };
    case types.UPDATE_MY_IDENTITY_SUCCESS:
    case types.UPDATE_ALLERGIES_SUCCESS:
    case types.UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        identity: {
          ...state.identity,
          ...action.payload,
        },
      };
    case types.SET_CONTEXT:
      if (
        process.env.NODE_ENV === 'development' &&
        allowedContextTypes.indexOf(action.payload.type) === -1
      ) {
        console.error('Invalid context type');
        return state;
      }
      return {
        ...state,
        context: {
          id: action.payload.id,
          type: action.payload.type,
        },
      };
    default:
      return state;
  }
};
