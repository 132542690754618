import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
// import PropTypes from 'prop-types';

import Spinner from '../../../components/Spinner';
import Loader from '../../../components/Loader';

import MealNutrition from '../../../components/MealNutrition';
// import Rating from '../../../components/MyLunchCalendar/Rating';
import NumberPicker from '../../../components/NumberPicker';
import Allergy from '../../../components/Allergy';
import typeSupportsAllergies from '../../../utils/typeSupportsAllergies';
import formatUserFrendlyDate from '../../../utils/formatUserFrendlyDate';
import formatPrice from '../../../utils/formatPrice';
import { truncateString } from '../../../utils/stringTransforms';
import useMobileSize from '../../../utils/useMobileSize';
import Chevron from '../../../components/Chevron';
import cn from 'classnames';
import './lunchCalendar.css';

//SubscriptionType Store functions
import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../stores/subscriptionType/actions';
import {
  selectAll as selectAllSubscriptionTypes,
  selectAllMeta as selectAllSubscriptionTypesMeta,
} from '../../../stores/subscriptionType/selectors';

//CompanyAgreement SubscriptionTypes Store functions
import { getForCompanyAgreement as getSubscriptionTypesForCompanyAgreement } from '../../../stores/subscriptionType/actions';
import { selectCompanyAgreementMeta as selectCompanyAgreementSubscriptionTypeMeta } from '../../../stores/subscriptionType/selectors';
import selectCompanyAgreementSubscriptionTypes from '../../../selectors/subscriptionType/selectForCompanyAgreement';

//Menu Store functions
import { getForDayIfNeeded as getMenusForDayIfNeeded } from '../../../stores/menu/actions';
import {
  selectMenusForDay,
  selectMenusForDayMeta,
} from '../../../stores/menu/selectors';

//No Delivery Day Store functions
import { getForMonthIfNeeded as getNoDeliveryDayForMonthIfNeeded } from '../../../stores/noDeliveryDay/actions';
import {
  selectNoDeliveryDaysForMonth,
  selectNoDeliveryDaysForMonthMeta,
} from '../../../stores/noDeliveryDay/selectors';

//Choice Store functions
import {
  getCustomerChoicesForMonthIfNeeded,
  updateCustomerChoiceForDay,
} from '../../../stores/choice/actions';
import {
  selectCustomerChoicesForMonth,
  selectCustomerChoicesForMonthMeta,
} from '../../../stores/choice/selectors';

//Order Store functions
import { getCustomerOrdersForMonthIfNeeded } from '../../../stores/order/actions';
// import {
//   selectCustomerOrdersForMonth,
//   selectCustomerOrdersForMonthMeta,
// } from '../../../stores/order/selectors';

//Subscription Store functions
import {
  selectCustomerSubscription,
  selectCustomerSubscriptionMeta,
} from '../../../stores/subscription/selectors';

//Customer rating Store functions
import {
  getUserRatingsForDay,
  // updateForMenu,
} from '../../../stores/customerRating/actions';
// import {
//   selectUserRatingsForDay,
//   selectUserRatingsForDayMeta,
// } from '../../../stores/customerRating/selectors';

//Customer allergies Store function
import { selectUserAllergies } from '../../../stores/user/selectors';

import SubscriptionService from '../../../services/SubscriptionService';
import SubscriptionTypeService from '../../../services/SubscriptionTypeService';
import isBoxType from '../../../utils/isBoxType';
import firstSubDate from '../../../utils/firstSubDate';

const LunchCalendar = props => {
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  const dispatch = useDispatch();

  const customer = props.customer;

  const subscriptionTypes = useSelector(selectAllSubscriptionTypes);
  const subscriptionTypesMeta = useSelector(selectAllSubscriptionTypesMeta);
  const subscription = useSelector(state =>
    selectCustomerSubscription(state, customer.id)
  );
  const subscriptionMeta = useSelector(state =>
    selectCustomerSubscriptionMeta(state, customer.id)
  );
  const menus = useSelector(state => selectMenusForDay(state, props.day));
  const menusMeta = useSelector(state =>
    selectMenusForDayMeta(state, props.day)
  );
  const subscriptionTypesCompanyAgreement = useSelector(
    selectCompanyAgreementSubscriptionTypes
  );
  const subscriptionTypesCompanyAgreementMeta = useSelector(
    selectCompanyAgreementSubscriptionTypeMeta
  );
  const choices = useSelector(state =>
    selectCustomerChoicesForMonth(state, customer.id, props.month)
  );
  const choicesMeta = useSelector(state =>
    selectCustomerChoicesForMonthMeta(state, customer.id, props.month)
  );
  const userAllergies = useSelector(state =>
    selectUserAllergies(state, customer.identity.id)
  );
  const noDeliveryDays = useSelector(state =>
    selectNoDeliveryDaysForMonth(state, props.month)
  );
  const noDeliveryDaysMeta = useSelector(state =>
    selectNoDeliveryDaysForMonthMeta(state, props.month)
  );
  const noDeliveryDay = noDeliveryDays.find(d =>
    moment(d.date).isSame(props.day, 'date')
  );
  // const orders = useSelector(state => selectCustomerOrdersForMonth(state, customer.id, props.month));
  // const ordersMeta = useSelector(state =>
  //   selectCustomerOrdersForMonthMeta(
  //     state,
  //     customer.id,
  //     props.month
  //   ));
  // const ratings = useSelector(state =>
  //   selectUserRatingsForDay(
  //     state,
  //     customer.identity.id,
  //     props.day
  //   ));
  // const ratingsMeta = useSelector(state =>
  //   selectUserRatingsForDayMeta(
  //     state,
  //     customer.identity.id,
  //     props.day
  //   ));

  useEffect(() => {
    dispatch(getAllSubscriptionTypesIfNeeded());
    customer.companyAgreement &&
      dispatch(
        getSubscriptionTypesForCompanyAgreement(customer.companyAgreement.id)
      );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getNoDeliveryDayForMonthIfNeeded(props.month));
    dispatch(getCustomerChoicesForMonthIfNeeded(customer.id, props.month));
    dispatch(getCustomerOrdersForMonthIfNeeded(customer.id, props.month));

    setInitiallyLoaded(!choicesMeta.loading);
  }, [props.month]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getMenusForDayIfNeeded(props.day));
    dispatch(getUserRatingsForDay(customer.identity.id, props.day));
  }, [props.day]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    !choicesMeta.loading && setInitiallyLoaded(true);
  }, [choicesMeta.loading]);

  const loading =
    subscriptionMeta.loading ||
    subscriptionTypesMeta.loading ||
    subscriptionTypesCompanyAgreementMeta.loading ||
    menusMeta.loading ||
    noDeliveryDaysMeta.loading ||
    !initiallyLoaded;

  if (loading)
    return (
      <div
        className={cn('calendar_container', {
          loading: true,
          blur: props.cancel,
        })}
      >
        <div className="title">
          <div className="row">
            <div
              className={cn('fa', 'fa-angle-left', 'arrow')}
              onClick={props.decrementDay}
            />
            <h3 className="title_day">{formatUserFrendlyDate(props.day)}</h3>
            <div
              className={cn('fa', 'fa-angle-right', 'arrow')}
              onClick={props.incrementDay}
            />
          </div>
        </div>
        <Loader />
      </div>
    );

  if (noDeliveryDay)
    return (
      <div className={cn('calendar_container', { blur: props.cancel })}>
        <div className="title">
          <div className="row">
            <div
              className={cn('fa', 'fa-angle-left', 'arrow')}
              onClick={props.decrementDay}
            />
            <h3 className="title_day">{formatUserFrendlyDate(props.day)}</h3>
            <div
              className={cn('fa', 'fa-angle-right', 'arrow')}
              onClick={props.incrementDay}
            />
          </div>
          <div>{noDeliveryDay.reason}</div>
        </div>
      </div>
    );

  if (!subscription) return <div></div>;

  const entries = {};

  subscriptionTypes.forEach(st => (entries[st.alias] = 0));
  const choice = choices.find(c => moment(c.date).isSame(props.day, 'date'));

  // set all entries from current subscription and choice for day
  if (
    moment(subscription.startDate).isSameOrBefore(props.day) &&
    (!subscription.endDate ||
      moment(subscription.endDate).isSameOrAfter(props.day))
  ) {
    if (choice) {
      if (subscription.type === 'daily') {
        choice.data.entries
          .filter(e => e.menuAmount)
          .forEach(e => (entries[e.type] = e.menuAmount));
      } else {
        choice.data.entries
          .filter(e => e.menuAmount)
          .forEach(e => (entries[e.type] = e.menuAmount));
      }
    } else {
      const dayActive = SubscriptionService.subscriptionAvailableForDay(
        subscription,
        props.day
      );

      if (dayActive) {
        if (subscription.type === 'daily') {
          const currentDay = props.day
            .lang('en')
            .format('dddd')
            .toLowerCase();
          subscription.data.dailyEntries
            .filter(de => de.day === currentDay)
            .forEach(e => {
              e.data.forEach(data => (entries[data.type] = data.menuAmount));
              if (e.accessories) {
                e.accessories.forEach(
                  data => (entries[data.type] = data.menuAmount)
                );
              }
            });
        } else {
          subscription.data.entries.forEach(
            e => (entries[e.type] = e.menuAmount)
          );
        }
      }
    }
  }

  const deliveryLocation = customer.companyAgreement
    ? customer.companyAgreement.deliveryLocation
    : customer.deliveryLocation;

  const types = SubscriptionTypeService.getEnabledSubscriptionTypes(
    subscriptionTypes,
    null,
    'customer',
    props.day
      .clone()
      .locale('en')
      .format('dddd')
      .toLowerCase(),
    deliveryLocation && deliveryLocation.address.postalCode.kitchenId,
    subscriptionTypesCompanyAgreement
  );

  let boxes = types.filter(t => isBoxType(t.alias, types));
  const extras = types.filter(t => !isBoxType(t.alias, types));

  const originalMenus = menus.filter(m => !m.isCopied);
  const copiedMenus = menus.filter(m => m.isCopied);

  copiedMenus.forEach(menu => {
    const original = originalMenus.find(m => m.meal.id === menu.meal.id);

    if (original) {
      const copiedBox = boxes.find(b => b.id === menu.type.id);
      const originalBox = { ...boxes.find(b => b.id === original.type.id) };

      if (copiedBox && originalBox) {
        entries[originalBox.alias] += entries[copiedBox.alias];
        entries[copiedBox.alias] = 0;

        if (copiedBox.alias !== 'Recommended') {
          originalBox.name += ' / ' + copiedBox.name;
        }

        boxes = boxes.filter(
          b => b.id !== copiedBox.id && b.id !== originalBox.id
        );
        boxes.push(originalBox);
      }
    }
  });

  function getSubscriptionType(alias, allSubscriptionTypes) {
    const subscriptionType = allSubscriptionTypes.find(
      obj => obj.alias === alias
    );
    if (subscriptionType === undefined) {
      return;
    }
    return subscriptionType;
  }

  // TODO: Filter for extra amount
  const boxAmount = Object.keys(entries)
    .filter(e => isBoxType(e, subscriptionTypes))
    .reduce((total, e) => total + entries[e], 0);
  const extraAmount = Object.keys(entries)
    .filter(e => !isBoxType(e, subscriptionTypes))
    .reduce((total, e) => total + entries[e], 0);
  const totalPrice = Object.keys(entries)
    .filter(e => entries[e] > 0)
    .reduce((total, e) => {
      const sub = getSubscriptionType(e, types);

      return (total += sub ? sub.currentPrice * entries[e] : 0 * entries[e]);
    }, 0);

  function getCustomerPrice() {
    let price = totalPrice;
    if (customer?.companyAgreement?.discountType === 'amount') {
      price = Math.max(
        0,
        totalPrice - customer.companyAgreement.employeeDiscount
      );
    }
    return Number(parseFloat(price).toFixed(1));
  }

  const save = (type, menuAmount) => {
    if (menuAmount < 0) return;
    entries[type] = menuAmount;
    dispatch(
      updateCustomerChoiceForDay(customer.id, props.day, {
        date: props.day.format(),
        data: {
          entries: Object.keys(entries).map(e => {
            return {
              type: e,
              menuAmount: entries[e],
            };
          }),
        },
      })
    );
  };

  return (
    <div className={cn('calendar_container', { blur: props.cancel })}>
      <div className="title">
        <div className="row">
          <div
            className={cn('fa', 'fa-angle-left', 'arrow')}
            onClick={props.decrementDay}
          />
          <h3 className="title_day">{formatUserFrendlyDate(props.day)}</h3>
          <div
            className={cn('fa', 'fa-angle-right', 'arrow')}
            onClick={props.incrementDay}
          />
        </div>
        {moment(firstSubDate()).isSameOrBefore(props.day, 'date') ? (
          <div>
            <div>
              Totalt {boxAmount} bokser, {extraAmount} tillegg
            </div>
            <div className="price">
              Du betaler: {formatPrice(getCustomerPrice())}
            </div>
            {customer?.companyAgreement?.discountType === 'amount' && (
              <div className="price">
                Bedriften betaler:{' '}
                {formatPrice(
                  Number(
                    parseFloat(
                      customer?.companyAgreement?.employeeDiscount
                    ).toFixed(1)
                  )
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            {' '}
            <div className={cn('fa', 'fa-lock')} style={{ marginRight: 10 }} />
            Denne dagen er låst
          </div>
        )}
      </div>
      {!props.cancel &&
        boxes.filter(st => !props.isDisabled() || entries[st.alias]).length +
          extras.filter(st => !props.isDisabled() || entries[st.alias])
            .length ===
          0 && (
          <div className="info_empty">Ingen bestillinger denne dagen.</div>
        )}
      {!props.cancel && (
        <div>
          <div className="row_products">
            {boxes
              .filter(st => !props.isDisabled() || entries[st.alias])
              .sort(st => st.name)
              .map(st => (
                <LunchBox
                  key={st.id + props.day.format('YYYY-MM-DD')}
                  name={st.name}
                  menu={originalMenus.find(m => m.type.id === st.id)}
                  amount={entries[st.alias]}
                  onChange={amount => save(st.alias, amount)}
                  rating={0}
                  onSubmitRating={() => {}}
                  disabled={
                    props.isDisabled() ||
                    !typeSupportsAllergies(userAllergies, st) ||
                    choicesMeta.loading
                  }
                  price={st.currentPrice}
                />
              ))}
          </div>
          <div className="row_extras">
            {extras
              .filter(st => !props.isDisabled() || entries[st.alias])
              .map(st => (
                <Extra
                  key={st.id + props.day.format('YYYY-MM-DD')}
                  name={st.name}
                  img={st.imagePath}
                  amount={entries[st.alias]}
                  onChange={amount => save(st.alias, amount)}
                  disabled={props.isDisabled() || choicesMeta.loading}
                  price={st.currentPrice}
                />
              ))}
          </div>
        </div>
      )}
      <div style={{ textAlign: 'end', marginRight: '20px' }}>
        <em>*Alle priser inkl. 15% mva</em>
      </div>
    </div>
  );
};

const LunchBox = props => {
  const [info, setInfo] = useState(true);
  const [newAmount, setNewAmount] = useState(props.amount);

  const saving = props.amount !== newAmount;

  const { isMobile } = useMobileSize();

  useEffect(() => {
    setInfo(!isMobile);
  }, [isMobile]);

  const toggleFullInfoMobile = () => {
    if (!props.disabled && isMobile && props.menu) setInfo(prev => !prev);
  };

  return (
    <div
      className={cn('lunchbox_wrapper', {
        disabled: props.disabled && !saving,
        active: props.amount && window.innerWidth <= 1000,
      })}
    >
      {isMobile && props.menu?.meal?.description && (
        <button onClick={() => toggleFullInfoMobile()} className="chevron">
          <Chevron direction="down" size="1em" reversed={info} />
        </button>
      )}
      <div
        className={cn('lunchbox', {
          disabled: props.disabled && !saving,
          active: props.amount && window.innerWidth > 900,
        })}
        onClick={() => toggleFullInfoMobile()}
      >
        <div className="lunchbox_title">
          <div>
            <strong>
              {saving && (
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 30, marginRight: 5 }}>
                    <Spinner black />
                  </div>
                  {props.name}
                </div>
              )}
              {props.amount > 0 && !saving && (
                <span>
                  <div className={cn('fa', 'fa-check')} /> {props.amount + 'x '}{' '}
                </span>
              )}
              {!saving && props.name}
            </strong>
          </div>
          <div>
            {Boolean(props.price && props.price > 0) && (
              <div className="price">{formatPrice(props.price)}</div>
            )}
            {/* {props.menu && !info && (
              <div className="info_link" onClick={() => setInfo(true)}>
                Mer info
              </div>
            )}
            {props.menu && info && (
              <div className="info_link" onClick={() => setInfo(false)}>
                Skjul info
              </div>
            )} */}
          </div>
        </div>
        <div
          className="lunchbox_content"
          onClick={() => {
            if (!props.disabled && !props.amount && !isMobile) {
              setNewAmount(1);
              props.onChange(1);
            }
          }}
        >
          {props.menu && (
            <div>
              {window.innerWidth > 1000 || info
                ? props.menu?.meal?.description
                : truncateString(props.menu?.meal?.description, 40)}
            </div>
          )}
          {props.menu && info && (
            <div className="info">
              <div>
                <i>
                  {props.menu.meal?.allergies.length > 0 && (
                    <div>
                      Inneholder:{' '}
                      {props.menu.meal.allergies
                        .map(a => <Allergy key={a.id} allergyId={a.id} />)
                        .reduce((prev, curr) => [prev, ', ', curr])}
                      {'. '}
                    </div>
                  )}
                </i>
                <MealNutrition meal={props.menu.meal} />
              </div>
              {props.menu.meal.carbonDioxide > 0 && (
                <img
                  src={`/icons/klimato/${parseFloat(
                    props.menu.meal.carbonDioxide
                  ).toFixed(1)}.svg`}
                  alt=" "
                  style={{ width: 70, marginLeft: 10 }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {!props.disabled && (newAmount > 0 || window.innerWidth <= 1000) && (
        <div className="lunchbox_picker">
          <NumberPicker
            value={newAmount}
            onChange={amount => {
              amount >= 0 && setNewAmount(amount);
              amount >= 0 && props.onChange(amount);
            }}
            big
          />
        </div>
      )}
      {/* {props.disabled && (
        props.rating ? (
          <Rating rating={this.props.rating.rating} />
        ) : !props.ratingMeta.loading && (
          <Rating submitRating={this.props.onSubmitRating} />
        )
      )} */}
    </div>
  );
};

const Extra = props => {
  // const [info, setInfo] = useState(false)
  const [newAmount, setNewAmount] = useState(props.amount);

  const saving = props.amount !== newAmount;

  const { isMobile } = useMobileSize();

  return (
    <div
      className={cn('extra_wrapper', {
        disabled: props.disabled && !saving,
        active: props.amount && window.innerWidth <= 1000,
      })}
    >
      <div
        className={cn('extra', {
          disabled: props.disabled && !saving,
          active: props.amount && window.innerWidth > 1000,
        })}
        onClick={() => {
          !props.disabled && !props.amount && !isMobile && setNewAmount(1);
          !props.disabled && !props.amount && !isMobile && props.onChange(1);
        }}
      >
        <div className="extra_title">
          <div>
            <strong>
              {saving && (
                <div style={{ display: 'flex' }}>
                  <div style={{ width: 30, marginRight: 5 }}>
                    <Spinner black />
                  </div>
                  {props.name}
                </div>
              )}
              {props.amount > 0 && !saving && (
                <span>
                  <div className={cn('fa', 'fa-check')} /> {props.amount + 'x '}{' '}
                </span>
              )}
              {!saving && props.name}
            </strong>
          </div>
          <div>
            {Boolean(props.price && props.price > 0) && (
              <div className="price">{formatPrice(props.price)}</div>
            )}
          </div>
        </div>
        {/* <div className="extra_content">
          {props.price && (
            <div>
              {formatPrice(props.price)}
            </div>
          )}
        </div> */}
        {/* <div className="extra_content">
          {info && (
            <div className="info">
              <i>{props.menu && props.menu.meal.allergies.length && (
                <div>
                  Inneholder:{' '}
                  {props.menu.meal.allergies
                    .map(a => <Allergy key={a.id} allergyId={a.id} />)
                    .reduce((prev, curr) => [prev, ', ', curr])}{'. '}
                </div>
              )}</i>
              <MealNutrition meal={props.menu.meal} />.
            </div>
          )}
        </div> */}
        {props.img && (
          <div
            style={{
              backgroundImage: `url(${props.img})`,
            }}
            className="extra_img"
          />
        )}
      </div>
      {!props.disabled && (newAmount > 0 || window.innerWidth <= 1000) && (
        <div className="extra_picker">
          <NumberPicker
            value={newAmount}
            onChange={amount => {
              amount >= 0 && setNewAmount(amount);
              amount >= 0 && props.onChange(amount);
            }}
            big
          />
        </div>
      )}
    </div>
  );
};

export default LunchCalendar;
