import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import styles from './searchableInput.module.css';
import { Input3 } from '..';
import DropDown from './DropDown';

class SearchableInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputFocused: false,
      searchText: '',
      search: '',
      first: true,
    };

    this.search = debounce(this._search, 1000);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.windowClicked.bind(this));
    this.search();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value === null && prevProps.value !== null) {
      this.setState({ searchText: '' });
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.windowClicked.bind(this));
    this.umounted = true;
  }

  windowClicked(e) {
    if (this.state.inputFocused && this.node && !this.node.contains(e.target)) {
      this.setState({ inputFocused: false });
    }
  }

  _search() {
    if (this.umounted) return;
    this.setState({ search: this.state.searchText });
    if (this.props.triggerWhenEmpty || this.state.searchText !== '') {
      this.props.dispatch(this.props.dataFetcher(this.state.searchText));
    }
  }

  render() {
    if (this.props.absolute && !this.state.inputFocused) {
      return (
        <div>
          <strong>{this.props.label}</strong>
          <div
            className={cn(styles.inputNotFocused, {
              [styles.colored]: this.props.colored,
              [styles.inputNotFocusedWithValue]: this.props.value,
            })}
            onClick={e => {
              let newState = {
                inputFocused: true,
                searchText:
                  this.state.searchText === '' && this.props.value
                    ? this.props.valueToString(this.props.value)
                    : this.state.searchText,
              };

              this.search();
              this.setState(newState);
            }}
          >
            {this.props.value
              ? this.props.valueToElement
                ? this.props.valueToElement(this.props.value)
                : this.props.valueToString(this.props.value)
              : this.state.searchText}
          </div>
        </div>
      );
    }

    return (
      <div
        ref={node => {
          this.node = node;
        }}
      >
        <strong>{this.props.label}</strong>
        <div className={styles.inputDropDown}>
          <Input3
            colored={this.props.colored}
            autoFocus
            value={this.state.searchText}
            onChange={(value, valid) => {
              this.setState({ searchText: value, first: false });
              this.search(value);
              this.props.search && value && this.props.search(value);
              if (!this.state.first && value === '') {
                this.props.onChange(null);
              }
            }}
            onFocus={e => {
              let newState = {
                inputFocused: true,
                searchText:
                  this.state.searchText === '' && this.props.value
                    ? this.props.valueToString(this.props.value)
                    : this.state.searchText,
              };

              this.search();

              this.setState(newState);
            }}
          />
          <DropDown
            withoutPrompt={this.props.withoutPrompt}
            rowComponent={this.props.rowComponent}
            search={this.state.search}
            searchText={this.state.searchText}
            onChange={value => {
              this.props.onChange(value);
              this.setState({ searchText: this.props.valueToString(value) });
            }}
            selector={this.props.selector}
            metaSelector={this.props.metaSelector}
            focused={this.state.inputFocused}
            changeFocus={focus => this.setState({ inputFocused: focus })}
            absolute={this.props.absolute}
            triggerWhenEmpty={this.props.triggerWhenEmpty}
          />
        </div>
      </div>
    );
  }
}

SearchableInput.defaultProps = {
  label: '',
  value: null,
  absolute: true,
  triggerWhenEmpty: false,
};

SearchableInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  selector: PropTypes.func.isRequired,
  dataFetcher: PropTypes.func.isRequired,
  rowComponent: PropTypes.func.isRequired,
  valueToString: PropTypes.func.isRequired,
  absolute: PropTypes.bool,
  triggerWhenEmpty: PropTypes.bool,
};

export default connect()(SearchableInput);
