import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './discountPicker.module.css';
import { Input3 } from '../Form';
import DropdownContent from './DropdownContent';
const discountTypes = [
  {
    text: '%',
    value: 'percent',
  },
  {
    text: 'kr',
    value: 'amount',
  },
];

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputFocused: false,
    };
  }

  componentDidMount() {
    document.body.addEventListener('click', this.windowClicked.bind(this));
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.windowClicked.bind(this));
  }

  windowClicked(e) {
    if (this.state.inputFocused && !this.node.contains(e.target)) {
      this.setState({ inputFocused: false });
    }
  }

  getTypeText() {
    const type = discountTypes.find(t => t.value === this.props.type);
    return type ? type.text : 'error';
  }

  render() {
    return (
      <div
        ref={node => {
          this.node = node;
        }}
        className={styles.discountPicker}
      >
        <div>
          <Input3
            type="number"
            min="0"
            className={styles.input}
            value={this.props.amount}
            onChange={value => {
              this.props.onChange(this.props.type, parseInt(value, 10));
            }}
          />
          <div className={styles.inputDropDown}>
            <div
              className={Input3.className}
              onClick={() =>
                this.setState({ inputFocused: !this.state.inputFocused })
              }
            >
              <div className={styles.verticalCenter}>
                <span>{this.getTypeText()}</span>
              </div>
              <div className={cn('fa fa-caret-down', styles.caret)} />
            </div>
            <DropdownContent
              onChange={type => this.props.onChange(type, this.props.amount)}
              discountTypes={discountTypes}
              focused={this.state.inputFocused}
              changeFocus={focus => this.setState({ inputFocused: focus })}
            />
          </div>
        </div>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  label: '',
  value: null,
};

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default Dropdown;
