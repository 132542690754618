import selectMeta from '../../selectors/selectMeta';

export const selectWeekMetaKey = (userId, week) =>
  `${userId}_${week
    .clone()
    .startOf('week')
    .format()}`;

export const selectDayMetaKey = day =>
  `day_${day
    .clone()
    .startOf('day')
    .format()}`;

export const selectUserDayMetaKey = (userId, day) =>
  `${userId}_day_${day
    .clone()
    .startOf('day')
    .format()}`;

export const selectUserRatingsForWeek = (state, userId, week) => {
  const meta = selectUserRatingsForWeekMeta(state, userId, week);

  return meta.ids.map(id => state.customerRating.entities[id]);
};

export const selectUserRatingsForWeekMeta = (state, userId, week) =>
  selectMeta(state.customerRating.meta[selectWeekMetaKey(userId, week)]);

export const selectRatingsForDay = (state, date) => {
  const meta = selectRatingsForDayMeta(state, date);

  return meta.ids.map(id => state.customerRating.entities[id]);
};

export const selectRatingsForDayMeta = (state, date) =>
  selectMeta(state.customerRating.meta[selectDayMetaKey(date)]);





export const selectUserRatingsForDay = (state, userId, day) => {
  const meta = selectUserRatingsForDayMeta(state, userId, day);

  return meta.ids.map(id => state.customerRating.entities[id]);
};

export const selectUserRatingsForDayMeta = (state, userId, day) =>
  selectMeta(state.customerRating.meta[selectUserDayMetaKey(userId, day)]);
