export default (state, companyAgreementId) => {
  const meta = state.companyAgreement.customers.meta[companyAgreementId];

  if (!meta) return [];

  const customers =
    state.companyAgreement.customers.entities[companyAgreementId];

  return customers ? customers : [];
};
