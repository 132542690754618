import React from 'react';

import { getIfNeeded } from '../../stores/address/actions';
import {
  selectAddresses,
  selectAddressesMeta,
} from '../../stores/address/selectors';

import styles from './addressSearch.module.css';
import { SearchableInput2 } from '../Form';

const AddressSearch = props => (
  <SearchableInput2
    label={props.label}
    value={props.choice}
    minimal={props.minimal}
    colored={props.colored}
    done={props.done}
    onClick={() => props.onClick()}
    onChange={props.onChange}
    rowComponent={props => (
      <div>
        <div>
          <strong>{props.entity.addressLine}</strong>
        </div>
        <div>
          {props.entity.postalCode && props.entity.postalCode.code} {props.entity.postalCode && props.entity.postalCode.place}
        </div>
      </div>
    )}
    valueToElement={entity => (
      <div>
        <strong>{entity.addressLine}</strong>
        <div className={styles.postalInfo}>
          {entity.postalCode && entity.postalCode.code} {entity.postalCode && entity.postalCode.place}
        </div>
      </div>
    )}
    valueToString={entity => entity.addressLine}
    disabled={entity => !entity.postalCode || !entity.postalCode.active}
    selector={selectAddresses}
    metaSelector={selectAddressesMeta}
    dataFetcher={search =>
      getIfNeeded(props.postalCode ? `${props.postalCode} ${search}` : search)
    }
  />
);

export default AddressSearch;
