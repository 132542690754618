import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from '../../SettingCard';
import userSourceToText from '../../../utils/userSourceToText';
import { selectUser } from '../../../stores/user/selectors';

const UserInfoCard = props => {
  return (
    <Card
      header="Profil"
      to={props.location.pathname + '/profil'}
      linkText="Endre"
    >
      <div className="d-flex">
        <div>
          <strong>Navn:</strong>
        </div>
        &nbsp;
        <div>{props.user.name}</div>
      </div>
      <div className="d-flex">
        <div>
          <strong>E-post:</strong>
        </div>
        &nbsp;
        <div>{props.user.email}</div>
      </div>
      <div className="d-flex">
        <div>
          <strong>Telefon:</strong>
        </div>
        &nbsp;
        <div>{props.user.phone}</div>
      </div>
      <div>
        <strong>{userSourceToText(props.user.meta.source)}</strong> -
        {props.user.meta.setupDone
          ? 'Fullført oppsett'
          : 'Ikke fullført oppsett'}
      </div>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => ({
  user: selectUser(state, ownProps.userId),
});

export default withRouter(connect(mapStateToProps)(UserInfoCard));
