import React from 'react';

import Link from '../../components/Link';
import Container from '../../components/Container';
import ProductDisplay from '../../components/ProductDisplay';
import ContactPhone from '../../routes/routes/SentryPages/components/ContactPhone';
import Header from '../../components/Header';
import Button from '../../components/Button';

import { Helmet } from 'react-helmet';

import Steps from './Home/Steps';
import styles from './Home/home.module.css';

import visFood from './Home/assets/Illustration-food.svg';
import visAccount from './Home/assets/Illustration-laptop.svg';
import visLocation from './Home/assets/Illustration-map.svg';

class Home extends React.Component {
  render() {
    return (
      <div id="home" className="section">
        <Helmet>
          <title>Lunsjkollektivet - Slik virker det</title>
        </Helmet>
        <Header />
        <div className={styles.companyBackground}>
          <Container withPadding width={1000}>
            <div className={styles.stepBody}>
              <h1 className={styles.textCenter}>
                Enkelt og trivelig for sjef og ansatt
              </h1>
              <Steps
                newLine
                steps={[
                  {
                    img: visAccount,
                    title: 'Ingen administrasjon',
                    content:
                      'Hver ansatt styrer sin egen lunsj ut fra når man er på jobb, så kan kontorsjefen bruke tida si på viktigere saker.*',
                  },
                  {
                    img: visLocation,
                    title: 'Fleksibilitet',
                    content:
                      'Alle med egen bruker bestiller, avbestiller og endrer sin lunsjbestilling enkelt på vår nettside innen kl. 11.00 dagen før.',
                  },
                  {
                    img: visAccount,
                    title: 'Kostnadskontroll',
                    content:
                      'Dere betaler kun for lunsj som blir levert, ingen løpende kostnader og ingen bindingstid!',
                  },
                ]}
              />
              <Steps
                newLine
                steps={[
                  {
                    img: visLocation,
                    title: 'Levering',
                    content:
                      'All lunsj er levert fra oss til kontoret senest 11.15.',
                  },
                  {
                    img: visFood,
                    title: 'Pris',
                    content: '79,13.-',
                  },
                  {
                    img: visFood,
                    title: 'Matsvinn og miljø',
                    content:
                      'Vi produserer kun mat på bestilling, slik at ingen overskuddsmat går i søpla. Lunsjen leveres porsjonspakket i våre egenlagde bokser laget av resirkulert papp.',
                  },
                ]}
              />
              <div className={styles.introBlock}>
                *Vimsete ansatte? Du skal selvsagt få lov til å bestille for
                alle sammen hvis du har veldig lyst ;) **Skikkelig stor bedrift?
                Slå på tråden eller send oss en mail så finner vi ut av det!
              </div>
              <div className="d-flex flex-center" style={{ marginTop: 30 }}>
                <Button size="medium" to="company-signup">
                  Kom i gang
                </Button>
              </div>
            </div>
          </Container>
        </div>
        <ProductDisplay />
        <Container withPadding color="beige">
          <div className={styles.textGroup}>
            <h3>Lunsjkollektivet om ernæring</h3>
            <p>
              Vi er like opptatt av at du skal få i deg mat som er ordentlig bra
              for kroppen din som at den er variert og smaker skikkelig godt.{' '}
              <Link gray to="/filosofi">
                Les mer
              </Link>
              .
            </p>
            <p>Huk av eventuelle allergier i profilen, vi ordner resten.</p>
          </div>
        </Container>
        <Container withPadding>
          <div className={styles.textGroup}>
            <h3>Prøv oss uforpliktende</h3>
            <p>
              Har du noen spørsmål om smått eller stort vil en av oss gjerne
              komme i kontakt med deg! Legg igjen telefonnummeret ditt her så
              tar vi kontakt med en gang.
            </p>
          </div>
          <ContactPhone />
        </Container>
      </div>
    );
  }
}
export default Home;
