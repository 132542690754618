import selectMeta from '../../selectors/selectMeta';

export const selectDeliveryInfos = (state, date) => {
  const meta = selectDeliveryInfosMeta(state, date);
  if (!meta) return [];

  return meta.ids.map(id => state.deliveryInfo.entities[id]);
};

export const selectDeliveryInfosMeta = (state, date) => {
  return selectMeta(state.deliveryInfo.meta[getKey(date)]);
};

export const selectDeliveryInfosForCustomerWeek = (state, customerId, week) => {
  const meta = selectDeliveryInfosForCustomerWeekMeta(state, customerId, week);
  if (!meta) return [];

  return meta.ids.map(id => state.deliveryInfo.entities[id]);
};

export const selectDeliveryInfosForCustomerWeekMeta = (
  state,
  customerId,
  week
) => {
  return selectMeta(
    state.deliveryInfo.meta[getKeyForCustomerWeek(customerId, week)]
  );
};

export const getKey = date => date.format('YYYY-MM-DD');
export const getKeyForCustomerWeek = (customerId, week) =>
  `${customerId}-${week
    .clone()
    .startOf('week')
    .format('YYYY-MM-DD')}`;
