import selectMeta from '../../selectors/selectMeta';

export const selectDeliveryLocations = (state, deliveryAddress) => {
  const meta = selectDeliveryLocationsMeta(state, deliveryAddress);
  if (!meta) return [];

  return meta.ids.map(id => state.deliveryLocation.entities[id]);
};

export const selectDeliveryLocationsMeta = (state, deliveryAddress) => {
  return selectMeta(
    state.deliveryLocation.meta[getDeliveryAddressKey(deliveryAddress)]
  );
};

export const getDeliveryAddressKey = deliveryAddress =>
  `${deliveryAddress.addressLine}-${deliveryAddress.postalCode.code}`;

export const getById = (state, id) => state.deliveryLocation.entities[id];
