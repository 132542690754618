import React from 'react';
import styles from './lunch.module.css';

export default props => (
  <div>
    <h4 className={styles.dayTitle}>{props.day}</h4>
    <div className={styles.dayDate}>{props.date}</div>
    <div className={styles.dayText}>{props.children}</div>
  </div>
);
