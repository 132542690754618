import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
  counts: {},
};

const updateEntities = (state, entities) => {
  return Object.values(entities).reduce(
    (state, entity) => {
      if (
        state[entity.id] &&
        state[entity.id].events &&
        (!entity.events || entity.events.length === 0)
      ) {
        state[entity.id] = {
          ...entity,
          events: state[entity.id].events,
        };
      } else {
        state[entity.id] = {
          ...entity,
        };
      }
      return state;
    },
    { ...state.entities }
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.search]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        entities: updateEntities(state, action.payload.entities),
        meta: {
          ...state.meta,
          [action.payload.search]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
        counts: {
          ...state.counts,
          [action.payload.totalKey]: action.payload.total,
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.search]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    default:
      return state;
  }
};
