import React from 'react';
import cn from 'classnames';
import styles from './weekPicker.module.css';

export default class WeekPicker extends React.Component {
  state = { expanded: false };
  render() {
    return (
      <div
        className={cn(styles.weekPicker, {
          [styles.background]: this.props.background,
        })}
      >
        <div>
          <div className={styles.picker}>
            <div
              className={cn('fa fa-chevron-left', styles.arrow)}
              onClick={() =>
                this.setState({ expanded: false }, () =>
                  this.props.onChange(this.props.week.clone().add(-1, 'week'))
                )
              }
            />
            <div>
              <div
                className={cn(styles.weekNumberText, styles.dropDownToggle)}
                onClick={() =>
                  this.setState({ expanded: !this.state.expanded })
                }
              >
                <div>Uke {this.props.week.isoWeek()}</div>
                <div className={styles.downChevron}>
                  <div className="fa fa-chevron-down" />
                </div>
              </div>
            </div>
            <div
              className={cn('fa fa-chevron-right', styles.arrow)}
              onClick={() =>
                this.setState({ expanded: false }, () =>
                  this.props.onChange(this.props.week.clone().add(1, 'week'))
                )
              }
            />
          </div>
          {this.state.expanded ? (
            <div className={styles.weekDropdown}>
              {[...Array(4)].map((x, i) => (
                <div
                  key={i}
                  onClick={() =>
                    this.setState({ expanded: false }, () =>
                      this.props.onChange(
                        this.props.week.clone().add(i + 1, 'week')
                      )
                    )
                  }
                  className={styles.weekNumber}
                >
                  <div className={styles.weekNumberText}>
                    Uke{' '}
                    {this.props.week
                      .clone()
                      .add(i + 1, 'week')
                      .isoWeek()}
                  </div>
                  <div>
                    {this.props.week
                      .clone()
                      .add(i + 1, 'week')
                      .startOf('isoWeek')
                      .format('D. MMMM')}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
