import React from 'react';
import { connect } from 'react-redux';
import Button from './Button';
import { Modal, Actions } from './Modal';
import Link from './Link';

/**
 * Me Store functions
 */
import { approveConsent } from '../stores/me/actions';

class ConfirmInvoice extends React.Component {
  state = {};
  approve() {
    this.setState({ saving: true, error: false, success: false });

    this.props
      .approveConsent(this.props.vilkarAndPolicyAlias)
      .then(() => {
        this.setState({ saving: false, success: true });
        setTimeout(() => this.setState({ success: false }), 2000);

        window.dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'Consent',
          eventAction: 'Approved',
          eventLabel: 'vilkar-og-personvern',
        });
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  }
  render() {
    return (
      <Modal open={true}>
        <p style={{ fontSize: '1.1em' }}>
          For å kunne fortsette å bruke nettsiden må du godkjenne{' '}
          <Link to={'/vilkar'} openInNewTab>
            generelle vilkår
          </Link>{' '}
          og{' '}
          <Link to={'/personvern'} openInNewTab>
            personvernerklæringen
          </Link>.
        </p>
        <Actions>
          <Button
            onClick={() => this.approve()}
            saving={this.state.saving}
            success={this.state.success}
          >
            Godkjenn
          </Button>
        </Actions>
      </Modal>
    );
  }
}

export default connect(() => ({}), { approveConsent })(ConfirmInvoice);
