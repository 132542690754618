import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';

import types from './types';

export const getForWeekForCompanyAgreementIfNeeded = (
  companyAgreementId,
  week
) => (dispatch, getState) => {
  const startDate = week.clone().startOf('week');
  const endDate = week.clone().endOf('week');
  const key = startDate.format() + endDate.format() + companyAgreementId;
  if (!shouldFetch(getState().singleOrder.meta[key])) return;

  dispatch({
    type: types.GET_FOR_COMPANY_AGREEMENT_START,
    payload: { key },
  });

  return fetch(
    `/company-agreements/${companyAgreementId}/single-orders/${startDate.format(
      'YYYY-MM-DD'
    )}/${endDate.format('YYYY-MM-DD')}`,
    'GET'
  )
    .then(response => {
      dispatch({
        type: types.GET_FOR_COMPANY_AGREEMENT_SUCCESS,
        payload: {
          key,
          singleOrders: Array.isArray(response.data) ? response.data : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_COMPANY_AGREEMENT_ERROR,
        payload: {
          key,
          error,
        },
      });
    });
};

export const updateSingleOrder = (companyAgreementId, date, data) => dispatch =>
  fetch(
    `/company-agreements/${companyAgreementId}/single-orders/${date.format(
      'YYYY-MM-DD'
    )}`,
    'POST',
    data
  ).then(response => {
    dispatch({
      type: types.UPDATE_SUCCESS,
      payload: {
        key:
          date
            .clone()
            .startOf('week')
            .format() +
          date
            .clone()
            .endOf('week')
            .format() +
          companyAgreementId,
        singleOrder: response.data,
      },
    });
  });
