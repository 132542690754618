import React from 'react';
import { connect } from 'react-redux';
import Card, { Separator } from '../../Card';
import Container from '../../Container';
import Button, { BackButton } from '../../Button';
import Checkbox from '../../Form/Checkbox';
import DeliveryLocationPicker from '../../DeliveryLocationPicker';

import { selectCompanyAgreement } from '../../../stores/companyAgreement/selectors';
import {
  updateCompanyAgreementDeliveryLocation,
  updateCompanyAgreementDeliveryCost,
} from '../../../stores/companyAgreement/actions';

class DeliveryAddress extends React.Component {
  state = {
    deliveryLocation: null,
    eligibleForDeliveryCost: true,
  };

  componentDidMount() {
    this.updateStateLocation();
    this.updateStateCost();
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.companyAgreement ||
      (this.props.companyAgreement &&
        this.props.companyAgreement.deliveryLocation !==
        prevProps.companyAgreement.deliveryLocation)
    ) {
      this.updateStateLocation();
    }
    if (
      !prevProps.companyAgreement ||
      (this.props.companyAgreement &&
        this.props.companyAgreement.eligibleForDeliveryCost !==
        prevProps.companyAgreement.eligibleForDeliveryCost)
    ) {
      this.updateStateCost();
    }
  }

  updateStateLocation() {
    this.setState({
      deliveryLocation:
        this.props.companyAgreement &&
          this.props.companyAgreement.deliveryLocation
          ? { ...this.props.companyAgreement.deliveryLocation }
          : null,
    });
  }

  updateStateCost() {
    this.setState({
      eligibleForDeliveryCost:
        this.props.companyAgreement &&
        this.props.companyAgreement.eligibleForDeliveryCost,
    });
  }

  save = () => {
    this.setState({ saving: true, error: false, success: false });

    const eligibleForDeliveryCost = this.state.eligibleForDeliveryCost;

    this.props
      .updateCompanyAgreementDeliveryLocation(
        this.props.companyAgreement.id,
        this.state.deliveryLocation
      )
      .then(() => {
        if (this.props.admin) {
          this.props.companyAgreement.eligibleForDeliveryCost !== eligibleForDeliveryCost &&
            this.props.updateCompanyAgreementDeliveryCost(
              this.props.companyAgreement.id,
              { eligibleForDeliveryCost }
            );
        }
      })
      .then(() => {
        this.setState({ saving: false, success: true, locationSaved: true });
        setTimeout(() => this.setState({ success: false }), 2000);
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  };

  render() {
    return (
      <Container withPadding color="gray" admin={this.props.admin} width={800}>
        {this.props.message ? this.props.message : ''}
        {this.props.backTo ? <BackButton to={this.props.backTo} /> : ''}
        <Card header="Leveringsadresse">
          <DeliveryLocationPicker
            onChange={deliveryLocation =>
              deliveryLocation && deliveryLocation.address
                ? this.setState({
                  deliveryLocation: {
                    ...deliveryLocation,
                    address: {
                      ...deliveryLocation.address,
                      addressLine: deliveryLocation.address.address,
                    },
                  },
                })
                : this.setState({
                  deliveryLocation: null,
                })
            }
            deliveryLocation={this.state.deliveryLocation}
            onReset={() => this.setState({ locationSaved: false })}
            locationSaved={this.state.locationSaved}
            admin={this.props.admin}
          />
          {this.props.admin ? (
            <div>
              <h4>Fraktkostnad</h4>
              <Checkbox
                checked={this.state.eligibleForDeliveryCost}
                onChange={eligibleForDeliveryCost =>
                  this.setState({ eligibleForDeliveryCost })
                }
              >
                Betaler for frakt
              </Checkbox>
            </div>
          ) : null}
          <Separator />
          <Button
            onClick={this.save}
            saving={this.state.saving}
            success={this.state.success}
            disabled={
              !this.props.companyAgreement ||
              !this.state.deliveryLocation ||
              (this.state.deliveryLocation &&
                this.props.deliveryLocation &&
                this.state.deliveryLocation.id ===
                this.props.companyAgreement.deliveryLocation.id &&
                this.state.eligibleForDeliveryCost ===
                this.props.companyAgreement.eligibleForDeliveryCost)
            }
          >
            Endre
          </Button>
        </Card>
      </Container>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      companyAgreement: selectCompanyAgreement(
        state,
        ownProps.companyAgreementId
      ),
    };
  },
  { updateCompanyAgreementDeliveryLocation, updateCompanyAgreementDeliveryCost }
)(DeliveryAddress);
