import React, { Component } from 'react';
import { connect } from 'react-redux';

import CreditCard from '../../../../components/payment/CreditCard';
import Button, { BackButton } from '../../../../components/Button';
import Container from '../../../../components/Container';
import CreditCardForm from '../../../../components/CreditCardForm';
import Card, { Separator } from '../../../../components/Card';
import { cardIsValid } from '../../../../utils/validateCard';

/**
 * Me Store functions
 */
import selectedCustomer from '../../../../selectors/me/selectedCustomer';
import {
  deleteCard,
  addCard,
  getAddCardToken,
} from '../../../../stores/me/actions';
import { getAllForUserIfNeeded as getCreditsForUserIfNeeded } from '../../../../stores/credit/actions';
import {
  selectUserCredits,
  selectUserCreditsMeta,
} from '../../../../stores/credit/selectors';

class ManagePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setupIntent: false,
    };
  }

  componentDidMount() {
    this.props.getCreditsForUserIfNeeded(this.props.userId);
  }

  componentDidUpdate() {
    this.props.getCreditsForUserIfNeeded(this.props.userId);
  }

  onSetupCompleted = setupIntent => {
    this.setState({ setupIntent });
  };

  onAddCardClicked = () => {
    if (!this.state.setupIntent) {
      return;
    }

    this.setState({ saving: true, error: false, success: false });

    this.props
      .addCard(this.props.customer.id, this.state.setupIntent.payment_method)
      .then(() => {
        this.props.onDone();
        this.setState({ saving: false, success: true });
        setTimeout(() => this.setState({ success: false }), 2000);

        window.dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'Private settings',
          eventAction: 'Update',
          eventLabel: 'Add card',
        });
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  };

  onRemoveCardClicked = card => {
    this.setState({ saving: true, error: false, success: false });

    this.props
      .deleteCard(this.props.customer.id, card.id)
      .then(() => {
        this.setState({ saving: false, success: true });
        setTimeout(() => this.setState({ success: false }), 2000);

        window.dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'Private settings',
          eventAction: 'Update',
          eventLabel: 'Remove card',
        });
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  };

  buildContent() {
    const cards = this.props.cards.filter(card => cardIsValid(card))
    if (cards.length === 0 ) {
      return (
        <div>
          <CreditCardForm
            getToken={() => this.props.getAddCardToken(this.props.customer.id)}
            onSetupCompleted={this.onSetupCompleted}
            companyAgreement={this.props.customer.companyAgreement}
            onSave={() => this.onAddCardClicked()}
            disabled={!this.state.setupIntent}
            saving={this.state.saving}
            success={this.state.success}
          />
        </div>
      );
    }
    return cards.map(card => (
      <div key={card.id} className="card">
        <CreditCard
          name={this.props.name}
          number={`************${card.last4}`}
          expiry={`${card.expirationMonth < 10 ? '0' : ''}${card.expirationMonth
            }/${card.expirationYear}`}
        />
        <Separator />
        <Button
          onClick={() => this.onRemoveCardClicked(card)}
          saving={this.state.saving}
          success={this.state.success}
        >
          Slett kortet
        </Button>
      </div>
    ));
  }

  render() {
    return (
      <Container withPadding color="gray">
        <BackButton to="/innstillinger" />
        <Card header="Betaling">
          {this.buildContent()}
          {!this.props.credits.length ? null :
            <div><Separator />
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '1.2em',
                  marginBottom: '10px',
                }}
              >
                Kreditt
          </div >
              <div style={{ marginBottom: '10px' }}>Du har totalt kr {this.props.credits.reduce((credit, c) => credit + c.remaining, 0)},- i kreditt.</div>
              {this.props.credits
                .map(credit => <div><strong>{credit.comment}:</strong> kr {credit.total},- ble kredittert, kr {credit.remaining},- gjenstår.</div>)}
            </div>}
        </Card>
      </Container>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const creditsMeta = selectUserCreditsMeta(state, ownProps.userId);
    return {
      name: state.me.identity.name,
      customer: selectedCustomer(state),
      cards: selectedCustomer(state).cards,
      credits: selectUserCredits(state, ownProps.userId),
      loading: creditsMeta.loading,
      error: creditsMeta.error,
    };
  },
  { deleteCard, addCard, getAddCardToken, getCreditsForUserIfNeeded }
)(ManagePayment);
