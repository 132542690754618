import moment from 'moment';
import types from './types';

const initialState = {
  entities: {},
  meta: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CUSTOMER_CHOICES_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: true,
            cancelling: false,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_CUSTOMER_CHOICES_SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities,
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            cancelling: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
      };
    case types.GET_CUSTOMER_CHOICES_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            cancelling: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case types.UPDATE_CHOICE_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            ...state.meta[action.payload.key],
            loading: true,
            cancelling: false,
            error: false,
          },
        },
      };
    case types.UPDATE_CHOICE_SUCCESS:
      let newIds = state.meta[action.payload.key]
        ? [...state.meta[action.payload.key].ids]
        : [];
      if (newIds.indexOf(action.payload.choice.id) === -1) {
        newIds.push(action.payload.choice.id);
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.choice.id]: action.payload.choice,
        },
        meta: {
          ...state.meta,
          [action.payload.key]: {
            loading: false,
            cancelling: false,
            error: false,
            loadedAt: moment().unix(),
            ids: newIds,
          },
        },
      };
    case types.UPDATE_CHOICE_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.key]: {
            ...state.meta[action.payload.key],
            loading: false,
            cancelling: false,
            error: true,
          },
        },
      };
    case types.CANCEL_CHOICES_START:
      const newMeta = state.meta;

      action.payload.keys.forEach(key =>
        newMeta[key] = {
          ...state.meta[key],
          cancelling: true,
          loading: false,
          error: false,
        });

      return {
        ...state,
        meta: newMeta
      };
    case types.CANCEL_CHOICES_SUCCESS:
      const cancelMeta = state.meta;
      const cancelEntities = state.entities;

      action.payload.keys.forEach(key => {
        let cancelIds = state.meta[key]
          ? [...state.meta[key].ids]
          : [];

        action.payload.choices
          .filter(c => key.includes((moment(c.date).format("YYYY-MM"))))
          .forEach(c => {
            const existingKey = Object.keys(cancelEntities)
              .find(e => moment(cancelEntities[e].date).isSame(moment(c.date), 'date'));

            if (cancelIds.indexOf(c.id) === -1) {
              if (existingKey) {
                cancelIds = cancelIds.filter(id => id !== existingKey);
              }
              cancelIds.push(c.id);
            }
            if (existingKey) {
              delete cancelEntities[existingKey];
            }
            cancelEntities[c.id] = c
          });

        cancelMeta[key] = {
          cancelling: false,
          loading: false,
          error: false,
          loadedAt: moment().unix(),
          ids: cancelIds,
        };
      });

      return {
        ...state,
        entities: cancelEntities,
        meta: cancelMeta
      }
    case types.CANCEL_CHOICES_ERROR:
      const errorMeta = state.meta;

      action.payload.keys.forEach(key =>
        errorMeta[key] = {
          ...state.meta[key],
          cancelling: false,
          loading: false,
          error: true,
        });

      return {
        ...state,
        meta: errorMeta
      };
    default:
      return state;
  }
};
