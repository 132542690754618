import React from 'react';
import moment from 'moment';

import Header from '../../components/Header';
import Container from '../../components/Container';
import formatUserFrendlyDate from '../../utils/formatUserFrendlyDate';

export const lastChange = '03-06-2022'; //DD-MM-YYYY
export const majorVersion = '3';
export const email = 'hei@lunsjkollektivet.no';

export default () => (
  <div>
    <Header withoutCard image="/images/winter/background.jpg" height={300} />
    <Container withPadding>
      <h1 style={{ textAlign: 'center' }}>Generelle vilkår</h1>
      <p style={{ textAlign: 'center' }}>
        <em>
          Ver venleg å les våre generelle vilkår nedanfor før du registrerer deg
          eller di bedrift som kunde av Lunsjkollektivet. Ved registrering og
          bestilling av vår teneste, godkjenner du våre vilkår og betingelser.
        </em>
      </p>
      <p style={{ textAlign: 'center' }}>
        <em>
          Desse generelle vilkåra (&ldquo;vilkår&rdquo;) gjelder for deg som
          kunde (&ldquo;kunden&rdquo;) og Lunsjkollektivet AS,
          organisasjonsnummer 919 366 265, adresse Krambugata 2, 7011 Trondheim
          (&ldquo;Lunsjkollektivet&rdquo;), ved bestilling og levering av lunsj
          til bedrift gjennom vår nettside (&ldquo;plattform&rdquo;). Ved å
          registrere ein konto hjå Lunsjkollektivet eller bruke vår Plattform,
          bekrefter du å ha lest om samtykker til Vilkår. Ved å bruke
          Plattformen kan du bestille lunsj til deg privat på kontoret eller til
          alle ansatte samla i di bedrift.
        </em>
      </p>
      <p style={{ textAlign: 'center' }}>
        <em>
          Om du har spørsmål til våre Vilkår, kan du ta kontakt med vår
          kundeservice.
        </em>
      </p>

      <h3>1. Om tjenesten</h3>
      <p>
        Lunsjkollektivet er ei fleksibel abonnementsordning, teneste for
        levering av bedriftslunsj. Det er inga oppstartsavgift eller
        bindingstid.
      </p>
      <p>
        Du administrerer enkelt ditt abonnement og dine bestillinger via eigen
        profil på vår nettside. Det er også mogleg å bestille felles lunsj samla
        til bedrift.
      </p>

      <h3>1.1 Produktet Lunsjkollektivet tilbyr</h3>
      <p>
        Sunn, bærekraftig og god lunsj. Porsjonspakka til kvar ansatt for å
        unngå matsvinn. Varierande meny frå dag til dag, og ulike val for
        standard oppsett. Ulike tilvalsprodukter er også tilgjengelig som
        supplement til lunsjen.
      </p>
      <p>
        Eksakte ingredienser er lista opp i våre menyer og vi merker alle
        Produkt i henhold til 14 merkepliktige allergen. Vi er per i dag ikkje
        pliktig til å merke andre mindre allergener utover dette, men vi
        tilrettelegger og tilpasser for allergier så langt det er mogleg.
      </p>

      <h3>1.2 Bestilling og levering</h3>
      <p>
        Siste frist for å endre bestilling er kl.11:00 dagen før levering. Dette
        gjelder alle dagar i veka, med unntak av bestilling for måndag, som har
        frist fredag 11:00.
      </p>
      <p>
        Vi bruker eksternt leveringsbyrå som leverer lunsjen til dykk innan
        09:15-11:15 kvar dag. Har det skjedd feil med levering eller dykk har
        spørsmål, er det best å ta kontakt med oss via chat eller kontortelefon
        (72909005) for hurtig svar.
      </p>
      <p>
        Alle skal få levert lunsj innan 11.30, med mindre anna avtale er inngått
        og godkjent av oss. Skulle det inntreffe vanskelig køyrefohold på grunn
        av v&ecirc;r, kø eller liknande, må forsinkelse påregnes.
      </p>

      <h3>1.3 Leveringsvilkår</h3>
      <p>
        Når du legger inn bestilling på vår Plattform, aksepterer og godkjenner
        du at Produktet som er bestilt blir levert til leveringspunktet som er
        oppretta for den aktuelle bestilling.
      </p>
      <p>
        Du må vere til stades på oppgitt leveringspunkt for å ta i mot
        Produktet. Om du ikkje er tilgjengelig for å motta bestilling ved
        levering, kan Lunsjkollektivet levere produktet ved dør. Ved levering
        ringer vi alltid opp, men om svar uteblir 2 gonger blir levering satt
        ved dør og bilde av leveransen sendt til kontaktperson. Når produktet er
        levert på avtalt adresse, vil risikoen for Produktet vere ditt ansvar.
      </p>

      <h3>2. Betaling</h3>
      <p>
        Betaling skjer kostnadsfritt, ved trekk frå registrert betalingskort i
        din profil. Bedrift setjer sjølv opp kostnadsfordeling for Produktet,
        ved å fordele kostnad per lunsj mellom bedrift og ansatt.
      </p>
      <p>
        Vi kan sende ut faktura om ein ynskjer dette, men vil då gjere dykk
        merksam på at det påløper fakturagebyr på 69 kr.
      </p>
      <p>
        Faktura blir sendt ut kvar veke til privatpersonar. Bedrifter faktureres
        to gongar i månaden, medio og ved månadsslutt.
      </p>
      <p>
        Lunsjkollektivet AS forbeholder seg retten til å seie opp avtaler som
        følgje av uteståande betaling.
      </p>

      <h3>3. Din forpliktelse som kunde av Lunsjkollektivet</h3>
      <p>
        Du er sjølv ansvarlig for at informasjonen du oppgir ved registrering av
        brukarkonto hjå oss er korrekt. Du samtykker til å halde
        pålogginsinformasjon og anna kontoinformasjon konfidensiell og sikker,
        og den skal ikkje delast med andre.
      </p>
      <p>
        Lunsjkollektivet forbeholder seg retten til å revidere og endre vilkår
        frå tid til anna, og kunder plikter seg til å setje seg inn i gjeldande
        vilkår. Skjer det endringer vil vi sjølvsagt gi alle kunder beskjed i
        rimelig tid og framheve endringane som trer i kraft. Dette kan til dømes
        vere endring i pris og meny.
      </p>

      <h3>3.1 Kommunikasjon med kunde</h3>
      <p>
        Lunsjkollektivet kan sende ut e-post og tekstmelding til alle kunder med
        viktig informasjon som omgår kundeforhold og levering av lunsj.
        Lunsjkollektivet kan også sende ut e-post og tekstmelding til kunder med
        relevant informasjon, om kunder ikkje har reservert seg frå dette.
      </p>

      <h3>3.2 Klager og tilbakemeldinger</h3>
      <p>
        Vi ynskjer å leverer dykk som kunder ei teneste som blir dagens
        høgdepunkt. Om produktet ikkje innfrir, ynskjer vi tilbakemelding på
        dette slik at vi saman kan kome fram til ei god løysing og tilpasse
        etter behov.
      </p>

      <h3>4. Behandling av personvernsopplysningar og personvern</h3>
      <p>
        Vi behandlar dine personopplysningar i samsvar med Lunsjkollektivet si
        <a href="https://m.lunsjkollektivet.no/personvern">
          Personvernserklæring
        </a>
      </p>
      <p>
        Ynskjer du å endre personopplysningar, som leveringsadresse, e-post
        eller telefonnummer, må du ta kontakt med oss via chat på nettside eller
        send ein e-post til <a href={'mailto:' + email}> {email}</a>
      </p>

      <h3>5. Kontaktinformasjon og kundeservice</h3>
      <p>
        Lunsjkollektivet gir support gjennom vår kundeservice. Du har tilgang
        til Kundeservice gjennom chat på vår plattform. Alternativt kan du
        kontakte oss på <a href={'mailto:' + email}> {email}</a>. Vi er
        tilgjengelig 09:00-15:00 alle arbeidsdager.
      </p>
      <i>
        <h3>Sist endret: </h3>
        {formatUserFrendlyDate(moment(lastChange, 'DD-MM-YYYY'))}
      </i>
    </Container>
  </div>
);
