import moment from 'moment';

export const formatDate = (momentDate, withHours = false) =>
  withHours
    ? momentDate.format('dddd D. MMMM YYYY [kl.] HH.mm')
    : momentDate.format('dddd D. MMMM YYYY');

export function formatDateString(dateString) {
  return formatDate(moment(new Date(dateString)));
}

export default formatDate;
