import React from 'react';
import { connect } from 'react-redux';

import Header from '../../../components/Header';
import Container from '../../../components/Container';
import Link from '../../../components/Link';
import Alert from '../../../components/Alert';

import fetch from '../../../utils/fetch';

import { Helmet } from 'react-helmet';

import vilkarAndPolicyAlias from '../../../utils/vilkarAndPolicyAlias';
import SignupSteps from './SignupSteps/SignupStepsV2';
import CustomerInfo from './Steps/CustomerInfo';
import CheckPostalCode from './Steps/CheckPostalCode';
import styles from './SignupCustomer.module.css';

import store from 'store';

const selectedDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

class CustomerSignup extends React.Component {
  state = {
    name: '',
    email: '',
    phone: '',
    companyAgreementCode: '',
    promotionalCode: '',
    deliveryAddress: null,
    type: 'Salad',
    notifications: [],
    juice: false,
    step: 0,
    errorMessage: null,
    allNotificationsSelected: false,
    invitedByAgreementCode: false,
  };

  componentDidMount() {
    let state = {};
    if (this.props.promotionalCode) {
      state.promotionalCode = this.props.promotionalCode;
    }

    if (this.props.companyAgreementCode) {
      state.companyAgreementCode = this.props.companyAgreementCode;
      state.step = 1;
    }

    this.setState(state);
  }

  componentDidUpdate(prevProps) {
    let state = {};

    if (this.props.promotionalCode !== prevProps.promotionalCode) {
      state.promotionalCode = this.props.promotionalCode;
    }

    if (this.props.companyAgreementCode !== prevProps.companyAgreementCode) {
      state.companyAgreementCode = this.props.companyAgreementCode;
      state.step = 1;
    }

    if (Object.keys(state).length !== 0) {
      this.setState(state);
    }
  }

  signup() {
    const data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      deliveryAddress: this.state.deliveryAddress,
      type: this.state.type,
      companyAgreementCode:
        this.state.companyAgreementCode.length === 0
          ? undefined
          : this.state.companyAgreementCode,
      vilkarAndPolicyAlias,
      notifications: this.state.notifications,
      promotionalCode: this.state.promotionalCode,
      selectedDays: selectedDays,
      juice: this.state.juice,
    };

    const entranceSource = store.get('entranceSource');
    const entranceCampaign = store.get('entranceCampaign');

    if (entranceSource) {
      data['entranceSource'] = entranceSource;
    }
    if (entranceCampaign) {
      data['entranceCampaign'] = entranceCampaign;
    }

    this.setState({ loading: true });

    fetch('/v2/customer-signup', 'POST', data)
      .then(() => {
        this.done = true;

        this.props.history.push('/customer-signup-done');
      })
      .catch(error => {
        this.setState({
          loading: false,
          error,
          errorMessage: error.response ? error.response.data : null,
        });
      });
  }

  info = () => {
    return (
      <span style={{ fontSize: '1.1em' }}>
        <p>
          Lunsjordning uten bedriftsavtale passer godt for deg som jobber mye på
          hjemmekontor, eller som jobber selvstendig. Du kan fortsatt endre
          mening og opprette{' '}
          <Link gray to="/company-signup">
            bedriftsavtale her.
          </Link>{' '}
        </p>
        <p>
          <strong>Fordeler med Bedriftsavtale:</strong>
        </p>
        <p>
          - Velge hvor mye bedriften skal sponse maten din. Da vil bedriften
          motta faktura for sin del, og du trekkes direkte via kortet du velger
          å legge inn
        </p>
        <p>
          - Du kan enkelt legge til alle dine kolleger under bedriftsavtalen
        </p>
        <p>- Du kan få en bedre pris per lunsj om dere er over 30 ansatte</p>
        <h2>Start med din jobbadresse:</h2>
      </span>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <Helmet>
          <title>Lunsjkollektivet - Registrering</title>
        </Helmet>
        <div className={styles.background}>
          <div style={{ borderTop: '1px solid #e7e7e7' }} />
          <Container noBorder width={800}>
            <SignupSteps
              signupType="customer"
              focusSingleStep={this.state.invitedByAgreementCode}
              values={this.state}
              onChange={state => this.setState(state)}
              onDone={() => this.signup()}
              onCancel={() => this.props.history.push('/')}
              onStep={(step, done) => this.setState({ step }, done)}
              step={this.state.step}
              onBack={() =>
                this.setState({
                  step: this.state.step > 0 ? this.state.step - 1 : 0,
                })
              }
            >
              <CheckPostalCode
                title={'Kom i gang uten bedriftsavtale'}
                content={this.info()}
              />
              <CustomerInfo />
            </SignupSteps>
            {this.state.error ? (
              <div className="mt-5">
                <Alert color="red">
                  {this.state.errorMessage ? (
                    <p>{this.state.errorMessage}</p>
                  ) : (
                    <p>
                      Noe skjedde. Ta kontakt på meldingstjenesten vår hvis
                      problemet vedvarer så skal vi fikse bestillingen for deg.
                    </p>
                  )}
                </Alert>
              </div>
            ) : null}
          </Container>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  promotionalCode: state.entryQuery.promotionalCode,
  companyAgreementCode: state.entryQuery.avtalekode,
}))(CustomerSignup);
