import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { BackButton } from '../../Button';
import Card, { Separator } from '../../Card';
import Button from '../../Button';
import Link from '../../Link';
import Container from '../../Container';
import { Actions } from '../../Modal';
import { Checkbox, CheckboxGroup } from '../../Form';
import CompanySubscriptionForm from './CompanySubscriptionForm';

import upperCaseFirstLetter from '../../../utils/upperCaseFirstLetter';

import {
  getIfNeeded,
  update,
} from '../../../stores/companySubscription/actions';
import { selectCompanyAgreementSubscription, selectCompanyAgreementSubscriptionMeta } from '../../../stores/companySubscription/selectors';
import selectAllSubscriptionTypes from '../../../selectors/subscriptionType/selectAll';
import selectCompanyAgreementSubscriptionTypes from '../../../selectors/subscriptionType/selectForCompanyAgreement';
import {
  getAllIfNeeded as getAllSubscriptionTypesIfNeeded,
  getForCompanyAgreement as getSubscriptionTypesForCompanyAgreement,
} from '../../../stores/subscriptionType/actions';
import {
  selectAllMeta as selectAllSubscriptionTypesMeta,
  selectCompanyAgreementMeta as selectCompanyAgreementSubscriptionTypeMeta,
} from '../../../stores/subscriptionType/selectors';
import Loader from '../../Loader';
import Alert from '../../Alert';
import DailyForm from './DailyForm';
import { selectCompanyAgreement } from '../../../stores/companyAgreement/selectors';

import CompanyBackground from '../../../routes/routes/MyCompany/components/CompanyBackground';

const availableDays = [
  { key: 'monday', name: 'Mandag' },
  { key: 'tuesday', name: 'Tirsdag' },
  { key: 'wednesday', name: 'Onsdag' },
  { key: 'thursday', name: 'Torsdag' },
  { key: 'friday', name: 'Fredag' },
];

class CompanySubscription extends React.Component {
  state = {
    selectedDays: [],
    values: [],
    dailyValues: [],
    type: 'weekly',
  };

  componentDidMount() {
    this.props.getIfNeeded(this.props.companyAgreementId);
    this.props.getAllSubscriptionTypesIfNeeded();
    this.props.getSubscriptionTypesForCompanyAgreement(
      this.props.companyAgreementId
    );
    this.setStateFromSubscriptionEntries();
  }

  componentDidUpdate(oldProps) {
    this.props.getIfNeeded(this.props.companyAgreementId);
    this.props.getAllSubscriptionTypesIfNeeded();
    this.props.getSubscriptionTypesForCompanyAgreement(
      this.props.companyAgreementId
    );
    this.setStateFromSubscriptionEntries(oldProps);
  }

  setStateFromSubscriptionEntries(oldProps) {
    if (
      !oldProps ||
      oldProps.subscriptionEntries !== this.props.subscriptionEntries ||
      this.props.subscriptionTypes !== oldProps.subscriptionTypes
    ) {
      if (
        !this.props.subscriptionEntries ||
        this.props.subscriptionEntries.length === 0 ||
        this.props.subscriptionTypes.length === 0
      ) {
        return this.setState({
          selectedDays: [],
          values: [],
          dailyValues: [],
          type: 'weekly',
        });
      }

      const entry = this.props.subscriptionEntries.reduce(
        (entry, subscription) => {
          if (!entry) return subscription;
          if (subscription.endDate === null) return subscription;
          if (moment(subscription.startDate).isAfter(moment(entry.startDate)))
            return subscription;
          return entry;
        },
        null
      );

      this.setState({
        type: entry.type,
        values: entry.data.entries.map(entry => ({
          type: this.props.subscriptionTypes.find(
            st => st.alias === entry.type
          ),
          menuAmount: entry.menuAmount,
          allergies: entry.allergies,
        })),
        dailyValues: entry.data.dailyEntries.map(entry => ({
          ...entry,
          data: entry.data.map(entry => ({
            ...entry,
            type: this.props.subscriptionTypes.find(
              st => st.alias === entry.type
            ),
          })),
        })),
        selectedDays: entry.days,
      });
    }
  }

  onDayChanged = day => {
    const index = this.state.selectedDays.indexOf(day.key);
    const days = [...this.state.selectedDays];

    if (index !== -1) {
      days.splice(index, 1);
    } else {
      days.push(day.key);
    }

    this.setState({ selectedDays: days });
  };

  buildDays() {
    return availableDays.map(day => (
      <Checkbox
        key={day.key}
        checked={this.state.selectedDays.indexOf(day.key) !== -1}
        onChange={() => this.onDayChanged(day)}
      >
        {upperCaseFirstLetter(day.name)}
      </Checkbox>
    ));
  }

  update = () => {
    this.setState({ saving: true, error: false, success: false });

    this.props
      .update(this.props.companyAgreementId, {
        type: this.state.type,
        data: {
          entries: this.state.values.map(v => ({ ...v, type: v.type.alias })),
          dailyEntries: this.state.dailyValues.map(entry => ({
            ...entry,
            data: entry.data.map(entry => ({
              ...entry,
              type: entry.type.alias,
            })),
          })),
        },
        days: this.state.selectedDays,
      })
      .then(() => {
        this.setState({ saving: false, success: true });
        this.setStateFromSubscriptionEntries();
        setTimeout(() => this.setState({ success: false }), 2000);
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  };

  buildContent() {
    const deliveryLocation = this.props.companyAgreement
      ? this.props.companyAgreement.deliveryLocation
      : null;

    if (
      this.props.subscriptionTypesMeta.loading ||
      this.props.subscriptionTypesCompanyAgreementMeta.loading ||
      this.props.subscriptionEntriesMeta.loading
    ) {
      return <Loader />;
    }

    return this.props.subscriptionEntries.length === 0 ? (
      <Actions>
        <Button
          medium
          saving={this.state.saving}
          success={this.state.success}
          onClick={this.update}
        >
          Start levering
        </Button>
      </Actions>
    ) : (
        <div>
          <div>
            <div className="mt-5">
              {this.state.type === 'daily' ? (
                <DailyForm
                  availableDays={availableDays}
                  subscriptionTypes={this.props.subscriptionTypes}
                  subscriptionTypesCompanyAgreement={
                    this.props.subscriptionTypesCompanyAgreement
                  }
                  companyAgreement={this.props.companyAgreement}
                  dailyEntries={this.state.dailyValues}
                  onChange={data => this.setState({ dailyValues: data })}
                  deliveryLocation={deliveryLocation}
                />
              ) : null}
              {this.state.type === 'weekly' ? (
                <div style={{ marginTop: '60px' }}>
                  <CompanySubscriptionForm
                    values={this.state.values}
                    onChange={values => this.setState({ values })}
                    admin={this.props.admin}
                    subscriptionTypes={this.props.subscriptionTypes}
                    subscriptionTypesCompanyAgreement={
                      this.props.subscriptionTypesCompanyAgreement
                    }
                    companyAgreement={this.props.companyAgreement}
                    deliveryLocation={deliveryLocation}
                  />
                  <CheckboxGroup direction={'row'} label="Ukedager">
                    {this.buildDays()}
                  </CheckboxGroup>
                </div>
              ) : null}
            </div>
            <div style={{ textAlign: 'end', marginRight: '20px' }}>
              <em>*Alle priser inkl. 15% mva</em>
            </div>
            <Separator />
          </div>
          <Actions>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                size='medium'
                saving={this.state.saving}
                success={this.state.success}
                onClick={this.update}
              >
                Lagre
          </Button>
              <div>
                <Button
                  color={this.state.type === 'weekly' ? 'main' : 'main-inverse'}
                  onClick={() => this.setState({ type: 'weekly' })}
                >
                  Ukentlig
            </Button>
                <Button
                  color={this.state.type === 'daily' ? 'main' : 'main-inverse'}
                  onClick={() => this.setState({ type: 'daily' })}
                >
                  Daglig
            </Button>
              </div>
            </div>
          </Actions>
        </div>
      );
  }

  render() {
    if (
      this.props.subscriptionTypesMeta.error ||
      this.props.subscriptionTypesCompanyAgreementMeta.error ||
      this.props.subscriptionEntriesMeta.error
    ) {
      return (
        <Alert color="red">
          Noe skjedde når vi lastet inn siden. Vennligst prøv igjen senere
        </Alert>
      );
    }

    if (this.props.admin) {
      return (
        <Container width={750} withPadding admin>
          <BackButton to={this.props.backTo} />
          <Card>{this.buildContent()}</Card>{' '}
        </Container>
      );
    }

    return (
      <CompanyBackground title="Fast levering til bedrift"
        info={<div>
          Her endrer du på bedriftens faste lunsjlevering fra oss. Ønsker du kun å
              avbestille eller endre lunsjtype enkelte dager gjør du det{' '}
          <strong>
            <Link gray to="https://company.lunsjkollektivet.no">
              her.
                </Link>
          </strong>
        </div>}>
        {this.buildContent()}
      </CompanyBackground>
    );
  }
}

export default withRouter(
  connect(
    (state, ownProps) => ({
      subscriptionEntries: selectCompanyAgreementSubscription(
        state,
        ownProps.companyAgreementId
      ),
      subscriptionEntriesMeta: selectCompanyAgreementSubscriptionMeta(
        state,
        ownProps.companyAgreementId
      ),
      companyAgreement: selectCompanyAgreement(
        state,
        ownProps.companyAgreementId
      ),
      subscriptionTypes: selectAllSubscriptionTypes(state),
      subscriptionTypesCompanyAgreement: selectCompanyAgreementSubscriptionTypes(
        state,
        ownProps.companyAgreementId
      ),
      subscriptionTypesMeta: selectAllSubscriptionTypesMeta(state),
      subscriptionTypesCompanyAgreementMeta: selectCompanyAgreementSubscriptionTypeMeta(
        state,
        ownProps.companyAgreementId
      ),
      loading: state.subscriptionType.meta.loading,
      error: state.subscriptionType.meta.error,
    }),
    {
      getIfNeeded,
      update,
      getAllSubscriptionTypesIfNeeded,
      getSubscriptionTypesForCompanyAgreement,
    }
  )(CompanySubscription)
);
