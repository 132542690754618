import React from 'react';
import cn from 'classnames';
import Link from '../Link';
import styles from './deliveryLocationPicker.module.css';

export default ({
  deliveryLocation,
  selected,
  colored,
  onClick,
  modifiable,
  onModify,
}) => (
  <div
    className={cn(styles.deliveryLocationChoice, {
      [styles.deliveryLocationSelected]: selected,
      [styles.colored]: colored,
    })}
    onClick={onClick}
  >
    <div>
      {selected ? 'Punkt:' : null} <strong>{deliveryLocation.name}</strong>
    </div>
    <div className={styles.row}>
      <div>
        {deliveryLocation.deliveryNote
          ? deliveryLocation.deliveryNote + ' -'
          : null}
        {deliveryLocation.floor ? 'Etasje: ' + deliveryLocation.floor : null}
      </div>
      {deliveryLocation.numberOfBoxWeekly != null ? (
        <div>
          Lunsj per dag: {parseInt(deliveryLocation.numberOfBoxWeekly / 5)}
        </div>
      ) : null}
    </div>
    {modifiable ? <Link onClick={onModify}>Endre</Link> : ''}
  </div>
);
