import auth0 from 'auth0-js';

const auth0Object = new auth0.WebAuth({
  domain: process.env.REACT_APP_Auth0_Domain,
  clientID: process.env.REACT_APP_Auth0_ClientId,
  audience: process.env.REACT_APP_Auth0_Audience,
  redirectUri: process.env.REACT_APP_Auth0_RedirectUri,
  responseType: 'token id_token',
  scope: 'openid profile email roles',
});

export default auth0Object;
