import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/shouldFetch';

import types from './types';
import caTypes from '../companyAgreement/types';

export const getAllForCustomerIfNeeded = (
  customerId,
  refetch = false
) => (dispatch, getState) => {
  if (
    !shouldFetch(getState().subscription.subscriptions) &&
    !refetch
  )
    return;

  dispatch({
    type: types.GET_ALL_FOR_CUSTOMER_START,
    payload: {
      customerId,
    },
  });

  const url = `/customers/${customerId}/subscriptions/`;

  return fetch(url, 'GET')
    .then(response => {
      dispatch({
        type: types.GET_ALL_FOR_CUSTOMER_SUCCESS,
        payload: {
          customerId,
          subscriptions: Array.isArray(response.data) ? response.data : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ALL_FOR_CUSTOMER_ERROR,
        payload: {
          customerId,
          error,
        },
      });
    });
};

export const getForCustomerIfNeeded = (
  customerId,
  companyAgreementId = null,
  refetch = false
) => (dispatch, getState) => {
  if (
    !shouldFetch(getState().subscription.meta[`customerId-${customerId}`]) &&
    !refetch
  )
    return;

  dispatch({
    type: types.GET_FOR_CUSTOMER_START,
    payload: {
      customerId,
    },
  });

  const url = !companyAgreementId
    ? `/customers/${customerId}/subscriptions/`
    : `/company-agreement/${companyAgreementId}/customers/${customerId}/subscriptions/`;

  return fetch(url, 'GET')
    .then(response => {
      dispatch({
        type: types.GET_FOR_CUSTOMER_SUCCESS,
        payload: {
          customerId,
          subscriptions: Array.isArray(response.data) ? response.data : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_CUSTOMER_ERROR,
        payload: {
          customerId,
          error,
        },
      });
    });
};

export const getCompanyAgreementCustomersWithSubscriptionsIfNeeded = companyAgreementId => (
  dispatch,
  getState
) => {
  if (
    !shouldFetch(
      getState().subscription.meta[`companyAgreementId-${companyAgreementId}`]
    )
  )
    return;

  dispatch({
    type: types.GET_FOR_COMPANYAGREEMENT_CUSTOMERS_START,
    payload: {
      companyAgreementId: companyAgreementId,
    },
  });
  dispatch({
    type: caTypes.GET_SUBSCRIPTION_CUSTOMERS_START,
    payload: {
      companyAgreementId: companyAgreementId,
    },
  });

  return fetch(
    '/company-agreement/' + companyAgreementId + '/customers/subscriptions',
    'GET'
  )
    .then(response => {
      dispatch({
        type: types.GET_FOR_COMPANYAGREEMENT_CUSTOMERS_SUCCESS,
        payload: {
          companyAgreementId,
          customerSubscriptions: Array.isArray(response.data)
            ? response.data
            : [],
        },
      });
      dispatch({
        type: caTypes.GET_SUBSCRIPTION_CUSTOMERS_SUCCESS,
        payload: {
          companyAgreementId,
          customers: Array.isArray(response.data)
            ? response.data.map(cs => cs.customer)
            : [],
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_FOR_COMPANYAGREEMENT_CUSTOMERS_ERROR,
        payload: {
          companyAgreementId,
          error,
        },
      });
      dispatch({
        type: caTypes.GET_SUBSCRIPTION_CUSTOMERS_ERROR,
        payload: {
          companyAgreementId,
          error,
        },
      });
    });
};

export const createCustomerSubscription = (
  customerId,
  companyAgreementId = null,
  data
) => dispatch => {
  dispatch({
    type: types.CREATE_FOR_CUSTOMER_START,
    payload: {
      customerId,
    },
  });

  const url = !companyAgreementId
    ? `/customers/${customerId}/subscriptions/`
    : `/company-agreement/${companyAgreementId}/customers/${customerId}/subscriptions/`;

  return fetch(url, 'POST', data)
    .then(response => {
      dispatch({
        type: types.CREATE_FOR_CUSTOMER_SUCCESS,
        payload: {
          customerId,
          subscription: response.data,
        },
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: types.CREATE_FOR_CUSTOMER_ERROR,
        payload: {
          customerId,
        },
      });
    });
};

export const updateCustomerSubscription = (
  customerId,
  subscriptionKey,
  data
) => dispatch => {
  dispatch({
    type: types.UPDATE_FOR_CUSTOMER_START,
    payload: {
      customerId,
    },
  });

  return fetch(
    `/customers/${customerId}/subscriptions/${subscriptionKey}`,
    'PUT',
    data
  )
    .then(response => {
      dispatch({
        type: types.UPDATE_FOR_CUSTOMER_SUCCESS,
        payload: {
          customerId,
          subscription: response.data,
        },
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: types.UPDATE_FOR_CUSTOMER_ERROR,
        payload: {
          customerId,
        },
      });
    });
};

export const deactivateCustomerSubscription = (
  customerId,
  key,
  reason,
  sendConfirmationEmail = false
) => dispatch => {
  dispatch({
    type: types.DEACTIVATE_START,
    payload: {
      customerId,
    },
  });

  return fetch(`/customers/${customerId}/subscriptions/${key}`, 'DELETE', {
    sendConfirmationEmail: sendConfirmationEmail ? 1 : 0,
    reason,
  })
    .then(response => {
      dispatch({
        type: types.DEACTIVATE_SUCCESS,
        payload: {
          key,
          customerId,
          subscriptions: response.data,
        },
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: types.DEACTIVATE_ERROR,
        payload: {
          customerId,
        },
      });
    });
};

export const createCustomerRangeSubscriptions = (customers, companyAgreementId) => dispatch => {
  dispatch({
    type: types.CREATE_FOR_CUSTOMERS_START,
    payload: {
      customers,
    },
  });

  const url = `/company-agreement/${companyAgreementId}/subsriptions/range/start`;

  return fetch(url, 'POST', { customers })
    .then(response => {
      dispatch({
        type: types.CREATE_FOR_CUSTOMERS_SUCCESS,
        payload: {
          customers,
          subscription: response.data,
        },
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: types.CREATE_FOR_CUSTOMERS_ERROR,
        payload: {
          customers,
        },
      });
    });
}

export const deactivateCustomerRangeSubscriptions = (customers, companyAgreementId) => dispatch => {
  dispatch({
    type: types.DEACTIVATE_FOR_CUSTOMERS_START,
    payload: {
      customers,
    },
  });

  const url = `/company-agreement/${companyAgreementId}/subsriptions/range/stop`;

  return fetch(url, 'POST', { customers })
    .then(response => {
      dispatch({
        type: types.DEACTIVATE_FOR_CUSTOMERS_SUCCESS,
        payload: {
          customers,
          subscription: response.data,
        },
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: types.DEACTIVATE_FOR_CUSTOMERS_ERROR,
        payload: {
          customers,
        },
      });
    });
}

export const getMultipleActiveCustomerSubscriptions = () => dispatch => {
  dispatch({
    type: types.GET_MULTIPLE_ACTIVE_START,
  });

  return fetch("/subscriptions/status/active", "GET")
    .then(response => {
      dispatch({
        type: types.GET_MULTIPLE_ACTIVE_SUCCESS,
        payload: {
          customerSubscriptions: response.data,
        },
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: types.GET_MULTIPLE_ACTIVE_ERROR,
      });
    });
}

export const deleteMultipleCustomerSubscriptions = subscriptionIds => dispatch => {
  dispatch({
    type: types.DELETE_MULTIPLE_ACTIVE_START,
  });

  return fetch("/subscriptions", "DELETE", subscriptionIds)
    .then(() => {
      dispatch({
        type: types.DELETE_MULTIPLE_ACTIVE_SUCCESS,
        payload: {
          subscriptionIds,
        },
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: types.DELETE_MULTIPLE_ACTIVE_ERROR,
      });
    });
}