import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './table.module.css';

const Table = props => (
  <table
    className={cn(styles.table, {
      [styles.clickable]: props.clickable,
      // zebra is the pattern with two different colors
      [styles.zebraPrimary]: props.zebra && props.zebra === 'primary',
      [styles.zebraSecondary]: props.zebra && props.zebra === 'secondary',
      [styles.bigHeader]: props.bigHeader,
    })}
  >
    {props.children}
  </table>
);

Table.defaultProps = {
  zebra: 'primary',
  clickable: false,
  bigHeader: false
};

Table.propTypes = {
  zebra: PropTypes.bool,
  clickable: PropTypes.bool,
  bigHeader: PropTypes.bool
};

export default Table;
