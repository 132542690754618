import React from 'react';
import AddressSearch from '../AddressSearch';
import Card from '../Card';

import styles from './deliveryLocationPicker.module.css';

import NewLocationForm from './NewLocationForm';
import DeliveryLocation from './DeliveryLocation';
import UpdateDeliveryLocationModal from './UpdateDeliveryLocationModal';
import DeliveryLocationFetcher from './DeliveryLocationFetcher';

export default class DeliveryLocationPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addressDeliverable: true,
      addNewLocation: false,
      deliveryAddress: props.deliveryAddress || null,
      deliveryLocations: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.locationSaved && this.props.locationSaved) {
      this.setState(
        {
          addressDeliverable: true,
          addNewLocation: false,
          deliveryAddress: null,
          deliveryLocations: [],
        },
        () => this.props.onReset()
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      deliveryAddress: nextProps.deliveryAddress || this.state.deliveryAddress,
    });
  }

  onDeliveryAddressChange = deliveryAddress => {
    this.setState({
      deliveryAddress: deliveryAddress
        ? {
          ...deliveryAddress,
          address: deliveryAddress.addressLine,
        }
        : null,
      postalCode: null,
      addNewLocation: false,
    });
  };

  getContent() {
    const localValue = !!this.state.deliveryAddress;
    const deliveryAddress = localValue
      ? this.state.deliveryAddress
      : this.props.deliveryLocation
        ? this.props.deliveryLocation.address
        : null;

    if (this.state.addNewLocation) {
      return (
        <NewLocationForm
          onDeliveryAddressChange={this.onDeliveryAddressChange}
          onDeliveryLocationChange={deliveryLocation =>
            this.props.onChange(deliveryLocation)
          }
          deliveryAddress={deliveryAddress}
          deliveryLocation={this.props.deliveryLocation || { name: '', floor: 1, deliveryNote: '' }}
          colored={this.props.colored}
          close={() => {
            this.setState({ addNewLocation: false });
            this.props.onChange(null);
          }}
        />
      );
    }

    return (
      <div>
        <AddressSearch
          label="Leveringsadresse"
          colored={this.props.colored}
          onChange={this.onDeliveryAddressChange}
          choice={deliveryAddress}
          done={!!this.props.deliveryLocation}
          onClick={() => {
            this.props.onChange(null);
            this.setState({ modify: null });
          }}
        />
        {!deliveryAddress ? (
          <div className={styles.labelDisabled}>Opprett leveringspunkt</div>
        ) : this.props.deliveryLocation ? (
          <DeliveryLocation
            deliveryLocation={this.props.deliveryLocation}
            selected
            colored={this.props.colored}
            onClick={() => {
              this.setState({ addNewLocation: true })
            }}
            modifiable={this.props.admin}
            onModify={() =>
              this.setState({ modify: this.props.deliveryLocation })
            }
          />
        ) : (
          <div>
            <div className={styles.label}>Opprett leveringspunkt</div>
            <DeliveryLocationFetcher
              deliveryAddress={this.state.deliveryAddress}
              onChange={dl => this.props.onChange(dl)}
              modifiable={this.props.admin}
              onModify={dl => this.setState({ modify: dl })}
              addNewLocation={() => {
                this.setState({ addNewLocation: true });
                this.props.onChange({
                  name: '',
                  floor: '',
                  deliveryNote: '',
                });
              }}
            />
          </div>
        )}
        <UpdateDeliveryLocationModal
          deliveryLocation={this.state.modify}
          onClose={() => this.setState({ modify: null })}
        />
      </div>
    );
  }

  render() {
    if (this.props.framed) {
      return (
        <div>
          <div>{this.props.label}</div>
          <Card>{this.getContent()}</Card>
        </div>
      );
    }
    return this.getContent();
  }
}
