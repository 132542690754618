import React from 'react';
import moment from 'moment';
import queryString from 'query-string';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import DatePicker from '../../DatePicker';

import Container from '../../Container';
import PageTitle from '../../PageTitle';
import Logo from '../../Logo';

import styles from './dayReport.module.css';

class DayReport extends React.Component {
  constructor(props) {
    super(props);
    let query = queryString.parse(location.search); // eslint-disable-line

    if (!query.date) {
      query.date = moment().format('DD-MM-YYYY');
      props.history.push({
        search: queryString.stringify(query),
      });
    }

    this.state = {
      focused: false,
      params: {
        date: moment(query.date, 'DD-MM-YYYY'),
      },
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.params !== this.state.params && nextState.params.date) {
      this.props.history.push({
        search: queryString.stringify({
          date: nextState.params.date.format('DD-MM-YYYY'),
        }),
      });
    }
  }

  setParamState = state => {
    this.setState({ params: { ...this.state.params, ...state } });
  };

  buildFilter() {
    if (this.props.filterComponent) {
      return (
        <this.props.filterComponent
          onChange={state => this.setParamState(state)}
          values={this.state.params}
          admin={this.props.admin}
          history={this.props.history}
        />
      );
    }

    return (
      <DatePicker
        required
        placeholder=""
        date={this.state.params.date}
        isOutsideRange={() => {
          return false;
        }}
        onDateChange={date => this.setParamState({ date })}
        focused={this.state.focused}
        onFocusChange={({ focused }) => this.setState({ focused })}
      />
    );
  }

  buildContent() {
    return (
      <div>
        {this.props.printHeader ? (
          <div className={cn(styles.logo, 'only-print')}>
            <Logo />
          </div>
        ) : null}
        <div className="no-print">{this.buildFilter()}</div>
        {this.props.printHeader ? (
          <h3 className={cn('only-print', styles.date)}>
            {this.state.params.date.format('dddd D. MMMM YYYY')}
          </h3>
        ) : null}
        {this.props.component ? (
          <this.props.component {...this.state.params} />
        ) : null}
      </div>
    );
  }

  render() {
    if (this.props.withContainers) {
      return (
        <div>
          <div className="no-print">
            {!this.props.admin && !this.props.chef && <PageTitle picker={this.props.picker} backTo={this.props.backTo}>
              {this.props.title}</PageTitle>}
          </div>
          <Container admin={this.props.admin || this.props.chef} withPadding width={this.props.width}>
            {this.buildContent()}
          </Container>
        </div>
      );
    }

    return this.buildContent();
  }
}

DayReport.defaultProps = {
  backTo: '/admin',
  printHeader: true,
};

export default withRouter(DayReport);
