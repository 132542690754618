import React from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Logo from '../Logo';

import styles from './logo.module.scss';
import { selectedContext } from '../../stores/me/selectors';

const LogoWrapper = props => (
  <div
    className={cn(styles.logo, {
      [styles.large]: props.large,
      [styles.homepage]: props.homepage,
      [styles.onlyMobile]: props.onlyMobile
    })}
    onClick={() =>
      !props.isAuthenticated
        ? props.history.push('/')
        : props.context.type === 'companyAgreement'
          ? props.history.push('https://company.lunsjkollektivet.no')
          : props.history.push('/')
    }
  >
    <Logo />
  </div>
);

function mapStateToProps(state) {
  return {
    isAuthenticated: state.me.isAuthenticated,
    context: selectedContext(state),
  };
}

export default withRouter(
  connect(mapStateToProps, {})(LogoWrapper)
);
