import React from 'react';
import cn from 'classnames';

import styles from './discountPicker.module.css';

export default props => {
  return (
    <div
      className={cn(styles.dropDown, {
        [styles.show]: props.focused,
      })}
    >
      {props.discountTypes.map((entity, i) => (
        <div
          className={styles.dropDownItem}
          key={i}
          onClick={e => {
            props.changeFocus(false);
            props.onChange(entity.value);
            e.stopPropagation();
          }}
        >
          {entity.text}
        </div>
      ))}
    </div>
  );
};
