import React from 'react';
import { debounce } from 'lodash';

import Button from '../../../../components/Button';
import Alert from '../../../../components/Alert';
import { Input3, CheckboxGroup, Checkbox } from '../../../../components/Form';
import EmailSettingPicker from '../../../../components/EmailSettingPicker';

import fetch from '../../../../utils/fetch';
import validateEmail from '../../../../utils/validateEmail';

const freeLunsjCompaignCode = 'lunsj18';

class CustomerInfo extends React.Component {
  state = {
    email: false,
    name: false,
    phone: false,
    companyAgreementCode: false,
    companyAgreement: null,
    consent: false,
  };

  constructor(props) {
    super(props);

    this.fetch = debounce(this._fetch, 1000);
    this.fetchPromotionalCode = debounce(this._fetchPromotionalCode, 1000);
  }

  componentDidMount() {
    this.checkAgreementCode(this.props);
    this.checkPromotionalCode(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.companyAgreementCode !== nextProps.companyAgreementCode) {
      this.checkAgreementCode(nextProps);
    }
    if (this.props.promotionalCode !== nextProps.promotionalCode) {
      this.checkPromotionalCode(nextProps);
    }
  }

  checkAgreementCode = props => {
    if (props.companyAgreementCode.length === 0) {
      return this.setState({ companyAgreement: null, fetching: null });
    }
    this.setState({ fetching: true, companyAgreement: null });
    this.fetch(props);
  };

  checkPromotionalCode = props => {
    if (props.promotionalCode.length === 0) {
      return this.setState({
        promotionalUser: null,
        fetchingPromotionalCode: false,
      });
    }
    if (props.promotionalCode === freeLunsjCompaignCode) return;

    this.setState({ fetchingPromotionalCode: true, promotionalUser: null });
    this.fetchPromotionalCode(props);
  };

  _fetchPromotionalCode = props => {
    this.setState({
      fetchingPromotionalCode: true,
      error: false,
    });

    fetch('/promotions/recruiting/' + props.promotionalCode)
      .then(response => {
        this.setState({
          promotionalUser: response.data,
          fetchingPromotionalCode: false,
        });
      })
      .catch(() => {
        this.setState({ fetchingPromotionalCode: false });
      });
  };

  onDeliveryAddressChange = deliveryAddress => {
    this.props.onChange({ deliveryAddress, postalCode: null });

    if (!deliveryAddress) return;

    this.setState({ fetching: true });

    fetch('/postal-codes/' + deliveryAddress.postalCode.code)
      .then(response => {
        this.setState({
          fetching: false,
          postalCodeError: !response.data.active,
        });

        this.props.onChange({
          postalCode: response.data.active ? response.data : null,
        });
      })
      .catch(() => {
        this.setState({ postalCodeError: true, fetching: false });
      });
  };

  _fetch = props => {
    fetch('/company-agreement-code/' + props.companyAgreementCode)
      .then(response => {
        this.props.onChange({
          invitedByAgreementCode: !!response.data
        })
        this.setState({
          companyAgreement: response.data,
          fetching: false,
        });
        this.props.onChange({
          postalCode: response.data.deliveryAddress.postalCode,
        });
      })
      .catch(() => {
        this.setState({ fetching: false });
      });
  };

  getDisabledReason() {
    if (!validateEmail(this.props.email)) {
      return 'Ugyldig e-post';
    }

    if (!this.state.consent) {
      return 'Du må godkjenne brukervilkårene og personvernserklæringen.';
    }

    if (!this.state.name) {
      return 'Ugyldig navn';
    }

    if (!this.state.phone) {
      return 'Ugyldig telefon';
    }

    if (!this.props.allNotificationsSelected) {
      return 'Du må velge ønskede varslinger';
    }

    if (
      this.props.promotionalCode.length !== 0 &&
      this.props.promotionalCode !== freeLunsjCompaignCode &&
      !this.state.fetchingPromotionalCode &&
      !this.state.promotionalUser
    ) {
      return 'Ugyldig kampanjekode';
    }

    if (
      this.props.companyAgreementCode.length !== 0 &&
      (!this.state.companyAgreement || !this.state.companyAgreement.company)
    ) {
      return 'Ugyldig avtalekode';
    }

    return null;
  }

  render() {
    if (this.props.overview) {
      return (
        <div>
          <div>
            <strong>Dine opplysninger</strong>
          </div>
          <div>Navn: {this.props.name}</div>
          <div>E-post: {this.props.email}</div>
          <div>Telefon: {this.props.phone}</div>
        </div>
      );
    }

    if (!this.props.focused) {
      return <span>Dine opplysninger</span>;
    }

    const disabledReason = this.getDisabledReason();

    return (
      <div>
        <div>
          <h2>Opprett profil</h2>
          {this.props.companyAgreementCode && !this.state.fetching ? (
            !this.state.companyAgreement ||
              !this.state.companyAgreement.company ? (
              <p style={{ color: 'red' }}>
                <strong>Vi fant ikke bedriftsavtalen.</strong>
              </p>
            ) : (
              <p>
                <strong>
                  Avtalekode for {this.state.companyAgreement.company.name}{' '}
                  registrert
                </strong>
              </p>
            )
          ) : (
            ''
          )}
          <br />
          <br />
          <div>
            <Input3
              colored
              label="Fullt navn"
              placeholder="Fornavn og etternavn"
              value={this.props.name}
              onChange={(value, valid) => {
                this.props.onChange({ name: value });
                this.setState({ name: valid });
              }}
              validate={string => string.length > 2}
              errorMessage="Navn må være minst 2 bokstaver lang"
            />
            <Input3
              colored
              label="E-post"
              placeholder="Din e-postadresse"
              value={this.props.email}
              onChange={(value, valid) => {
                this.props.onChange({ email: value });
                this.setState({ email: valid });
              }}
              validate={validateEmail}
              errorMessage="E-postadressen er ikke gyldig"
            />
          </div>
          <Input3
            colored
            short
            label="Telefon"
            placeholder="Ditt telefonnummer"
            value={this.props.phone}
            onChange={(value, valid) => {
              this.props.onChange({ phone: value });
              this.setState({ phone: valid });
            }}
            validate={string => string.length >= 8}
            errorMessage="Telefonnummer må være minst 8 bokstaver lang"
          />
          <div>
            <h3>Brukervilkår, personvern og relevant informasjon</h3>
            <EmailSettingPicker
              values={this.props.notifications}
              onChange={(notifications, allNotificationsSelected) => this.props.onChange({ notifications, allNotificationsSelected })}
            />
            <CheckboxGroup direction="column">
              <Checkbox
                checked={this.state.consent}
                onChange={consent => this.setState({ consent })}
              >
                Jeg aksepterer{' '}
                <a style={{ borderBottom: '2px solid #acacac', paddingBottom: '3px' }}
                  href="/vilkar" target="_blank">
                  brukervilkårene
              </a>{' '}
              og{' '}
                <a style={{ borderBottom: '2px solid #acacac', paddingBottom: '3px' }}
                  href="/personvern" target="_blank">
                  personvernserklæring
              </a>
              .
            </Checkbox>
            </CheckboxGroup>
            <br />
          </div>
          <Button
            medium
            onClick={() => this.props.onNext()}
            onDisabledClick={() => this.setState({ submitedWithError: true })}
            disabled={disabledReason}
            saving={
              this.state.fetching ||
              this.state.fetchingPromotionalCode ||
              this.props.loading
            }
          >
            Ferdig
          </Button>
          {this.state.submitedWithError && disabledReason ? (
            <div className="mt-3">
              <Alert color="red">{disabledReason}</Alert>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default CustomerInfo;
