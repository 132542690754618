import React from 'react';
import cn from 'classnames';

import styles from './day.module.css';

export default ({ locked, children, header, footer, open }) => {
  return (
    <div className={cn(styles.day, { [styles.locked]: locked })}>
      {header}
      {children !== null ? (
        <div
          className={cn(styles.content, { [styles.locked]: locked || open })}
        >
          {children}
        </div>
      ) : null}
      {footer}
    </div>
  );
};
