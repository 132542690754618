import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CompanyMeetingFoodSchema from './CompanyMeetingFoodSchema';

export default props => {
  return (
    <Switch>
      <Route
        exact
        path={props.match.url}
        render={cprops => {
          return (
            <CompanyMeetingFoodSchema
              {...cprops}
              backTo={props.backTo}
              companyAgreementId={props.companyAgreementId}
              admin={props.admin}
            />
          );
        }}
      />
      <Route
        exact
        path={props.match.url + '/:meetingFoodOrderId'}
        render={cprops => {
          return (
            <CompanyMeetingFoodSchema
              {...cprops}
              backTo={props.backTo}
              companyAgreementId={props.companyAgreementId}
              meetingFoodOrderId={cprops.match.params.meetingFoodOrderId}
              admin={props.admin}
            />
          );
        }}
      />
    </Switch>
  );
};
