import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';

import Card, { Actions, CollapsableCard } from '../../Card';
import Button from '../../Button';
import Link from '../../Link';

import firstSubDate from '../../../utils/firstSubDate';

import DatePicker from '../../DatePicker';

import {
  getForCustomerIfNeeded as getSubscriptionForCustomer,
  getAllForCustomerIfNeeded as getAllSubscriptionsForCustomer,
} from '../../../stores/subscription/actions';
import {
  selectCustomerSubscription,
  selectCustomerSubscriptions,
  selectCustomerSubscriptionMeta,
} from '../../../stores/subscription/selectors';
import { selectUser } from '../../../stores/user/selectors';
import {
  selectAllForCustomers,
  selectAllMeta,
} from '../../../stores/subscriptionType/selectors';
import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../stores/subscriptionType/actions';
import {
  deactivateCustomerSubscription,
  updateCustomerSubscription,
  createCustomerSubscription,
} from '../../../stores/subscription/actions';

import { getForCompanyAgreement as getSubscriptionTypesForCompanyAgreement } from '../../../stores/subscriptionType/actions';
import { selectCompanyAgreementMeta as selectCompanyAgreementSubscriptionTypeMeta } from '../../../stores/subscriptionType/selectors';
import selectCompanyAgreementSubscriptionTypes from '../../../selectors/subscriptionType/selectForCompanyAgreement';

import styles from './subscription.module.css';
import Loader from '../../Loader';
import DeactivateModal from './DeactivateModal';
import SubscriptionTypeService from '../../../services/SubscriptionTypeService';
import { selectCustomer } from '../../../stores/customer/selectors';
import { selectUserAllergies } from '../../../stores/user/selectors';
import typeSupportsAllergies from '../../../utils/typeSupportsAllergies';

import DeliveryDayPicker from '../../DeliveryDayPicker/DeliveryDayPicker';
import ColoredDots from '../../ColoredDots';

const availableDays = [
  { key: 'monday', name: 'Mandag', name_plural: 'Mandager' },
  { key: 'tuesday', name: 'Tirsdag', name_plural: 'Tirsdager' },
  { key: 'wednesday', name: 'Onsdag', name_plural: 'Onsdager' },
  { key: 'thursday', name: 'Torsdag', name_plural: 'Torsdager' },
  { key: 'friday', name: 'Fredag', name_plural: 'Fredager' },
];

const limit = moment(firstSubDate());

function Subscription(props) {
  const [state, setState] = useState({
    loaded: false,
    type: 'daily',
    days: [],
    entries: [],
    dailyEntries: [],
    startDate: limit,
    endDate: null,
    dayPicker: false,
    changes: false,
    dropdownFocused: false,
    datePickerFocused: false,
    companyAgreementStartDate: null,
    newSubscriptionStartDate: moment(firstSubDate()),
  });

  const [success, setSuccess] = useState(false);
  const [newSubscriptionCreated, setNewSubscriptionCreated] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [updateExecuted, setUpdateExecuted] = useState(false);
  const [deactivateModalOpened, setDeactivateModalOpened] = useState(false);

  useEffect(() => {
    props.getAllSubscriptionTypesIfNeeded();
    props.getSubscriptionForCustomer(props.customerId);
    props.getAllSubscriptionsForCustomer(props.customerId);
    props.companyAgreement &&
      props.getSubscriptionTypesForCompanyAgreement(props.companyAgreement.id);

    setSubscriptionState(props.subscription);

    if (
      props.companyAgreement &&
      props.companyAgreement.startDate &&
      moment(props.companyAgreement.startDate).isSameOrAfter(firstSubDate())
    ) {
      setState(prevstate => ({
        ...prevstate,
        companyAgreementStartDate: moment(props.companyAgreement.startDate),
        newSubscriptionStartDate: moment(props.companyAgreement.startDate),
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deliveryLocation = props.companyAgreement
    ? props.companyAgreement.deliveryLocation
    : props.customer
    ? props.customer.deliveryLocation
    : null;

  const kitchenId = deliveryLocation
    ? deliveryLocation.address.postalCode.kitchenId
    : null;

  const allEnabledSubscriptionTypes = SubscriptionTypeService.getEnabledSubscriptionTypes(
    props.subscriptionTypes,
    null,
    'customer',
    null,
    kitchenId,
    props.subscriptionTypesCompanyAgreement
  );

  const pickMostRelevantLunch = lunches => {
    const constructLunch = lunch => {
      return { type: lunch[0].type, menuAmount: 1 };
    };
    if (lunches.some(lunch => lunch.type === 'Vegan')) {
      const lunch = lunches.filter(lunch => lunch.type === 'Vegan');
      return constructLunch(lunch);
    }

    if (lunches.some(lunch => lunch.type === 'Vegetar')) {
      const lunch = lunches.filter(lunch => lunch.type === 'Vegetar');
      return constructLunch(lunch);
    }

    if (lunches.some(lunch => lunch.type === 'Salad')) {
      const lunch = lunches.filter(lunch => lunch.type === 'Salad');
      return constructLunch(lunch);
    }

    if (lunches.some(lunch => lunch.type === 'Recommended')) {
      return { type: 'Salad', menuAmount: 1 };
    }

    return { type: 'Vegan', menuAmount: 1 };
  };

  const swapEntriesWithDailyEntries = useCallback(
    sub => {
      // const subscription = { ...sub };
      const lunchTypes = allEnabledSubscriptionTypes.filter(
        a => a.type === 'box'
      );
      const allAccessories = allEnabledSubscriptionTypes.filter(
        a => a.type !== 'box'
      );

      const days = sub.days;
      const lunches = sub.data.entries.filter(
        lt =>
          lt.menuAmount > 0 && lunchTypes.map(lt => lt.alias).includes(lt.type)
      );

      const accessories = sub.data.entries.filter(a =>
        allAccessories.map(a => a.alias).includes(a.type)
      );

      const dailyEntries = days.map((entryDay, index) => {
        const newData = [];
        if (lunches && lunches.length > 0) {
          const lunch = pickMostRelevantLunch(lunches);
          newData.push(lunch);
        }
        const entry = {
          day: entryDay,
          data: newData,
          accessories: accessories ? accessories : [],
        };
        return entry;
      });
      sub.data.entries = [];
      sub.data.dailyEntries = dailyEntries;
      sub.type = 'daily';
      return sub;
    },
    [allEnabledSubscriptionTypes]
  );

  const setSubscriptionState = useCallback(
    sub => {
      if (isEmpty(sub)) return;

      if (sub && sub.type === 'weekly') {
        sub = swapEntriesWithDailyEntries(sub);
      }

      setState(prevState => ({
        ...prevState,
        loaded: true,
        type: sub.type,
        days: sub.days ? sub.days : [],
        entries: sub.data.entries ? sub.data.entries : [],
        dailyEntries: sub.data.dailyEntries ? sub.data.dailyEntries : [],
        startDate: moment(sub.startDate),
        endDate: sub.endDate ? moment(sub.endDate) : null,
        pause: sub.pause,
      }));
    },
    [swapEntriesWithDailyEntries]
  );

  useEffect(() => {
    if (
      !state.loaded &&
      props.subscription &&
      props.subscriptionTypes.length > 0
    ) {
      const isSame = isEqual(state, props.subscription);
      if (!isSame) {
        setSubscriptionState(props.subscription);
      }
    }
  }, [
    props.subscription,
    setSubscriptionState,
    state,
    props.subscriptionTypes,
  ]);

  useEffect(() => {
    if (props.meta.saving) {
      setSuccess(false);
    }

    const setSuccessState = state => {
      setSuccess(state);
      setTimeout(() => setSuccess(false), 2000);
    };

    if (!props.meta.saving && props.meta.success && newSubscriptionCreated) {
      setSuccessState(props.meta.success);
      setSubscriptionState(props.subscription);
      setNewSubscriptionCreated(false);
    }

    if (!props.meta.saving && props.meta.success && updateExecuted) {
      setSuccessState(props.meta.success);
      setUpdateExecuted(false);

      if (deactivateModalOpened) {
        setDeactivateModalOpened(false);
        setSubscriptionState(props.subscription);
      }
    }
  }, [
    deactivateModalOpened,
    newSubscriptionCreated,
    props.meta.saving,
    props.meta.success,
    props.subscription,
    setSubscriptionState,
    updateExecuted,
  ]);

  const createSubscription = () => {
    const dailyEntries = availableDays.map(day => {
      return {
        day: day.key,
        data: [
          {
            type: 'Salad',
            menuAmount: 1,
          },
        ],
      };
    });
    props.createCustomerSubscription(props.customerId, null, {
      type: 'daily',
      data: {
        entries: [],
        dailyEntries: [...dailyEntries],
      },
      startDate: state.newSubscriptionStartDate,
      days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    });

    setNewSubscriptionCreated(true);
  };

  const updateSubscription = () => {
    const newDailyEntries =
      state.type === 'daily'
        ? state.dailyEntries.filter(de => {
            if (
              (de.accessories && de.accessories.length > 0) ||
              de.data.length > 0
            ) {
              return de;
            }
            return null;
          })
        : state.days;
    console.log(newDailyEntries);

    if (
      !props.forAdmin &&
      newDailyEntries.filter(de => de.data.length > 0).length === 0
    ) {
      return;
    }

    const days = newDailyEntries.map(de => de.day);
    props.updateCustomerSubscription(props.customerId, props.subscription.key, {
      type: state.type,
      data: {
        entries: state.entries,
        dailyEntries: state.dailyEntries,
      },
      days: days,
    });
    setUpdateExecuted(true);
  };

  const deactivateSubscription = reason => {
    props.deactivateCustomerSubscription(
      props.customerId,
      props.subscription.key,
      { note: reason },
      false
    );

    setUpdateExecuted(true);
  };

  const hasActiveSubscription = () => {
    if (!props.subscription || !state.startDate) return false;
    if (!state.endDate) return true;

    return moment(state.endDate).isSameOrAfter(limit, 'day');
  };

  const noDaysSelected = () => {
    if (state.type === 'daily') {
      return state.dailyEntries.filter(de => de.data.length > 0).length === 0;
    } else {
      return state.days.length === 0;
    }
  };

  // Render functions

  function buildActivateSubscription() {
    const firstAllowedStartDate =
      state.companyAgreementStartDate || moment(firstSubDate());
    firstAllowedStartDate.set({ hours: 0, minutes: 0 });

    return (
      <div style={{ marginTop: 20 }}>
        {props.forAdmin && (
          <div>
            <div>Første levering</div>
            <DatePicker
              required
              placeholder=""
              date={moment(firstSubDate())}
              isOutsideRange={day => {
                return (
                  day.isBefore(firstAllowedStartDate) || day.isoWeekday() > 5
                );
              }}
              onDateChange={newSubscriptionStartDate =>
                setState(prev => ({ ...prev, newSubscriptionStartDate }))
              }
              focused={state.datePickerFocused}
              onFocusChange={({ focused }) =>
                setState(prev => ({ ...prev, datePickerFocused: focused }))
              }
            />
          </div>
        )}
        <Button
          size="large"
          onClick={() => createSubscription()}
          saving={props.meta.saving}
          success={success}
        >
          Start fast levering <div className="fa fa-arrow-right" />
        </Button>
      </div>
    );
  }

  const selectLunchForDay = (key, pickedLunch) => {
    const newState = { ...state };

    var day = newState.dailyEntries.find(o => o.day === key);
    if (!day) {
      newState.dailyEntries.push(pickedLunch);
    } else {
      day.data = pickedLunch.data;
    }

    setState(newState);
  };

  const addAccessory = (key, accessories) => {
    const newState = { ...state };
    const day = newState.dailyEntries.find(o => o.day === key);
    if (day) {
      day.accessories = accessories;
    } else {
      newState.dailyEntries.push({
        day: key,
        data: [],
        accessories: accessories,
      });
    }
    setState(newState);
  };

  function buildNewInstillinger() {
    const { forAdmin, meta, subscriptionTypesMeta } = props;
    if (
      meta.loading ||
      !state ||
      // !props.subscription ||
      subscriptionTypesMeta.loading ||
      subscriptionTypesMeta.companyAgreement.loading
    ) {
      return <Loader />;
    }

    return (
      <Card rounded isAdmin={forAdmin}>
        {!forAdmin && (
          <div className={styles.infoText}>
            <h2>Fast levering</h2>
            Her endrer du på din faste lunsjlevering fra oss. Ønsker du kun å
            avbestille eller endre lunsjtype enkelte dager gjør du det{' '}
            <strong>
              <a gray href="https://my.lunsjkollektivet.no">
                her.
              </a>
            </strong>
          </div>
        )}
        <br />
        {!hasActiveSubscription() ? (
          buildActivateSubscription()
        ) : (
          <>
            <h1>Ukedager</h1>
            <div className={styles.weeklyColumn}>
              {availableDays.map(day => {
                const copiedState = { ...state };
                const allEnabledSubTypes = SubscriptionTypeService.getEnabledSubscriptionTypes(
                  props.subscriptionTypes,
                  null,
                  'customer',
                  day.key,
                  kitchenId,
                  props.subscriptionTypesCompanyAgreement
                );
                const lunchTypes = allEnabledSubTypes.filter(a => {
                  if (
                    a.type === 'box' &&
                    typeSupportsAllergies(props.allergies, a)
                  ) {
                    return a;
                  }
                  return null;
                });

                const selectedLunchType = lunchTypes.filter(
                  lt =>
                    lt.alias ===
                    copiedState.dailyEntries.find(x => x.day === day.key)
                      ?.data[0]?.type
                );

                const accessories = allEnabledSubTypes.filter(
                  a => a.type !== 'box'
                );

                const selectedAccessories = copiedState.dailyEntries.find(
                  x => x.day === day.key
                )?.accessories;

                let savedAccessories = {};

                if (selectedAccessories && selectedAccessories.length > 0) {
                  const res = selectedAccessories.map(sa => {
                    const acc = allEnabledSubTypes.find(
                      a => a.alias === sa.type
                    );
                    acc.menuAmount = sa.menuAmount;

                    return acc;
                  });

                  savedAccessories = res;
                }

                return (
                  <DeliveryDayPicker
                    key={day.key}
                    day={day.key}
                    weekday={day.name}
                    selectLunchHandler={selectLunchForDay}
                    lunchType={selectedLunchType}
                    savedAccessories={savedAccessories}
                    addAccessory={addAccessory}
                    allAccessories={accessories}
                    allLunsjTypes={lunchTypes}
                  />
                );
              })}
            </div>

            <br />
            <ColoredDots
              colors={[
                { color: '#cbdfd1', text: 'dager med fast levering' },
                { color: '#DCD4D4', text: 'dager uten fast levering' },
              ]}
            />
            <br />
            <Actions>
              <Button
                size="medium"
                onClick={() => updateSubscription()}
                disabled={!props.forAdmin && noDaysSelected()}
                saving={props.meta.saving}
                success={success}
              >
                Lagre
              </Button>
              <Link
                gray
                onClick={() => setDeactivateModalOpened(true)}
                size="medium"
              >
                Stopp leveringer
              </Link>
            </Actions>
            <DeactivateModal
              open={deactivateModalOpened}
              onDeactivate={deactivateSubscription}
              onClose={() => setDeactivateModalOpened(false)}
              isDeactivating={props.meta.saving}
            />
          </>
        )}
      </Card>
    );
  }

  if (props.forAdmin) {
    return (
      <CollapsableCard
        header="Abonnement"
        collapsed={collapsed}
        onChange={collapsed => setCollapsed(collapsed)}
      >
        {buildNewInstillinger()}
      </CollapsableCard>
    );
  }

  return <>{buildNewInstillinger()}</>;
}

export default withRouter(
  connect(
    (state, ownProps) => {
      const customer = selectCustomer(state, ownProps.customerId);
      const companyAgreement = customer.companyAgreement
        ? customer.companyAgreement
        : null;
      return {
        subscriptionTypesCompanyAgreement: selectCompanyAgreementSubscriptionTypes(
          state,
          companyAgreement ? companyAgreement.id : null
        ),
        subscriptionTypesCompanyAgreementMeta: selectCompanyAgreementSubscriptionTypeMeta(
          state,
          companyAgreement ? companyAgreement.id : null
        ),
        subscription: selectCustomerSubscription(state, ownProps.customerId),
        allSubscriptions: selectCustomerSubscriptions(
          state,
          ownProps.customerId
        ),
        subscriptionTypes: selectAllForCustomers(state),
        subscriptionTypesMeta: selectAllMeta(state),
        allergies: selectUserAllergies(state, ownProps.userId),
        meta: selectCustomerSubscriptionMeta(state, ownProps.customerId),
        user: selectUser(state, ownProps.userId),
        companyAgreement,
        customer,
      };
    },
    {
      getSubscriptionForCustomer,
      getAllSubscriptionsForCustomer,
      getAllSubscriptionTypesIfNeeded,
      getSubscriptionTypesForCompanyAgreement,
      updateCustomerSubscription,
      createCustomerSubscription,
      deactivateCustomerSubscription,
    }
  )(Subscription)
);
