import React from 'react';
import Latest from './Latest';

import SanityHoc from '../../../../hocs/SanityHoc';

const GET_LATEST = `
*[_type == $type && isPublished == true] | order(_createdAt desc) {
  title,
  body,
  ingress,
  publishedAt,
  author->,
  receipe->,
  mainImage,
  _id,
  _type
}
`;

export default props => (
  <SanityHoc fetch={client => client.fetch(GET_LATEST, { type: 'post' })}>
    {(loading, error, sanityData) => (
      <Latest
        {...props}
        sanityData={sanityData ? sanityData.slice(0, 15) : []}
        loading={loading}
        error={error}
      />
    )}
  </SanityHoc>
);
