import moment from 'moment';
import types from './types';
import meTypes from '../me/types';
import customerTypes from '../customer/types';

const initialState = {
  entities: {},
  meta: {},
  counts: {},
};

const updateEntities = (state, entities) => {
  return Object.values(entities).reduce(
    (state, entity) => {
      if (
        state[entity.id] &&
        state[entity.id].customers &&
        (!entity.customers || entity.customers.length === 0)
      ) {
        state[entity.id] = {
          ...entity,
          customers: state[entity.id].customers,
        };
      } else {
        state[entity.id] = {
          ...entity,
        };
      }
      return state;
    },
    { ...state.entities }
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_START:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.search]: {
            loading: true,
            error: false,
            loadedAt: null,
            ids: [],
          },
        },
      };
    case types.GET_SUCCESS:
      return {
        ...state,
        entities: updateEntities(state, action.payload.entities),
        meta: {
          ...state.meta,
          [action.payload.search]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
      };
    case types.GET_V2_SUCCESS:
      return {
        ...state,
        entities: updateEntities(state, action.payload.entities),
        meta: {
          ...state.meta,
          [action.payload.search]: {
            loading: false,
            error: false,
            loadedAt: moment().unix(),
            ids: Object.keys(action.payload.entities),
          },
        },
        counts: {
          ...state.counts,
          [action.payload.totalKey]: action.payload.total,
        },
      };
    case types.GET_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          [action.payload.search]: {
            loading: false,
            error: true,
            loadedAt: moment().unix(),
            ids: [],
          },
        },
      };
    case meTypes.GET_ME_SUCCESS:
    case customerTypes.GET_ONE_SUCCESS:
      return {
        ...state,
        entities: updateEntities(state, {
          [action.payload.identity.id]: action.payload.identity,
        }),
      };
    case meTypes.UPDATE_MY_IDENTITY_SUCCESS:
      return {
        ...state,
        entities: updateEntities(state, {
          [action.payload.id]: action.payload,
        }),
      };
    case customerTypes.GET_SUCCESS:
    case customerTypes.GET_LATEST_SUCCESS:
      return {
        ...state,
        entities: updateEntities(state, {
          ...Object.values(action.payload.entities).reduce(
            (users, customer) => {
              users[customer.identity.id] = customer.identity;
              return users;
            },
            {}
          ),
        }),
      };
    case customerTypes.UPDATE_CUSTOMER_IDENTITY_SUCCESS:
      return {
        ...state,
        entities: updateEntities(state, {
          [action.payload.response.id]: action.payload.response,
        }),
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        entities: updateEntities(state, {
          [action.payload.id]: action.payload,
        }),
      };
    default:
      return state;
  }
};
