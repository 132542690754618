import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import Spinner from '../../Spinner';

import styles from './searchableInput.module.css';

class DropDown extends React.Component {
  mapEntities = () => {
    const Row = this.props.rowComponent;

    if (!this.props.disabled) {
      return <div>
        {this.props.entities.map((entity, i) =>
          <div
            className={styles.dropDownItem}
            key={i}
            onClick={e => {
              this.props.changeFocus(false);
              this.props.onChange(entity);
              e.stopPropagation();
            }}
          >
            <Row entity={entity} index={i} />
          </div>
        )}
      </div>
    }

    else {
      return <div>
        {this.props.entities.filter(e => !this.props.disabled(e)).map((entity, i) =>
          <div
            className={styles.dropDownItem}
            key={i}
            onClick={e => {
              this.props.changeFocus(false);
              this.props.onChange(entity);
              e.stopPropagation();
            }}
          >
            <Row entity={entity} index={i} />
          </div>
        )}
        {this.props.entities.filter(e => this.props.disabled(e)).map((entity, i) =>
          <div
            className={styles.dropDownItemDisabled}
            key={i}
            onClick={e => {
              this.props.changeFocus(false);
              this.props.onChange(entity);
              e.stopPropagation();
            }}
          >
            <Row entity={entity} index={i} />
          </div>
        )}</div>
    }
  };

  render() {
    if (this.props.searchText === '') return null;

    return (
      <div
        className={cn(styles.dropDown, {
          [styles.show]: this.props.focused,
          [styles.absolute]: this.props.absolute,
        })}
      >
        {(this.props.meta && this.props.meta.loading &&
          (this.props.meta.loadingActive || !this.props.entities.length)) ||
          this.props.search !== this.props.searchText ? (
            <div className={styles.dropDownItemDisabled}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '20px', marginTop: '5px' }}>
                  <Spinner black />
                </div>
                <strong>Søker...</strong>
              </div>
            </div>
          ) : (this.props.meta && this.props.meta.loading) ? (
            <div>
              {this.mapEntities()}
              <div className={styles.dropDownItemDisabled}>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: '20px', marginTop: '5px' }}>
                    <Spinner black />
                  </div>
                  <strong>Henter flere...</strong>
                </div>
              </div>
            </div>
          ) : !this.props.entities.length ? (
            <div className={styles.dropDownItemDisabled}>
              <div>
                <strong>Vi fant ingenting i databasen.</strong>
              </div>
            </div>
          ) : this.mapEntities()}
      </div>
    );
  }
}

export default connect((state, ownProps) => {
  return {
    entities: ownProps.selector(state, ownProps.search),
    meta: ownProps.metaSelector
      ? ownProps.metaSelector(state, ownProps.search)
      : null,
  };
})(DropDown);
