import React from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';

import styles from './pageTitle.module.css';

const PageTitle = props => (
  <div className={styles.header}>
    <div onClick={() => {
          if (!props.backTo) return;
          props.history.push(props.backTo);
        }}
        style={{display: 'flex'}}>
      {props.prev && <div className={styles.prev}>
      <i className={cn('fa fa-chevron-left', styles.chevron)} />
      {props.prev}
      </div>
        }
      <h3
        className={cn(styles.title, styles.preventOverlappingByMenu, {
          [styles.clickable]: props.backTo,
        })}
      >
        {props.backTo ? <i className={cn('fa fa-chevron-left', styles.chevron)} /> : ''}
        {props.children}
      </h3>
    </div>
    {props.picker && (
      <div className={`${styles.pickerWrapper} ${styles.preventOverlappingByMenu}`}>
        <div onClick={e => e.stopPropagation()}
            className="no-print"
            >
          <props.picker />
        </div>
      </div>
    )}
  </div>
);

export default withRouter(PageTitle);
