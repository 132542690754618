import React from 'react';

import Container from '../../components/Container';
import Header from '../../components/Header';

import homeStyles from './Home/home.module.css';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      signupDialogOpen: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Header image="/images/background-v2.jpg" height={600}>
          <div
            className={homeStyles.heroContent}
            style={{ color: 'white', marginTop: 80 }}
          >
            <h1 className="text-center">
              Lyst på litt ekstra god stemning <br />
              på kontoret på fredagene?
            </h1>
          </div>
        </Header>
        <Container width={700} style={{ paddingBottom: 40 }} withPadding>
          <div className={homeStyles.introBlock}>
            <p>
              Lyst på litt ekstra god stemning på kontoret på fredagene? La
              Lunsjkollektivet fikse felleslunsj til dere!
            </p>
            <p>
              Er dere flere enn 10 personer ordner vi gjerne varmmat på fredager
              eller suppe på mandag.
            </p>
            <p>
              Hver fredag disker kokkene våre opp med en ny og smakfull rett,
              gjerne med langtidshevet ferskt brød til om dere ønsker det.
            </p>
            <p>
              Vi leverer lunsjen fiks ferdig klar til å spises - dere fordeler
              selv.
            </p>
            <p>
              Har noen på kontoret allergier? Det tilpasser vi selvsagt for.
            </p>
            <p>
              <strong>
                Lyst til å vite mer? Ta kontakt på chatten vår, så hjelper vi
                deg!
              </strong>
            </p>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default Home;
