export default (alias, subscriptionTypes) => {
  if (!alias || !subscriptionTypes || !subscriptionTypes.length) return false;

  const match = Object.values(subscriptionTypes).filter(
    type => type.alias === alias
  );

  if (!match || !match.length) return false;

  return match[0].type === 'box';
};
