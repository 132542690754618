import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../Button';
import NumberPicker from '../../NumberPicker';

import NewAllergyOrder from '../../MyLunchCalendar/NewAllergyOrder';

import SubscriptionTypeService from '../../../services/SubscriptionTypeService';
import getSubscriptionPrice from '../../../utils/subscriptionPrice';
import typeSupportsAllergies from '../../../utils/typeSupportsAllergies';
import styles from './day.module.css';

class DayOrder extends React.Component {
  state = {
    creatingNew: false,
  };

  onChange = (group, value) => {
    if (this.props.saving) return;
    this.props.onChange(
      group.types.map((type, index) => {
        return {
          menuAmount: index === 0 ? value : 0,
          allergies: group.allergies,
          type,
        };
      })
    );
  };

  getSubscriptionTypes = () => {
    return SubscriptionTypeService.getEnabledSubscriptionTypes(
      this.props.subscriptionTypes,
      this.props.companyAgreement,
      'company',
      this.props.day
        ? this.props.day
          .clone()
          .locale('en')
          .format('dddd')
          .toLowerCase()
        : this.props.weekDayString
          ? this.props.weekDayString
          : null,
      this.props.deliveryLocation
        ? this.props.deliveryLocation.address?.postalCode?.kitchenId
        : null,
      this.props.subscriptionTypesCompanyAgreement
    );
  };

  buildProduct = group => {
    const type = group.types[0];
    return (<div
      key={group.key}
      className={
        group.amount
          ? styles.typePickerActive
          : styles.typePicker
      }
    >
      <div
        style={{
          backgroundImage: `url(${type.imagePath})`,
        }}
        className={styles.image}
      />
      <div className={styles.info}>
        <div>
          <strong>
            {group.amount > 0 ? (
              <div
                className={cn('fa', 'fa-check', styles.check)}
              />
            ) : null}{' '}
            {group.amount > 0 ? group.amount + 'x ' : ''}
            {type.name}
          </strong>{' '}
          {getSubscriptionPrice(
            this.props.subscription,
            this.props.companyAgreement,
            type,
            this.props.user
          )}
                ,-{' '}
        </div>
        {group.allergies.length > 0 &&
          <div>Uten {group.allergies.map(a => a.name).join(', ')}</div>}
        <div>
          {group.amount > 0 ? (
            <NumberPicker
              value={group.amount}
              onChange={number =>
                this.onChange(group, number)
              }
            />
          ) : (
              <Button
                className={styles.button}
                onClick={() => this.onChange(group, 1)}
              >
                Velg
              </Button>
            )}
        </div>
      </div>
    </div>
    );
  };


  render() {
    let groups = {};

    let subscriptionTypes = this.getSubscriptionTypes();

    subscriptionTypes.forEach(type => {
      let values = this.props.values.filter(
        v => v.type && v.type.id === type.id
      );

      if (values.filter(v => v.allergies.length === 0).length === 0) {
        values.push({
          allergies: [],
          menuAmount: 0,
        });
      }
      values.forEach(value => {
        const allergies = Array.isArray(value.allergies) ? value.allergies : [];
        const key = `${type.id}_${type.onlyAdmin}_${type.isExtra ? 'isExtra' : 'isNotExtra'
          }_${allergies.map(a => JSON.stringify(a)).join('-')}`;

        if (allergies.length > 0 && value.menuAmount === 0) {
          return;
        }

        if (!groups[key]) {
          groups[key] = {
            types: [],
            onlyAdmin: type.onlyAdmin,
            isExtra: type.isExtra,
            allergies,
            amount: 0,
            key,
          };
        }

        groups[key].types.push(type);
        groups[key].amount += value.menuAmount;
      });
    });

    return (
      <div>
        <div className={styles.wrapper}>
          <div className={styles.section}>
            1 - <strong>Lunsjbokser</strong>
          </div>
          <div className={styles.weeklyColumn}>
            {Object.values(groups)
              .filter(
                group =>
                  (this.props.admin || !group.onlyAdmin || group.amount > 0)
              )
              .filter(a => a.types[0].type === 'box')
              .map(group =>
                this.buildProduct(group)
              )}
          </div>
          <div className={styles.section}>
            2 - <strong>Tilbehør</strong>
          </div>
          <div className={styles.weeklyColumn}>
            {Object.values(groups)
              .filter(
                group =>
                  (this.props.admin || !group.onlyAdmin || group.amount > 0)
              )
              .filter(a => a.types[0].type !== 'box')
              .map(group =>
                this.buildProduct(group)
              )}
          </div>
        </div>
        {this.state.creatingNew ? (
          <NewAllergyOrder
            fullAllergyObject
            subscriptionTypes={
              this.getSubscriptionTypes()
                .filter(t => typeSupportsAllergies(
                  true,
                  t
                )).filter(
                  st => (this.props.admin || !st.onlyAdmin) && !st.isExtra
                )}
            onAdd={(type, allergies) => {
              this.props.onChange([
                {
                  menuAmount: 1,
                  allergies: allergies,
                  type,
                },
              ]);
              this.setState({ creatingNew: false });
            }}
            onClose={() => this.setState({ creatingNew: false })}
            admin={this.props.admin}
          />
        ) : (
            <div style={{ margin: '15px 0' }}>
              <Button onClick={() => this.setState({ creatingNew: true })}>
                <div className="fa fa-plus" /> Allergitilpasset
            </Button>
            </div>
          )}
      </div>
    );
  };
}

DayOrder.defaultProps = {
  enablePriceSplitting: false,
  day: null
};

DayOrder.propTypes = {
  success: PropTypes.bool,
  saving: PropTypes.bool,
  day: PropTypes.object,
  subscription: PropTypes.object,
  subscriptionTypes: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  enableRating: PropTypes.bool,
  enablePriceSplitting: PropTypes.bool,
};

export default DayOrder;
