import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Container from '../../../../components/Container';
import Loader from '../../../../components/Loader';
import Alert from '../../../../components/Alert';
import Table from '../../../../components/Table';
import Card from '../../../../components/Card';
import { BackButton } from '../../../../components/Button';
import formatUserFrendlyDate from '../../../../utils/formatUserFrendlyDate';

/**
 * Invoice Store functions
 */
import { getCustomerInvoices } from '../../../../stores/invoice/actions';
import selectCustomerInvoices from '../../../../selectors/invoice/selectCustomerInvoices';
import selectCustomerInvoicesMeta from '../../../../selectors/invoice/selectCustomerInvoicesMeta';

/**
 * Me Store functions
 */
import selectedCustomer from '../../../../selectors/me/selectedCustomer';

class InvoiceList extends React.Component {
  componentDidMount() {
    this.props.getCustomerInvoices(this.props.customerId);
  }

  componentDidUpdate() {
    this.props.getCustomerInvoices(this.props.customerId);
  }

  getContent() {
    if (this.props.loading) {
      return <Loader />;
    }

    if (this.props.error) {
      return <Alert color="red">Noe skjedde</Alert>;
    }

    if (this.props.invoices.length === 0) {
      return <Card header="Faktura">Ingen Faktura</Card>;
    }

    var invoices = this.props.invoices.filter(x => x.totalPrice);

    return (
      <Card header="Faktura">
        <Table clickable>
          <thead>
            <tr>
              <th>Fakturadato</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map(invoice => {
              const grandTotal = invoice.totalPrice + (invoice.deliveryCost || 0);

              return (
                <tr
                  key={invoice.id}
                  onClick={() =>
                    this.props.history.push(
                      this.props.match.url + '/' + invoice.id
                    )
                  }
                >
                  <td>{formatUserFrendlyDate(moment(invoice.invoiceDate))}</td>
                  <td>{grandTotal}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
    );
  }
  render() {
    return (
      <Container color="gray" withPadding height="42vh">
        <BackButton to="/innstillinger" />
        <div style={{ marginTop: 20 }}>{this.getContent()}</div>
      </Container>
    );
  }
}

export default connect(
  state => {
    const customerId = selectedCustomer(state).id;

    const invoicesMeta = selectCustomerInvoicesMeta(state, customerId);

    return {
      invoices: selectCustomerInvoices(state, customerId),
      loading: invoicesMeta.loading,
      error: invoicesMeta.error,
      customerId,
    };
  },
  { getCustomerInvoices }
)(InvoiceList);
