import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Container from '../../Container';
import Card from '../../Card';
import { BackButton } from '../../Button';
import Alert from '../../Alert';
import Loader from '../../Loader';
import firstDate from '../../../utils/firstDate';
import { selectCompanyAgreement } from '../../../stores/companyAgreement/selectors';

import NoExtraConfirmation from './NoExtraConfirmation';

import {
  getMeetingFoodOrderIfNeeded,
  updateMeetingFoodOrder,
  createMeetingFoodOrder,
  deleteMeetingFoodOrder,
} from '../../../stores/meetingFoodOrder/actions';
import {
  selectOneForCompanyAgreementMeta,
  selectOneForCompanyAgreement,
} from '../../../stores/meetingFoodOrder/selectors';

import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../stores/subscriptionType/actions';
import selectAllSubscriptionTypes from '../../../selectors/subscriptionType/selectAll';

import CompanyMeetingFoodSchemaRaw from './CompanyMeetingFoodSchemaRaw';

class Motemat extends React.Component {
  state = {
    date: firstDate(1),
    orders: [],
    modalOpened: false,
  };

  componentDidMount() {
    this.props.getAllSubscriptionTypesIfNeeded();
    if (this.props.meetingFoodOrderId) {
      this.props.getMeetingFoodOrderIfNeeded(
        this.props.companyAgreementId,
        this.props.meetingFoodOrderId
      );
      this.update(null);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.getAllSubscriptionTypesIfNeeded();
    if (this.props.meetingFoodOrderId) {
      this.props.getMeetingFoodOrderIfNeeded(
        this.props.companyAgreementId,
        this.props.meetingFoodOrderId
      );
      this.update(prevProps);
    }
  }

  update(prevProps) {
    if (
      this.props.meetingFoodOrder &&
      ((!prevProps && this.props.meetingFoodOrder) ||
        (prevProps &&
          prevProps.meetingFoodOrder !== this.props.meetingFoodOrder))
    ) {
      this.setState({
        date: moment(this.props.meetingFoodOrder.date),
        orders: this.props.meetingFoodOrder.data.entries.map(e => ({
          menuAmount: e.amount,
          type: e.type,
          allergies: e.allergies,
        })),
      });
    }
  }

  save() {
    this.setState({ saving: true, error: false, success: false });

    let promise = null;
    const data = {
      details: this.state.orders.map(e => ({
        amount: e.menuAmount,
        type: e.type,
        allergies: e.allergies,
      })),
      date: this.state.date.format('YYYY-MM-DD'),
    };

    if (this.props.meetingFoodOrderId) {
      promise = this.props.updateMeetingFoodOrder(
        this.props.companyAgreementId,
        this.props.meetingFoodOrderId,
        data
      );
    } else {
      promise = this.props.createMeetingFoodOrder(
        this.props.companyAgreementId,
        data
      );
    }

    promise
      .then(order => {
        if (!this.props.meetingFoodOrderId) {
          this.props.history.push(this.props.match.url + `/${order.id}`);
        }
        this.setState({ saving: false, success: true });
        setTimeout(() => this.setState({ success: false }), 2000);
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  }

  deleteOrder() {
    this.setState({ saving: true, error: false, success: false });

    const emptyOrders = this.state.orders.map(e => ({
      menuAmount: 0,
      type: e.type,
      allergies: e.allergies,
    }));

    this.setState({
      orders: emptyOrders,
    });

    this.props
      .updateMeetingFoodOrder(
        this.props.companyAgreementId,
        this.props.meetingFoodOrderId,
        { details: emptyOrders, date: this.state.date.format('YYYY-MM-DD') }
      )
      .then(() => this.props.history.push(this.props.backTo))
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  }

  hasExtraOrder(orders) {
    return orders.filter(o => o.menuAmount > 0 && o.type.isExtra).length > 0;
  }

  onSave() {
    if (
      this.props.admin ||
      this.state.dismissedExtra ||
      this.hasExtraOrder(this.state.orders)
    ) {
      return this.save();
    }

    this.setState({ modalOpened: true });
  }

  buildContent() {
    if (this.props.meetingFoodOrderId && !this.props.meetingFoodOrder) {
      if (this.props.meta.loading) {
        return <Loader />;
      }
      if (this.props.meta.error) {
        return (
          <Alert color="red">
            Noe skjedde når vi lastet inn bestillingen din
          </Alert>
        );
      }
    }
    return (
      <CompanyMeetingFoodSchemaRaw
        withSummary={this.props.withSummary}
        orders={this.state.orders}
        date={this.state.date}
        companyAgreementId={this.props.companyAgreementId}
        companyAgreement={this.props.companyAgreement}
        meetingFoodOrderId={this.props.meetingFoodOrderId}
        onUpdate={state => this.setState(state)}
        onSave={() => this.onSave()}
        onDeleteOrder={() => this.deleteOrder()}
        saving={this.state.saving}
        success={this.state.success}
        admin={this.props.admin}
        extraModalOpened={this.state.extraModalOpened}
        onExtraModalChange={extraModalOpened =>
          this.setState({ extraModalOpened })
        }
      />
    );
  }

  render() {
    return (
      <Container color="gray" width={870} withPadding>
        <BackButton to={this.props.backTo} />
        <Card rounded>
          {this.buildContent()}
          <NoExtraConfirmation
            open={this.state.modalOpened}
            onContinue={() => {
              this.setState({ modalOpened: false }, () => this.save());
            }}
            onOrderExtra={() =>
              this.setState({ extraModalOpened: true, modalOpened: false })
            }
          />
        </Card>
      </Container>
    );
  }
}

Motemat.defaultProps = {
  withSummary: true,
};

export default connect(
  (state, ownProps) => ({
    subscriptionTypes: selectAllSubscriptionTypes(state),
    companyAgreement: selectCompanyAgreement(
      state,
      ownProps.companyAgreementId
    ),
    meta: selectOneForCompanyAgreementMeta(
      state,
      ownProps.companyAgreementId,
      ownProps.meetingFoodOrderId
    ),
    meetingFoodOrder: selectOneForCompanyAgreement(
      state,
      ownProps.companyAgreementId,
      ownProps.meetingFoodOrderId
    ),
  }),
  {
    getAllSubscriptionTypesIfNeeded,
    getMeetingFoodOrderIfNeeded,
    createMeetingFoodOrder,
    updateMeetingFoodOrder,
    deleteMeetingFoodOrder,
  }
)(Motemat);
