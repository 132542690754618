import React from 'react';
import PropTypes from 'prop-types';

import styles from './lunch.module.css';

import Day from './Day';
import { addChoice } from '../../utils/lunchOverviewUtils';

class DayOrder extends React.Component {
  state = {
    creatingAllergyOrder: false,
  };

  getSubscriptionType(alias) {
    return (
      this.props.subscriptionTypes.find(
        subscriptionType => subscriptionType.alias === alias
      ) || {}
    );
  }

  getValuesForDay() {
    if (!this.props.values) return [];

    let values = [];

    this.props.values.forEach(value => {
      values = this.addChoice(values, value);
    });

    return values;
  }

  addChoice(original, value) {
    let dayValues = original;

    let found = false;

    dayValues = dayValues.reduce((dayValues, dayValue) => {
      if (
        dayValue.type === value.type &&
        value.allergies.length === dayValue.allergies.length &&
        !value.allergies.find(
          allergy => dayValue.allergies.indexOf(allergy) === -1
        )
      ) {
        dayValue.menuAmount = value.menuAmount;
        found = true;
      }
      dayValues.push(dayValue);
      return dayValues;
    }, []);

    if (!found) {
      dayValues.push(value);
    }

    return dayValues;
  }

  onChange(changes) {
    this.props.onChange(
      changes.reduce(
        (values, change) => addChoice(values, change),
        this.getValuesForDay()
      )
    );
  }
  render() {
    return (
      <div className={styles.dayCard}>
        <Day
          subscriptionTypes={this.props.subscriptionTypes}
          subscriptionTypesCompanyAgreement={
            this.props.subscriptionTypesCompanyAgreement
          }
          values={this.getValuesForDay()}
          onChange={changes => this.onChange(changes)}
          orders={[]}
          onCancelOrder={() => { }}
          hasActiveSubscription
          enableAllergySelection
          subscriptionTypeUser="company"
          companySubscription
          showWrapper={false}
          showButtons={false}
          hasFullWeekDiscount={this.props.hasFullWeekDiscount}
          admin={this.props.admin}
          deliveryLocation={this.props.deliveryLocation}
          weekDayString={this.props.weekDayString}
          companyAgreement={this.props.companyAgreement}
        />
      </div>
    );
  }
}

DayOrder.defaultProps = {};

DayOrder.propTypes = {
  subscriptionTypes: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
};

export default DayOrder;
