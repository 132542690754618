import React from 'react';
import cn from 'classnames';

import styles from './checkboxGroup.module.css';

const CheckboxGroup = ({ label, children, direction }) => (
  <div>
    <div>{label}</div>
    <div
      className={cn({
        [styles.checkboxGroupColumn]: direction === 'column',
        [styles.checkboxGroupRow]: direction === 'row',
      })}
    >
      {children}
    </div>
  </div>
);

CheckboxGroup.defaultProps = {
  direction: 'column',
};

export default CheckboxGroup;
