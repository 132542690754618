import React from 'react';

import Header from '../../components/Header';
import Container from '../../components/Container';

export default () => (
  <div>
    <Header image="/images/winter/background.jpg" height={200} />
    <Container withPadding>
      <h1>Informasjonskapsler</h1>
      <p>
        Lunsjkollektivet AS bruker cookies (også kalt informasjonskapsler). Ved
        å tillate cookies i nettleseren du bruker, samtykker du til at
        nettstedet du besøker, herunder Lunsjkollektivet.no, kan bruke disse.
        Har du deaktivert cookies kan vi ikke garantere at nettsiden fungerer
        som den skal.
      </p>
      <h4>Hva er informasjonskapsler?</h4>
      <p>
        Cookies er små tekstfiler som lagres lokalt på din datamaskin eller
        mobiltelefon når du besøker en nettside for å kunne kjenne deg igjen
        neste gang du besøker nettstedet. Cookies blir mye brukt for å gjøre
        brukeropplevelsen på nettsider bedre og for å huske brukerpreferanser.
      </p>
      <h4>Hva brukes informasjonen til?</h4>
      <p>
        Informasjonen som lagres i cookies brukes hovedsakelig til å gjøre
        brukeropplevelsen din bedre, både på og utenfor våre nettsider. I
        tillegg brukes noe av informasjonen til analyse gjennom programmet
        Google Analytics, Google Tag Manager, Facebook Pixel og Hotjar.
      </p>
      <h4>Hvilken informasjon lagres i informasjonskapslene?</h4>
      <p>
        Session-cookies brukes blant annet til å lagre informasjon om hvem det
        er som er logget inn på nettsidene, slik at den besøkende ikke må logge
        seg inn på nytt hver gang de åpner en ny side. Disse cookiene brukes
        også til å lagre og sende informasjon mellom de forskjellige sidene man
        besøker. Cookier av denne typen slettes automatisk når nettleseren
        lukkes.
      </p>
      <p>
        Analytiske cookies er cookies som brukes av verktøyet Google Analytics,
        Facebook Pixel og andre webanalyse-verktøy. Verktøyet brukes til å følge
        med på hvordan nettsidene våre brukes, og spiller derfor en stor rolle
        når vi jobber med å forbedre nettsidene våre. Informasjonen som hentes
        inn består blant annet av hvor mange besøkende det er, hvor mange
        sidevisninger det er, og hvor besøkende kommer fra. Google Analytics
        lagrer ikke informasjon som direkte kan identifisere de besøkende.
      </p>
      <h4>Hvordan deaktivere cookies?</h4>
      <p>
        Ønsker du å unngå cookies kan du deaktivere disse i
        sikkerhetsinstillingene til nettleseren din. Man kan da velge å
        deaktivere cookies for alle nettsteder eller bare for noen enkelte
        nettsteder.Vi gjør oppmerksom på at dersom du deaktiverer cookies på
        lunsjkollektivet.no kan vi ikke garantere at nettsiden fungerer som den
        skal.
      </p>
    </Container>
  </div>
);
