import React from 'react';
import SentryPages from './SentryPages';

import SanityHoc from '../../../hocs/SanityHoc';

const GET_PAGE = `
*[_type == $type && url == $url] {
  title,
  body,
  _type
}
`;

export default props => (
  <SanityHoc
    fetch={client =>
      client.fetch(GET_PAGE, {
        type: 'webPage',
        url: props.location.pathname,
      })
    }
  >
    {(loading, error, sanityData) => (
      <SentryPages
        {...props}
        webpageData={
          sanityData && sanityData.length !== 0 ? sanityData[0] : null
        }
        loading={loading}
        error={error}
      />
    )}
  </SanityHoc>
);
