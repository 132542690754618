import React from 'react';
import { connect } from 'react-redux';
import Container from '../../Container';
import Button, { BackButton } from '../../Button';
import AllergyPicker from '../../AllergyPicker';
import Alert from '../../Alert';
import Card, { Separator } from '../../Card';

import selectAllergies from '../../../selectors/allergy/selectAllergies';

import { updateAllergies } from '../../../stores/user/actions';
import { selectUserAllergies } from '../../../stores/user/selectors';
import { getForCustomerIfNeeded as getSubscriptionForCustomer } from '../../../stores/subscription/actions';
import {
  selectCustomerSubscription,
  selectCustomerSubscriptionMeta,
} from '../../../stores/subscription/selectors';
import typeSupportsAllergies from '../../../utils/typeSupportsAllergies';
import Spinner from '../../Spinner';

class Allergies extends React.Component {
  state = { selectedAllergies: [] };

  componentDidMount() {
    this.props.getSubscriptionForCustomer(this.props.customerId);
    this.setState({
      selectedAllergies: this.props.allergies,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.allergies !== prevProps.allergies) {
      this.setState({
        selectedAllergies: this.props.allergies,
      });
    }
  }

  onSaveAllergiesClicked(e) {
    this.setState({ saving: true, error: false, success: false });

    this.props
      .updateAllergies(this.props.userId, this.state.selectedAllergies)
      .then(() => {
        this.setState({ saving: false, success: true });
        setTimeout(() => this.setState({ success: false }), 2000);

        window.dataLayer.push({
          event: 'GAEvent',
          eventCategory: 'Private settings',
          eventAction: 'Update',
          eventLabel: 'Allergies',
        });
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  }

  render() {
    if (this.props.subscriptionMeta.loading) {
      return (
        <Container withPadding color="gray">
          <BackButton to={this.props.backTo} />
          <Spinner />
        </Container>
      );
    }
    let allergiesSupported = true;

    // check subscription entries
    if (
      !this.props.subscription.data.entries.every(
        type =>
          !type.menuAmount ||
          typeSupportsAllergies(this.props.allAllergies, type)
      )
    ) {
      allergiesSupported = false;
    }
    // check subscription daily entries
    if (
      !this.props.subscription.data.dailyEntries.every(
        type =>
          !type.menuAmount ||
          typeSupportsAllergies(this.props.allAllergies, type)
      )
    ) {
      allergiesSupported = false;
    }

    return (
      <Container withPadding color="gray">
        <BackButton to={this.props.backTo} />
        <Card header="Allergier">
          <p style={{ color: '#FB8C00' }}>
            NB! Tilleggsprodukt allergitilpasses ikke og du må selv følge med på
            allergimerkingen.
          </p>

          {allergiesSupported ? (
            <AllergyPicker
              allergies={
                this.state.selectedAllergies ? this.state.selectedAllergies : []
              }
              onChange={selectedAllergies =>
                this.setState({ selectedAllergies })
              }
              fullObject
              admin={this.props.admin}
            />
          ) : (
            <p>Allergier må kombineres med salat/vegetar-menyene våre.</p>
          )}

          <Separator />
          {this.state.error ? <Alert color="red">Noe skjedde</Alert> : ''}
          {allergiesSupported && (
            <Button
              onClick={this.onSaveAllergiesClicked.bind(this)}
              saving={this.state.saving}
              success={this.state.success}
            >
              Lagre
            </Button>
          )}
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  allAllergies: selectAllergies(state, ownProps.admin),
  allergies: selectUserAllergies(state, ownProps.userId),
  subscription: selectCustomerSubscription(state, ownProps.customerId),
  subscriptionMeta: selectCustomerSubscriptionMeta(state, ownProps.customerId),
});

export default connect(mapStateToProps, {
  updateAllergies,
  getSubscriptionForCustomer,
})(Allergies);
