import React from 'react';
import styles from './lunchBoxOverview.module.css';
import NumberPicker from '../../../NumberPicker';
import Link from '../../../Link';

export default class NumberPickerContainer extends React.Component {
  state = {
    orderMore: true,
  };
  render() {
    if (this.state.orderMore || this.props.amount > 1) {
      return (
        <div className={styles.numberPicker}>
          <NumberPicker
            value={this.props.amount}
            onChange={number => this.props.onChange(parseInt(number, 10))}
          />
        </div>
      );
    }

    return (
      <div className={styles.numberPicker}>
        <Link onClick={() => this.setState({ orderMore: true })}>
          Bestill flere
        </Link>
      </div>
    );
  }
}
