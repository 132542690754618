import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../Card';
import styles from './settingCard.module.css';

export default props =>
  props.to ? (
    <Link to={props.to}>
      <Card
        link
        header={props.header}
        className={styles.card}
        style={props.warning ? { border: '3px solid red' } : {}}
      >
        <div className={styles.settingCard}>
          <div>{props.children}</div>
          <div className={styles.linkColumn}>
            <div className={styles.link}>{props.linkText}</div>
          </div>
        </div>
      </Card>
    </Link>
  ) : (
    <Card header={props.header}>
      <div className={styles.settingCard}>
        <div>{props.children}</div>
      </div>
    </Card>
  );
