import React from 'react';
import BlockRenderer from '../../../components/BlockRenderer';
import Spinner from '../../../components/Spinner';
import Container from '../../../components/Container';
import Header from '../../../components/Header/Header';
import PageNotFound from '../../../components/PageNotFound';
import HeaderCampaign from './components/HeaderCampaign';
import { buildImageUrl } from '../../../utils/sanity';

import styles from './sentryPages.module.css';

export default props => {
  if (props.loading) {
    return (
      <div style={{ height: '70px', marginTop: '30px' }}>
        <Spinner black />
      </div>
    );
  }

  if (!props.webpageData) {
    return <PageNotFound />;
  }

  return (
    <div>
      {props.webpageData.body.map((wpd, index) => {
        switch (wpd._type) {
          case 'headerImage':
            return (
              <Header
                key={index}
                image={buildImageUrl(wpd.image)}
                height={wpd.imageHeight}
                darken
              >
                <BlockRenderer content={wpd.headerBody} />
              </Header>
            );
          case 'headerCampaign':
            return (
              <HeaderCampaign
                key={index}
                image={wpd.image}
                body={wpd.headerBody}
                usps={wpd.usps}
              />
            );
          default:
            return (
              <Container
                key={index}
                width={wpd.width ? wpd.width : 900}
                color={wpd.color}
                style={{
                  padding:
                    wpd.padding === 'thin'
                      ? '10px 0'
                      : wpd.padding === 'bottom'
                        ? '0 0 50px 0'
                        : '50px 0',
                }}
                className="text-center"
              >
                <div className={styles.container}>
                  <BlockRenderer content={wpd.body} />
                </div>
              </Container>
            );
        }
      })}
    </div>
  );
};
