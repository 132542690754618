import React from 'react';
import { connect } from 'react-redux';
import Button from '../../Button';
import DatePicker from '../../DatePicker';
import Alert from '../../Alert';
import OrderPicker from './OrderPicker';
import firstDate from '../../../utils/firstDate';
import formatPrice from '../../../utils/formatPrice';
import { getSumForDaySingleOrder } from '../../MyLunchCalendar';

import { getAllIfNeeded as getAllSubscriptionTypesIfNeeded } from '../../../stores/subscriptionType/actions';
import selectAllSubscriptionTypes from '../../../selectors/subscriptionType/selectAll';
import Link from '../../Link';

class Motemat extends React.Component {
  state = {
    datePickerFocused: false,
  };

  componentDidMount() {
    this.props.getAllSubscriptionTypesIfNeeded();
  }

  getTotal() {
    return getSumForDaySingleOrder(
      null,
      this.props.subscriptionTypes,
      this.props.companyAgreement,
      this.props.orders
    );
  }

  render() {
    return (
      <div>
        <br />
        <h3>Velg dato</h3>
        <DatePicker
          required
          placeholder="Dato"
          date={this.props.date}
          isOutsideRange={day => {
            return (
              firstDate(this.props.admin ? 0 : 1).isSameOrAfter(day) ||
              [5, 6].indexOf(day.weekday()) !== -1
            );
          }}
          onDateChange={date =>
            this.props.onUpdate({
              date,
            })
          }
          focused={this.state.datePickerFocused}
          onFocusChange={({ focused: datePickerFocused }) =>
            this.setState({ datePickerFocused })
          }
        />
        <br />
        <OrderPicker
          date={this.props.date}
          orders={this.props.orders}
          onChange={state => this.props.onUpdate(state)}
          subscriptionTypes={this.props.subscriptionTypes}
          kitchen={
            this.props.companyAgreement
              ? this.props.companyAgreement.deliveryLocation.address.postalCode
                  .kitchenId
              : ''
          }
          admin={this.props.admin}
          extraModalOpened={this.props.extraModalOpened}
          onExtraModalChange={this.props.onExtraModalChange}
          disableAllergies
        />
        <br />
        {this.props.withSummary ? (
          <div>
            <div>
              <div>
                <strong>Total</strong>
              </div>
              <div>{formatPrice(this.getTotal())}</div>
            </div>
            <div className="mt-5">
              <div>
                <strong>Leveringsadresse</strong>
              </div>
              <div>
                {this.props.companyAgreement.deliveryLocation.name},{' '}
                {
                  this.props.companyAgreement.deliveryLocation.address
                    .addressLine
                }
                ,{' '}
                {
                  this.props.companyAgreement.deliveryLocation.address
                    .postalCode.code
                }{' '}
                {
                  this.props.companyAgreement.deliveryLocation.address
                    .postalCode.place
                }
              </div>
            </div>
            <div className="mt-5 d-flex">
              <Button
                onClick={() => this.props.onSave()}
                saving={this.props.saving}
                success={this.props.success}
              >
                {this.props.meetingFoodOrderId ? 'Oppdater' : 'Bestill'}
              </Button>
              {this.props.meetingFoodOrderId ? (
                <div
                  style={{ marginLeft: 10 }}
                  className="d-flex flex-col-center"
                >
                  <Link onClick={() => this.props.onDeleteOrder()}>
                    Avbestill
                  </Link>
                </div>
              ) : null}
            </div>
            {this.props.error ? (
              <div className="mt-5">
                <Alert color="red">Noe skjedde</Alert>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

Motemat.defaultProps = {
  withSummary: true,
  withContainer: true,
};

export default connect(
  state => ({
    subscriptionTypes: selectAllSubscriptionTypes(state),
  }),
  {
    getAllSubscriptionTypesIfNeeded,
  }
)(Motemat);
