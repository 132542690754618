import addPrefix from '../../utils/addPrefix';

export default addPrefix('me', [
  'GET_ME_START',
  'GET_ME_SUCCESS',
  'GET_ME_ERROR',
  'SET_COMPANY_AGREEMENT',
  'GET_MY_KITCHENS_START',
  'GET_MY_KITCHENS_SUCCESS',
  'GET_MY_KITCHENS_ERROR',
  'SET_SELECTED_KITCHEN',
  'LOGOUT',
  'UPDATE_ALLERGIES_SUCCESS',
  'UPDATE_MY_IDENTITY_SUCCESS',
  'UPDATE_MY_CUSTOMER_DELIVERY_ADDRESS_SUCCESS',
  'UPDATE_NOTIFICATION_SETTINGS_SUCCESS',
  'DELETE_CARD_SUCCESS',
  'ADD_CARD_SUCCESS',
  'UPDATE_IDENTITY',
  'SET_CONTEXT',
]);
