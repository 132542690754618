import fetch from '../../utils/fetch';
import shouldFetchLatestCustomers from '../../selectors/customer/shouldFetchLatestCustomers';
import shouldFetch from '../../selectors/customer/shouldFetch';
import shouldFetchCustomer from '../../selectors/customer/shouldFetchCustomer';

import types from './types';

export const getLatestIfNeeded = count => (dispatch, getState) => {
  if (!shouldFetchLatestCustomers(getState(), count)) return;

  dispatch({
    type: types.GET_LATEST_START,
    payload: {
      count: count,
    },
  });

  return fetch('/customers', 'GET', {
    latest: count,
  })
    .then(response => {
      dispatch({
        type: types.GET_LATEST_SUCCESS,
        payload: {
          count,
          entities: response.data.reduce((customers, customer) => {
            customers[customer.id] = customer;
            return customers;
          }, {}),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_LATEST_ERROR,
        payload: {
          count,
          error,
        },
      });
    });
};

export const getIfNeeded = (search = '') => (dispatch, getState) => {
  if (!shouldFetch(getState(), search)) return;

  dispatch({
    type: types.GET_START,
    payload: {
      search,
    },
  });

  return fetch('/customers', 'GET', {
    q: search,
  })
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          search,
          entities: response.data.reduce((customers, customer) => {
            customers[customer.id] = customer;
            return customers;
          }, {}),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          search,
          error,
        },
      });
    });
};

export const activateAdmin = customer => dispatch => {
  return fetch(`/customers/${customer.id}/activate`, 'POST')
    .then(response => {
      dispatch({
        type: types.GET_ONE_SUCCESS,
        payload: { ...customer, identity: response.data },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ONE_ERROR,
        payload: {
          id: customer.id,
        },
      });
    });
};

export const getOneIfNeeded = id => (dispatch, getState) => {
  if (!shouldFetchCustomer(getState(), id)) return;

  dispatch({
    type: types.GET_ONE_START,
    payload: {
      id,
    },
  });

  return fetch('/customers/' + id, 'GET')
    .then(response => {
      dispatch({
        type: types.GET_ONE_SUCCESS,
        payload: response.data,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ONE_ERROR,
        payload: {
          id,
        },
      });
    });
};

export const deleteCustomer = (id, search) => dispatch => {
  dispatch({
    type: types.DELETE_START,
    payload: {
      id,
    },
  });

  return fetch('/customers/' + id, 'DELETE')
    .then(response => {
      dispatch({
        type: types.DELETE_SUCCESS,
        payload: {
          id: response.data.result[0],
          search
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.DELETE_ERROR,
        payload: {
          id,
        },
      });
    });
};

export const updateCustomerCompanyAgreement = (
  customerId,
  customer
) => dispatch =>
    fetch(
      '/customers/' + customerId + '/company-agreement',
      'POST',
      customer
    ).then(response => {
      dispatch({
        type: types.UPDATE_CUSTOMER_SUCCESS,
        payload: response.data,
      });
});

export const removeCustomerFromCompanyAgreement = (
  customerId,
  companyAgreementId
) => dispatch =>
fetch(
  '/customers/' + customerId + '/company-agreement/' + companyAgreementId + '/remove',
  'POST'
).then(response => {
  dispatch({
    type: types.UPDATE_CUSTOMER_SUCCESS,
    payload: response.data,
  });
});

export const updateCustomerDeliveryLocation = (customerId, data) => dispatch =>
  fetch('/customers/' + customerId + '/delivery-location', 'POST', data).then(
    response => {
      dispatch({
        type: types.UPDATE_CUSTOMER_SUCCESS,
        payload: response.data,
      });
    }
  );

