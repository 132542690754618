import React from 'react';
import { connect } from 'react-redux';
import Button from '../../../../../../../components/Button';
import { Input } from '../../../../../../../components/Form';
import Alert from '../../../../../../../components/Alert';
import validateEmail from '../../../../../../../utils/validateEmail';
import fetch from '../../../../../../../utils/fetch';

import styles from './newCustomerForm.module.css';

/**
 * Me Store functions
 */
import { selectedCompanyAgreement } from '../../../../../../../stores/me/selectors';

class NewCustomerForm extends React.Component {
  state = { email: '', valid: false };

  add = () => {
    this.setState({ saving: true, error: false, success: false });

    fetch(
      `/company-agreements/${this.props.companyAgreement.id}/invite/${this.state.email}`,
      'POST'
    )
      .then(() => {
        this.setState({ saving: false, success: this.state.email, email: '' });
      })
      .catch(() => {
        this.setState({ saving: false, error: true });
      });
  };

  render() {
    return (
      <div>
        <div className={styles.form}>
          <Input
            value={this.state.email}
            onChange={(email, valid) => this.setState({ email, valid })}
            placeholder="E-post"
            validate={validateEmail}
            errorMessage="E-postadressen er ikke gyldig"
          />
          <div>
            <Button
              logo="plus"
              disabled={!this.state.valid}
              onClick={this.add}
              saving={this.state.saving}
            >
              Legg til
            </Button>
          </div>
        </div>
        <div className={styles.feedback}>
          {this.state.success ? (
            <Alert color="green">
              E-post sendt til {this.state.success}. Når brukeren er ferdig
              registrert, vil den havne her.
            </Alert>
          ) : null}
          {this.state.error ? (
            <Alert color="red">
              Noe skjedde når vi prøvde å invitere denne brukeren.
            </Alert>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect((state, ownProps) => ({
  companyAgreement: ownProps.companyAgreement || selectedCompanyAgreement(state),
}))(NewCustomerForm);
