import fetch from '../../utils/fetch';
import shouldFetch from '../../selectors/companyAgreement/shouldFetch';
import shouldFetchCustomers from '../../selectors/companyAgreement/shouldFetchCustomers';
import getMetaKey from '../../selectors/companyAgreement/metaKey';

import types from './types';

export const getIfNeeded = (companyId, search = '') => (dispatch, getState) => {
  const metaKey = getMetaKey(companyId, search);
  if (!shouldFetch(getState(), companyId, search)) return;

  dispatch({
    type: types.GET_START,
    payload: {
      metaKey,
    },
  });

  return fetch(`/admin/companies/${companyId}/companyagreements`, 'GET')
    .then(response => {
      dispatch({
        type: types.GET_SUCCESS,
        payload: {
          metaKey,
          entities: response.data.reduce(
            (companyAgreements, companyAgreement) => {
              companyAgreements[companyAgreement.id] = {
                ...companyAgreement,
                _id: companyAgreement.id,
              };
              return companyAgreements;
            },
            {}
          ),
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_ERROR,
        payload: {
          metaKey,
          error,
        },
      });
    });
};

export const getCustomersIfNeeded = companyAgreementId => (
  dispatch,
  getState
) => {
  if (!shouldFetchCustomers(getState(), companyAgreementId)) return;

  dispatch({
    type: types.GET_CUSTOMERS_START,
    payload: {
      companyAgreementId,
    },
  });

  return fetch(`/companyAgreements/${companyAgreementId}/customers`, 'GET')
    .then(response => {
      dispatch({
        type: types.GET_CUSTOMERS_SUCCESS,
        payload: {
          companyAgreementId,
          customers: response.data,
        },
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: types.GET_CUSTOMERS_ERROR,
        payload: {
          companyAgreementId,
          error,
        },
      });
    });
};

export const updateCompanyAgreement = (
  companyAgreementId,
  data
) => dispatch => {
  return fetch('/company-agreements/' + companyAgreementId, 'POST', data).then(
    response => {
      dispatch({
        type: types.UPDATE_COMPANY_AGREEMENT,
        payload: {
          companyAgreement: response.data,
        },
      });
    }
  );
};

export const updateCompanyAgreementPrices = (
  companyAgreementId,
  data
) => dispatch => {
  return fetch(
    '/company-agreements/' + companyAgreementId + '/prices',
    'POST',
    data
  ).then(response => {
    dispatch({
      type: types.UPDATE_COMPANY_AGREEMENT,
      payload: {
        companyAgreement: response.data,
      },
    });
  });
};

export const updateCompanyAgreementAccountManager = (
  companyAgreementId,
  data
) => dispatch => {
  return fetch(
    '/company-agreements/' + companyAgreementId + '/account-manager',
    'POST',
    data
  ).then(response => {
    dispatch({
      type: types.UPDATE_COMPANY_AGREEMENT,
      payload: {
        companyAgreement: response.data,
      },
    });
  });
};

export const updateCompanyAgreementDeliveryLocation = (
  companyAgreementId,
  data
) => dispatch =>
    fetch(
      '/company-agreements/' + companyAgreementId + '/delivery-location',
      'POST',
      data
    ).then(response => {
      dispatch({
        type: types.UPDATE_COMPANY_AGREEMENT,
        payload: {
          companyAgreement: response.data,
        },
      });
    });

export const updateCompanyAgreementDeliveryCost = (
  companyAgreementId,
  data
) => dispatch => {
  fetch(
    '/company-agreements/' + companyAgreementId + '/delivery-cost',
    'POST',
    data
  ).then(response => {
    dispatch({
      type: types.UPDATE_COMPANY_AGREEMENT,
      payload: {
        companyAgreement: response.data,
      },
    });
  });
};

export const getCompanyAgreementNotes = (
  companyAgreementId,
) => dispatch => {
  return fetch(
    '/company-agreements/' + companyAgreementId + '/additional-notes/admin', 'GET'
  ).then(response => {
    dispatch({
      type: types.GET_COMPANY_AGREEMENT_NOTES,
      payload: {
        companyAgreementId,
        notes: response.data,
      },
    });
  });
};

export const addCompanyAgreementNote = (
  companyAgreementId,
  data
) => dispatch => {
  return fetch(
    '/company-agreements/' + companyAgreementId + '/additional-notes/admin',
    'POST',
    data
  ).then(response => {
    dispatch({
      type: types.UPDATE_COMPANY_AGREEMENT_NOTES,
      payload: {
        companyAgreementId,
        notes: response.data,
      },
    });
  });
};
