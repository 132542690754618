import special4ServiceTypeAliases from '../constants/special4ServiceTypeAliases';

const isSubscriptionTypeEnableForCurrentTypeUser = typeUser => type => {
  switch (typeUser) {
    case 'customer':
      return type.forCustomers;
    case 'company':
      return type.forCompanies;
    case 'meeting':
      return type.forMeetingFood;
    case 'homeoffice':
      return type.forHomeDelivery;
    default:
      return false;
  }
};

const isEnabledForDay = day => type => {
  if (!day) return type.days.length >= 5;

  return !!type.days.find(typeDay => typeDay === day);
};

const isEnabledForKitchen = kitchenId => type => {
  if (!type.kitchens || type.kitchens.length === 0) return true;
  if (!kitchenId) {
    return false;
  }

  return !!type.kitchens.find(k => k.id === kitchenId);
};

const isSubscriptionTypeEnabledForCompanyAgreement = typesFromCompanyAgreement => type => {
  if (type.limited === false) {
    return true;
  }
  if (!typesFromCompanyAgreement) {
    return false;
  }

  const typeFromCA = typesFromCompanyAgreement.find(t => {
    if (t.length > 0) {
      return t[0].id === type.id;
    } else {
      return t.id === type.id;
    }
  });

  return !!typeFromCA;
};

const companyAgreementSpecialSubscriptionTypes = typesFromCompanyAgreement => type => {
  if (!typesFromCompanyAgreement) {
    return true;
  }

  if (
    typesFromCompanyAgreement.some(t =>
      special4ServiceTypeAliases.includes(t.alias)
    )
  ) {
    return special4ServiceTypeAliases.includes(type.alias);
  }

  return true;
};

const getEnabledSubscriptionTypes = (
  subscriptionTypes,
  companyAgreement,
  typeUser,
  day = null,
  kitchenId,
  typesFromCompanyAgreement = null
) => {
  return (
    subscriptionTypes
      .filter(
        isSubscriptionTypeEnabledForCompanyAgreement(typesFromCompanyAgreement)
      )
      .filter(isSubscriptionTypeEnableForCurrentTypeUser(typeUser))
      .filter(isEnabledForDay(day))
      //Filtering is performed on back-end
      .filter(isEnabledForKitchen(kitchenId))
      .filter(st => st.active)
      .filter(type => !type.forStore)
      //Temporary hack for special warm-food types
      .filter(
        companyAgreementSpecialSubscriptionTypes(typesFromCompanyAgreement)
      )
  );
};

const getActiveSubscriptionTypes = (subscriptionTypes, day) =>
  subscriptionTypes.filter(st => st.active).filter(isEnabledForDay(day));

export default {
  getEnabledSubscriptionTypes,
  getActiveSubscriptionTypes,
  getNameFromAlias: (subscriptionTypes, alias) => {
    const subscriptionType = subscriptionTypes.find(st => st.alias === alias);
    return subscriptionType ? subscriptionType.name : alias;
  },
};
