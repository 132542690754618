import React from 'react';
import { SingleDatePicker } from 'react-dates';
import cn from 'classnames';

import './datePicker.css';

export default ({ label, ...props }) => {
  return (
    <div className={cn("date-picker", {"date-picker-colored": props.colored})}>
      <div>{label}</div>
      <SingleDatePicker
        {...props}
        verticalHeight={350}
        orientation={window.innerWidth < 800 ? 'vertical' : 'horizontal'}
      />
    </div>
  );
};
