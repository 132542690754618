import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectCompanyAgreement } from '../../../stores/companyAgreement/selectors';
import { isDeliveryLocationInvalid } from '../../../utils/deliveryLocation';
import Card from '../../SettingCard';

const DeliveryAddressCard = props => {
  return (
    <Card
      header="Leveringsadresse"
      to={props.to ? props.to : props.location.pathname + '/leveringsadresse'}
      linkText="Endre"
    >
      {!isDeliveryLocationInvalid(props.companyAgreement.deliveryLocation) ? (
        <div>
          <div>
            {props.companyAgreement.deliveryLocation.address.addressLine}
          </div>
          <div>
            {props.companyAgreement.deliveryLocation.address.postalCode.code},{' '}
            {props.companyAgreement.deliveryLocation.address.postalCode.place}
          </div>
          <div>
            Bedriften betaler kr{' '}
            {props.companyAgreement.eligibleForDeliveryCost ? '120' : '0'},- i
            frakt. <em> *pris eks. 15% mva</em>
          </div>
          {props.companyAgreement.deliveryLocation.deliveryNote ? (
            <div style={{ width: '80%' }}>
              <strong>Ekstra info: </strong>
              {props.companyAgreement.deliveryLocation.deliveryNote}
            </div>
          ) : null}
        </div>
      ) : (
        <div>Ingen</div>
      )}
    </Card>
  );
};

export default withRouter(
  connect((state, ownProps) => {
    return {
      companyAgreement: selectCompanyAgreement(
        state,
        ownProps.companyAgreementId
      ),
    };
  })(DeliveryAddressCard)
);
