import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="#3c4249"
      d="M16.2 13.6C15 14.5 13.6 15 12 15 10.4 15 9 14.5 7.8 13.6 7.8 13.6 7.7 13.7 7.7 13.7 6.1 15.3 5.1 17.3 5 19.5 6.8 20.5 9.1 21 12 21 14.9 21 17.2 20.5 19 19.5 18.9 17.3 17.9 15.3 16.3 13.7 16.3 13.7 16.2 13.6 16.2 13.6ZM17.6 12.2C17.6 12.2 17.7 12.3 17.7 12.3 19.8 14.4 21 17 21 20 21 20 21 20.3 20.9 20.5 20.7 20.7 20.6 20.8 20.6 20.8 18.4 22.3 15.6 23 12 23 8.5 23 5.6 22.3 3.4 20.8 3.2 20.6 3 20.3 3 20 3 17 4.2 14.4 6.3 12.3 6.3 12.3 6.4 12.2 6.4 12.2 5.5 11 5 9.6 5 8 5 4.1 8.1 1 12 1 15.9 1 19 4.1 19 8L18 8 19 8C19 9.6 18.5 11 17.6 12.2ZM18 8L17 8C17 5.2 14.8 3 12 3 9.2 3 7 5.2 7 8 7 10.8 9.2 13 12 13 14.8 13 17 10.8 17 8L18 8Z"
    />
  </svg>
);
