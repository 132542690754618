import moment from 'moment';

export default (days = 1) => {
  const limit = moment()
    .hours(11)
    .minutes(0)
    .seconds(0);

  if (moment().isBefore(limit)) {
    limit.add(days, 'day');
  } else {
    limit.add(days + 1, 'day');
  }

  if ([5, 6].indexOf(limit.weekday()) !== -1) {
    limit.add(1, 'week').startOf('week');
  }

  return limit;
};
