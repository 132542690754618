import selectMeta from '../../selectors/selectMeta';

export const getKey = (customerId, homeOfficeId = null) =>
  `home-office-${customerId}${
    homeOfficeId !== null ? `-${homeOfficeId}` : ''
  }`;

export const selectForCustomerMeta = (state, customerId) =>
  selectMeta(state.homeOfficeOrder.meta[getKey(customerId)]);

export const selectForCustomer = (state, customerId) =>
  selectForCustomerMeta(state, customerId).ids.map(
    id => state.homeOfficeOrder.entities[id]
  );
