import React from 'react';
import cn from 'classnames';

import Container from '../../../../components/Container';

import styles from './signupSteps.module.css';

export default class SignupSteps extends React.Component {
  componentDidMount() {
    this.logStep(this.props.step);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step !== this.props.step) {
      this.logStep(this.props.step);
    }
  }

  onNext = () => {
    const amount = React.Children.count(this.props.children);

    if (amount - 1 === this.props.step) {
      this.props.onDone();
    } else {
      const step = this.props.step + 1;
      this.props.onStep(step, () => this.logStep(step));
      window.scrollTo(0, 0);
    }
  };

  logStep = step => {
    if (this.props.signupType) {
      if (step + 1 === 2) {
      }

      window.dataLayer.push({
        event: 'EEcheckout',
        ecommerce: {
          checkout: {
            actionField: {
              step,
            },
          },
        },
      });
    }
  };

  render() {
    return (
      <div className={styles.stepBody}>
        <div className={styles.header}>
          <div className={styles.arrowWrapper}>
            {!this.props.focusSingleStep && this.props.step > 0 && (
              <div style={{ display: 'flex' }} onClick={this.props.onBack}>
                <div className={cn('fa', 'fa-arrow-left', styles.back)} />
                <div className="mt-2">Tilbake</div>
              </div>
            )}
            <div style={{ display: 'flex' }} onClick={this.props.onCancel}>
              <div className="mt-2">Avbryt</div>
              <div className={cn(styles.close)} />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            {!this.props.focusSingleStep &&
              this.props.children.map((c, i) => (
                <div
                  onClick={() => this.props.step >= i && this.props.onStep(i)}
                  className={cn(styles.stepLine, {
                    [styles.onStep]: this.props.step >= i,
                  })}
                />
              ))}
          </div>
        </div>
        <div>
          {React.Children.map(this.props.children, (child, index) => {
            if (this.props.step !== index) {
              return <span />;
            }
            return (
              <Container width={900}>
                <div className={styles.fadein}>
                  {React.cloneElement(child, {
                    ...this.props.values,
                    onChange: this.props.onChange,
                    checkExisting: this.props.checkExisting,
                    focused: this.props.step === index,
                    index: index + 1,
                    onNext: this.onNext,
                  })}
                </div>
              </Container>
            );
          })}
        </div>
      </div>
    );
  }
}
