import React from 'react';
import { connect } from 'react-redux';

import { selectedCompanyAgreement } from '../../../../../../stores/me/selectors';
import selectCustomers from '../../../../../../selectors/companyAgreement/selectCustomers';

import {
  selectCompanyAgreementCustomerSubscriptions,
  selectCompanyAgreementCustomerSubscriptionsMeta,
} from '../../../../../../stores/subscription/selectors';

import {
  getCompanyAgreementCustomersWithSubscriptionsIfNeeded as getCustomersAndSubscriptions,
  deactivateCustomerRangeSubscriptions,
  createCustomerRangeSubscriptions
} from '../../../../../../stores/subscription/actions';

import { removeCustomerFromCompanyAgreement } from '../../../../../../stores/customer/actions';

import {
  cancelCustomerChoicesRange,
  reverseCancelCustomerChoicesRange,
} from '../../../../../../stores/choice/actions';

import { selectCompanyAgreement } from '../../../../../../stores/companyAgreement/selectors';
import { updateCompanyAgreement } from '../../../../../../stores/companyAgreement/actions';

class CompanyAdmin extends React.Component {
  componentDidMount() {
    this.fetchCustomers(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.fetchCustomers(nextProps);
  }

  fetchCustomers(props) {
    if (props.withCustomers && props.companyAgreement) {
      props.getCustomersAndSubscriptions(props.companyAgreement.id);
    }
  }

  render() {
    if (!this.props.companyAgreement) return null;

    return (
      <this.props.children
        {...this.props}
        error={this.props.meta.error}
        loading={this.props.meta.loading && !this.props.meta.saving}
      />
    );
  }
}

export default connect(
  (state, ownProps) => {
    const companyAgreement = ownProps.companyAgreementId
      ? selectCompanyAgreement(state, ownProps.companyAgreementId)
      : selectedCompanyAgreement(state);
    const customers = companyAgreement
      ? selectCustomers(state, companyAgreement.id)
      : [];
    return {
      companyAgreement,
      customers,
      subscriptions: selectCompanyAgreementCustomerSubscriptions(
        state,
        customers
      ),
      meta: selectCompanyAgreementCustomerSubscriptionsMeta(
        state,
        companyAgreement.id
      ),
    };
  },
  {
    getCustomersAndSubscriptions,
    createCustomerRangeSubscriptions,
    deactivateCustomerRangeSubscriptions,
    cancelCustomerChoicesRange,
    reverseCancelCustomerChoicesRange,
    removeCustomerFromCompanyAgreement,
    updateCompanyAgreement,
  }
)(CompanyAdmin);
