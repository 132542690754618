import React from 'react';
import Container from '../../../../components/Container';
import MinSideTitle from '../../../../components/MinSideTitle';
import Subscription from '../../../../components/CustomerSettings/Subscription';
import styles from './subscription.module.css';

export default ({ customerId, userId, onDone }) => (
  <div>
    <MinSideTitle showTabs />
    <div className={styles.background}>
      <Container width={870} withPadding>
        <Subscription customerId={customerId} userId={userId} onDone={onDone} />
        <img
          src={'/images/logo_white.png'}
          alt="LunsjKollektivet"
          draggable="false"
          className={styles.image}
        ></img>
      </Container>
    </div>
  </div>
);
